<template>
  <v-container class="py-8">
    <template v-if="loaded">
      <DashboardAgreements />

      <DashboardV2
        v-if="v2"
        :group="group"
        :group-schema="groupSchema"
        data-testid="dashboard-v2"
      />

      <DashboardV1
        v-else
        :group="group"
        :group-schema="groupSchema"
        data-testid="dashboard-v1"
      />

      <DecisionDialog
        @confirm="goToSurvey"
        ref="appSubmissionSurveyDialog"
        :content="surveySchema?.description"
        cancel-button-text="Skip"
        confirm-button-text="Continue"
        title="Application successfully submitted"
      />
    </template>
  </v-container>
</template>

<script setup>
import api from '@/shared/services/bright_finder';
import DashboardAgreements from '@/parent/components/dashboards/DashboardAgreements.vue';
import DashboardV1 from '@/parent/components/dashboards/DashboardV1.vue';
import DashboardV2 from '@/parent/components/dashboards/DashboardV2.vue';
import DecisionDialog from '@/shared/components/DecisionDialog.vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

const route = useRoute();
const store = useStore();

const group = ref(null);
const groupSchema = ref(null);
const loaded = ref(false);
const surveySchema = ref(null);
const appSubmissionSurveyDialog = ref(null);

const emit = defineEmits(['replace']);

const v2 = computed(() => store.state.config.enable_parent_dashboard_v2);

async function followPendingRedirect() {
  const pendingRedirect = JSON.parse(window.localStorage.redirect);
  window.localStorage.removeItem('redirect');
  emit('replace', pendingRedirect);
}

async function goToSurvey() {
  emit('replace', {
    name: 'AnonymousSurveyShow',
    params: { schemaId: surveySchema.value.id },
  });
}

async function load() {
  await loadGroup();
  loaded.value = true;
}

async function loadGroup() {
  const resp = await api.group.get();
  group.value = resp.data;
  groupSchema.value = store.state.schemas[group.value.schema_id];
}

async function loadSurveySchema() {
  const resp = await api.public_api.organization.schema.get(route.query.submittedSurveySchemaId);

  if (!resp) return;
  surveySchema.value = resp.data;
  appSubmissionSurveyDialog.value.open();
}

async function redirectMember() {
  if (store.state.pages.Search.enabled && store.state.profile.search_id) {
    emit('replace', { name: 'SearchShow', params: { searchId: store.state.profile.search_id } });
  } else {
    emit('replace', { name: 'Landing' });
  }
}

async function redirectOrLoad() {
  if (!store.state.pages.Dashboard.enabled) return redirectMember();
  if (window.localStorage.redirect) return followPendingRedirect();

  if (store.state.is_anonymous) {
    store.dispatch('identify', {
      async success() {
        await load();
      },
      failure() {
        emit('replace', { name: 'Signup' });
      },
    });
  }

  await load();
}

onMounted(redirectOrLoad);

watchEffect(async () => {
  if (appSubmissionSurveyDialog.value) {
    if (route.query.submittedSurveySchemaId) {
      await loadSurveySchema();
    }
  }
});
</script>
