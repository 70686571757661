<template>
  <div v-if="provider">
    <v-card
      class="pa-0 bg-primary"
      border
      flat
    >
      <v-card-text>
        <div class="d-flex align-start">
          <div class="pr-2">
            <v-icon class="c-white"> info </v-icon>
          </div>
          <div class="">
            <h4 class="pb-3 c-white">
              We’ve identified that you accessed this application using a unique link provided by a
              preschool provider.
            </h4>
            <div class="fs-16 fw-normal c-white">
              The preschool provider sent you this link because you either have a current child
              already enrolled at this location, or a family member works at this location. If you’d
              like to be prioritized at this location, please confirm that this is true. This
              location will automatically become selected and added to your application as your
              first choice.
            </div>
          </div>
        </div>

        <div class="d-flex w-full justify-center pt-4">
          <v-btn
            v-if="providerProgram"
            @click="handleConfirmProgram"
            class="focus-very-visible"
            color="white"
            size="x-large"
          >
            <span v-t="'Confirm'" />
          </v-btn>
        </div>
      </v-card-text>

      <v-card class="mx-3 my-3 partial-opacity">
        <v-card-text>
          <v-row class="d-flex align-start">
            <v-col>
              <div class="c-black">
                <v-row
                  class="ma-0 pa-0 d-flex align-center"
                  dense
                >
                  <v-col cols="11">
                    <h4
                      v-t="provider.name"
                      class="c-black fw-600 fs-20"
                      data-cy="selection-name"
                      tabindex="0"
                      dense
                    />
                    <div
                      v-if="provider.license_business_name != provider.name"
                      class="fs-16"
                    >
                      {{ provider.license_business_name }}
                    </div>
                  </v-col>
                </v-row>
                <v-row
                  class="ma-0 pa-0"
                  dense
                >
                  <v-col>
                    <span
                      class="fs-16 fw-400 me-1"
                      tabindex="0"
                    >
                      {{ [provider.address1, provider.city, provider.zip].join(', ') }}
                    </span>
                    <span
                      v-if="provider.distance_from_origin"
                      class="fs-16 fw-400"
                      >- {{ provider.distance_from_origin }} {{ $t('miles away') }}
                    </span>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
          <template v-if="provider.public_notice">
            <v-row class="bg-primary mb-0 c-white">
              <v-col>
                <span
                  v-t="provider.public_notice"
                  class="fs-14 px-8"
                />
              </v-col>
            </v-row>
          </template>
        </v-card-text>
        <template v-if="!hideViewMore">
          <v-card-actions class="bt-1 pt-2 pb-2 px-4 bc-extra-light-grey pointer">
            <v-btn
              @click="$emit('open')"
              class="focus-very-visible"
              variant="text"
              block
            >
              <v-row class="d-flex align-center">
                <v-col class="d-flex justify-start">
                  <div
                    v-t="'View more information'"
                    class="fs-14 fw-400"
                    data-cy="match-view-more"
                  />
                </v-col>
                <v-col class="d-flex justify-end">
                  <v-icon class="fs-22 fw-500"> chevron_right </v-icon>
                </v-col>
              </v-row>
            </v-btn>
          </v-card-actions>
        </template>
      </v-card>
    </v-card>
  </div>
</template>

<script>
export default {
  compatConfig: { MODE: 2 },

  props: {
    hideViewMore: Boolean,
    provider: {
      type: Object,
      default: null,
    },
  },

  emits: ['confirm', 'open'],

  computed: {
    providerProgram() {
      return this.provider?.programs[0];
    },
  },

  methods: {
    handleConfirmProgram() {
      return this.$emit('confirm', this.providerProgram.id);
    },
  },
};
</script>

<style>
.partial-opacity {
  opacity: 0.8;
}
</style>
