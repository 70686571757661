<template>
  <v-row class="mb-4">
    <v-col
      class="d-flex align-center justify-start"
      cols="12"
      sm="9"
    >
      <h1
        ref="title"
        class="focus-invisible fs-36 fw-600 me-4"
        tabindex="0"
      >
        {{ $t('Hi') }}, {{ firstName }}!
      </h1>
    </v-col>
    <v-col
      v-if="!hubExperience"
      class="d-flex justify-end"
      cols="12"
      sm="3"
    >
      <v-btn
        v-if="addChild && !v2"
        @click="emit('add-child')"
        class="focus-very-visible"
        color="primary"
        data-cy="add-child-button"
        size="x-large"
        rounded
      >
        <v-icon start> add </v-icon>
        <span>{{ $t('Add child') }}</span>
      </v-btn>
      <v-btn
        v-if="addChild && v2"
        @click="emit('add-application')"
        class="focus-very-visible"
        color="primary"
        size="x-large"
        rounded
      >
        <v-icon start> add </v-icon>
        <span>{{ $t('New application') }}</span>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

defineProps({
  addChild: {
    type: Boolean,
    default: false,
  },
  firstName: {
    type: String,
    default: null,
  },
  hubExperience: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['add-application', 'add-child']);
const store = useStore();

const v2 = computed(() => store.state.config.enable_parent_dashboard_v2);
</script>
