<template>
  <div>
    <v-row class="bg-extra-light-grey px-6 px-md-12 px-lg-12 pt-4 pb-2">
      <v-col
        cols="6"
        md="3"
      >
        <div
          v-t="'Support'"
          class="fs-14 fw-600 mb-2"
          style="text-transform: uppercase"
        />
        <div
          id="report_link"
          class="fs-14 mb-2 hover-underlined"
          tracked
        >
          <a
            v-t="'Report an issue'"
            :href="supportURL"
            target="_blank"
          />
        </div>
        <div
          id="submit_feedback_link"
          class="fs-14 mb-2 hover-underlined"
          tracked
        >
          <a
            v-t="'Submit feedback'"
            :href="feedbackURL"
            target="_blank"
          />
        </div>
        <div
          id="support_contact_link"
          class="fs-14 mb-2 hover-underlined"
          tracked
        >
          <a
            v-t="'Contact us'"
            :href="
              'mailto:' + ($store.state.site.parent_support_email || 'support@getbridgecare.com')
            "
          />
        </div>
      </v-col>
      <v-col
        cols="6"
        md="3"
      >
        <div v-if="$store.state.site.provider_root_url">
          <div
            v-t="'For providers'"
            class="fs-14 fw-600 mb-2"
            style="text-transform: uppercase"
          />
          <div
            id="update_link"
            class="fs-14 mb-2 hover-underlined"
            tracked
          >
            <a
              v-t="'Update your profile'"
              :href="$store.state.site.provider_root_url"
              target="_blank"
            />
          </div>
          <div
            id="provider_contact_link"
            class="fs-14 mb-2 hover-underlined"
            tracked
          >
            <a
              v-t="'Contact us'"
              :href="
                'mailto:' +
                ($store.state.site.provider_support_email || 'support@getbridgecare.com')
              "
            />
          </div>
        </div>
      </v-col>
      <v-col
        class="ta-right"
        cols="12"
        md="6"
      >
        <div
          v-t="'Powered by'"
          class="fs-14 mb-1"
          style="text-transform: uppercase"
        />
        <a
          id="parent_bridgecare_logo_link"
          href="https://www.getbridgecare.com"
          target="_blank"
          tracked
          ><img
            :alt="$t('BridgeCare Homepage')"
            :src="$a.url('images.bridgecare_logo')"
            style="max-width: 170px"
        /></a>
      </v-col>
      <v-col
        class="ta-right"
        cols="12"
      >
        <a
          id="terms_link"
          v-t="'Terms of Service'"
          class="fs-14 me-2 hover-underlined"
          href="https://www.getbridgecare.com/terms-of-service"
          target="_blank"
          tracked
        />
        <span>|</span>
        <a
          id="privacy_link"
          v-t="'Privacy Policy'"
          class="ms-2 fs-14 hover-underlined"
          href="https://www.getbridgecare.com/privacy-policy"
          target="_blank"
          tracked
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  compatConfig: { MODE: 2 },

  data() {
    return {
      feedbackURL: this.getFeedbackURL(),
      supportURL: this.getSupportURL(),
    };
  },

  methods: {
    getFeedbackURL() {
      const url = this.$store.state.config.feedback_url;
      if (!url) return 'https://airtable.com/shrOPCN7sZRbl2uof';

      return this.mailtoOrURL(url);
    },

    getSupportURL() {
      const email = this.$store.state.site.parent_support_email;
      return email ? this.mailtoOrURL(email) : null;
    },

    mailtoOrURL(url) {
      if (url.includes('@') && !url.includes('mailto')) {
        return `mailto:${url}`;
      }

      return url;
    },
  },
};
</script>
