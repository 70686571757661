<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    content-class="bg-white"
    offset="5"
    eager
  >
    <template #activator="{ props }">
      <button
        v-if="!inlineStyle"
        @keyup.down="focusOnLabeledVolume(1, 0)"
        v-bind="props"
        ref="childrenMenuActivator"
        :ripple="false"
        class="bg-white c-black fullheight fullwidth rounded pa-3 focus-very-visible"
      >
        <v-row>
          <v-col
            class="ta-left"
            cols="10"
          >
            <div
              v-t="'Children'"
              class="fs-16 mb-1"
              data-cy="children_dropdown"
              data-testid="children_dropdown"
            />
            <div
              v-text="addChildrenText"
              :aria-label="addChildrenText"
              class="fs-16 fw-600"
            />
          </v-col>
          <v-col
            class="d-flex align-center justify-end"
            cols="2"
          >
            <v-icon>expand_more</v-icon>
          </v-col>
        </v-row>
      </button>

      <v-btn
        v-else
        @keydown.tab="focusOnLabeledVolume(1, 0)"
        v-bind="props"
        ref="childrenMenuActivator"
        append-icon="expand_more"
        aria-label="Change age groups"
        class="justify-space-between"
        color="surface-light"
        variant="flat"
        block
      >
        <v-icon
          aria-hidden="true"
          class="me-2 fs-24"
          color="primary"
          role="presentation"
        >
          person_outline
        </v-icon>
        <span
          :aria-label="addChildrenText"
          class="mxw-70pc constrained fs-16"
        >
          {{ addChildrenText }}
        </span>
        <v-spacer />
      </v-btn>
    </template>
    <SearchDobChild
      @onAddChild="(child) => $emit('onAddChild', child)"
      @onRemoveChild="(index) => $emit('onRemoveChild', index)"
      @onUpdateChild="(child, index) => $emit('onUpdateChild', child, index)"
      ref="searchDobChild"
      :children="children"
    />
    <v-card-actions>
      <v-spacer />
      <v-btn
        @click="close"
        :ripple="false"
        class="focus-very-visible mt-3"
        color="primary"
        data-cy="children_done"
        data-testid="children_done"
        block
      >
        {{ $t('Done') }}
      </v-btn>
    </v-card-actions>
  </v-menu>
</template>

<script>
import SearchDobChild from '@/parent/components/searches/SearchDobChild.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    SearchDobChild,
  },

  props: {
    children: {
      type: Array,
      default() {
        return [{ dob: null, care_needed_date: null }];
      },
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    inlineStyle: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['onAddChild', 'onRemoveChild', 'onUpdateChild'],

  data() {
    return {
      menu: false,
    };
  },
  computed: {
    addChildrenText() {
      const childQuerySum = this.children.filter(
        (child) => child.dob !== null && child.care_needed_date !== null,
      ).length;

      const label = [
        childQuerySum,
        childQuerySum === 1 ? this.$t('child') : this.$t('children'),
      ].join(' ');

      if (this.inlineStyle === false) {
        if (childQuerySum > 0) {
          return label;
        }
        return this.$t('Add children');
      }

      return label;
    },
  },

  mounted() {
    if (this.autofocus) this.focus();
  },

  methods: {
    close() {
      const closeable = this.$refs.searchDobChild.handleDoneClick();

      if (closeable === true) {
        this.menu = false;
      }
    },

    down(index) {
      if (index + 1 >= this.$refs.childVolume.length) return this.focusOnLabeledVolume(1, 0);

      return this.focusOnLabeledVolume(1, index + 1);
    },

    focus() {
      this.$refs.childrenMenuActivator.focus();
    },

    focusOnLabeledVolume(x, y) {
      this.$refs.childVolume[y].focus();
    },

    up(index) {
      if (index <= 0) return this.focusOnLabeledVolume(1, this.$refs.childVolume.length - 1);

      return this.focusOnLabeledVolume(1, index - 1);
    },
  },
};
</script>

<style>
.centered {
  margin-left: auto;
  margin-right: auto;
}

.card-break {
  border-top: 1px #eeeceb solid;
}

.bg-white {
  background-color: white;
}

#children_menu .v-input {
  padding-top: 12px;
  margin-top: 4px;
}
</style>
