<template>
  <v-container
    v-show="loaded"
    class="pt-16"
  >
    <v-card
      class="mx-auto"
      max-width="800"
      flat
      tile
    >
      <v-card-title>
        <h1
          class="fs-28 text-wrap"
          tabindex="0"
        >
          {{ $t('Which application would you like to create for') }}
          <br />
          {{ childName }}?
        </h1>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col class="d-flex flex-column align-items-center mt-5">
            <ChildApplySubsidyCard
              v-for="subsidyProgram in subsidyPrograms"
              @action="subsidyButtonAction(subsidyProgram)"
              :key="subsidyProgram.id"
              :actionable="subsidyProgram.rank !== 3"
              :applicationInProgress="
                childHasInProgressApplicationForSubsidyProgram(subsidyProgram)
              "
              :buttonText="subsidyButtonLabel(subsidyProgram)"
              :childCanApply="childCanApplyToSubsidyProgram(subsidyProgram)"
              :childEligible="childEligibleForSubsidyProgram(subsidyProgram)"
              :previouslyApplied="childPreviouslyAppliedToSubsidyProgram(subsidyProgram)"
              :subsidyProgram="subsidyProgram"
              data-testid="subsidy-program-card"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script setup>
import Api from '@/shared/services/bright_finder';
import useEventBus from '@/shared/composables/useEventBus';
import { capitalize } from '@/plugins/filters';
import { useRoute, useRouter } from 'vue-router';
import ChildApplySubsidyCard from '@/parent/components/children/ChildApplySubsidyCard.vue';

const eventBus = useEventBus();
const route = useRoute();
const router = useRouter();

const child = ref(null);
const loaded = ref(false);
const subsidyPrograms = ref([]);
const subsidies = ref([]);

const childName = computed(() => {
  return `${capitalize(child.value?.first_name)} ${capitalize(child.value?.last_name)}`;
});

function childCanApplyToSubsidyProgram(subsidyProgram) {
  return childPreviouslyAppliedToSubsidyProgram(subsidyProgram)
    ? subsidyProgram.enable_multiple_applications
    : true;
}

function childEligibleForSubsidyProgram(subsidyProgram) {
  if (
    subsidyProgram.eligibility_start_date &&
    subsidyProgram.eligibility_start_date > child.value.dob
  )
    return false;

  if (subsidyProgram.eligibility_end_date && subsidyProgram.eligibility_end_date < child.value.dob)
    return false;

  return true;
}

function childHasInProgressApplicationForSubsidyProgram(subsidyProgram) {
  return subsidies.value.some(
    (subsidy) => subsidy.subsidy_program_id === subsidyProgram.id && subsidy.submitted_at == null,
  );
}

function childPreviouslyAppliedToSubsidyProgram(subsidyProgram) {
  return subsidies.value.some(
    (subsidy) => subsidy.subsidy_program_id === subsidyProgram.id && subsidy.submitted_at != null,
  );
}

async function continueChildSubsidy(subsidyProgram) {
  const subsidy = subsidies.value.find(
    (subsidy) => subsidy.subsidy_program_id === subsidyProgram.id && subsidy.submitted_at == null,
  );

  await router.push({ name: 'SubsidyContinue', params: { subsidyId: subsidy.id } });
}

async function createSubsidy(subsidyProgram) {
  const resp = await Api.subsidy
    .create({ child_id: child.value.id, subsidy_program_id: subsidyProgram.id })
    .catch((err) => {
      eventBus.chime(err.response.data.errors[0]);
      return;
    });

  if (resp.data)
    await router.push({ name: 'SubsidyContinue', params: { subsidyId: resp.data.id } });
}

async function loadChild() {
  const resp = await Api.parent.children.get(route.params.childId);

  if (resp.data) child.value = resp.data;
}

async function loadSubsidies() {
  const resp = await Api.subsidy.index({ child_id: route.params.childId });

  if (resp.data) subsidies.value = resp.data;
}

async function loadSubsidyPrograms() {
  const resp = await Api.public_api.organization.subsidy_program.index();

  if (resp.data) {
    const allSubsidyPrograms = resp.data;
    const openSubsidyPrograms = allSubsidyPrograms.filter(subsidyProgramIsOpen);

    openSubsidyPrograms.forEach(
      (subsidyProgram) => (subsidyProgram.rank = subsidyProgramRankValue(subsidyProgram)),
    );

    subsidyPrograms.value = openSubsidyPrograms.sort(subsidyRankingFunction);
  }
}

async function subsidyButtonAction(subsidyProgram) {
  if (childHasInProgressApplicationForSubsidyProgram(subsidyProgram)) {
    await continueChildSubsidy(subsidyProgram);
    return;
  }

  await createSubsidy(subsidyProgram);
}

function subsidyButtonLabel(subsidyProgram) {
  if (childHasInProgressApplicationForSubsidyProgram(subsidyProgram)) return 'Continue';
  return childPreviouslyAppliedToSubsidyProgram(subsidyProgram) ? 'Re-apply' : 'Apply';
}

function subsidyProgramIsOpen(subsidyProgram) {
  if (subsidyProgram.opens_at && Date.parse(subsidyProgram.opens_at) >= new Date()) return false;
  if (subsidyProgram.closes_at && Date.parse(subsidyProgram.closes_at) <= new Date()) return false;

  return true; // return true if in-bounds or values are absent
}

function subsidyProgramRankValue(subsidyProgram) {
  if (!childEligibleForSubsidyProgram(subsidyProgram)) return 3; // not actionable

  if (
    childCanApplyToSubsidyProgram(subsidyProgram) &&
    !childHasInProgressApplicationForSubsidyProgram(subsidyProgram)
  )
    return 0;

  if (childHasInProgressApplicationForSubsidyProgram(subsidyProgram)) return 1;

  if (
    !childCanApplyToSubsidyProgram(subsidyProgram) &&
    childPreviouslyAppliedToSubsidyProgram(subsidyProgram)
  )
    return 2;
}

function subsidyRankingFunction(subsidyProgramA, subsidyProgramB) {
  const rankA = subsidyProgramA.rank;
  const rankB = subsidyProgramB.rank;

  const result =
    rankA === rankB ? subsidyProgramA.name.localeCompare(subsidyProgramB.name) : rankA - rankB;
  return result;
}

onMounted(async () => {
  await loadChild();
  await loadSubsidies();
  await loadSubsidyPrograms();

  loaded.value = true;
});
</script>
