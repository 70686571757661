import sections from '@/parent/services/family-subsidy-sections';
const LARGE_INDEX = 1000;

export default function useFamilySubsidyStepper({
  assertHouseholdMemberRequirements,
  familySubsidyProgram,
  householdMembers,
  householdMemberQuestionRegister,
  processing,
  save,
  saveHouseholdMember,
  saveThirdPartyData,
  updateQuery,
  validate,
  validEligibilityQuestions,
  validOtherQuestions,
  validVerificationQuestions,
}) {
  const section = ref(null);
  const stepCount = ref(1);

  async function backFrom(section) {
    processing.value = true;
    await validate();

    if (section === sections.eligibility)
      return await stepBack(
        `${sections.eligibilityQuestions}-${validEligibilityQuestions.value.length - 1}`,
      );

    if (section === sections.manageHouseholdMembers) return backFromOtherQuestion(false);

    if (section === sections.confirm) return backFromVerificationQuestion(false);

    return true;
  }

  async function backFromEligibilityQuestion(index) {
    processing.value = true;
    if (!(await save())) {
      processing.value = false;
      return;
    }
    await validate();
    await stepBack(`${sections.eligibilityQuestions}-${index - 1}`);
  }

  async function backFromHouseholdMember(questionIndex, householdMemberIndex = false) {
    processing.value = true;
    let householdMember;
    let revisedHouseholdMemberIndex = householdMemberIndex;
    let revisedQuestionIndex = questionIndex;

    if (revisedQuestionIndex === false) {
      if (revisedHouseholdMemberIndex === false)
        revisedHouseholdMemberIndex = householdMembers.value.length;

      const reverseHouseholdMemberIndex = householdMembers.value
        .map((member) => member.id)
        .slice(0, revisedHouseholdMemberIndex)
        .reverse()
        .findIndex(
          (hmid) =>
            householdMemberQuestionRegister.value[hmid].filter((question) => question.valid)
              .length > 0,
        );

      if (reverseHouseholdMemberIndex < 0) {
        await stepBack(sections.manageHouseholdMembers);
        return;
      }

      revisedHouseholdMemberIndex = revisedHouseholdMemberIndex - 1 - reverseHouseholdMemberIndex;
      householdMember = householdMembers.value[revisedHouseholdMemberIndex];

      await validate(); // Necessary for correct valid question count in next line
      revisedQuestionIndex = householdMemberQuestionRegister.value[householdMember.id].filter(
        (question) => question.valid,
      ).length;
    } else {
      householdMember = householdMembers.value[revisedHouseholdMemberIndex];
      const resp = await saveHouseholdMember(householdMember);
      if (resp?.status !== 200) return;

      householdMembers.value[revisedHouseholdMemberIndex] = resp.data;
      await validate();
    }

    if (revisedQuestionIndex - 1 >= 0) {
      await stepBack(
        `${sections.householdMember}${revisedHouseholdMemberIndex}-${revisedQuestionIndex - 1}`,
      );
      return;
    }

    if (revisedHouseholdMemberIndex - 1 < 0) {
      await stepBack(sections.manageHouseholdMembers);
      return;
    }

    await backFromHouseholdMember(false, revisedHouseholdMemberIndex);
  }

  async function backFromThirdPartyQuestions() {
    processing.value = true;
    if (!(await save())) {
      processing.value = false;
      return;
    }
    await validate();

    await stepBack(sections.eligibility);
  }

  async function backFromOtherQuestion(index) {
    processing.value = true;
    let revisedIndex = index;

    if (revisedIndex === false) {
      revisedIndex = validOtherQuestions.value.length;
    } else {
      await validate();
      if (!(await save())) {
        processing.value = false;
        return;
      }
    }

    if (revisedIndex - 1 < 0) {
      if (familySubsidyProgram.enable_third_party_applications) {
        await stepBack(sections.thirdPartyQuestions);
        return;
      }
      await stepBack(sections.eligibility);
      return;
    }
    await stepBack(`${sections.otherQuestions}-${revisedIndex - 1}`);
  }

  async function backFromVerificationQuestion(index) {
    processing.value = true;
    let revisedIndex = index;

    if (revisedIndex === false) revisedIndex = validVerificationQuestions.value.length;

    if (revisedIndex - 1 < 0) {
      if (familySubsidyProgram.enable_household_members) {
        await backFromHouseholdMember(false, false);
      } else {
        await backFromOtherQuestion(false);
      }
    } else {
      await stepBack(`${sections.verificationQuestions}-${revisedIndex - 1}`);
    }
  }

  async function forwardFromEligibilityQuestion(index) {
    processing.value = true;
    if (!(await save())) {
      processing.value = false;
      return;
    }
    await validate();

    if (index + 1 >= validEligibilityQuestions.value.length) {
      await stepForward(sections.eligibility);
    } else {
      await stepForward(`${sections.eligibilityQuestions}-${index + 1}`);
    }
  }

  async function forwardFromEligibilitySection() {
    processing.value = true;
    if (!(await save())) {
      processing.value = false;
      return;
    }
    await validate();

    if (familySubsidyProgram.value.enable_third_party_applications) {
      await stepForward(sections.thirdPartyQuestions);
    } else if (validOtherQuestions.value.length > 0) {
      await stepForward(`${sections.otherQuestions}-0`);
    } else {
      await forwardFromOtherQuestion(LARGE_INDEX);
    }
  }

  async function forwardFromHouseholdMember(questionIndex, householdMemberIndex = false) {
    processing.value = true;
    let householdMember;
    let revisedHouseholdMemberIndex = householdMemberIndex;
    let revisedQuestionIndex = questionIndex;

    if (revisedQuestionIndex === false) {
      revisedHouseholdMemberIndex = householdMembers.value.findIndex(
        (member) =>
          householdMemberQuestionRegister.value[member.id].filter((question) => question.valid)
            .length > 0,
      );

      revisedQuestionIndex = -1;
      if (householdMemberIndex < 0) {
        await forwardFromVerificationQuestion(-1);
        return;
      }
      householdMember = householdMembers.value[revisedHouseholdMemberIndex];
    } else {
      householdMember = householdMembers.value[revisedHouseholdMemberIndex];
      const resp = await saveHouseholdMember(householdMember);
      if (resp?.status !== 200) return;

      householdMembers.value[revisedHouseholdMemberIndex] = resp.data;
      await validate();
    }

    if (
      revisedQuestionIndex + 1 >=
      householdMemberQuestionRegister.value[householdMember.id].filter((question) => question.valid)
        .length
    ) {
      if (revisedHouseholdMemberIndex + 1 >= householdMembers.value.length) {
        await forwardFromVerificationQuestion(-1);
        return;
      }
      await forwardFromHouseholdMember(-1, revisedHouseholdMemberIndex + 1);
    } else {
      await stepForward(
        `${sections.householdMember}${revisedHouseholdMemberIndex}-${revisedQuestionIndex + 1}`,
      );
    }
  }

  async function forwardFromHouseholdMemberManagement(section) {
    processing.value = true;
    await validate();

    assertHouseholdMemberRequirements(section);
  }

  async function forwardFromOtherQuestion(index) {
    processing.value = true;
    if (!(await save())) {
      processing.value = false;
      return;
    }
    await validate();

    if (index + 1 >= validOtherQuestions.value.length) {
      if (familySubsidyProgram.value.enable_household_members) {
        await stepForward(sections.manageHouseholdMembers);
      } else {
        await forwardFromVerificationQuestion(-1);
      }
    } else {
      await stepForward(`${sections.otherQuestions}-${index + 1}`);
    }
  }

  async function forwardFromThirdPartyQuestions() {
    processing.value = true;
    const saved = await saveThirdPartyData();
    if (!saved) {
      processing.value = false;
      return;
    }
    await validate();

    if (validOtherQuestions.value.length > 0) {
      await stepForward(`${sections.otherQuestions}-0`);
    } else {
      await forwardFromOtherQuestion(LARGE_INDEX);
    }
  }

  async function forwardFromVerificationQuestion(index) {
    processing.value = true;

    if (index + 1 >= validVerificationQuestions.value.length) {
      await stepForward(sections.confirm);
    } else {
      await stepForward(`${sections.verificationQuestions}-${index + 1}`);
    }
  }

  function getStartingSection() {
    if (validEligibilityQuestions.value.length > 0) {
      return `${sections.eligibilityQuestions}-0`;
    }

    if (familySubsidyProgram.value.enable_third_party_applications) {
      return sections.thirdPartyQuestions;
    }

    return `${sections.otherQuestions}-0`;
  }

  function progressBackward() {
    stepCount.value -= 1;
  }

  function progressForward() {
    stepCount.value += 1;
  }

  async function stepBack(newSection, params) {
    progressBackward();
    await step(newSection, params);
  }

  async function stepForward(newSection, params) {
    progressForward();
    await step(newSection, params);
  }

  async function step(newSection, params = {}) {
    await updateQuery(Object.assign(params, { section: newSection, step: stepCount.value }));
    processing.value = false;
  }

  return {
    backFrom,
    backFromEligibilityQuestion,
    backFromHouseholdMember,
    backFromOtherQuestion,
    backFromThirdPartyQuestions,
    backFromVerificationQuestion,
    forwardFromEligibilityQuestion,
    forwardFromEligibilitySection,
    forwardFromHouseholdMember,
    forwardFromHouseholdMemberManagement,
    forwardFromOtherQuestion,
    forwardFromThirdPartyQuestions,
    forwardFromVerificationQuestion,
    getStartingSection,
    section,
    sections,
    stepBack,
  };
}
