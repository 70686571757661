<template>
  <v-row
    class="mb-1"
    dense
  >
    <v-col class="px-3">
      <v-list class="py-0">
        <v-list-item
          v-if="provider.quality_rating_score > 0"
          class="pa-0"
          style="min-height: 0px"
        >
          <template #prepend>
            <img
              v-if="
                $store.state.site['Custom Ratings'] &&
                $store.state.site['Custom Ratings'].length > 0
              "
              :src="$store.state.site['Custom Ratings'][0].pass"
              alt="Custom Quality Rating Score"
              height="24"
            />
            <v-icon
              v-else
              alt=""
              color="#FFD200"
              role="presentation"
              size="24"
            >
              star
            </v-icon>
          </template>
          <v-list-item-title
            v-if="provider.quality_rating_score"
            class="fs-16 fw-400"
          >
            <span
              >{{ $t(provider.quality_rating_name) }}: {{ provider.quality_rating_score }}
              {{ $t('out of') }} {{ provider.quality_rating_max }}</span
            >
          </v-list-item-title>
          <v-list-item-title
            v-else
            class="fs-16 fw-400"
          >
            {{ $t('No state rating.') }}
          </v-list-item-title>
        </v-list-item>
        <template
          v-if="
            $store.state.schemas[provider.schema_id].meta.openings.enabled &&
            $store.state.schemas[provider.schema_id].meta.openings.public
          "
        >
          <v-list-item
            v-for="(spot, index) in provider.vacancy_descriptions"
            v-show="spot.vacant != null"
            :key="index"
            class="pa-0"
            style="min-height: 0px"
          >
            <template #prepend>
              <v-icon
                v-if="spot.vacant"
                class="c-green"
                size="24"
              >
                check_circle
              </v-icon>
              <v-icon
                v-if="spot.vacant == false"
                class="c-red"
                size="24"
              >
                cancel
              </v-icon>
            </template>
            <v-list-item-title class="fs-16 fw-400">
              <span
                v-if="spot.vacant == false"
                v-t="'Openings not available for'"
              />
              <span
                v-if="spot.vacant == true"
                v-t="'Openings available for'"
              />
              <span class="ms-1"
                >{{ monthsToAgeString(spot.min) }} - {{ monthsToAgeString(spot.max) }}</span
              >
            </v-list-item-title>
          </v-list-item>
        </template>
        <v-list-item
          class="pa-0"
          style="min-height: 0px"
        >
          <template #prepend>
            <v-icon
              class="c-black"
              size="24"
            >
              query_builder
            </v-icon>
          </template>
          <v-list-item-title
            v-if="provider.hours_description"
            class="c-black fs-16 fw-400"
          >
            {{ provider.hours_description }}
          </v-list-item-title>
          <v-list-item-title
            v-else
            class="fs-16 fw-400"
          >
            {{ $t('Hours not available') }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="provider.payment_subsidies_accepted.length > 0"
          class="pa-0"
          style="min-height: 0px"
        >
          <template #prepend>
            <v-icon
              color="#578052"
              size="24"
            >
              attach_money
            </v-icon>
          </template>
          <v-list-item-title class="c-black fs-16 fw-400">
            {{ $t('Financial assistance options available') }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-col>
  </v-row>
</template>

<script>
import AgeCalculations from '@/shared/mixins/age_calculations';

export default {
  compatConfig: { MODE: 2 },

  mixins: [AgeCalculations],

  props: {
    provider: {
      type: Object,
      default: null,
    },
  },
};
</script>
