import Api from '@/shared/services/all_bright_finder';
import LinkedList from '@/shared/services/linked-list';
import ValueExtractor from '@/shared/services/ValueExtractor';
import { ref } from 'vue';

/**
 * Find, validate, and organize the questions for a family subsidy program
 * application.
 *
 * @returns {{
 *   loadQuestions: array
 *   validEligibilityQuestions: array
 *   validOtherQuestions: array
 *   validVerificationQuestions: array
 * }}
 */
export default function useMyFamilySubsidyUtils({
  familySubsidyProgram,
  householdMembers,
  subsidy,
}) {
  const displayAll = ref(false);
  const questions = ref([]);
  const familySubsidyQuestions = ref([]);
  const householdMemberQuestions = ref([]);
  const householdMemberQuestionRegister = ref({});
  const validEligibilityQuestions = ref([]);
  const validOtherQuestions = ref([]);
  const validVerificationQuestions = ref([]);

  async function validate() {
    setHouseholdMemberQuestionRegister();

    await revalidateQuestions();

    validEligibilityQuestions.value = sortQuestions(
      familySubsidyQuestions.value,
      'FamilySubsidy',
      true,
    ).filter((question) => question.valid);

    validOtherQuestions.value = sortQuestions(
      familySubsidyQuestions.value,
      'FamilySubsidy',
      false,
    ).filter((question) => question.valid);

    validVerificationQuestions.value = familySubsidyQuestions.value
      .filter((question) => question.verification)
      .filter((question) => displayAll.value || (question.published && question.valid));
  }

  async function loadQuestions() {
    const params = {
      owner_id: familySubsidyProgram.value.id,
      owner_type: 'FamilySubsidyProgram',
    };

    const resp = await Api.public_api.organization.question.index(params);
    questions.value = resp.data;
    familySubsidyQuestions.value = filterQuestionsForModel(questions.value, 'FamilySubsidy');
    householdMemberQuestions.value = LinkedList.sort(
      filterQuestionsForModel(questions.value, 'HouseholdMember'),
    );
    await validate();
  }

  function filterQuestionsForModel(listOfQuestions, syncedModel) {
    return listOfQuestions.filter((question) => question.synced_model == syncedModel);
  }

  function createAssertions({ labels = [], questions, value }) {
    return questions
      .filter((question) => question.conditions?.length > 0)
      .map((question) => ({
        assertion: {
          conditions: question.conditions,
          labels: [...labels, question.id],
          value: ValueExtractor.extract(
            value,
            question.conditions.map((condition) => condition.field),
          ),
        },
      }));
  }

  async function revalidateQuestions() {
    const familySubsidyAssertions = createAssertions({
      questions: familySubsidyQuestions.value,
      value: subsidy.value,
    });

    const householdMemberAssertions = householdMembers.value
      .map((householdMember) =>
        createAssertions({
          labels: [householdMember.id],
          questions: householdMemberQuestions.value,
          value: householdMember,
        }),
      )
      .flat();

    const assertions = familySubsidyAssertions.concat(householdMemberAssertions);
    const { data } = await Api.public_api.assertion.promiseBulkCreate(assertions);

    data.results.forEach((result) => {
      const familySubsidyQuestion = familySubsidyQuestions.value.find((question) =>
        result.labels.includes(question.id),
      );

      if (familySubsidyQuestion) {
        familySubsidyQuestion.valid = result.result;
        return;
      }

      const id = Object.keys(householdMemberQuestionRegister.value).find((householdMemberId) =>
        result.labels.includes(householdMemberId),
      );

      if (!id) return;

      const question = householdMemberQuestionRegister.value[id].find((question) =>
        result.labels.includes(question.id),
      );

      if (question) question.valid = result.result;
    });
  }

  function setHouseholdMemberQuestionRegister() {
    // Prevent question flickering by only updating householdMemberQuestionRegister when householdMembers IDs
    // don't match the keys in householdMemberQuestionRegister
    const hmIds = householdMembers.value.map((member) => member.id).sort();
    const hmRegisterKeys = Object.keys(householdMemberQuestionRegister.value).sort();
    const hmQuestionsAreOutdated =
      hmIds.length !== hmRegisterKeys.length ||
      !hmIds.every((id, index) => id === hmRegisterKeys[index]);
    if (!hmQuestionsAreOutdated) return;

    householdMembers.value.forEach((householdMember) => {
      householdMemberQuestionRegister.value[householdMember.id] = JSON.parse(
        JSON.stringify(householdMemberQuestions.value),
      );
    });
  }

  function sortQuestions(listOfQuestions, syncedModel, eligibility) {
    return LinkedList.sort(
      filterQuestionsForModel(listOfQuestions, syncedModel).filter(
        (question) => question.eligibility == eligibility,
      ),
    );
  }

  return {
    householdMemberQuestionRegister,
    loadQuestions,
    questions,
    validate,
    validEligibilityQuestions,
    validOtherQuestions,
    validVerificationQuestions,
  };
}
