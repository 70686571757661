<template>
  <v-container
    v-if="child"
    class="py-4"
  >
    <v-btn
      @click="$router.push({ name: 'Dashboard' })"
      class="focus-very-visible px-0 mb-4"
      variant="text"
    >
      <v-icon start> west </v-icon>
      <span v-t="'Back to dashboard'" />
    </v-btn>

    <v-card
      border
      flat
      tile
    >
      <v-card-title>
        <h1
          ref="title"
          class="d-flex align-center"
        >
          <v-avatar
            class="fs-28 c-white me-3"
            color="primary"
            size="60"
          >
            {{ [child.first_name[0], child.last_name[0]].join('').toUpperCase() }}
          </v-avatar>
          <div>
            <div
              v-text="[child.first_name, child.last_name].join(' ')"
              class="c-black fs-28"
              data-cy="child-title"
            />

            <div
              class="c-light-black fs-18"
              data-cy="child-dob"
            >
              <LongDate :date="child.dob" />
            </div>
          </div>
        </h1>
      </v-card-title>

      <v-card-text>
        <template v-if="schema.meta.parent_layout.applications_instructions">
          <v-card
            class="bg-primary mb-4"
            border
            flat
            tile
          >
            <v-card-text class="c-white fs-16 fw-500 markdown">
              <MarkdownContent :content="schema.meta.parent_layout.applications_instructions" />
            </v-card-text>
          </v-card>
        </template>

        <div
          v-for="subsidyProgram in childSubsidyPrograms"
          :key="subsidyProgram.id"
        >
          <MultipleSubsidiesCard
            v-if="subsidyProgram.enable_multiple_applications"
            :child="child"
            :child-schema="schema"
            :subsidies="
              subsidies.filter((subsidy) => subsidy.subsidy_program_id == subsidyProgram.id)
            "
            :subsidy-program="subsidyProgram"
          />
          <SubsidyCard
            v-else
            :child-data="child"
            :child-schema="schema"
            :subsidy="subsidies.find((subsidy) => subsidy.subsidy_program_id == subsidyProgram.id)"
            :subsidy-program="subsidyProgram"
          />
        </div>
      </v-card-text>
    </v-card>

    <VerticalSpacer :min-height="60" />
  </v-container>
</template>

<script>
import API from '@/shared/mixins/api';
import LongDate from '@/shared/components/LongDate.vue';
import MultipleSubsidiesCard from '@/parent/components/subsidy/MultipleSubsidiesCard.vue';
import SubsidyCard from '@/parent/components/subsidy/SubsidyCard.vue';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';
import MarkdownContent from '@/shared/components/MarkdownContent.vue';
import { subsidyProgramIsOpen } from '@/parent/services/subsidies';

export default {
  compatConfig: { MODE: 2 },

  components: {
    LongDate,
    MarkdownContent,
    MultipleSubsidiesCard,
    SubsidyCard,
    VerticalSpacer,
  },

  mixins: [API],

  data() {
    return {
      child: null,
      schema: null,
      subsidies: [],
      childSubsidyPrograms: [],
    };
  },

  watch: {
    '$route.params.childId': {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.load();
        }
      },
    },
  },

  methods: {
    load() {
      this.child = null;
      this.subsidies = [];
      this.childSubsidyPrograms = [];

      this.loadChild();

      this.api.subsidy.index({ child_id: this.$route.params.childId }, (subsidiesResp) => {
        this.subsidies = subsidiesResp.data;

        this.api.public_api.organization.subsidy_program.index(
          { child_id: this.$route.params.childId },
          (resp) => {
            this.childSubsidyPrograms = resp.data.filter(
              (childSubsidyProgram) =>
                subsidyProgramIsOpen(childSubsidyProgram) ||
                this.subsidies.find(
                  (subsidy) =>
                    subsidy.subsidy_program_id === childSubsidyProgram.id && subsidy.submitted_at,
                ),
            );
          },
        );
      });
    },

    async loadChild() {
      const resp = await this.api.child.get(this.$route.params.childId);
      this.child = resp.data;
      this.schema = this.$store.state.schemas[this.child.schema_id];
    },
  },
};
</script>
