<template>
  <FormQuestion
    @back="$emit('back')"
    @next="$emit('next')"
    :header="$t('Child') + ` - ${childData.name}`"
    :hide-actions="hideActions"
    :next-disabled="nextDisabled"
    :processing="processing"
    :subtitle="subsidyProgram.preference_subtitle"
    :title="subsidyProgram.preference_title"
  >
    <v-stepper-vertical
      v-model="step"
      class="pb-0 full-width"
      flat
      hide-actions
      tile
    >
      <v-card
        class="mb-4 w-100pc"
        border
        flat
        tile
      >
        <v-card-text class="py-0 px-0">
          <v-stepper-vertical-item
            :complete="step > 1"
            value="1"
          >
            <template #title>
              <h3 v-t="subsidyProgram.select_providers_title" />
              <h4
                v-t="subsidyProgram.select_providers_subtitle"
                class="fs-16 c-light-black"
              />
            </template>

            <SelectProvidersStep
              @copy="copyPreferences"
              @next="step = 2"
              @open="openProvider($event)"
              @toggle="toggleSelection($event[0], $event[1])"
              :api="api"
              :child="childData"
              :default-query="defaultQuery"
              :previous-child-name="previousChildName"
              :previous-subsidy-provider-ids="previousSubsidyProviderIds"
              :processing="processing"
              :provider-ids="subsidy.provider_ids"
              :selected-providers="selectedProviders"
              :subsidy-program="subsidyProgram"
            />
          </v-stepper-vertical-item>
        </v-card-text>
      </v-card>

      <v-card
        class="mb-4 w-100pc"
        border
        flat
        tile
      >
        <v-card-text class="py-0 px-0">
          <v-stepper-vertical-item
            :complete="step > 2"
            value="2"
          >
            <template #title>
              <h3
                v-t="subsidyProgram.rank_providers_title"
                tabindex="0"
              />
              <h4
                v-t="subsidyProgram.rank_providers_subtitle"
                class="fs-16 c-light-black"
                tabindex="0"
              />
            </template>

            <RankProvidersStep
              @back="step = 1"
              @move="reorderProviders($event[0], $event[0] + $event[1])"
              @next="step = 3"
              @toggle="toggleInclusion($event[0], $event[1])"
              :api="api"
              :provider-ids="subsidy.provider_ids"
              :subsidy-program="subsidyProgram"
            />
          </v-stepper-vertical-item>
        </v-card-text>
      </v-card>

      <template v-if="subsidyProgram.allow_enrolled">
        <v-card
          class="mb-4 w-100pc"
          data-testid="currentEnrollmentStep"
          border
          flat
          tile
        >
          <v-card-text class="py-0 px-0">
            <v-stepper-vertical-item
              :complete="step > 3"
              value="3"
            >
              <template #title>
                <h3
                  v-t="subsidyProgram.current_provider_title"
                  tabindex="0"
                />
                <h4
                  v-t="subsidyProgram.current_provider_subtitle"
                  class="fs-16 c-light-black"
                  tabindex="0"
                />
              </template>

              <CurrentEnrollmentStep
                v-model="subsidy.current_provider_id"
                @back="step = 2"
                @na="currentProviderNotApplicableClicked"
                :na="currentProviderNotApplicable"
                :providers="selectedProviders"
                :subsidy-program="subsidyProgram"
                show-na
              />
            </v-stepper-vertical-item>
          </v-card-text>
        </v-card>
      </template>
    </v-stepper-vertical>

    <ContentDialog
      ref="providerDialog"
      :max-width="800"
    >
      <template #content="{ data }">
        <ProviderMatch
          v-if="data"
          :match="data"
          :outlined="false"
          :schema="providerSchema"
        />
      </template>
    </ContentDialog>
  </FormQuestion>
</template>

<script>
import API from '@/shared/mixins/api';
import CurrentEnrollmentStep from '@/parent/components/subsidy/CurrentEnrollmentStep.vue';
import ContentDialog from '@/shared/components/ContentDialog.vue';
import FormQuestion from '@/shared/components/form/FormQuestion.vue';
import ProviderMatch from '@/shared/components/search/ProviderMatch.vue';
import RankProvidersStep from '@/parent/components/subsidy/RankProvidersStep.vue';
import SelectProvidersStep from '@/parent/components/subsidy/SelectProvidersStep.vue';
import { VStepperVertical, VStepperVerticalItem } from 'vuetify/labs/VStepperVertical';

export default {
  compatConfig: { MODE: 2 },

  components: {
    ContentDialog,
    CurrentEnrollmentStep,
    FormQuestion,
    ProviderMatch,
    RankProvidersStep,
    SelectProvidersStep,
    VStepperVertical,
    VStepperVerticalItem,
  },

  mixins: [API],

  props: {
    childData: {
      type: Object,
      default: null,
    },
    defaultQuery: {
      type: String,
      default: null,
    },
    hideActions: Boolean,
    initialStep: {
      type: Number,
      default: 1,
    },
    previousChildName: {
      type: String,
      default: null,
    },
    previousSubsidyProviderIds: {
      type: Array,
      default: null,
    },
    readonly: Boolean,
    processing: Boolean,
    subsidy: {
      type: Object,
      default: null,
    },
    subsidies: {
      type: Array,
      default: null,
    },
    subsidyProgram: {
      type: Object,
      default: null,
    },
  },

  emits: ['back', 'next'],

  data() {
    return {
      currentProviderNotApplicable: false,
      providerSchema: this.$store.state.schemas[this.subsidyProgram.provider_schema_id],
      selectedProviders: [],
      step: this.initialStep,
    };
  },

  computed: {
    nextDisabled() {
      return (
        this.subsidy.provider_ids.length === 0 ||
        (this.step < 3 && this.subsidyProgram.allow_enrolled)
      );
    },
  },

  created() {
    this.loadSelectedProviders();
  },

  methods: {
    copyPreferences() {
      // eslint-disable-next-line vue/no-mutating-props
      this.subsidy.provider_ids = JSON.parse(JSON.stringify(this.previousSubsidyProviderIds));
      this.viewSelectedResults = true;
      this.loadSelectedProviders();
    },

    currentProviderNotApplicableClicked() {
      this.currentProviderNotApplicable = !this.currentProviderNotApplicable;

      if (this.currentProviderNotApplicable === true) {
        this.subsidy.current_provider_id = null;
      }
    },

    async loadSelectedProviders() {
      if (this.subsidy.provider_ids.length === 0) return;
      const resp = await this.api.public_api.organization.provider.index({
        ids: this.subsidy.provider_ids,
        page_size: 200,
      });
      this.selectedProviders = resp.data;
    },

    openProvider(providerId) {
      this.api.public_api.provider.get(providerId, (resp) => {
        this.$refs.providerDialog.open(resp.data);
      });
    },

    reorderProviders(fromIndex, toIndex) {
      const element = this.subsidy.provider_ids[fromIndex];
      // eslint-disable-next-line vue/no-mutating-props
      this.subsidy.provider_ids.splice(fromIndex, 1);
      // eslint-disable-next-line vue/no-mutating-props
      this.subsidy.provider_ids.splice(toIndex, 0, element);
    },

    toggleInclusion(index, newVal) {
      if (newVal) {
        // eslint-disable-next-line vue/no-mutating-props
        this.subsidy.provider_ids.push(this.subsidy.excluded_provider_ids.splice(index, 1)[0]);
      } else {
        // eslint-disable-next-line vue/no-mutating-props
        this.subsidy.excluded_provider_ids.push(this.subsidy.provider_ids.splice(index, 1)[0]);
      }
      this.loadSelectedProviders();
    },

    toggleSelection(providerId, newVal) {
      if (newVal) {
        if (!this.subsidy.provider_ids.includes(providerId)) {
          // eslint-disable-next-line vue/no-mutating-props
          this.subsidy.provider_ids.push(providerId);
          if (
            this.subsidyProgram.selection_limit > 0 &&
            this.subsidyProgram.selection_limit === this.subsidy.provider_ids.length
          ) {
            this.$eventBus.$emit(
              'chime',
              'You reached your selection limit. Deselect another option if you want to add more.',
            );
          }
        }
      } else if (this.subsidy.provider_ids.includes(providerId)) {
        // eslint-disable-next-line vue/no-mutating-props
        this.subsidy.provider_ids.splice(
          this.subsidy.provider_ids.findIndex((id) => id === providerId),
          1,
        );
      }
      this.loadSelectedProviders();
    },
  },
};
</script>
