<template>
  <div>
    <v-card
      v-if="claims.length > 0"
      border
      flat
      tile
    >
      <v-card-text class="px-0 py-0">
        <v-card
          class="bb-1 bc-extra-light-gray"
          flat
          tile
        >
          <v-card-text class="py-3 fs-12">
            <v-row
              class="d-flex"
              dense
            >
              <div class="mt-2">
                <span class="fw-600">{{ $t('Total funding amount:') + ' ' }}</span>
                <span class="ms-2">{{ currency(familySubsidy.award_amount) }}</span>
              </div>
              <v-spacer />

              <v-tooltip location="bottom">
                <template #activator="{ props }">
                  <div class="mt-2">
                    <v-icon
                      v-bind="props"
                      class="d-inline pe-2"
                    >
                      info
                    </v-icon>
                    <span class="fw-600">{{ $t('Remaining funding amount:') + ' ' }}</span>
                    <span class="ms-2">{{
                      currency(familySubsidy.award_amount - familySubsidy.approved_amount)
                    }}</span>
                  </div>
                </template>
                <span>
                  {{ $t('Unapproved claims are not included in this amount.') }}
                </span>
              </v-tooltip>
              <v-spacer />

              <v-btn
                @click="$refs.createContinueClaimDialog.open()"
                color="primary"
              >
                {{ $t('New claim') }}
              </v-btn>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card
          class="bb-1 bc-extra-light-gray pa-0"
          flat
          tile
        >
          <v-card-text class="pa-3 pr-0">
            <v-row dense>
              <v-col cols="1">
                <!-- Lock column -->
              </v-col>
              <v-col class="fw-600 fs-14"> ID </v-col>
              <v-col class="fw-600 fs-14 ta-center">
                {{ $t('Requested') }}
              </v-col>
              <v-col class="fw-600 fs-14 ta-center">
                {{ $t('Approved') }}
              </v-col>
              <v-col class="fw-600 fs-14 ta-center">
                {{ $t('Status') }}
              </v-col>
              <v-col class="fw-600 fs-14 ta-center">
                {{ $t('Created at') }}
              </v-col>
              <v-col class="fw-600 fs-14 ta-center">
                {{ $t('Last updated') }}
              </v-col>
              <v-col cols="1">
                <!-- Action column -->
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card
          v-for="(claim, index) in claims"
          :key="index"
          class="bb-1 bc-extra-light-gray pa-0"
          flat
          tile
        >
          <v-card-text class="pa-3 pr-0">
            <v-row
              class="c-black fs-14 fw-500"
              dense
            >
              <v-col cols="1">
                <v-tooltip location="bottom">
                  <template #activator="{ props }">
                    <v-icon
                      v-bind="props"
                      size="24"
                    >
                      {{ claim.locked ? 'lock' : 'lock_open' }}
                    </v-icon>
                  </template>
                  <span>
                    {{
                      $t(
                        claim.locked
                          ? 'The claim is locked. You cannot make changes.'
                          : 'The claim is unlocked. You can make changes.',
                      )
                    }}
                  </span>
                </v-tooltip>
              </v-col>
              <v-col>
                <span>{{ claim.id.slice(0, 6).toUpperCase() }}</span>
              </v-col>
              <v-col class="ta-center">
                <span>{{ currency(claim.requested_amount) }}</span>
              </v-col>
              <v-col class="fs-14 fw-500 ta-center">
                <span v-if="claim.approved">{{ currency(claim.approved_amount) }}</span>
              </v-col>
              <v-col class="ta-center">
                <v-tooltip location="bottom">
                  <template #activator="{ props }">
                    <span
                      v-bind="props"
                      :class="getStatusClasses(claim)"
                      class="rounded b-1 px-3 py-1"
                    >
                      {{ $t(getClaimStatus(claim)) }}
                    </span>
                  </template>
                  <span
                    ><LongDate
                      :date="claim.approved_at || claim.denied_at || claim.submitted_at"
                      short-month
                  /></span>
                </v-tooltip>
              </v-col>
              <v-col class="ta-center">
                <LongDate
                  :date="claim.created_at"
                  short-month
                />
              </v-col>
              <v-col class="ta-center">
                <LongDate
                  :date="claim.updated_at"
                  short-month
                />
              </v-col>
              <v-col
                class="c-primary ta-right mr-2"
                cols="1"
              >
                <ActionMenu
                  @click:action:continue="$refs.createContinueClaimDialog.open(claim.id)"
                  @click:action:delete="deleteClaim(claim)"
                  @click:action:view="$refs.claimReviewDialog.open(claim.id)"
                  :items="getClaimActions(claim)"
                  button-icon="more_vert"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>

    <NullState
      v-else
      @new="$refs.createContinueClaimDialog.open()"
      data-testid="claims-null-state"
      new-button-title="New claim"
    />

    <CreateContinueClaimDialog
      v-if="familySubsidyProgram?.claim_schema_id"
      @save="load()"
      ref="createContinueClaimDialog"
      :family-subsidy="familySubsidy"
      :schema-id="familySubsidyProgram.claim_schema_id"
    />

    <ClaimReviewDialog
      @change="load()"
      ref="claimReviewDialog"
      :family-subsidy="familySubsidy"
    />
  </div>
</template>

<script>
import { currency } from '@/plugins/filters';
import API from '@/shared/mixins/api';
import ActionMenu from '@/shared/components/ActionMenu.vue';
import ClaimReviewDialog from '@/shared/components/ClaimReviewDialog.vue';
import CreateContinueClaimDialog from '@/shared/components/CreateContinueClaimDialog.vue';
import LongDate from '@/shared/components/LongDate.vue';
import NullState from '@/shared/components/NullState.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    ActionMenu,
    ClaimReviewDialog,
    CreateContinueClaimDialog,
    LongDate,
    NullState,
  },

  mixins: [API],

  props: {
    familySubsidy: {
      type: Object,
      default: null,
    },
    familySubsidyProgram: {
      type: Object,
      default: null,
    },
  },

  setup() {
    return { currency };
  },

  data() {
    return {
      claims: [],
      processing: false,
    };
  },

  created() {
    this.processing = true;
    this.load();
  },

  methods: {
    async load() {
      const params = {
        family_subsidy_id: this.familySubsidy.id,
      };

      const resp = await this.api.parent.claim.index(params);
      if (resp?.status !== 200) return;

      this.claims = resp.data;
      this.processing = false;
    },

    async deleteClaim(claim) {
      this.processing = true;
      const resp = await this.api.parent.claim.destroy(claim.id);
      this.processing = false;
      if (resp?.status !== 200) return false;

      this.$eventBus.$emit('chime', 'Claim deleted.');
      this.load();
      return true;
    },

    getClaimActions(claim) {
      if (claim.locked) return [{ avatar: 'visibility', title: 'Review', event: 'view' }];
      if (claim.submitted) return [{ avatar: 'edit', title: 'Edit', event: 'view' }];

      return [
        { avatar: 'edit', title: 'Continue', event: 'continue' },
        { avatar: 'delete', title: 'Delete', event: 'delete' },
      ];
    },

    getClaimStatus(claim) {
      if (claim.approved) return 'Approved';
      if (claim.denied) return 'Denied';
      if (claim.submitted) return 'Submitted';
      return 'Draft';
    },

    getStatusClasses(claim) {
      if (claim.approved) return 'bc-primary c-primary';
      if (claim.denied) return 'bc-red c-red';
      if (claim.submitted) return 'bc-black c-black';
      return 'bc-gray c-gray';
    },
  },
};
</script>
