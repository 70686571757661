<template>
  <v-btn
    @click="handleClick"
    class="overflow-x-visible"
    color="primary"
    variant="flat"
    data-skip-event
  >
    {{ $t(ctaText) }}
  </v-btn>
</template>

<script setup>
import useSegment from '@/shared/composables/analytics/useSegment';
import { computed } from 'vue';

const segment = useSegment();

const props = defineProps({
  eligibilityProgram: {
    type: Object,
    required: true,
  },
});

const ctaText = computed(() => {
  return props.eligibilityProgram.cta_button_text || 'Learn more';
});

function handleClick() {
  segment.track('Eligibility Program CTA Button Clicked', {
    eligibilityProgramId: props.eligibilityProgram.id,
    eligibilityProgramName: props.eligibilityProgram.name,
    destination: props.eligibilityProgram.cta_button_link,
    text: ctaText.value,
  });
  window.open(props.eligibilityProgram.cta_button_link);
}
</script>
