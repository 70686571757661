export default {
  set(config) {
    document.title = config.title;
    document
      .getElementById('meta_description')
      ?.setAttribute('content', config.pages.Welcome.intro);
    document.getElementById('meta_og_title')?.setAttribute('content', config.title);
    document.getElementById('meta_og_image')?.setAttribute('content', config.brand.logo.full);
    document.getElementById('meta_og_url')?.setAttribute('content', config.site.parent_root_url);
    document
      .getElementById('meta_og_description')
      ?.setAttribute('content', config.pages.Welcome.intro);
  },
};
