<template>
  <FormQuestion
    :header="$t('Child') + ' - ' + childName"
    :subtitle="subsidyProgram.preference_subtitle"
    :title="subsidyProgram.preference_title"
    hide-actions
  >
    <v-card
      class="mb-4"
      border
      flat
      tile
    >
      <v-card-title>
        <h4
          v-t="'Ranked selections'"
          class="fs-18"
        />
      </v-card-title>

      <v-divider />

      <v-card-text class="px-0 py-0">
        <div class="bg-super-light-blue px-4 py-3">
          <SelectedProgramCard
            v-for="(programId, index) in programIds"
            @open="openProvider($event)"
            :key="programId"
            :api="api"
            :index="index"
            :length="programIds.length"
            :program-id="programId"
            class="my-4"
            active
            ordered
            readonly
          />
        </div>
      </v-card-text>
    </v-card>

    <template v-if="subsidyProgram.allow_enrolled">
      <v-card
        border
        flat
        tile
      >
        <v-card-title>
          <h4
            v-t="'Current Preschool enrollment'"
            class="fs-18"
          />
        </v-card-title>

        <v-divider />

        <v-card-text class="px-0 py-0">
          <div class="bg-super-light-blue px-4 py-3">
            <template v-if="currentProviderId">
              <SimpleProviderCard
                :api="api"
                :provider-id="currentProviderId"
                hide-toggle
                hide-view-more
              />
            </template>
            <template v-else>
              <p
                v-t="'Not currently enrolled at any of the ranked locations.'"
                class="fs-16 mb-0"
              />
            </template>
          </div>
        </v-card-text>
      </v-card>

      <template v-if="subsidyProgram.enable_sibling_provider_selection">
        <v-card
          border
          flat
          tile
        >
          <v-card-title>
            <h4
              v-t="'Sibling(s) currently enrolled at:'"
              class="fs-18"
            />
          </v-card-title>

          <v-divider />

          <v-card-text class="px-0 py-0">
            <div class="bg-super-light-blue px-4 py-3">
              <template v-if="siblingProviderIds?.length > 0">
                <SimpleProviderCard
                  v-for="(siblingId, index) in siblingProviderIds"
                  :key="index"
                  :api="api"
                  :provider-id="siblingId"
                  hide-toggle
                  hide-view-more
                />
              </template>
              <template v-else>
                <p
                  v-t="'No siblings currently enrolled at any of the ranked locations.'"
                  class="fs-16 mb-0"
                />
              </template>
            </div>
          </v-card-text>
        </v-card>
      </template>

      <template v-if="subsidyProgram.enable_staff_provider_selection">
        <v-card
          border
          flat
          tile
        >
          <v-card-title>
            <h4
              v-t="'Family member a staff member at:'"
              class="fs-18"
            />
          </v-card-title>

          <v-divider />

          <v-card-text class="px-0 py-0">
            <div class="bg-super-light-blue px-4 py-3">
              <template v-if="staffProviderIds?.length > 0">
                <SimpleProviderCard
                  v-for="(staffId, index) in staffProviderIds"
                  :key="index"
                  :api="api"
                  :provider-id="staffId"
                  hide-toggle
                  hide-view-more
                />
              </template>
              <template v-else>
                <p
                  v-t="
                    'None of the family members are employed as staff at any of the ranked locations.'
                  "
                  class="fs-16 mb-0"
                />
              </template>
            </div>
          </v-card-text>
        </v-card>
      </template>
    </template>

    <ContentDialog
      ref="providerDialog"
      :max-width="800"
    >
      <template #content="{ data, unmounted }">
        <ProviderMatch
          v-if="data"
          @unmounted="unmounted"
          :match="data"
          :outlined="false"
          :schema="providerSchema"
        />
      </template>
    </ContentDialog>
  </FormQuestion>
</template>

<script>
import API from '@/shared/mixins/api';
import ContentDialog from '@/shared/components/ContentDialog.vue';
import FormQuestion from '@/shared/components/form/FormQuestion.vue';
import ProviderMatch from '@/shared/components/search/ProviderMatch.vue';
import SelectedProgramCard from '@/parent/components/subsidy/SelectedProgramCard.vue';
import SimpleProviderCard from '@/shared/components/SimpleProviderCard.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    ContentDialog,
    FormQuestion,
    ProviderMatch,
    SelectedProgramCard,
    SimpleProviderCard,
  },

  mixins: [API],

  props: {
    childName: {
      default: null,
      type: String,
    },
    currentProviderId: {
      default: null,
      type: String,
    },
    programIds: {
      default: null,
      type: Array,
    },
    siblingProviderIds: {
      default: null,
      type: Array,
    },
    staffProviderIds: {
      default: null,
      type: Array,
    },
    subsidyProgram: {
      default: null,
      type: Object,
    },
  },

  data() {
    return {
      providerSchema: this.$store.state.schemas[this.subsidyProgram.provider_schema_id],
    };
  },

  methods: {
    openProvider(providerId) {
      this.api.public_api.provider.get(providerId, (resp) => {
        this.$refs.providerDialog.open(resp.data);
      });
    },
  },
};
</script>
