<template>
  <div>
    <v-divider />

    <div class="bg-super-light-blue px-4 py-3">
      <transition-group>
        <div
          v-for="(programId, index) in programIds"
          :key="programId"
          class="mb-6 sortable-item"
        >
          <SelectedProgramCard
            @move="$emit('move', [index, $event])"
            @open="$emit('open', $event)"
            @remove="$emit('remove', programId)"
            :api="api"
            :hide-remove="hideRemove"
            :index="index"
            :length="programIds.length"
            :program-id="programId"
            class="my-4"
            tabindex="0"
            active
            ordered
          />
        </div>
      </transition-group>
    </div>

    <v-divider />

    <v-row class="my-4 px-4">
      <v-col class="d-flex justify-start">
        <v-btn
          @click="$emit('back')"
          class="focus-very-visible"
          color="primary"
          size="x-large"
          tabindex="0"
          variant="outlined"
        >
          <span v-t="'Back to search'" />
        </v-btn>
      </v-col>

      <template v-if="showNext">
        <v-col class="d-flex justify-end">
          <v-btn
            @click="$emit('next')"
            :disabled="programIds.length == 0"
            class="focus-very-visible"
            color="primary"
            data-testid="rankContinueButton"
            size="x-large"
            tabindex="0"
          >
            <span v-t="'Continue'" />
            <v-icon class="ms-2"> east </v-icon>
          </v-btn>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script>
import SelectedProgramCard from '@/parent/components/subsidy/SelectedProgramCard.vue';

export default {
  compatConfig: {
    MODE: 2,
    TRANSITION_GROUP_ROOT: false,
  },

  components: {
    SelectedProgramCard,
  },

  props: {
    api: {
      type: Object,
      default: null,
    },
    programIds: {
      type: Array,
      default: null,
    },
    hideRemove: Boolean,
    selectedProviders: {
      type: Array,
      default: null,
    },
    showNext: Boolean,
    subsidyProgram: {
      type: Object,
      default: null,
    },
  },

  emits: ['back', 'move', 'next', 'open', 'remove'],
};
</script>
