<template>
  <FormQuestion
    :header="$t('Child') + ' - ' + childName"
    :subtitle="subsidyProgram.preference_subtitle"
    :title="subsidyProgram.preference_title"
    hide-actions
  >
    <v-card
      class="mb-4"
      border
      flat
      tile
    >
      <v-card-title>
        <h4
          v-t="'Ranked selections'"
          class="fs-18"
        />
      </v-card-title>

      <v-divider />

      <v-card-text class="px-0 py-0">
        <div class="bg-super-light-blue px-4 py-3">
          <FavoriteCard
            v-for="(providerId, index) in providerIds"
            :key="providerId"
            :api="api"
            :index="index"
            :provider-id="providerId"
            class="mb-3"
            hide-toggle
            ordered
            readonly
          />
        </div>
      </v-card-text>
    </v-card>

    <template v-if="subsidyProgram.allow_enrolled">
      <v-card
        border
        flat
        tile
      >
        <v-card-title>
          <h4
            v-t="'Current Preschool enrollment'"
            class="fs-18"
          />
        </v-card-title>

        <v-divider />

        <v-card-text class="px-0 py-0">
          <div class="bg-super-light-blue px-4 py-3">
            <template v-if="currentProviderId">
              <SimpleProviderCard
                :api="api"
                :provider-id="currentProviderId"
                hide-toggle
                hide-view-more
              />
            </template>
            <template v-else>
              <p
                v-t="'Not currently enrolled at any of the ranked locations.'"
                class="fs-16 mb-0"
              />
            </template>
          </div>
        </v-card-text>
      </v-card>
    </template>
  </FormQuestion>
</template>

<script>
import API from '@/shared/mixins/api';
import FavoriteCard from '@/parent/components/favorites/FavoriteCard.vue';
import FormQuestion from '@/shared/components/form/FormQuestion.vue';
import SimpleProviderCard from '@/shared/components/SimpleProviderCard.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    FavoriteCard,
    FormQuestion,
    SimpleProviderCard,
  },

  mixins: [API],

  props: {
    childName: {
      type: String,
      default: null,
    },
    currentProviderId: {
      type: String,
      default: null,
    },
    providerIds: {
      type: Array,
      default: null,
    },
    subsidyProgram: {
      type: Object,
      default: null,
    },
  },
};
</script>
