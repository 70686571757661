<template>
  <div
    v-if="search"
    class="flex-grow-1"
  >
    <v-row
      v-if="$vuetify.display.mdAndUp"
      dense
    >
      <v-col
        cols="12"
        md="6"
      >
        <v-menu
          v-model="locationMenuOpen"
          :close-on-content-click="false"
          offset="5"
        >
          <template #activator="{ props }">
            <v-btn
              @keydown.tab.exact.stop.prevent="focusFirstLocationType()"
              v-bind="props"
              ref="locationMenuActivator"
              append-icon="expand_more"
              aria-label="Change search location"
              class="mxw-100pc justify-space-between"
              color="surface-light"
              variant="flat"
              block
            >
              <v-icon
                aria-hidden="true"
                class="me-2 fs-24"
                color="primary"
              >
                location_on
              </v-icon>
              <span
                v-t="search.unformatted_origin || search.formatted_origin"
                class="fs-16 mxw-70pc constrained fs-16"
              />
              <v-spacer />
            </v-btn>
          </template>

          <template v-if="searchFlags.regionSearchEnabled">
            <v-card class="pa-4">
              <div
                v-t="'Search by zip code, city or county'"
                class="fs-20 mb-4"
              />
              <v-text-field
                v-model="search.unformatted_origin"
                @keyup.enter="search.unformatted_origin ? updateLocation() : false"
                :placeholder="$t('Enter zip code, city or county')"
                variant="solo-filled"
                hide-details
              />
              <div class="mt-3 ta-right">
                <v-btn
                  @click="updateLocation"
                  :disabled="!search.unformatted_origin"
                  class="focus-very-visible"
                  color="primary"
                  data-cy="location_done"
                >
                  {{ $t('Done') }}
                </v-btn>
              </div>
            </v-card>
          </template>
          <template v-else>
            <v-card>
              <v-card-text>
                <div
                  v-t="'Search for care near:'"
                  class="c-black fs-16 fw-600"
                />
                <SearchLocation
                  @close="updateLocation"
                  ref="searchLocation"
                  :disable-mode="true"
                  :value="search"
                />
              </v-card-text>
            </v-card>
          </template>
        </v-menu>
      </v-col>

      <v-col
        v-if="searchFlags.transportationOptionsEnabled"
        cols="12"
        md="3"
      >
        <v-menu
          v-model="travelModeMenuOpen"
          :close-on-content-click="false"
          offset="5"
        >
          <template #activator="{ props }">
            <v-btn
              @keydown.tab.exact.stop.prevent="focusFirstTravelMode()"
              v-bind="props"
              ref="travelModeActivator"
              append-icon="expand_more"
              aria-label="Change travel mode"
              class="mxw-100pc justify-space-between"
              color="surface-light"
              variant="flat"
              block
            >
              <v-icon
                aria-hidden="true"
                class="me-2 fs-24"
                color="primary"
                >{{ $t(travelModeIcon) }}</v-icon
              >
              <span
                v-t="$t(travelMode)"
                class="mxw-70pc constrained fs-16"
              />
              <v-spacer />
            </v-btn>
          </template>

          <v-card>
            <v-card-text class="pe-8">
              <v-radio-group
                v-model="search.travel_mode"
                class="mt-0 pa-1"
                hide-details
              >
                <v-radio
                  v-for="type in $a.assets.transit_options"
                  :key="type.value"
                  ref="travelMode"
                  :label="$t(type.text)"
                  :value="type.value"
                />
              </v-radio-group>
            </v-card-text>

            <v-card-actions class="ta-right">
              <v-spacer />
              <v-btn
                @click="updateSearch()"
                class="focus-very-visible"
                color="primary"
              >
                {{ $t('Done') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-col>

      <v-col
        v-if="searchFlags.dobSearchEnabled"
        cols="12"
        md="3"
      >
        <ChildrenMenuV2
          @addChild="(child) => handleAddChild(child)"
          @removeChild="(index) => handleRemoveChild(index)"
          @updateChild="(child, index) => handleChildrenOverride(child, index)"
          :children="search.children"
          :inline-style="true"
        />
      </v-col>

      <v-col
        v-else-if="!searchFlags.defaultAgeGroupEnabled"
        cols="12"
        md="3"
      >
        <v-menu
          v-model="childrenMenu"
          :close-on-content-click="false"
          offset="5"
        >
          <template #activator="{ props }">
            <v-btn
              @keydown.tab="focusChildVolume()"
              v-bind="props"
              ref="childrenMenuActivator"
              append-icon="expand_more"
              aria-label="Change age groups"
              class="justify-space-between"
              color="surface-light"
              variant="flat"
              block
            >
              <v-icon
                aria-hidden="true"
                class="me-2 fs-24"
                color="primary"
                role="presentation"
              >
                person_outline
              </v-icon>
              <span
                v-t="$t(childrenValue)"
                class="mxw-70pc constrained fs-16"
              />
              <v-spacer />
            </v-btn>
          </template>

          <v-card>
            <v-card-text>
              <VolumeList
                v-model="ageGroups"
                ref="childVolume"
                :open="childrenMenu"
              />
              <v-divider class="my-4" />
              <v-checkbox
                v-model="search.include_all_children"
                :label="$t('Only show me results that serve all ages selected')"
                hide-details
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                @click="updateAgeGroups()"
                class="focus-very-visible"
                color="primary"
              >
                Done
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>

    <div v-if="$vuetify.display.smAndDown">
      <v-row
        v-if="$store.state.expandAppBarSmAndDown"
        class="d-inline-flex"
        dense
      >
        <v-dialog
          v-model="searchDialog"
          fullscreen
        >
          <v-card style="position: relative; padding-bottom: 50px">
            <v-card-text class="px-3 pt-8">
              <div v-if="searchDialogMode == 'location'">
                <div
                  v-t="'Search for care near:'"
                  class="c-black fs-18 fw-600 mb-2"
                />
                <SearchLocation
                  :open="searchDialog"
                  :value="search"
                  hide-close
                />
              </div>

              <div v-if="searchDialogMode == 'mode'">
                <div
                  v-t="'Show commutes by:'"
                  class="c-black fs-18 fw-600 mb-2"
                />
                <v-list
                  v-model:selected="search.travel_mode"
                  mandatory
                >
                  <v-list-item
                    v-for="type in $a.assets.transit_options"
                    :key="type.value"
                    :value="type.value"
                  >
                    <v-list-item-title>{{ $t(type.text) }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </div>

              <div v-if="searchDialogMode == 'children' && !searchFlags.dobSearchEnabled">
                <div
                  v-t="'Add children:'"
                  class="c-black fs-18 fw-600 mb-2"
                />
                <LabeledVolume
                  v-for="(item, index) in ageGroups"
                  @decrease="modifyCount(index, -1)"
                  @increase="modifyCount(index, 1)"
                  :key="item.name"
                  :count="item.count"
                  :dense="ageGroups.length > 6"
                  :subtitle="item.subtitle || item.subtext"
                  :title="item.title || item.text"
                />
              </div>

              <div v-if="searchDialogMode == 'children' && searchFlags.dobSearchEnabled">
                <SearchDobChild
                  @addChild="(child) => handleAddChild(child)"
                  @removeChild="(index) => handleRemoveChild(index)"
                  @updateChild="(child, index) => handleChildrenOverride(child, index)"
                  :children="search.children"
                />
              </div>
            </v-card-text>
            <v-card-actions style="position: absolute; bottom: 0; width: 100%">
              <v-btn
                @click="searchDialog = false"
                color="primary"
                block
              >
                {{ $t('Done') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-col
          cols="12"
          md="6"
        >
          <div class="d-flex">
            <v-text-field
              @click="openSearchDialog('location')"
              @click:append="openSearchDialog('location')"
              :model-value="search.formatted_origin"
              append-icon="expand_more"
              density="compact"
              variant="filled"
              hide-details
              readonly
            >
              <template #prepend-inner>
                <v-icon
                  @click="openSearchDialog('location')"
                  class="me-1 pt-2px"
                  color="primary"
                >
                  location_on
                </v-icon>
              </template>
            </v-text-field>
          </div>
        </v-col>

        <v-col
          v-if="searchFlags.transportationOptionsEnabled"
          cols="12"
          md="3"
        >
          <v-text-field
            @click="openSearchDialog('mode')"
            @click:append="openSearchDialog('mode')"
            :model-value="$t(travelMode)"
            append-icon="expand_more"
            density="compact"
            variant="filled"
            hide-details
            readonly
          >
            <template #prepend-inner>
              <v-icon
                @click="openSearchDialog('mode')"
                class="me-1 pt-2px"
                color="primary"
              >
                directions_car
              </v-icon>
            </template>
          </v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            @click="openSearchDialog('children')"
            @click:append="openSearchDialog('children')"
            :model-value="childrenValue"
            append-icon="expand_more"
            density="compact"
            variant="filled"
            hide-details
            readonly
          >
            <template #prepend-inner>
              <v-icon
                @click="openSearchDialog('children')"
                class="me-1 pt-2px"
                color="primary"
              >
                person_outline
              </v-icon>
            </template>
          </v-text-field>
        </v-col>
      </v-row>

      <v-row v-else>
        <v-col cols="12">
          <v-text-field
            @click:append="expand"
            @focus="expand"
            :model-value="search.formatted_origin"
            append-icon="expand_more"
            density="compact"
            variant="filled"
            hide-details
            readonly
          >
            <template #prepend-inner>
              <v-icon
                class="me-1 pt-2px"
                color="primary"
              >
                location_on
              </v-icon>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import ChildrenMenuV2 from '@/parent/components/children/ChildrenMenuV2.vue';
import SearchDobChild from '@/parent/components/searches/SearchDobChild.vue';
import SearchFlags from '@/parent/services/search-flags';
import SearchLocation from '@/shared/components/search/location.vue';
import VolumeList from '@/shared/components/form/VolumeList.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    SearchLocation,
    VolumeList,
    ChildrenMenuV2,
    SearchDobChild,
  },

  data() {
    const searchFlags = new SearchFlags(this.$store);

    return {
      ageGroups: searchFlags.ageGroups,
      childrenMenu: false,
      locationMenuOpen: false,
      query: null,
      search: null,
      searchDialog: false,
      searchDialogMode: false,
      searchFlags,
      searchedProviders: [],
      selectedProvider: null,
      travelModeMenuOpen: false,
    };
  },

  computed: {
    sum() {
      if (!this.searchFlags.dobSearchEnabled) {
        let sum = 0;
        this.ageGroups.forEach((item) => {
          sum += item.count;
        });
        return sum;
      }

      return this.search.children.filter(
        (child) => child.dob !== null && child.care_needed_date !== null,
      ).length;
    },

    childrenValue() {
      return [this.sum, this.sum === 1 ? this.$t('child') : this.$t('children')].join(' ');
    },

    travelMode() {
      return (
        (this.search.travel_mode || 'DRIVING').charAt(0).toUpperCase() +
        (this.search.travel_mode || 'DRIVING').slice(1).toLowerCase()
      );
    },

    travelModeIcon() {
      switch (this.search.travel_mode) {
        case 'DRIVING':
          return 'directions_car';
        case 'BICYCLING':
          return 'directions_bike';
        case 'TRANSIT':
          return 'directions_transit';
        case 'WALKING':
          return 'directions_walk';
        default:
          return null;
      }
    },
  },

  watch: {
    childrenMenu(newVal) {
      if (!newVal) {
        this.$refs.childrenMenuActivator.$el.focus();
      }
    },

    '$store.state.search': {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.search = newVal;
          this.setChildren();
        }
      },
    },

    locationMenuOpen(newVal) {
      if (!newVal) {
        this.$refs.locationMenuActivator.$el.focus();
      }
    },

    travelModeMenuOpen(newVal) {
      if (!newVal) {
        this.$refs.travelModeActivator.$el.focus();
      }
    },

    searchDialog(newVal) {
      if (!newVal) {
        if (this.searchDialogMode === 'location' || this.searchDialogMode === 'mode') {
          this.updateLocation();
        }

        if (!this.searchFlags.dobSearchEnabled) {
          this.updateAgeGroups();
        }
      }
    },
  },

  methods: {
    handleAddChild(child) {
      // We need to duplicate the child object otherwise the equality check on remove has false positive
      this.search.children.push({ ...child });
    },

    handleRemoveChild(index) {
      const childrenWithoutRemoved = this.search.children.filter(
        (child) => child !== this.search.children[index],
      );
      this.search.children = childrenWithoutRemoved;
      this.$eventBus.$emit('search-update', this.search);
    },

    handleChildrenOverride(child, index) {
      this.$set(this.search.children, index, child);
      this.$eventBus.$emit('search-update', this.search);
    },

    expand() {
      this.$store.commit('setExpandAppBarSmAndDown', true);
    },

    focusChildVolume() {
      this.$nextTick(() => {
        if (this.$refs.childVolume) {
          this.$refs.childVolume.focus();
        }
      });
    },

    focusFirstLocationType() {
      this.$nextTick(() => {
        if (this.locationMenuOpen) {
          this.$refs.searchLocation.$refs.locationHome.$el.focus();
        } else {
          this.$refs.travelModeActivator.$el.focus();
        }
      });
    },

    focusFirstTravelMode() {
      this.$nextTick(() => {
        if (this.travelModeMenuOpen) {
          this.$refs.travelMode[0].$refs.input.focus();
        } else {
          this.$refs.childrenMenuActivator.$el.focus();
        }
      });
    },

    openSearchDialog(mode) {
      this.searchDialogMode = mode;
      this.searchDialog = true;
    },

    updateAgeGroups() {
      this.childrenMenu = false;
      this.search.ageGroups = this.ageGroups;
      this.$eventBus.$emit('search-update', this.search);
    },

    updateLocation() {
      this.travelModeMenuOpen = false;
      this.updateSearch();
    },

    updateSearch() {
      this.locationMenuOpen = false;
      this.travelModeMenuOpen = false;
      this.$eventBus.$emit('search-update', this.search);
    },

    modifyCount(index, addition) {
      this.ageGroups[index].count += addition;
    },

    setChildren() {
      this.search.children.forEach((child) => {
        const index = this.ageGroups.findIndex((age) => age.name === child.group);

        if (index >= 0) {
          this.ageGroups[index].count = child.count;
          this.ageGroups[index].group = this.ageGroups[index].name;
        }
      }, this);
    },
  },
};
</script>
