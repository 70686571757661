<template>
  <div>
    <template v-if="registrationEnabled">
      <v-row>
        <v-col>
          <v-btn
            @click="updateQuery({ mode: 'signup' })"
            :ripple="false"
            class="fs-16 fw-500 pointer c-primary bc-link-hover px-0 underlined"
            data-cy="signup-mode-button"
            variant="text"
            >{{ $t('Or, create an account.') }}</v-btn
          >
        </v-col>
      </v-row>
    </template>

    <template v-if="ssoEnabled">
      <v-row>
        <v-col>
          <LoginWithSSO />
        </v-col>
      </v-row>
    </template>

    <template v-if="magicLinkEnabled || passwordEnabled">
      <template v-if="ssoEnabled">
        <v-divider class="my-8" />
      </template>

      <v-row>
        <template v-if="phoneAuthenticationEnabled">
          <LabeledSimpleSelect
            v-model="loginMode"
            @change="resetLoginFactors"
            :items="[
              { text: $t('Email'), value: 'Email' },
              { text: $t('Cell phone'), value: 'Cell phone' },
            ]"
            :message="'Email or phone'"
            :tile="false"
            :variant="'outlined'"
            cols="12"
            data-cy="login-mode-selector"
            md="3"
            mandatory
          />
        </template>

        <LabeledTextfield
          v-show="loginMode == 'Email'"
          v-model="email"
          :aria-label="$t('Enter your email')"
          :md="phoneAuthenticationEnabled ? '9' : '12'"
          :message="'Email'"
          :tile="false"
          :variant="'outlined'"
          autocomplete="off"
          cols="12"
          data-cy="login_email"
          mandatory
        />

        <LabeledTextfield
          v-show="loginMode == 'Cell phone'"
          v-model="phone"
          @keyup.enter="login"
          :aria-label="$t('Enter phone number')"
          :message="'Cell phone number'"
          :tile="false"
          :variant="'outlined'"
          cols="12"
          data-cy="login_phone"
          mask="(###) ###-####"
          md="9"
          placeholder="(555) 555-5555"
          mandatory
        />

        <template v-if="passwordEnabled">
          <LabeledTextfield
            v-show="!requestingLoginCode"
            v-model="password"
            @keyup.enter="login"
            :aria-label="$t('Enter password')"
            :message="'Password'"
            :tile="false"
            :variant="'outlined'"
            data-cy="login_password"
            type="password"
            mandatory
          />

          <v-col>
            <PasswordReset scope="parent" />
          </v-col>
        </template>
      </v-row>

      <v-row class="mt-3">
        <v-col>
          <v-btn
            @click="login"
            :disabled="submitDisabled"
            :loading="processing"
            :ripple="false"
            class="c-white focus-very-visible"
            color="primary"
            data-cy="login-button"
            size="x-large"
            block
          >
            <span v-t="'Login'" />
          </v-btn>
        </v-col>
      </v-row>

      <template v-if="passwordEnabled">
        <v-row class="d-flex align-center">
          <v-col>
            <template v-if="requestingLoginCode">
              <v-btn
                @click="requestingLoginCode = false"
                :loading="processing"
                :ripple="false"
                color="primary"
                data-cy="toggle-password-button"
                size="small"
                variant="outlined"
              >
                <span v-t="'Login with email and password.'" />
              </v-btn>
            </template>

            <template v-else-if="magicLinkEnabled">
              <v-btn
                @click="requestingLoginCode = true"
                :loading="processing"
                :ripple="false"
                color="primary"
                data-cy="toggle-code-button"
                size="large"
                variant="outlined"
              >
                <span v-t="'Request one-time login code link.'" />
              </v-btn>
            </template>
          </v-col>
        </v-row>
      </template>
    </template>

    <v-dialog
      v-model="challengeDialogIsVisible"
      :max-width="600"
    >
      <v-card
        border
        flat
        tile
      >
        <v-card-text class="pa-6">
          <v-row dense>
            <v-col class="d-flex justify-center">
              <p
                v-t="'Enter the one-time passcode sent to your device:'"
                class="fs-16 fw-600"
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col class="d-flex justify-center">
              <v-otp-input
                v-model="code"
                @finish="createSession"
                :disabled="processing"
                class="mxw-400"
                data-cy="otp-input"
                length="6"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import API from '@/shared/mixins/api';
import LoginWithSSO from '@/shared/components/LoginWithSSO.vue';
import PasswordReset from '@/shared/components/PasswordReset.vue';
import RouterHelper from '@/shared/mixins/RouterHelper';

export default {
  compatConfig: { MODE: 2 },

  components: {
    LoginWithSSO,
    PasswordReset,
  },

  mixins: [API, RouterHelper],

  data() {
    return {
      challenge: null,
      challengeDialogIsVisible: false,
      code: null,
      email: null,
      loading: true,
      loginMode: 'Email',
      mfaAttempts: 0,
      password: null,
      phone: null,
      processing: null,
      requestingLoginCode: false,
    };
  },

  computed: {
    magicLinkEnabled() {
      return this.$store.state.config.enable_magic_link_authentication;
    },

    passwordEnabled() {
      return this.$store.state.config.enable_password_authentication;
    },

    phoneAuthenticationEnabled() {
      return this.$store.state.config.enable_phone_authentication;
    },

    registrationEnabled() {
      return !this.$store.state.config.disable_self_registration;
    },

    ssoEnabled() {
      return this.$store.state.site.sso_connections?.length > 0;
    },

    submitDisabled() {
      if (this.loginMode === 'Email' && !this.email) return true;

      if (this.loginMode === 'Cell phone' && !this.phone) return true;

      return false;
    },
  },

  methods: {
    login() {
      if (this.passwordEnabled && !this.requestingLoginCode) {
        this.createSession();
      } else {
        this.createLoginCode();
      }
    },

    createLoginCode() {
      this.processing = true;
      this.api.member.login_code.create(
        {
          is_parent: true,
          email: this.email,
          organization_id: window.organization_id,
          phone: this.phone,
          redirect_url: this.$route.query.redirect_url,
          site_id: window.site_id,
        },
        (resp) => {
          this.processing = false;
          this.$router.push({
            name: 'CodeSent',
            query: {
              ...this.$route.query,
              mode: 'login',
              phone: this.phone,
              token: resp.data.code_id,
              email_sent_to: this.email,
            },
          });
        },
        (err) => {
          this.processing = false;
          this.$eventBus.$emit('chime', err.response.data.errors[0]);
        },
      );
    },

    createSession() {
      this.processing = true;
      this.api.member.session.create(
        {
          challenge_id: this.challenge,
          code: this.code,
          email: this.email,
          organization_id: window.organization_id,
          password: this.password,
          scope: 'parent',
        },
        (resp) => {
          const { status } = resp.data;
          this.processing = false;
          if (status === 'challenge') {
            this.challenge = resp.data.challenge_id;
            this.challengeDialogIsVisible = true;
            this.code = null;
            this.mfaAttempts += 1;
            if (this.mfaAttempts > 1) {
              this.$eventBus.$emit(
                'chime',
                'Your previous code has expired. A new code has been sent to your device.',
              );
            }
          } else if (status === 'mfa_failed') {
            this.code = null;
            this.$eventBus.$emit(
              'chime',
              'You entered an incorrect code. Please re-enter and try again.',
            );
          } else if (status === 'authenticated') {
            const vm = this;
            this.$store.dispatch('identify', {
              success: () => {
                if (this.$route.query.redirect_url) {
                  this.$router.push(this.$route.query.redirect_url);
                } else {
                  vm.$router.push({ name: 'Dashboard' });
                }
              },
              failure: () => {
                vm.$eventBus.$emit(
                  'chime',
                  'An unknown error has occurred. Please contact support for assistance.',
                );
              },
            });
          } else {
            this.$eventBus.$emit(
              'longChime',
              'An unknown error has occurred. Please contact support for assistance.',
            );
          }
        },
        () => {
          this.processing = false;
          this.$eventBus.$emit(
            'chime',
            'The password/email you entered is incorrect. Please try again.',
          );
        },
      );
    },

    resetLoginFactors() {
      this.email = null;
      this.phone = null;
    },
  },
};
</script>
