import { calculateMonthsOldOnDate } from '@/parent/services/calculate-months-old-on-date';

function getAgeGroup({ searchFlags, child, careNeededDate }) {
  if (!searchFlags.dobSearchEnabled && searchFlags.defaultAgeGroupEnabled) {
    return searchFlags.defaultAgeGroup;
  }

  const monthsOld = calculateMonthsOldOnDate(child.dob, careNeededDate);

  return searchFlags.ageGroups.find(
    (ageGroupObject) => ageGroupObject.min <= monthsOld && ageGroupObject.max >= monthsOld,
  );
}

export default function childrenSearchParam({ searchFlags, child, careNeededDate, toArray }) {
  const ageGroup =
    getAgeGroup({
      searchFlags,
      child,
      careNeededDate,
    }) || {};

  const childObject = {
    ageGroupName: ageGroup?.name || null, // Included for reporting
    dob: child.dob,
    care_needed_date: careNeededDate,
    included: true,
  };

  // This field is being deprecated in the Search API
  if (child.name) childObject.name = child.name;

  return toArray ? [childObject] : childObject;
}
