<template>
  <v-card
    class="b-1 mb-4 b-radius-8"
    data-testid="dashboard-task"
    elevation="0"
    style="border-color: #f79009"
  >
    <v-card-text>
      <v-row>
        <v-col class="d-flex align-center">
          <v-avatar
            class="me-3"
            color="#F79009"
            size="40"
            variant="tonal"
          >
            <v-icon
              color="#F79009"
              size="24"
            >
              priority_high
            </v-icon>
          </v-avatar>

          <h3 class="fs-18">
            <span class="fw-500 me-2">{{ $t(item.action) }}:</span>
            <span v-if="item.child_name"> {{ item.child_name }}, </span>
            <span data-testid="application-task-program-name">
              {{ $t(item.program_name) }}
            </span>
          </h3>
        </v-col>

        <v-col class="d-flex justify-end">
          <v-btn
            :to="link"
            class="fs-16"
            color="primary"
            role="link"
          >
            {{ $t(item.action) }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { getNextStepLink } from '@/parent/services/dashboard';

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

const link = computed(() => getNextStepLink(props.item));
</script>
