const waitForElement = (selector) =>
  new Promise((resolve) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });

export function hideInaccessibleRecaptchaElements() {
  waitForElement('#g-recaptcha-response').then((element) => {
    element.setAttribute('aria-hidden', 'true');
    element.setAttribute('aria-label', 'do not use');
    element.setAttribute('aria-readonly', 'true');
  });
}
