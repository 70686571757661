<template>
  <v-card
    border="sm"
    class="mb-4 py-4"
    rounded="lg"
    style="border: 1px solid #e4e7ec"
  >
    <v-card-text>
      <template v-if="$vuetify.display.smAndDown">
        <v-row class="d-flex">
          <v-col class="d-flex align-center">
            <h3 :class="{ 'c-gray': !actionable }">
              {{ subsidyProgram.name }}
            </h3>
          </v-col>
        </v-row>

        <v-row v-if="shouldShowChips">
          <v-col class="d-flex align-center flex-wrap ga-2">
            <SubsidyProgramApplyChips
              :showInProgress="applicationInProgress"
              :showIneligible="!childEligible"
              :showSubmitted="previouslyApplied"
            />
          </v-col>
        </v-row>

        <v-row v-if="shouldShowApplyButton">
          <v-col
            class="d-flex align-center"
            cols="auto"
          >
            <v-btn
              @click="$emit('action')"
              color="primary"
            >
              {{ $t(buttonText) }}
            </v-btn>
          </v-col>
        </v-row>
      </template>

      <template v-else>
        <v-row class="d-none d-sm-flex mt-none">
          <v-col class="d-flex align-center">
            <h3 :class="{ 'c-gray': !actionable }">
              {{ subsidyProgram.name }}
            </h3>
          </v-col>
          <v-spacer />
          <v-col
            v-if="shouldShowChips"
            class="d-flex align-center ga-2"
            cols="auto"
          >
            <SubsidyProgramApplyChips
              :showInProgress="applicationInProgress"
              :showIneligible="!childEligible"
              :showSubmitted="previouslyApplied"
            />
          </v-col>
          <v-col
            v-if="shouldShowApplyButton"
            class="d-flex align-center"
            cols="auto"
          >
            <v-btn
              @click="$emit('action')"
              color="primary"
              data-testid="action-button"
            >
              {{ $t(buttonText) }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-card-text>
  </v-card>
</template>

<script setup>
import SubsidyProgramApplyChips from '@/parent/components/ChildApplySubsidyProgramChips.vue';

const props = defineProps({
  subsidyProgram: {
    type: Object,
    required: true,
  },
  applicationInProgress: {
    type: Boolean,
    required: true,
  },
  childEligible: {
    type: Boolean,
    required: true,
  },
  childCanApply: {
    type: Boolean,
    required: true,
  },
  previouslyApplied: {
    type: Boolean,
    required: true,
  },
  buttonText: {
    type: String,
    required: true,
  },
  actionable: {
    type: Boolean,
    default: false,
  },
});

defineEmits(['action']);

const shouldShowChips = computed(() => {
  return (
    !props.childEligible ||
    (props.childEligible && (props.previouslyApplied || props.applicationInProgress))
  );
});

const shouldShowApplyButton = computed(() => {
  return props.childEligible && props.childCanApply;
});
</script>
