<template>
  <div>
    <p
      v-t="'Search for your provider by name'"
      class="fs-16 fw-500 mb-4"
    />
    <v-autocomplete
      v-model="selectedProvider"
      v-model:search="providerQuery"
      @update:model-value="setProvider($event)"
      :items="searchedProviders"
      :placeholder="$t('Search')"
      aria-label="Search by location name"
      class="mb-4"
      color="secondary"
      density="compact"
      item-title="name"
      item-value="id"
      prepend-inner-icon="search"
      variant="filled"
      auto-select-first
      hide-details
      hide-no-data
      no-filter
      return-object
      rounded
    >
      <template #item="{ item, props }">
        <v-list-item
          v-bind="props"
          :subtitle="item.raw.location"
        />
      </template>
    </v-autocomplete>

    <div
      v-for="provider_id in subsidy.provider_ids"
      :key="provider_id"
      class="mb-6"
    >
      <FavoriteCard
        :active="true"
        :index="0"
        :length="1"
        :provider-id="provider_id"
        class="my-4"
        hide-toggle
      />
    </div>
  </div>
</template>

<script>
import Api from '@/shared/services/bright_finder';
import FavoriteCard from '@/parent/components/favorites/FavoriteCard.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    FavoriteCard,
  },

  props: {
    child: { type: Object, default: null },
    subsidy: { type: Object, default: null },
  },

  data() {
    return {
      providerQuery: null,
      searchedProviders: [],
      selectedProvider: null,
    };
  },

  watch: {
    providerQuery(val) {
      if (val) {
        this.searchProviders();
      }
    },
  },

  methods: {
    searchProviders() {
      Api.public_api.provider.index({ query: this.providerQuery }, (resp) => {
        this.searchedProviders = resp.data;
      });
    },

    setProvider(provider) {
      if (provider) {
        // eslint-disable-next-line vue/no-mutating-props
        this.subsidy.provider_ids.splice(0);
        // eslint-disable-next-line vue/no-mutating-props
        this.subsidy.provider_ids.push(provider.id);
      } else {
        // eslint-disable-next-line vue/no-mutating-props
        this.subsidy.provider_ids.splice(0);
      }
    },
  },
};
</script>
