<template>
  <div v-if="program">
    <v-card
      class="pa-0 border-t-0"
      border
      flat
      tile
    >
      <v-row class="py-4 d-flex align-center">
        <v-col class="d-flex align-center px-8">
          <template v-if="ordered">
            <template v-if="readonly">
              <SelectionRanking :index="index" />
            </template>
            <template v-else>
              <v-btn
                @click.stop.prevent="$emit('move', -1)"
                :disabled="!(index > 0)"
                class="me-3 focus-very-visible"
                color="primary"
                data-cy="selection-down"
                data-testid="selection-down"
                size="small"
                variant="outlined"
                icon
              >
                <v-icon>expand_less</v-icon>
              </v-btn>
              <SelectionRanking :index="index" />
              <v-btn
                @click.stop.prevent="$emit('move', 1)"
                :disabled="!(length > index + 1)"
                class="ms-3 focus-very-visible"
                color="primary"
                data-cy="selection-up"
                data-testid="selection-up"
                size="small"
                variant="outlined"
                icon
              >
                <v-icon>expand_more</v-icon>
              </v-btn>
            </template>
          </template>
        </v-col>
        <template v-if="!readonly && !hideRemove">
          <v-col class="d-flex justify-end px-8">
            <v-btn
              @click="remove"
              class="c-white focus-very-visible"
              color="red"
              size="small"
              tabindex="0"
            >
              <span v-t="'Remove'" />
            </v-btn>
          </v-col>
        </template>
      </v-row>

      <v-divider />

      <v-card-text>
        <v-row class="d-flex align-start">
          <v-col>
            <div class="c-black">
              <v-row
                class="ma-0 pa-0 d-flex align-center"
                dense
              >
                <v-col cols="11">
                  <h4
                    v-t="program.provider.name"
                    class="c-black fw-600 fs-20"
                    data-cy="selection-name"
                    tabindex="0"
                    dense
                  />
                  <div
                    v-if="program.provider.license_business_name != program.provider.name"
                    class="fs-16"
                  >
                    {{ program.provider.license_business_name }}
                  </div>
                </v-col>
              </v-row>
              <v-row
                class="ma-0 pa-0"
                dense
              >
                <v-col>
                  <span
                    class="fs-16 fw-400 me-1"
                    tabindex="0"
                    >{{
                      [program.provider.address1, program.provider.city, program.provider.zip].join(
                        ', ',
                      )
                    }}</span
                  >
                  <span
                    v-if="program.provider.distance_from_origin"
                    class="fs-16 fw-400"
                    tabindex="0"
                    >- {{ program.provider.distance_from_origin }} {{ $t('miles away') }}</span
                  >
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <template v-if="program.provider.public_notice">
          <v-row class="bg-primary mb-0 c-white">
            <v-col>
              <span
                v-t="program.provider.public_notice"
                class="fs-14 px-8"
              />
            </v-col>
          </v-row>
        </template>
      </v-card-text>
    </v-card>
    <v-card
      :key="program.id"
      class="px-3 py-0 border-t-0"
      tabindex="0"
      border
      flat
      tile
    >
      <v-row
        class="d-flex align-center"
        dense
      >
        <v-col class="py-6 px-3">
          <v-icon
            class="c-black me-3"
            size="22"
          >
            chair_alt
          </v-icon>
          <span v-t="program.name" />
        </v-col>
      </v-row>
    </v-card>
    <v-card
      class="border-t-0"
      border
      flat
      tile
    >
      <v-card-actions class="bt-1 border-t-0 px-4 bc-extra-light-grey pointer justify-end">
        <v-btn
          @click="$emit('open', program.provider_id)"
          append-icon="chevron_right"
          class="focus-very-visible"
          color="primary"
          data-cy="match-view-more"
          variant="outlined"
        >
          {{ $t('View more information') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import API from '@/shared/mixins/api';
import SelectionRanking from '@/shared/components/subsidy/SelectionRanking.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    SelectionRanking,
  },

  mixins: [API],

  props: {
    active: Boolean,
    index: {
      type: Number,
      default: null,
    },
    length: {
      type: Number,
      default: null,
    },
    ordered: Boolean,
    programId: {
      type: String,
      default: null,
    },
    readonly: Boolean,
    hideRemove: Boolean,
  },

  emits: ['move', 'open', 'remove'],

  data() {
    return {
      program: null,
    };
  },

  watch: {
    programId: {
      immediate: true,
      async handler(newVal) {
        if (newVal) {
          this.load();
        }
      },
    },
  },

  methods: {
    async load() {
      const resp = await this.api.public_api.organization.program.get(this.programId, {
        include: 'provider',
      });
      this.program = resp.data;
    },

    remove() {
      this.$emit('remove');
    },
  },
};
</script>
