<template>
  <v-container
    class="px-0 pt-0"
    fluid
  >
    <template v-if="dashboardView && defaultScreenerProgramId">
      <router-link
        :to="{
          name: 'EligibilityScreener',
          params: {
            screenerProgramId: defaultScreenerProgramId,
          },
          query: {
            eligibilityScreenerId: draftEligibilityScreenerId,
          },
        }"
        class="d-flex justify-end hover-underlined fw-600"
      >
        {{
          $t(
            eligibilityScreener
              ? 'Retake the Early Childhood Services Finder questionnaire'
              : 'Take the Early Childhood Services Finder questionnaire',
          )
        }}
      </router-link>
    </template>

    <template v-if="eligibilityScreener">
      <div
        v-if="!dashboardView"
        class="bg-primary pt-6"
      >
        <div class="d-flex align-center mxw-1000 mx-auto justify-end">
          <v-btn
            :to="{ path: '/register' }"
            append-icon="save"
            class="c-primary fs-16"
            variant="flat"
          >
            <MarkdownContent
              :content="
                screenerProgram.create_account_cta_button_text ||
                'Create an account to save your results'
              "
            />
          </v-btn>
        </div>
      </div>

      <PageHero
        v-if="!dashboardView"
        :subtitle="
          screenerProgram?.results_page_subtitle ||
          'Based on the information you provided, we believe you are likely eligible for the following programs'
        "
        :title="
          screenerProgram.results_page_title ||
          'Based on the information you provided, here are your eligibility results'
        "
        class="pb-6 mb-6"
      />

      <div
        v-if="eligibleEligibilityPrograms.length"
        :class="resultsWidthClasses"
      >
        <div class="mb-4">
          <h2 class="fs-24">
            {{
              $t(
                screenerProgram.eligible_programs_title ||
                  'We believe you are likely eligible for the following programs',
              )
            }}
          </h2>

          <MarkdownContent
            :content="
              screenerProgram.eligible_programs_subtitle ||
              'Based on the information you provided, we believe you are likely eligible for the following programs'
            "
          />
        </div>

        <EligibilityProgramCard
          v-for="eligibilityProgram in eligibleEligibilityPrograms"
          :key="eligibilityProgram.id"
          :eligibility-program="eligibilityProgram"
          :funding-sources="fundingSources"
        />
      </div>

      <div
        v-if="ineligibleEligibilityPrograms.length"
        :class="resultsWidthClasses"
      >
        <div class="mb-4">
          <h2 class="fs-24">
            {{
              $t(
                screenerProgram.ineligible_programs_title ||
                  'We believe you are likely not eligible for the following programs',
              )
            }}
          </h2>

          <MarkdownContent
            :content="
              screenerProgram.ineligible_programs_subtitle ||
              'Based on the information you provided, we believe you are likely ineligible for the following programs'
            "
          />
        </div>

        <EligibilityProgramCard
          v-for="eligibilityProgram in ineligibleEligibilityPrograms"
          :key="eligibilityProgram.id"
          :eligibility-program="eligibilityProgram"
          :funding-sources="fundingSources"
          ineligible
        />
      </div>
    </template>
  </v-container>
</template>

<script setup>
import Api from '@/shared/services/bright_finder';
import EligibilityProgramCard from '@/parent/components/eligibility-screener/EligibilityProgramCard.vue';
import MarkdownContent from '@/shared/components/MarkdownContent.vue';
import PageHero from '@/shared/components/content/PageHero.vue';
import store from '@/parent/store';
import { useRoute } from 'vue-router';

const route = useRoute();

const props = defineProps({
  dashboardView: { type: Boolean, default: false },
});

const draftEligibilityScreenerId = ref(null);
const eligibilityScreener = ref(null);
const eligibilityScreenerId = ref(null);
const eligibleEligibilityPrograms = ref([]);
const fundingSources = ref([]);
const ineligibleEligibilityPrograms = ref([]);
const processing = ref(false);
const screenerProgram = ref(null);

const defaultScreenerProgramId = computed(() => store.state.config.screener_program_id);

const resultsWidthClasses = computed(() => {
  if (props.dashboardView) return 'mx-auto mb-6';

  return 'mxw-1000 mx-auto px-6 mb-6';
});

async function setEligibilityScreenerId() {
  const response = await Api.parent.eligibilityScreener.index();
  const eligibilityScreeners = response?.data;

  if (route.params.id) {
    eligibilityScreenerId.value = route.params.id;
  } else if (route.query.eligibilityScreenerId) {
    eligibilityScreenerId.value = route.query.eligibilityScreenerId;
  } else {
    const latestSubmittedEligibilityScreener = eligibilityScreeners
      ?.filter((eligibilityScreener) => eligibilityScreener.submitted_at)
      .reduce((latest, eligibilityScreener) => {
        if (!latest) return eligibilityScreener;
        return new Date(eligibilityScreener.submitted_at) > new Date(latest.submitted_at)
          ? eligibilityScreener
          : latest;
      }, null);

    eligibilityScreenerId.value = latestSubmittedEligibilityScreener?.id;
  }

  const latestUnsubmittedEligibilityScreener = eligibilityScreeners
    ?.filter(
      (eligibilityScreener) =>
        !eligibilityScreener.submitted_at &&
        eligibilityScreener.screener_program_id === defaultScreenerProgramId.value,
    )
    .reduce((latest, screener) => {
      if (!latest || new Date(screener.created_at) > new Date(latest.created_at)) return screener;
      return latest;
    }, null);

  draftEligibilityScreenerId.value = latestUnsubmittedEligibilityScreener?.id;
}

async function load() {
  processing.value = true;

  await setEligibilityScreenerId();
  let id = eligibilityScreenerId.value;
  if (!id) return;

  const response = await Api.parent.eligibilityScreener.get(id);
  eligibilityScreener.value = response?.data;
  fundingSources.value = response?.data.meta.funding_sources;
  screenerProgram.value = response?.data.meta.screener_program;

  const eligibilityPrograms = response?.data.meta.eligibility_programs;
  eligibilityPrograms.forEach((eligibilityProgram) => {
    let eligible = false;

    for (const fundingSourceId of eligibilityProgram.funding_source_sequence) {
      const fundingSource = fundingSources.value.find(
        (fundingSource) => fundingSource.id === fundingSourceId,
      );
      const allPassing =
        fundingSource.criteria_results.length &&
        fundingSource.criteria_results.every((result) => result.passes === true);
      fundingSource['passing'] = allPassing;

      if (allPassing) eligible = true;
    }

    if (eligible) {
      eligibleEligibilityPrograms.value.push(eligibilityProgram);
    } else {
      ineligibleEligibilityPrograms.value.push(eligibilityProgram);
    }
  });

  processing.value = false;
}

onMounted(load);
</script>
