<template>
  <v-card
    :aria-label="childData.name"
    tabindex="0"
    border
    flat
    tile
  >
    <v-card-text class="ta-center">
      <v-avatar
        class="fs-96 c-white mb-6"
        color="primary"
        data-cy="child-initials"
        size="200"
        aria-hidden
      >
        {{ [childData.first_name[0], childData.last_name[0]].join('').toUpperCase() }}
      </v-avatar>
      <div
        v-text="childData.name"
        class="fs-24 mb-4"
        data-cy="child-name"
      />
    </v-card-text>

    <v-row v-if="$store.state.pages.Attendance.enabled">
      <v-col>
        <div class="pa-4">
          <v-btn
            @click="draftCheckInOut"
            :variant="checkedIn ? 'outlined' : undefined"
            color="primary"
            block
          >
            <template v-if="checkedIn">
              <v-icon class="me-2"> check_circle </v-icon>
              <span v-t="'Checked in'" />
            </template>
            <template v-else>
              <span v-t="'Check in'" />
            </template>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-divider />
    <v-row
      class="ta-left"
      dense
    >
      <v-col>
        <v-btn
          @click="$router.push({ name: 'ChildShow', params: { childId: childData.id } })"
          class="focus-very-visible td-none justify-space-between"
          data-cy="view_child_info"
          size="x-large"
          variant="text"
          block
          tile
        >
          <span>{{ $t('Submit and view applications') }}</span>
          <template #append>
            <v-icon color="primary"> east </v-icon>
          </template>
        </v-btn>
      </v-col>
    </v-row>

    <ResourceDialog
      @save="checkInOut"
      ref="checkInOutDialog"
      :processing="processing"
      :save-button-text="checkInOutButtonText"
      :title="checkedIn ? 'Check-out' : 'Check-in'"
    >
      <template #form>
        <p
          v-t="checkInOutText"
          class="fs-16"
        />
        <EsignCanvas ref="esignCanvas" />
      </template>
    </ResourceDialog>
  </v-card>
</template>

<script>
import Api from '@/shared/services/bright_finder';
import EsignCanvas from '@/shared/components/form/EsignCanvas.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    EsignCanvas,
    ResourceDialog,
  },

  props: {
    childData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      checkedIn: this.childData.checked_in,
      dateTime: null,
      position: null,
      processing: false,
      signature: null,
    };
  },

  computed: {
    checkInOutText() {
      return [
        'Add your signature to',
        this.checkedIn ? 'check-out' : 'check-in',
        `<strong>${this.childData.name}</strong>`,
        'at',
        `<strong>${this.dateTime}</strong>.`,
      ].join(' ');
    },

    checkInOutButtonText() {
      return this.checkedIn ? 'Check-out' : 'Check-in';
    },
  },

  methods: {
    checkInOut() {
      if (this.$refs.esignCanvas.filled()) {
        this.processing = true;
        const params = {
          event_type: this.checkedIn ? 'out' : 'in',
          signature: this.$refs.esignCanvas.getDataUrl(),
          child_id: this.childData.id,
          latitude: this.latitude,
          longitude: this.longitude,
        };

        Api.parent.attendance_event.create(
          params,
          () => {
            this.checkedIn = !this.checkedIn;
            this.processing = false;
            this.$refs.checkInOutDialog.close();
            this.signature = null;
            this.$refs.esignCanvas.clear();
          },
          (err) => {
            this.$eventBus.$emit('error', err);
          },
        );
      } else {
        this.$eventBus.$emit('chime', 'Add your signature to submit');
      }
    },

    draftCheckInOut() {
      this.dateTime = this.getDateTime();

      // eslint-disable-next-line no-constant-condition
      if (location.protocol === 'https:' && false) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.position = position;
          this.$refs.checkInOutDialog.open();
        });
      } else {
        this.position = {
          latitude: 37.7749,
          longitude: 122.4194,
        };
        this.$refs.checkInOutDialog.open();
      }
    },

    getDateTime() {
      const options = {
        month: this.shortMonth ? 'short' : 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        timeZone: 'America/Los_Angeles',
      };
      return new Date().toLocaleDateString(this.$i18n.locale, options);
    },
  },
};
</script>
