<template>
  <div class="mt-12">
    <h2 class="fs-24 mb-4 mt-8">
      {{ $t('Applications') }}
    </h2>

    <DashboardApplicationSummary
      v-for="item in items"
      :key="item.id"
      :item="item"
    />

    <NullState
      v-if="items.length === 0"
      :image-height="90"
      :subtitle="NULL_STATE_SUBTITLE"
      :title="NULL_STATE_TITLE"
      src="https://assets.getbridgecare.com/null-state-box.svg"
      card
    />
  </div>
</template>

<script setup>
import DashboardApplicationSummary from '@/parent/components/dashboards/DashboardApplicationSummary.vue';
import NullState from '@/shared/components/NullState.vue';

const NULL_STATE_SUBTITLE =
  "We didn't find any applications linked to the email you used to create your account. \
  This doesn't mean your application doesn't exist; it could be that the email we have doesn't match an existing application. \
  If you no longer see an application you saw before, you may have lost access. For help, please contact your county representative.";
const NULL_STATE_TITLE = 'Application not found';

defineProps({
  items: {
    type: Array,
    default: () => [],
  },
});
</script>
