<template>
  <v-card
    class="mb-3"
    border
    flat
  >
    <v-row class="ma-0">
      <v-col
        :cols="eligibilityProgram.cta_button_link && $vuetify.display.mdAndUp ? 6 : 12"
        class="pb-0"
      >
        <v-card-title class="pa-2 fs-18 wrap-text">
          {{ $t(eligibilityProgram.name) }}
        </v-card-title>
      </v-col>

      <v-col
        v-show="eligibilityProgram.cta_button_link && $vuetify.display.mdAndUp"
        class="ta-right"
        cols="6"
      >
        <EligibilityProgramCTAButton :eligibility-program="eligibilityProgram" />
      </v-col>
    </v-row>

    <v-row
      v-show="eligibilityProgram.cta_button_link && $vuetify.display.smAndDown"
      class="ma-0"
    >
      <v-col
        class="py-2 px-4"
        cols="12"
      >
        <EligibilityProgramCTAButton
          :eligibility-program="eligibilityProgram"
          block
        />
      </v-col>
    </v-row>

    <v-row
      v-if="eligibilityProgram.description"
      class="ma-0"
    >
      <v-col class="pa-1">
        <v-card-subtitle class="fs-16 pt-0 wrap-text">
          <MarkdownContent :content="eligibilityProgram.description" />
        </v-card-subtitle>
      </v-col>
    </v-row>

    <v-divider />

    <v-card-text class="pa-0">
      <v-expansion-panels
        rounded="0"
        variant="accordion"
        eager
        flat
      >
        <v-expansion-panel>
          <v-expansion-panel-title class="c-primary pl-5 pr-4">
            {{ $t('See why') }}
          </v-expansion-panel-title>

          <v-expansion-panel-text class="c-black pt-2">
            <FundingSourceCriteriaResultIndicator
              v-for="(result, index) in getCriteriaResults()"
              :key="index"
              :message="result.message"
              :passes="result.passes"
            />
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>

<script setup>
import EligibilityProgramCTAButton from '@/parent/components/eligibility-screener/EligibilityProgramCTAButton.vue';
import FundingSourceCriteriaResultIndicator from '@/parent/components/eligibility-screener/FundingSourceCriteriaResultIndicator.vue';
import MarkdownContent from '@/shared/components/MarkdownContent.vue';

const props = defineProps({
  eligibilityProgram: {
    required: true,
  },
  fundingSources: {
    type: Array,
    required: true,
  },
  ineligible: {
    type: Boolean,
    default: false,
  },
});

function getCriteriaResults() {
  const uniqueResults = [];

  props.eligibilityProgram.funding_source_sequence
    .map((fundingSourceId) => getFundingSource(fundingSourceId))
    .filter((fundingSource) => props.ineligible || fundingSource.passing)
    .map((fundingSource) => fundingSource.criteria_results)
    .flat()
    .forEach((result) => {
      if (!isResultDuplicate(result, uniqueResults)) {
        uniqueResults.push(result);
      }
    });

  return uniqueResults;
}

function isResultDuplicate(result, uniqueResults) {
  return uniqueResults.some((uniqueResult) => {
    return uniqueResult.message === result.message && uniqueResult.passes === result.passes;
  });
}

function getFundingSource(id) {
  return props.fundingSources.find((fundingSource) => fundingSource.id === id);
}
</script>
