import { newUtcDate } from '@/shared/services/date-helper';

export const calculateMonthsOldOnDate = (dateOfBirth, date) => {
  let startDate = newUtcDate(dateOfBirth);
  let endDate = newUtcDate(date);
  let negativeValue = false;

  if (startDate > endDate) {
    negativeValue = true;

    const oldStartDate = startDate;
    startDate = endDate;
    endDate = oldStartDate;
  }

  let monthsOld = 0;
  const currentDate = startDate;

  while (endDate > currentDate) {
    monthsOld += 1;

    const currentMonth = currentDate.getUTCMonth();

    let nextMonth = currentMonth + 1;
    if (nextMonth === 12) nextMonth = 0;

    currentDate.setUTCMonth(currentDate.getUTCMonth() + 1);

    // Adding a month to a date like the 31st might spill into two months ahead of the current date
    // In this case, we reduce the date by one until it is in the proper next month (last day of the month)
    while (currentDate.getUTCMonth() !== nextMonth) {
      currentDate.setUTCDate(currentDate.getUTCDate() - 1);
    }
  }

  if (currentDate > endDate) monthsOld -= 1;

  // JS has positive and negative zeros, so avoid action on 0
  if (monthsOld !== 0 && negativeValue) monthsOld *= -1;

  return monthsOld;
};
