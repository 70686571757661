<template>
  <div class="w-100pc h-100pc bg-super-light-blue">
    <v-row
      class="w-100pc h-100pc bg-white oy-hidden"
      no-gutters
    >
      <v-col
        v-show="$vuetify.display.mdAndUp || activeThread == false"
        class="br-1 bc-extra-light-gray"
        cols="12"
        md="4"
      >
        <h1
          ref="title"
          class="bb-1 bc-extra-light-gray pb-2 fs-24 fw-600 mt-2"
        >
          <span class="ml-4">{{ $t('My messages') }}</span>
        </h1>
        <div class="pa-4 bb-1 bc-extra-light-gray d-flex align-center">
          <v-text-field
            v-model="query"
            :label="$t('Search by provider name')"
            data-cy="search_provider"
            density="comfortable"
            prepend-inner-icon="search"
            variant="solo-filled"
            flat
            hide-details
            rounded
          />
          <v-btn
            @click="load"
            :loading="processing"
            class="ms-1"
            variant="text"
            icon
          >
            <v-icon>refresh</v-icon>
          </v-btn>
        </div>
        <div
          v-if="$store.state.pages.Messaging.features.enable_support"
          @click="switchThread()"
          class="pa-4 bb-1 bc-extra-light-gray d-flex align-center pointer"
          data-cy="support-thread"
          data-testid="support-thread"
        >
          <v-icon
            class="me-2"
            size="26"
          >
            support_agent
          </v-icon>
          <div>
            <div
              v-t="$store.state.brand.organization_name"
              class="fs-18 fw-500"
            />
            <div
              v-t="'Need assistance? Message one of our staff members.'"
              class="fs-16 c-light-black"
            />
          </div>
          <v-spacer />
          <v-icon
            v-if="
              messagesWithOrg.length > 0 &&
              [messagesWithOrg[messagesWithOrg.length - 1]].some(
                (message) =>
                  (message.meta.member.is_provider || message.meta.member.is_specialist) &&
                  !message.read_at,
              )
            "
            class="mx-1"
            color="green"
          >
            circle
          </v-icon>
        </div>
        <div
          v-for="provider in filteredProviderThreads"
          @click="switchThread(provider)"
          :key="provider.id"
          class="pa-4 bb-1 bc-extra-light-gray d-flex align-center pointer"
          data-cy="provider-thread"
        >
          <v-icon
            class="me-2"
            size="26"
          >
            storefront
          </v-icon>
          <div>
            <div
              v-t="provider.name"
              class="fs-18 fw-500"
            />
            <MarkdownContent
              :content="
                messagesWithProviders[provider.id][messagesWithProviders[provider.id].length - 1]
                  ?.text
              "
              class="fs-16 c-light-black h-20 oy-hidden"
            />
          </div>
          <v-spacer />
          <v-icon
            v-if="
              [
                messagesWithProviders[provider.id][messagesWithProviders[provider.id].length - 1],
              ].some(
                (message) =>
                  (message.meta.member.is_provider || message.meta.member.is_specialist) &&
                  !message.read_at,
              )
            "
            class="mx-1"
            color="green"
          >
            circle
          </v-icon>
        </div>
      </v-col>

      <v-col
        v-show="$vuetify.display.mdAndUp || activeThread != false"
        class="p-relative"
        cols="12"
        data-cy="conversation"
        md="8"
      >
        <div class="h-100pc w-100pc d-flex flex-column">
          <MessageThreadHeader
            @back="switchThread(false)"
            :subtitle="messageHeaderSubtitle"
            :title="messageHeaderTitle"
          />

          <div
            v-if="$store.state.pages.Messaging.features.enable_support && activeThread == null"
            id="messages_list"
            :class="messageListClass"
            style="flex: 1 1 0"
          >
            <MessageItem
              v-for="message in messagesWithOrg"
              :key="message.id"
              :message="message"
            />
          </div>

          <div
            v-else
            id="messages_list"
            :class="messageListClass"
            style="flex: 1 1 0"
          >
            <div
              v-for="provider in filteredProviderThreads"
              :key="provider.id"
            >
              <div v-if="activeThread == provider">
                <MessageItem
                  v-for="message in messagesWithProviders[provider.id]"
                  :key="message.id"
                  :message="message"
                />
              </div>
            </div>
          </div>

          <div
            v-if="activeThread || $store.state.pages.Messaging.features.enable_support"
            class="bt-0 bc-extra-light-gray bg-white w-100pc"
            data-cy="message-composer"
          >
            <div class="pa-2">
              <v-row dense>
                <v-col>
                  <v-card
                    class="bb-1 bc-very-light-grey"
                    style="background: rgba(0, 0, 0, 0.04)"
                    flat
                    tile
                  >
                    <v-btn
                      @click="$refs.attachmentDialog.open({})"
                      class="mb-2"
                      data-cy="attach-button"
                      variant="flat"
                    >
                      <v-icon start> attachment </v-icon>
                      <span v-t="'Attach document'" />
                    </v-btn>
                  </v-card>
                </v-col>
              </v-row>
              <AttachmentDialog
                @save="createAttachmentMessages"
                @upload="uploadedFiles.push($event)"
                ref="attachmentDialog"
                :processing="processing"
              />
              <v-textarea
                v-model="newMessageText"
                :aria-label="$t('Type message')"
                :disabled="noMessages"
                :label="$t('Message')"
                class="mb-2"
                data-cy="message-textarea"
                rows="3"
                variant="filled"
                hide-details
              />
              <v-btn
                @click="createMessage"
                :disabled="submitDisabled"
                :loading="processing"
                color="primary"
                data-cy="send-message-button"
                size="x-large"
                block
              >
                {{ $t('Send message') }}
              </v-btn>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Api from '@/shared/services/bright_finder';
import AttachmentDialog from '@/shared/components/attachments/AttachmentDialog.vue';
import MarkdownContent from '@/shared/components/MarkdownContent.vue';
import MessageItem from '@/shared/components/MessageItem.vue';
import MessageThreadHeader from '@/shared/components/MessageThreadHeader.vue';
import vuetify from '@/plugins/vuetify';

export default {
  compatConfig: { MODE: 2 },

  components: {
    AttachmentDialog,
    MarkdownContent,
    MessageItem,
    MessageThreadHeader,
  },

  data() {
    return {
      activeThread: vuetify.display.mdAndUp ? null : false,
      currentMemberId: this.$store.state.profile.id,
      lastMessageDates: {},
      lastOrgMessageDate: null,
      messageListClass: 'bg-white w-100pc d-flex flex-column pa-4 top-100 oy-scroll',
      messages: [],
      messagesWithOrg: [],
      messagesWithProviders: {},
      newMessageText: null,
      processing: false,
      providerThreads: [],
      query: null,
      readDates: {},
      readOrgDate: null,
      uploadedFiles: [],
    };
  },
  computed: {
    filteredProviderThreads() {
      if (this.query && this.query !== '') {
        return this.providerThreads.filter((thread) =>
          thread.name.toLowerCase().includes(this.query.toLowerCase()),
        );
      }
      return this.providerThreads;
    },

    messageHeaderSubtitle() {
      if (this.activeThread) {
        return this.activeThread.address;
      }
      if (this.$store.state.pages.Messaging.features.enable_support) {
        return this.$t('Support staff will message you on this channel.');
      }
      return this.$t('Messages will appear here when you send or receive a message.');
    },

    messageHeaderTitle() {
      if (this.activeThread) {
        return this.activeThread.name;
      }
      if (this.$store.state.pages.Messaging.features.enable_support) {
        return this.$store.state.brand.organization_name;
      }
      return this.$t('No messages yet!');
    },

    noMessages() {
      return (
        this.activeThread == null && !this.$store.state.pages.Messaging.features.enable_support
      );
    },

    submitDisabled() {
      return !this.newMessageText || this.newMessageText.trim().length === 0 || this.noMessages;
    },
  },

  created() {
    this.$store.commit('setNewMessage', false);
    this.load();
  },

  methods: {
    createMessage() {
      this.processing = true;
      Api.message.create(
        { text: this.newMessageText, provider_id: this.activeThread ? this.activeThread.id : null },
        (resp) => {
          this.messages.push(resp.data);
          this.processing = false;
          this.newMessageText = null;
          this.sortMessages();
          this.scrollToBottom();
        },
      );
    },

    async createAttachmentMessages() {
      this.processing = true;

      const params = { provider_id: this.activeThread ? this.activeThread.id : null };

      const responses = await Promise.all(
        this.uploadedFiles.map(async (asset) => {
          const attachmentResponse = await Api.member.attachment.create(asset);
          if (attachmentResponse?.status !== 201) {
            return;
          }
          const messageResponse = await Api.message.promiseCreate({
            ...params,
            attachment_id: attachmentResponse.data.id,
          });
          this.messages.push(messageResponse.data);
          return messageResponse;
        }),
      );

      const allSuccessful = responses.every((resp) => resp?.status === 201);
      if (!allSuccessful) {
        this.$refs.attachmentDialog.cancelAll();
      } else {
        this.$refs.attachmentDialog.close();
      }

      this.uploadedFiles = [];
      this.processing = false;
      this.newMessageText = null;
      this.sortMessages();
      this.scrollToBottom();
    },

    load() {
      this.messages = [];
      this.messagesWithOrg = [];
      this.messagesWithProviders = {};
      this.providerThreads = [];

      this.processing = true;
      Api.message.index({}, (resp) => {
        this.messages = resp.data;
        this.processing = false;
        this.sortMessages();
        this.scrollToBottom();
      });
    },

    scrollToBottom() {
      this.$nextTick(() => {
        const list = document.getElementById('messages_list');
        if (list) {
          list.scrollTop = list.scrollHeight;
        }
      });
    },

    sortMessages() {
      this.messages.forEach((message) => {
        if (message.provider_id == null) {
          if (!this.messagesWithOrg.includes(message)) {
            this.messagesWithOrg.push(message);
          }
        } else {
          if (this.messagesWithProviders[message.provider_id] === undefined) {
            this.messagesWithProviders[message.provider_id] = [];
            if (!this.providerThreads.includes(message.meta.provider)) {
              this.providerThreads.push(message.meta.provider);
            }
          }

          if (!this.messagesWithProviders[message.provider_id].includes(message)) {
            this.messagesWithProviders[message.provider_id].push(message);
          }
        }
      }, this);

      if (this.activeThread) {
        this.switchThread(
          this.filteredProviderThreads.find((thread) => thread.id === this.activeThread.id),
        );
      } else if (this.$route.query.provider_id) {
        this.switchThread(
          this.filteredProviderThreads.find(
            (thread) => thread.id === this.$route.query.provider_id,
          ),
        );
      } else if (
        !this.$store.state.pages.Messaging.features.enable_support &&
        this.activeThread == null
      ) {
        this.switchThread(this.providerThreads[0]);
      }
    },

    switchThread(thread) {
      this.activeThread = thread;
      if (thread !== false) {
        this.scrollToBottom();
        let msg;
        if (thread) {
          msg =
            this.messagesWithProviders[this.activeThread.id][
              this.messagesWithProviders[this.activeThread.id].length - 1
            ];
        } else {
          msg = this.messagesWithOrg[this.messagesWithOrg.length - 1];
        }
        if (msg) {
          if (!msg.read_at) {
            Api.message.get(msg.id, () => {});
            msg.read_at = true;
          }
        }
      }
    },
  },
};
</script>
