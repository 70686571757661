<template>
  <v-menu
    v-model="menu"
    ref="location_menu"
    :close-on-content-click="false"
    offset="5"
    eager
  >
    <template #activator="{ props }">
      <button
        v-bind="props"
        ref="activatorButton"
        :ripple="false"
        class="bg-white c-black fullheight fullwidth rounded pa-3 focus-very-visible"
      >
        <v-row>
          <v-col
            class="ta-left"
            cols="10"
          >
            <div
              class="fs-16 mb-1"
              data-cy="location_menu"
            >
              {{ $t('Search address, city or zip code') }}
            </div>
            <div
              v-text="search.formatted_origin || search.zip || $t('Locations near you')"
              class="fs-16 fw-600"
            />
          </v-col>
          <v-col
            class="d-flex align-center justify-end"
            cols="2"
          >
            <v-icon>expand_more</v-icon>
          </v-col>
        </v-row>
      </button>
    </template>
    <v-card>
      <v-card-text class="py-2">
        <div class="c-black fs-16 fw-600 mb-1">
          {{ $t('Search for care near:') }}
        </div>
        <SearchLocation
          @close="focus(true)"
          :open="menu"
          :value="localValue"
        />
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import SearchLocation from '@/shared/components/search/location.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    SearchLocation,
  },

  props: {
    autofocus: {
      type: Boolean,
      default: true,
    },
    search: Object,
  },

  emits: ['close'],

  data() {
    return { localValue: this.search, menu: false };
  },

  watch: {
    menu(newVal) {
      if (!newVal) {
        this.$emit('close');
      }
    },
  },

  mounted() {
    this.focus(this.autofocus);
  },

  methods: {
    focus(drawFocus) {
      this.menu = false;
      if (!drawFocus) return;
      this.$nextTick(() => {
        const activatorButtonRef = this.$refs.activatorButton;
        activatorButtonRef.focus();
      });
    },
  },
};
</script>
