<template>
  <div v-if="provider">
    <v-card
      class="pa-0 border-t-0"
      border
      flat
      tile
    >
      <v-card-text>
        <v-row class="d-flex align-start">
          <v-col>
            <div class="c-black">
              <v-row
                class="ma-0 pa-0 d-flex align-center"
                dense
              >
                <v-col cols="11">
                  <h4
                    v-t="provider.name"
                    class="c-black fw-600 fs-20"
                    data-cy="selection-name"
                    tabindex="0"
                    dense
                  />
                  <div
                    v-if="provider.license_business_name != provider.name"
                    class="fs-16"
                  >
                    {{ provider.license_business_name }}
                  </div>
                </v-col>
              </v-row>
              <v-row
                class="ma-0 pa-0"
                dense
              >
                <v-col>
                  <span
                    class="fs-16 fw-400 me-1"
                    tabindex="0"
                    >{{ [provider.address1, provider.city, provider.zip].join(', ') }}</span
                  >
                  <span
                    v-if="provider.distance_from_origin"
                    class="fs-16 fw-400"
                    >- {{ provider.distance_from_origin }} {{ $t('miles away') }}</span
                  >
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <template v-if="provider.public_notice">
          <PublicNotice
            v-if="provider.public_notice"
            :public-notice="provider.public_notice"
            small
          />
        </template>
      </v-card-text>
    </v-card>
    <v-card
      v-for="program in provider.programs"
      :key="program.id"
      class="px-3 py-0 border-t-0"
      border
      flat
      tile
    >
      <PublicNotice
        v-if="program.public_notice"
        :public-notice="program.public_notice"
        small
      />
      <v-row
        class="d-flex align-center"
        dense
      >
        <v-col
          class="py-6"
          cols="1"
        >
          <template v-if="frozenProgramIds.includes(program.id)">
            <v-checkbox
              :aria-label="'Selected ' + program.name"
              :model-value="true"
              class="py-0 my-0"
              density="compact"
              disabled
              hide-details
            />
          </template>
          <template v-else>
            <v-checkbox
              @click="$emit('check', [program.id, $event])"
              @update:model-value="$emit('toggle', [program.id, $event])"
              :aria-label="$t('Select') + ' ' + program.name"
              :indeterminate="
                dobIneligible(program) || (!programIds.includes(program.id) && !selectable)
              "
              :model-value="programIds.includes(program.id)"
              :readonly="
                (!programIds.includes(program.id) && !selectable) || dobIneligible(program)
              "
              class="py-0 my-0"
              density="compact"
              hide-details
            />
          </template>
        </v-col>
        <v-col>{{ $t(program.name) }}</v-col>
        <v-col
          v-if="dobIneligible(program)"
          class="d-flex justify-end"
        >
          <v-tooltip location="bottom">
            <template #activator="{ props }">
              <v-chip
                v-bind="props"
                class="ma-2"
              >
                Ineligible
              </v-chip>
            </template>
            <span>
              {{ $t("The child's date of birth must fall within the following dates") }}:
              {{ program?.dob_start_date }}, {{ program?.dob_end_date }}
            </span>
          </v-tooltip>
        </v-col>

        <v-col
          v-if="!dobIneligible(program) && showAvailability(program)"
          class="d-flex justify-end"
          data-testid="seat-availability-badge"
        >
          <SeatsAvailability :program="program" />
        </v-col>
      </v-row>
    </v-card>
    <v-card
      class="border-t-0"
      border
      flat
      tile
    >
      <template v-if="!hideViewMore">
        <v-card-actions class="bt-1 border-t-0 px-5 bc-extra-light-grey pointer justify-end">
          <v-btn
            @click="$emit('open')"
            append-icon="chevron_right"
            class="focus-very-visible"
            color="primary"
            variant="outlined"
          >
            {{ $t('View more information') }}
          </v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </div>
</template>

<script>
import PublicNotice from '@/shared/components/PublicNotice.vue';
import SeatsAvailability from '@/shared/components/SeatsAvailability.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    PublicNotice,
    SeatsAvailability,
  },

  props: {
    frozenProgramIds: {
      default: () => [],
      type: Array,
    },
    hideToggle: Boolean,
    hideViewMore: Boolean,
    dob: {
      default: null,
      type: String,
    },
    provider: {
      default: null,
      type: Object,
    },
    programIds: {
      default: null,
      type: Array,
    },
    selectable: {
      default: true,
      type: Boolean,
    },
    subsidyProgram: {
      default: null,
      type: Object,
    },
  },

  computed: {
    enableSeatAvailability() {
      return this.subsidyProgram?.enable_show_seat_availability === true;
    },
  },

  emits: ['check', 'open', 'toggle'],

  methods: {
    showAvailability(program) {
      return this.enableSeatAvailability && this.availabilityLabel(program) !== null;
    },

    dobIneligible(program) {
      if (!this.dob) {
        return false;
      }

      return this.dob < program?.dob_start_date || this.dob > program?.dob_end_date;
    },

    availabilityColor(program) {
      switch (program?.meta?.seat_availability_flag) {
        case 'available':
          return 'green accent-1';
        case 'low':
          return 'green accent-1';
        default:
          return 'yellow lighten-3';
      }
    },

    availabilityLabel(program) {
      switch (program?.meta?.seat_availability_flag) {
        case 'available':
          return 'Seats available';
        case 'low':
          return 'Seats available - Low availability!';
        case 'none':
          return 'No seats available';
        default:
          return null;
      }
    },
  },
};
</script>
