<template>
  <div>
    <v-row dense>
      <v-col
        class="c-black fs-16 fw-500 mb-4 ta-center"
        cols="12"
        >{{ $t(title) }}</v-col
      >
      <v-col
        class="ta-center"
        cols="12"
      >
        <div
          v-for="(partner, index) in partners"
          :key="index"
          class="d-inline-block"
        >
          <img
            :alt="$t(`Partnership with ${partner.name}`)"
            :src="partner.img"
            class="h-60 me-4 mt-2"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  compatConfig: { MODE: 2 },

  props: {
    partners: {
      type: Array,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
  },
};
</script>
