import { isAuthenticated } from '@/shared/services/auth';

export function proceed({ to }) {
  if (to.meta?.title) {
    document.title = to.meta.title;
  }
  return true;
}

function directAuthenticatedUser({ store, to }) {
  if (to.meta.registration) return proceed({ to });
  if (to.name === 'SignOut') return proceed({ to });
  if (to.meta.anonymous && store.state.profile.is_anonymous) return proceed({ to });

  if (store.state.profile.is_anonymous) {
    return { path: '/register', query: { mode: 'login', redirect_url: to.fullPath } };
  }

  if (store.state.config.terms_agreement && !store.state.profile.terms_agreement_id) {
    return { name: 'TermsAgreement' };
  }

  if (store.state.profile && !store.state.profile.email) {
    return { name: 'EmailUpdate' };
  }

  return proceed({ to });
}

function handleUnauthenticatedUser({ to }) {
  if (to.name === 'ProviderCrawlerShow' || to.name === 'PublicProviderShow') {
    return proceed({ to });
  }

  if (to.name === 'SubsidyReview' || to.name === 'FamilySubsidyReview' || to.name === 'Dashboard') {
    return { path: '/register', query: { mode: 'login', redirect_url: to.fullPath } };
  }

  return { path: '/' };
}

export function handleProtectedRoute({ store, to }) {
  if (isAuthenticated(store)) return directAuthenticatedUser({ store, to });

  return new Promise((resolve) => {
    store.dispatch('identify', {
      success() {
        resolve(directAuthenticatedUser({ store, to }));
      },
      failure() {
        resolve(handleUnauthenticatedUser({ to }));
      },
    });
  });
}
