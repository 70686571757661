<template>
  <v-card
    border
    tile
  >
    <v-card-text>
      <v-row class="d-flex align-center">
        <v-col cols="10">
          <div
            @click="$emit('open')"
            class="fs-18 fw-500 underlined c-primary pointer"
          >
            {{ agreement.name }}
          </div>
          <div
            class="fs-16"
            data-cy="agreement-signer"
          >
            {{ `${$t('Signer')}: ${memberName}` }}
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  compatConfig: { MODE: 3 },

  props: {
    agreement: {
      type: Object,
      required: true,
    },
  },

  emits: ['open'],

  data() {
    return {
      processing: false,
    };
  },

  computed: {
    memberName() {
      return this.$store.state.profile.name;
    },
  },
};
</script>
