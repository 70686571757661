<template>
  <v-card
    v-if="childSchema"
    class="mb-4"
    flat
    tile
  >
    <v-card-text class="px-0">
      <v-row class="d-flex align-center">
        <v-col>
          <h2
            v-t="subsidyProgram.name"
            class="fs-22 fw-600"
            tabindex="0"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <p
            v-t="subsidyProgram.description"
            class="c-light-black fs-16 fw-500 mb-0"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <template v-if="subsidy">
            <template v-if="!subsidySubmittedAt">
              <v-btn
                :block="$vuetify.display.smAndDown"
                :to="{ name: 'SubsidyContinue', params: { subsidyId: subsidyId } }"
                class="focus-very-visible"
                color="primary"
                data-cy="continue-button"
                variant="outlined"
                tile
              >
                <span>{{ $t('Continue') }} {{ $t(subsidyProgram.name) }}</span>
              </v-btn>
            </template>
          </template>
          <template v-else>
            <v-btn
              @click="create"
              :block="$vuetify.display.smAndDown"
              :loading="processing"
              class="focus-very-visible"
              color="primary"
              data-cy="start-button"
              variant="outlined"
              tile
            >
              <span>{{ $t('Start') }} {{ $t(subsidyProgram.name) }}</span>
            </v-btn>
          </template>
        </v-col>
      </v-row>

      <template v-if="subsidySubmittedAt">
        <v-divider class="my-4" />

        <v-row
          class="mb-2"
          dense
        >
          <v-col class="d-flex align-center">
            <router-link
              :to="{ name: 'SubsidyReview', params: { subsidyId: subsidyId } }"
              class="td-none"
            >
              <v-icon
                class="material-icons-outlined td-none"
                color="primary"
                size="24"
                start
              >
                check_circle
              </v-icon>
              <LongDateTime
                :date="subsidySubmittedAt"
                class="underlined"
                data-cy="app_submitted"
                prefix="Application submitted at"
              />
            </router-link>
          </v-col>
        </v-row>
      </template>

      <ChildSubsidyPreferenceList
        v-model:processing="processing"
        :child-data="childData"
        :child-schema="childSchema"
        :subsidy="subsidy"
        :subsidy-program="subsidyProgram"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import API from '@/shared/mixins/api';
import LongDateTime from '@/shared/components/LongDateTime.vue';
import ChildSubsidyPreferenceList from '@/parent/components/subsidy/ChildSubsidyPreferenceList.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    LongDateTime,
    ChildSubsidyPreferenceList,
  },

  mixins: [API],

  props: {
    childData: {
      type: Object,
      default: null,
    },
    childSchema: {
      type: Object,
      default: null,
    },
    subsidy: {
      type: Object,
      default: null,
    },
    subsidyProgram: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      enrollments: [],
      countEnrollmentsTowardPreferencesLimit: 0,
      subsidyId: this.subsidy?.id,
      subsidyLocked: this.subsidy?.locked,
      subsidyProgramIds: this.subsidy?.program_ids || [],
      subsidySubmittedAt: this.subsidy?.submitted_at,
      subsidyUnlockedAt: this.subsidy?.preferences_unlocked_at
        ? new Date(this.subsidy.preferences_unlocked_at)
        : null,
      processing: false,
      readonly: false,
      allowAddPrograms: false,
    };
  },

  created() {
    this.loadEnrollments();
  },

  methods: {
    addProgramsAllowed() {
      if (
        this.subsidyProgram?.enable_iep_direct_placement &&
        this.subsidy?.child_application_data?.individualized_education_plan
      ) {
        return false;
      }
      if (this.subsidy?.preferences_unlocked_at) return true;
      if (!this.subsidyProgram.enable_automatic_preference_unlock) return false;

      // Handle when automatic preferences unlock is enabled
      if (this.enrollments.length === 0) return true;

      if (!this.enrollments.some((enrollment) => enrollment.prevents_automatic_preference_unlock))
        return true;
      return false;
    },

    async addPrograms(newVals) {
      this.processing = true;
      await this.saveProgramIds(this.subsidyProgramIds.concat(newVals));
      await this.loadEnrollments();
      this.$refs.addProgramSelectionDialog.close();
      this.processing = false;
    },

    create() {
      this.processing = true;
      this.api.subsidy.create(
        { child_id: this.childData.id, subsidy_program_id: this.subsidyProgram.id },
        (resp) => {
          this.$router.push({ name: 'SubsidyContinue', params: { subsidyId: resp.data.id } });
        },
        (err) => {
          this.$eventBus.$emit('chime', err.response.data.errors[0]);
        },
      );
    },

    getRemovable(enrollment) {
      if (!this.subsidyLocked || !this.subsidyUnlockedAt) return false;
      if (enrollment.public_status !== 'Selected') return false;
      return true;
    },

    async loadEnrollments() {
      const resp = await this.api.parent.enrollment.index({ child_id: this.$route.params.childId });

      if (resp?.data) {
        const enrollments = resp.data;
        const filteredEnrollments = enrollments
          .filter((enrollment) => enrollment.subsidy_program_id === this.subsidyProgram.id)
          .sort((a, b) => a.preference_order - b.preference_order);

        this.enrollments = filteredEnrollments;
        this.allowAddPrograms = this.addProgramsAllowed();
      }

      const countResponse = await this.api.parent.enrollment.index({
        child_id: this.$route.params.childId,
        count: true,
        subsidy_id: this.subsidyId,
      });

      if (countResponse?.data) {
        this.countEnrollmentsTowardPreferencesLimit = countResponse.data.count;
      }
    },

    async remove(fromIndex) {
      this.processing = true;
      this.subsidyProgramIds.splice(fromIndex, 1);
      await this.saveProgramIds(this.subsidyProgramIds);
      await this.loadEnrollments();
      this.processing = false;
    },

    async reorder(fromIndex, change) {
      this.processing = true;
      const element = this.subsidyProgramIds[fromIndex];
      this.subsidyProgramIds.splice(fromIndex, 1);
      this.subsidyProgramIds.splice(fromIndex + change, 0, element);
      await this.saveProgramIds(this.subsidyProgramIds);
      await this.loadEnrollments();
      this.processing = false;
    },

    async saveProgramIds(newVals) {
      const res = await this.api.subsidy
        .update(this.subsidyId, { program_ids: newVals })
        .catch((error) => {
          this.$eventBus.$emit('error', error);
        });

      this.subsidyProgramIds = res.data.program_ids;
    },

    async updateEnrollmentStatus(enrollment, newVal) {
      this.processing = true;
      await this.api.parent.enrollment_status
        .update(enrollment.id, newVal)
        .catch((error) => this.$eventBus.$emit('error', error));

      await this.loadEnrollments();
      this.processing = false;
    },
  },
};
</script>
