<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    offset="5"
    eager
  >
    <template #activator="{ props }">
      <button
        @keyup.down="focusOnLabeledVolume(1, 0)"
        v-bind="props"
        ref="childrenMenuActivator"
        :ripple="false"
        class="bg-white c-black fullheight fullwidth rounded pa-3 focus-very-visible"
      >
        <v-row>
          <v-col
            class="ta-left"
            cols="10"
          >
            <div
              v-t="'Children'"
              class="fs-16 mb-1"
              data-cy="children_dropdown"
            />
            <div
              v-text="addChildrenText"
              :aria-label="addChildrenLabel"
              class="fs-16 fw-600"
            />
          </v-col>
          <v-col
            class="d-flex align-center justify-end"
            cols="2"
          >
            <v-icon>expand_more</v-icon>
          </v-col>
        </v-row>
      </button>
    </template>
    <v-card>
      <v-card-text>
        <LabeledVolume
          v-for="(item, index) in ageGroups"
          @decrease="$emit('decrease', index)"
          @down="down(index)"
          @increase="$emit('increase', index)"
          @up="up(index)"
          :key="index"
          ref="childVolume"
          :autofocus="index == 0"
          :count="item.count"
          :dense="true"
          :subtitle="item.subtitle || item.subtext"
          :title="item.title || item.text"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          @click="close"
          :ripple="false"
          class="focus-very-visible"
          color="primary"
          data-cy="children_done"
          >{{ $t('Done') }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  compatConfig: { MODE: 2 },

  props: {
    ageGroups: {
      type: Array,
      default: null,
    },
    autofocus: {
      type: Boolean,
      default: true,
    },
    sum: {
      type: Number,
      default: null,
    },
    value: {
      type: Object,
      default: null,
    },
  },

  emits: ['close', 'decrease', 'increase'],

  data() {
    return {
      menu: false,
    };
  },

  computed: {
    addChildrenText() {
      if (this.sum > 0) {
        return [this.sum, this.sum === 1 ? this.$t('child') : this.$t('children')].join(' ');
      }
      return this.$t('Add children');
    },

    addChildrenLabel() {
      if (this.sum > 0) {
        const str = [];
        this.ageGroups.forEach((item) => {
          if (item.count > 0) {
            str.push([this.$t(item.count.toString()), this.$t(item.name)].join(' '));
          }
        }, this);
        return str.join(' ');
      }
      return this.$t('Add children');
    },
  },

  mounted() {
    if (!this.autofocus) return;

    this.focus();
  },

  methods: {
    close() {
      this.menu = false;
      this.focus();
      this.$emit('close');
    },

    down(index) {
      if (index + 1 >= this.$refs.childVolume.length) return this.focusOnLabeledVolume(1, 0);

      return this.focusOnLabeledVolume(1, index + 1);
    },

    focus() {
      this.$refs.childrenMenuActivator.focus();
    },

    focusOnLabeledVolume(x, y) {
      this.$refs.childVolume[y].focus();
    },

    up(index) {
      if (index <= 0) return this.focusOnLabeledVolume(1, this.$refs.childVolume.length - 1);

      return this.focusOnLabeledVolume(1, index - 1);
    },
  },
};
</script>
