export default {
  compatConfig: { MODE: 2 },
  data() {
    return {
      modalSequence: null,
      modals: {
        modalRef: null,
        modalValidator: false,
        nextModalRef: null,
        nextModalValidator: false,
      },
      proceedFn: () => null, // Initialize proceedFn to a no-op function
    };
  },

  methods: {
    // "private" methods (uses internal data state to prevent being called directly from components)
    handleModalDisplay() {
      // Check to see if the validator is true for the first modal
      if (this.modals.modalValidator === true) {
        return this.modals.modalRef.open();
      }

      // The validator is false so open the next modal
      return this.handleSequentialModals({
        ...this.modals,
        modalSequence: 2,
        proceedFn: this.proceedFn,
      });
    },

    handleNextModalDisplay() {
      // Close the first modal
      this.modals.modalRef.close();

      // Check to see if the validator is true for the next modal
      if (this.modals.nextModalValidator === true) {
        return this.modals.nextModalRef.open();
      }

      // We need to call proceedFn here since the fist modal is valid and the second is not
      return this.proceedFn();
    },

    // Component methods
    handleSequentialModals({
      modalRef = null,
      modalValidator = false,
      nextModalRef = null,
      nextModalValidator = false,
      proceedFn = () => null,
      modalSequence = 1,
    } = {}) {
      // Supports two modals opening sequentially via confirm flow operation

      // Initially persist the state for sequential operations
      this.modalSequence = modalSequence;
      this.proceedFn = proceedFn;
      this.modals = {
        modalRef,
        modalValidator,
        nextModalRef,
        nextModalValidator,
      };

      // Early exit; 2 modals required, and at least one valid case
      if (modalRef === null || nextModalRef === null) {
        return proceedFn();
      }
      if (modalValidator === false && nextModalValidator === false) {
        return proceedFn();
      }

      switch (modalSequence) {
        case 1:
          return this.handleModalDisplay();
        case 2:
          return this.handleNextModalDisplay();
        default:
          return null;
      }
    },

    handleNextOrCloseSequentialModal() {
      switch (this.modalSequence) {
        case 1:
          return this.handleSequentialModals({
            ...this.modals,
            modalSequence: 2,
            proceedFn: this.proceedFn,
          });
        case 2:
          // Next is being called on the last modal which is a close and proceed
          this.modals.nextModalRef.close();
          return this.proceedFn();
        default:
          return null;
      }
    },
  },
};
