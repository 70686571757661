<template>
  <div v-if="loaded">
    <ManageSubsidyHeader
      :submitted-at="familySubsidy.submitted_at"
      :title="familySubsidyProgram.name"
      :titleSubtext="familySubsidyProgram.description"
    />

    <v-container
      v-if="shouldShowClaims"
      data-testid="claims-section"
    >
      <div class="mb-12">
        <h2 class="mb-4">Claims</h2>
        <ClaimsTable
          :family-subsidy="familySubsidy"
          :family-subsidy-program="familySubsidyProgram"
        />
      </div>
    </v-container>

    <v-container>
      <v-row>
        <v-col cols="auto">
          <h2 class="mb-4">Application</h2>
        </v-col>

        <v-spacer />

        <v-col cols="auto">
          <v-btn
            :aria-label="$t('Download application')"
            :href="downloadLink"
            class="focus-very-visible"
            color="primary"
            data-testid="download-button"
            target="_blank"
          >
            <span class="fs-16">{{ $t('Download') }}</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-card
        class="b-1"
        elevation="0"
        tile
      >
        <FamilySubsidyReview
          :download-button="true"
          :family-subsidy-program-value="familySubsidyProgram"
          :family-subsidy-value="familySubsidy"
          :toolbar="false"
        />
      </v-card>
    </v-container>
  </div>
</template>

<script setup>
import ClaimsTable from '../components/family_subsidy/ClaimsTable.vue';
import FamilySubsidyReview from '@/parent/views/family-subsidies/FamilySubsidyReview.vue';
import ManageSubsidyHeader from '@/parent/components/subsidy/ManageSubsidyHeader.vue';
import baseApi from '@/shared/services/bright_finder';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

const route = useRoute();
const store = useStore();

const familySubsidy = ref(null);
const familySubsidyProgram = ref(null);
const loaded = ref(false);

const shouldShowClaims = computed(
  () => familySubsidyProgram.value.enable_claims && familySubsidyProgram.value.claim_schema_id,
);

const downloadLink = computed(() => {
  return baseApi.parent.family_subsidy.downloadUrl(
    route.params.id,
    store.state.profile?.default_locale || 'en',
    'Application',
  );
});

async function load() {
  const { data: familySubsidyData } = await baseApi.parent.family_subsidy.get(route.params.id);
  familySubsidy.value = familySubsidyData;

  const { data: familySubsidyProgramData } =
    await baseApi.public_api.organization.family_subsidy_program.get(
      familySubsidy.value.family_subsidy_program_id,
    );
  familySubsidyProgram.value = familySubsidyProgramData;
  loaded.value = true;
}

onMounted(() => load());
</script>
