<template>
  <div>
    <v-divider />

    <div class="bg-super-light-blue px-4 pt-6">
      <v-select
        v-model="modelValue"
        v-model:menu="menu"
        :aria-label="$t(ariaLabel)"
        :items="providers"
        :label="$t(placeholderValue)"
        :menu-props="{
          closeOnContentClick: true,
        }"
        class="b-radius-0"
        item-title="name"
        item-value="id"
        variant="solo"
        clearable
        eager
      >
        <template #prepend-item>
          <v-list-item
            @click="handleNAChange"
            :title="$t(LABELS.NOT_APPLICABLE)"
          >
            <template #prepend>
              <v-checkbox
                :model-value="na"
                class="mt-0 pt-0"
                color="primary"
                density="compact"
                false-icon="check_box_outline_blank"
                true-icon="check_box"
                hide-details
              />
            </template>
          </v-list-item>
          <v-divider class="mt-2" />
        </template>
      </v-select>
    </div>

    <v-divider />

    <v-row class="my-4 px-4">
      <v-col>
        <v-btn
          @click="$emit('back')"
          class="focus-very-visible"
          color="primary"
          size="x-large"
          variant="outlined"
        >
          <span v-t="'Back to ranking'" />
        </v-btn>
      </v-col>

      <template v-if="showNext">
        <v-col class="d-flex justify-end">
          <v-btn
            @click="$emit('next')"
            :disabled="!valid"
            class="focus-very-visible"
            color="primary"
            size="x-large"
            tabindex="0"
          >
            <span v-t="'Continue'" />
            <v-icon class="ms-2"> east </v-icon>
          </v-btn>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script setup>
import { LABELS } from '@/shared/assets/constants';

const props = defineProps({
  na: Boolean,
  providers: {
    default: () => [],
    type: Array,
  },
  showNext: Boolean,
  subsidyProgram: {
    default: null,
    type: Object,
  },
  valid: Boolean,
  value: {
    default: null,
    type: String,
  },
});

const emit = defineEmits(['back', 'change', 'na', 'next']);

const modelValue = defineModel();

const menu = ref(false);

const ariaLabel = computed(() => {
  const listProvider = props.providers.find((provider) => provider.id === modelValue.value);

  if (listProvider) return `${listProvider.name} selected`;

  if (props.providers.length > 0) return 'Select one provider';

  return 'There are no providers; please contact an admin';
});

const placeholderValue = computed(() => {
  return props.na === true ? LABELS.NOT_APPLICABLE : 'Selection required';
});

function handleNAChange() {
  emit('na');
  menu.value = false;
}
</script>
