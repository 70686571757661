<template>
  <v-card
    :class="gapClass"
    style="border: 1px #e4e7ec"
  >
    <v-card-text>
      <v-radio
        :value="value"
        class="bg-super-light-blue pa-2 bc-extra-light-gray b-1 my-1"
        data-testid="radio"
      >
        <template #label>
          <v-row>
            <v-col class="d-flex flex-column">
              <span data-testid="title">{{ title }}</span>
              <span
                v-if="subtitle || subtitleIcon"
                class="d-flex align-items-center c-dark-gray"
              >
                <v-icon
                  v-if="subtitleIcon"
                  :icon="subtitleIcon"
                  class="fs-14 mr-2 pt-2"
                  size="x-small"
                />
                <span
                  v-if="subtitle"
                  class="fs-12"
                  data-testid="subtitle"
                >
                  {{ subtitle }}
                </span>
              </span>
            </v-col>
          </v-row>
        </template>
      </v-radio>
    </v-card-text>
  </v-card>
</template>

<script setup>
const props = defineProps({
  gap: {
    type: Number,
    default: 4,
  },
  subtitle: {
    type: String,
    required: false,
  },
  subtitleIcon: {
    type: String,
    required: false,
  },
  title: {
    type: String,
    required: true,
  },
  value: {
    type: Object,
    default: () => ({}),
  },
});

const gapClass = computed(() => `mt-${props.gap}`);
</script>
