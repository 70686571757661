<template>
  <div
    v-if="shouldRender"
    class="mt-12"
    data-testid="dashboard-family-subsidy-programs"
  >
    <h2 class="fs-24 mb-4">
      {{ $t('Other programs you might be interested in') }}
    </h2>

    <DashboardFamilySubsidyProgram
      v-for="item in items"
      :key="item.id"
      :item="item"
    />
  </div>
</template>

<script setup>
import DashboardFamilySubsidyProgram from '@/parent/components/dashboards/DashboardFamilySubsidyProgram.vue';

const props = defineProps({
  items: {
    type: Array,
    default: () => [],
  },
});

const shouldRender = computed(() => {
  return props.items.length > 0;
});
</script>
