<template>
  <v-container class="mxw-800 mx-auto py-12">
    <template v-if="questions.length > 0 && schema && eligibilityScreener">
      <div
        ref="progress"
        :aria-label="$t('Progress indicator - question') + ' ' + currentSection"
        class="px-2 focus-invisible"
        role="progressbar"
        tabindex="0"
        aria-live
      >
        <v-progress-linear
          v-model="progress"
          :indeterminate="!currentSection"
          class="focus-invisible mb-4"
          color="primary"
        />
      </div>

      <QuestionSet
        v-model="eligibilityScreener"
        @back="backFromQuestion($event)"
        @next="forwardFromQuestion($event)"
        :questions="questions"
        :reversible="currentSection != defaultSection"
        :schema="schema.definition"
        :section="currentSection"
        :transition-name="currentTransitionName"
        key-name="question"
      />
    </template>
  </v-container>
</template>

<script setup>
import store from '@/parent/store';
import QuestionSet from '@/shared/components/form/QuestionSet.vue';
import useSegment from '@/shared/composables/analytics/useSegment';
import useRouterHelper from '@/shared/composables/useRouterHelper';
import baseApi from '@/shared/services/bright_finder.js';
import { useRoute, useRouter } from 'vue-router';

const { updateQuery } = useRouterHelper();

const route = useRoute();
const router = useRouter();
const segment = useSegment();

const defaultSection = 'question-0';
const defaultTransitionName = 'slide-left';

const track = async (button, questionIndex) => {
  const questionId = questions.value[questionIndex]?.id;
  await segment.track(`Eligibility Screener - Question ${questionIndex + 1} ${button} Clicked`, {
    screenerId: route.params.screenerProgramId,
    questionIndex,
    questionId,
    stepId: currentStep.value,
  });
};

const eligibilityScreener = ref(null);
const currentStep = ref(1);
const currentSection = ref(defaultSection);
const currentTransitionName = ref(defaultTransitionName);
const questions = ref([]);
const schema = ref(null);

const progress = computed(() => (currentStep.value / questionCount.value) * 100);
const questionCount = computed(() => questions.value.length);

watch(
  () => route.query,
  (newQuery) => {
    // Handle browser Back and Forward buttons
    let updateQueryNeeded = false;
    if (newQuery.section && newQuery.section !== currentSection.value) {
      currentSection.value = newQuery.section;
      updateQueryNeeded = true;
    }

    if (newQuery.step && newQuery.step !== currentStep.value) {
      currentStep.value = parseInt(newQuery.step, 10);
      updateQueryNeeded = true;
    }

    if (updateQueryNeeded) {
      updateQuery(Object.assign({ step: currentStep.value, section: currentSection.value }));
    }
  },
  { deep: true },
);

async function setRouteDefaults() {
  if (route.query.step && route.query.section) return;

  updateQuery({ step: 1, section: defaultSection });
}

async function forwardFromQuestion(currentIndex) {
  await save(async () => await handleStepChange({ currentIndex, stepDirection: 'forward' }));
  await track('Next', currentIndex);
  if (progress.value > 100) {
    return handleSubmit();
  }
}

async function handleSubmit() {
  const response = await baseApi.eligibilityScreener.update(eligibilityScreener.value, {
    eligibility_screener: { submitted: true },
  });
  if (!response?.status === 200) return;

  const id = eligibilityScreener?.value?.id;

  if (store.getters.isAuthenticatedRegisteredParent) {
    router.push({ name: 'Dashboard', query: { eligibilityScreenerId: id } });
  } else {
    router.push({ name: 'EligibilityScreenerResult', params: { id } });
  }
}

async function backFromQuestion(currentIndex) {
  await save(async () => await handleStepChange({ currentIndex, stepDirection: 'back' }));
  await track('Back', currentIndex);
}

async function handleStepChange({ currentIndex = null, stepDirection = null } = {}) {
  let newIndex;
  let newTransitionName;
  if (stepDirection === 'forward') {
    newIndex = currentIndex + 1;
    newTransitionName = 'slide-left';
  } else if (stepDirection === 'back') {
    newIndex = currentIndex - 1;
    newTransitionName = 'slide-right';
  } else {
    console.log('BAD VALUE PASSED TO handleStepChange'); // TODO: Keep temporarily for HUB debugging
    return;
  }

  currentSection.value = `question-${newIndex}`;
  currentStep.value = newIndex + 1;
  currentTransitionName.value = newTransitionName;
  await updateQuery({ step: currentStep.value, section: currentSection.value });
  return;
}

async function save(callback) {
  const response = await baseApi.eligibilityScreener.update(eligibilityScreener.value);

  if (response?.status === 200) {
    eligibilityScreener.value = response?.data;

    await getQuestions();

    if (callback && typeof callback === 'function') {
      callback();
    }
  }
}

async function getQuestions() {
  const response = await baseApi.eligibilityScreener.questions(eligibilityScreener?.value?.id);
  const questionsData = response?.data;

  questions.value = questionsData;
}

async function getEligibilityScreener(screenerProgram) {
  const { eligibilityScreenerId } = route.query;

  if (eligibilityScreenerId) {
    const response = await baseApi.eligibilityScreener.get(eligibilityScreenerId);

    if (response.status === 200) {
      eligibilityScreener.value = response?.data;

      await getQuestions();
      await getSchema(eligibilityScreener.value.schema_id);

      return;
    }
  }

  // No eligibilityScreenerId query param present or not a 200 response
  return await createEligibilityScreener(screenerProgram);
}

async function createEligibilityScreener(screenerProgram) {
  const response = await baseApi.eligibilityScreener.create({
    screener_program_id: screenerProgram?.id,
    schema_id: screenerProgram?.eligibility_screener_schema_id,
  });
  eligibilityScreener.value = response?.data;
  await updateQuery({ eligibilityScreenerId: eligibilityScreener.value.id });

  await getQuestions();
  await getSchema(eligibilityScreener.value.schema_id);
}

async function getScreenerProgramById() {
  const id = route.params.screenerProgramId;
  const response = await baseApi.screenerProgram.get(id);
  const screenerProgram = response?.data;

  await getEligibilityScreener(screenerProgram);
}

async function getSchema(id) {
  const resp = await baseApi.public_api.organization.schema.get(id);
  schema.value = resp?.data;
}

// Check for authentication before creating anonymous user like done in search
async function createAnonymousMember() {
  const member = {
    default_locale: 'en',
    is_anonymous: true,
    is_parent: true,
    organization_id: window.organization_id,
  };

  const response = await baseApi.member.create(member);

  store.dispatch('identify', {
    profile: response.data,
    async success() {
      await getScreenerProgramById();
    },
  });
}

async function identifyMember() {
  store.dispatch('identify', {
    async success() {
      await getScreenerProgramById();
    },
    async failure() {
      await createAnonymousMember();
    },
  });
}

onMounted(async () => {
  await identifyMember();
  await setRouteDefaults();
});
</script>
