import AnonymousSurveyShow from '@/shared/views/AnonymousSurveyShow.vue';
import ChildApply from '@/parent/views/children/ChildApply.vue';
import ChildCheck from '@/parent/views/children/ChildCheck.vue';
import ChildNew from '@/parent/views/children/ChildNew.vue';
import ChildSelectOrCreate from '@/parent/views/children/ChildSelectOrCreate.vue';
import ChildShow from '@/parent/views/children/ChildShow.vue';
import CodeSent from '@/shared/views/CodeSent.vue';
import Dashboard from '@/parent/views/ParentDashboard.vue';
import EmailUpdate from '@/parent/views/EmailUpdate.vue';
import EligibilityScreener from '@/parent/views/eligibility-screener/EligibilityScreener.vue';
import EligibilityScreenerResult from '@/parent/views/eligibility-screener/EligibilityScreenerResult.vue';
import FallbackRouter from '@/shared/views/FallbackRouter.vue';
import FamilySubsidyNew from '@/parent/views/family-subsidies/FamilySubsidyNew.vue';
import FamilySubsidyContinue from '@/parent/views/family-subsidies/FamilySubsidyContinue.vue';
import FamilySubsidyReview from '@/parent/views/family-subsidies/FamilySubsidyReview.vue';
import FormNew from '@/parent/views/FormNew.vue';
import WebpageLanding from '@/shared/views/WebpageLanding.vue';
import LoginCode from '@/shared/components/LoginCodes.vue';
import ManageChildSubsidy from '@/parent/views/ManageChildSubsidy.vue';
import ManageFamilySubsidy from '@/parent/views/ManageFamilySubsidy.vue';
import MemberProfile from '@/parent/views/MemberProfile.vue';
import MessageIndex from '@/parent/views/MessageIndex.vue';
import ProviderReferral from '@/parent/views/ProviderReferral.vue';
import PublicProviderShow from '@/public/views/ProviderShow.vue';
import PublicSurveyShow from '@/public/views/SurveyShow.vue';
import SearchShow from '@/parent/views/SearchShow.vue';
import SignOut from '@/shared/views/SignOut.vue';
import RegisterPassword from '@/shared/views/RegisterPassword.vue';
import ParentSignup from '@/parent/views/ParentSignup.vue';
import ContactSubscriptions from '@/shared/views/ContactSubscriptions.vue';
import SubsidyContinue from '@/parent/views/child-subsidies/ChildSubsidyContinue.vue';
import SubsidyNew from '@/parent/views/child-subsidies/ChildSubsidyNew.vue';
import SubsidyReview from '@/parent/views/child-subsidies/ChildSubsidyReview.vue';
import TermsAgreement from '@/parent/views/TermsAgreement.vue';
import ParentWelcome from '@/parent/views/ParentWelcome.vue';
import WebpageShow from '@/shared/views/WebpageShow.vue';
import { createRouter, createWebHistory } from 'vue-router';

export const routes = [
  {
    component: AnonymousSurveyShow,
    meta: {
      app_bar: false,
      public: true,
      title: 'Survey',
    },
    name: 'AnonymousSurveyShow',
    path: '/public/surveys/:schemaId',
  },

  {
    component: Dashboard,
    name: 'Dashboard',
    path: '/dashboard',
    meta: {
      background: 'bg-super-light-blue',
      depth: 1,
      title: 'Dashboard',
    },
  },

  {
    component: ChildApply,
    name: 'ChildApply',
    path: '/children/:childId/apply',
    meta: {
      depth: 1,
      title: 'Apply to eligible programs',
    },
  },

  {
    component: ChildNew,
    name: 'ChildNew',
    path: '/children/new',
    meta: {
      depth: 1,
      public: true,
      title: 'New Child',
    },
  },

  {
    component: ChildCheck,
    name: 'ChildCheck',
    path: '/children/check',
    meta: {
      depth: 1,
      public: true,
      title: 'New application',
    },
  },

  {
    component: ChildSelectOrCreate,
    name: 'ChildSelectOrCreate',
    path: '/children/select_or_create',
    meta: {
      depth: 1,
      public: true,
      title: 'Select Child',
    },
  },

  {
    component: ChildShow,
    name: 'ChildShow',
    path: '/children/:childId',
    meta: {
      background: 'bg-super-light-blue',
      depth: 9,
      title: 'Child profile',
    },
  },

  {
    component: CodeSent,
    meta: {
      background: 'bg-super-light-blue',
      public: true,
      title: 'Code sent',
    },
    name: 'CodeSent',
    path: '/code_sent',
    props: (route) => ({ routeParams: route.params, routeQuery: route.query }),
  },

  {
    component: EligibilityScreenerResult,
    meta: {
      background: 'bg-super-light-blue',
      public: true,
      title: 'Eligibility results',
    },
    name: 'EligibilityScreenerResult',
    path: '/eligibility_results/:id',
  },

  {
    component: EmailUpdate,
    meta: {
      registration: true,
      title: 'Update your email',
    },
    name: 'EmailUpdate',
    path: '/email_update',
  },

  {
    component: EligibilityScreener,
    meta: {
      public: true,
      title: 'Eligibility Screener',
    },
    name: 'EligibilityScreener',
    path: '/screener/:screenerProgramId',
  },

  {
    component: FamilySubsidyNew,
    meta: {
      background: 'bg-white',
      public: true,
      title: 'New application',
    },
    name: 'FamilySubsidyNew',
    path: '/family_applications/:familySubsidyProgramId/new',
  },

  {
    component: FamilySubsidyContinue,
    meta: {
      depth: 9,
      title: 'Continue application',
    },
    name: 'FamilySubsidyContinue',
    path: '/family_applications/:id/continue',
  },

  {
    component: FamilySubsidyReview,
    meta: {
      depth: 9,
      title: 'Review application',
    },
    name: 'FamilySubsidyReview',
    path: '/family_applications/:id/review',
  },

  {
    component: FormNew,
    meta: {
      background: 'bg-white',
      public: true,
      title: 'New form',
    },
    name: 'FormNew',
    path: '/forms/:schemaId/new',
  },

  {
    component: ManageChildSubsidy,
    name: 'ManageChildSubsidy',
    path: '/child_applications/:subsidyId/manage',
    meta: {
      background: 'bg-super-light-blue',
      depth: 9,
      title: 'Manage application',
    },
  },

  {
    component: ManageFamilySubsidy,
    name: 'ManageFamilySubsidy',
    path: '/family_applications/:id/manage',
    meta: {
      background: 'bg-super-light-blue',
      depth: 9,
      title: 'Manage application',
    },
  },

  {
    component: SearchShow,
    name: 'SearchShow',
    path: '/searches/:searchId',
    meta: {
      anonymous: true,
      depth: 9,
      expandedNav: true,
      fillHeight: true,
      title: 'Search',
    },
  },

  {
    component: RegisterPassword,
    name: 'RegisterPassword',
    path: '/password',
    meta: {
      depth: 2,
      registration: true,
      title: 'Set password',
    },
  },

  {
    component: ContactSubscriptions,
    meta: {
      background: 'bg-super-light-blue',
      depth: 999,
      public: true,
      title: 'Subscriptions',
    },
    name: 'Subscriptions',
    path: '/subscriptions',
  },

  {
    path: '/login',
    meta: {
      public: true,
      title: 'Login',
    },
    redirect: {
      path: '/register',
      query: {
        mode: 'login',
      },
    },
  },

  {
    component: LoginCode,
    meta: {
      background: 'bg-super-light-blue',
      public: true,
      title: 'Login code',
    },
    name: 'DeprecatedLoginCode',
    path: '/parent/login_codes/:login_code',
  },

  {
    component: LoginCode,
    meta: {
      background: 'bg-super-light-blue',
      public: true,
      title: 'Login code',
    },
    name: 'LoginCode',
    path: '/login_codes/:login_code',
  },

  {
    path: '/parent',
    redirect: '/welcome',
  },

  {
    component: MessageIndex,
    meta: {
      fillHeight: true,
      title: 'My messages',
    },
    name: 'MessageIndex',
    path: '/messages',
  },

  {
    component: MemberProfile,
    meta: {
      background: 'bg-super-light-blue',
      title: 'My profile',
    },
    name: 'MemberProfile',
    path: '/member/profile',
  },

  {
    component: ProviderReferral,
    meta: {
      depth: 1,
      public: true,
      title: 'Provider Referral',
    },
    name: 'ProviderReferral',
    path: '/provider_referral',
  },

  {
    component: PublicProviderShow,
    meta: {
      background: 'bg-super-light-blue',
      public: true,
      title: 'Provider profile',
    },
    name: 'ProviderCrawlerShow',
    path: '/providers/:city/:zip/:name',
  },

  {
    component: PublicProviderShow,
    meta: {
      background: 'bg-super-light-blue',
      public: true,
      title: 'Provider profile',
    },
    name: 'PublicProviderShow',
    path: '/providers/:providerId',
  },

  {
    component: PublicSurveyShow,
    meta: {
      background: 'bg-super-light-blue',
      public: true,
      title: 'Survey',
    },
    name: 'PublicSurveyShow',
    path: '/surveys/:schemaId',
  },

  {
    component: SignOut,
    meta: {
      app_bar: true,
      depth: 10,
      title: 'Sign out',
    },
    name: 'SignOut',
    path: '/sign_out',
  },

  {
    component: ParentSignup,
    meta: {
      background: 'bg-white',
      public: true,
      title: 'Sign up',
    },
    name: 'Signup',
    path: '/register',
  },

  {
    component: SubsidyNew,
    meta: {
      background: 'bg-white',
      public: true,
      title: 'New application',
    },
    name: 'ChildSubsidyNew',
    path: '/applications/:subsidyProgramId/new',
  },

  {
    component: SubsidyReview,
    meta: {
      title: 'Review application',
    },
    name: 'SubsidyReview',
    path: '/applications/:subsidyId/review',
  },

  {
    component: SubsidyContinue,
    meta: {
      title: 'Continue application',
    },
    name: 'SubsidyContinue',
    path: '/applications/:subsidyId/continue',
  },

  {
    component: TermsAgreement,
    meta: {
      registration: true,
      title: 'Terms agreement',
    },
    name: 'TermsAgreement',
    path: '/terms_agreement',
  },

  {
    component: WebpageShow,
    meta: {
      background: 'bg-white',
      public: true,
    },
    name: 'WebpageShow',
    path: '/pages/:webpageId',
  },

  {
    component: ParentWelcome,
    meta: {
      background: 'bg-super-light-blue',
      public: true,
    },
    name: 'Welcome',
    path: '/welcome',
  },

  {
    component: WebpageLanding,
    meta: {
      public: true,
    },
    name: 'Landing',
    path: '/',
  },

  {
    path: '/:pathMatch(.*)*',
    component: FallbackRouter,
    meta: {
      public: true,
    },
  },
];

export default createRouter({
  history: createWebHistory(import.meta.env.VUE_BASE_URL),
  routes,
});
