<template>
  <v-container class="mxw-800 mx-auto py-12 px-1">
    <template v-if="!isTest && isClosed">
      <p v-t="'Program closed'" />
    </template>

    <template v-else-if="!isTest && !isOpen">
      <p v-t="'Program not open'" />
    </template>

    <template v-else>
      <slot name="open" />
    </template>
  </v-container>
</template>

<script>
export default {
  compatConfig: { MODE: 2 },

  props: {
    subsidyProgram: {
      type: Object,
      default: null,
    },
  },
  computed: {
    isTest() {
      return this.$route.query.test === 'daremightythings';
    },

    isClosed() {
      if (this.subsidyProgram?.closes_at) {
        return this.convertToUTC(new Date()) > this.convertToUTC(this.subsidyProgram.closes_at);
      }

      return false;
    },

    isOpen() {
      if (this.subsidyProgram?.opens_at) {
        return this.convertToUTC(new Date()) > this.convertToUTC(this.subsidyProgram.opens_at);
      }

      return true;
    },
  },

  methods: {
    convertToUTC(date) {
      const inputDate = new Date(date);

      const utcYear = inputDate.getUTCFullYear();
      const utcMonth = inputDate.getUTCMonth();
      const utcDay = inputDate.getUTCDate();
      const utcHours = inputDate.getUTCHours();
      const utcMinutes = inputDate.getUTCMinutes();
      const utcSeconds = inputDate.getUTCSeconds();

      return new Date(Date.UTC(utcYear, utcMonth, utcDay, utcHours, utcMinutes, utcSeconds));
    },
  },
};
</script>
