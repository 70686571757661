<template>
  <div>
    <slot
      :child="child"
      :previous-child="previousChild"
      :previous-child-name="previousChildName"
      :previous-subsidy="previousSubsidy"
      :subsidy="subsidy"
    />
  </div>
</template>

<script>
export default {
  compatConfig: { MODE: 2 },

  props: {
    child: {
      type: Object,
      default: null,
    },
    previousChild: {
      type: Object,
      default: null,
    },
    subsidies: {
      type: Array,
      default: null,
    },
  },

  computed: {
    previousChildName() {
      return this.previousChild?.name;
    },

    previousSubsidy() {
      return this.previousChild
        ? this.subsidies.find((subsidy) => subsidy.child_id === this.previousChild.id)
        : null;
    },

    subsidy() {
      return this.subsidies.find((subsidy) => subsidy.child_id === this.child.id);
    },
  },
};
</script>
