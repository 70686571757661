import actions from '@/shared/store/actions';
import mutations from '@/shared/store/mutations';
import state from '@/shared/store/state';
import Api from '@/shared/services/bright_finder';
import _ from 'lodash';
import { createStore } from 'vuex';
import SegmentService from '@/shared/services/analytics/SegmentService';

const parentState = {
  expandAppBarSmAndDown: false,
  group: { favorites: [], id: null, members: [] },
  is_authenticated: false,
  is_anonymous: false,
  is_parent: null,
  languages: [],
  newMessage: false,
  search_id: null,
  site: null,
  siteWebpageId: null,
  userSessionTimeout: null,
  userSessionActionTimestamp: null,
};

const defaultState = {
  ...state,
  ...parentState,
};

export const definition = {
  actions: {
    ...actions,

    async identify(context, args = {}) {
      if (args.profile && args.profile.organization_id === window.organization_id) {
        context.commit('setAuthenticated', true);
        context.dispatch('setProfileAndTimeout', args.profile);
        if (args.success) args.success();
        return;
      }

      const resp = await (args.api || Api).member.session.get('parent');

      if (resp.data?.status !== 'authenticated' && resp.data?.status !== 'password_reset') {
        if (args.failure) args.failure();
        return;
      }

      context.commit('setAuthenticated', true);
      const profileResp = await (args.api || Api).member.profile.get();

      if (profileResp.data?.organization_id !== window.organization_id) {
        context.commit('setAuthenticated', false);
        context.dispatch('setProfileAndTimeout', null);

        if (args.failure) args.failure();
        return;
      }

      context.dispatch('setProfileAndTimeout', profileResp.data);
      SegmentService.forParent(window.analytics).identifyMember(profileResp.data);
      if (args.success) args.success(profileResp.data);
    },
  },

  state: {
    ...defaultState,
  },

  getters: {
    isAuthenticatedRegisteredParent: (state) => {
      // TODO: Refine our handling of auth state vs profile
      if (state?.is_authenticated && state?.is_parent && !state?.is_anonymous) return true;

      return (
        state?.profile?.is_authenticated &&
        state?.profile?.is_parent &&
        !state?.profile?.is_anonymous
      );
    },
  },

  mutations: {
    ...mutations,

    setExpandAppBarSmAndDown(state, val) {
      state.expandAppBarSmAndDown = val;
    },

    setNewMessage(state, val) {
      state.newMessage = val;
    },

    setProfile(state, profile) {
      state.profile = profile;
      if (profile) {
        if (profile.default_locale) {
          state.default_locale = profile.default_locale;
        }
        state.is_anonymous = profile.is_anonymous;
        state.is_parent = profile.is_parent;
      } else {
        state.is_anonymous = null;
        state.lists = null;
      }
    },

    setSearch(state, search) {
      state.search = search;
    },

    setAnonymous(state, val) {
      state.is_anonymous = val;
    },

    setAuthenticated(state, val) {
      state.is_authenticated = val;
    },

    resetState(state) {
      if (import.meta.env.NODE_ENV !== 'test')
        throw new Error('resetState should not be called in production');

      Object.assign(state, defaultState);
    },

    /* eslint no-param-reassign: ["error", { "props": false }] */
    resetTimeout: _.throttle((state) => {
      if (!!state.userSessionTimeout && state.is_authenticated && !state.is_anonymous) {
        Api.member.session.get('parent', (resp) => {
          if (resp.data?.status === 'authenticated') state.userSessionActionTimestamp = new Date();
        });
      }
    }, 15000),
  },
};

export default createStore(definition);
