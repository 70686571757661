<template>
  <div v-if="loaded">
    <v-row>
      <v-col>
        <v-btn
          @click="updateQuery({ mode: 'login' })"
          :ripple="false"
          class="focus-visible fs-16 fw-500 c-primary bc-link-hover px-0 mt-0 underlined"
          data-cy="login-mode-button"
          tabindex="0"
          variant="text"
        >
          {{ $t('Log in if you already have an account.') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row dense>
      <LabeledTextfield
        v-model="first_name"
        :aria-label="$t('Enter first name')"
        :message="'First name'"
        class="mb-2"
        cols="12"
        data-cy="input_first_name"
        md="6"
        mandatory
        outlined
      />

      <LabeledTextfield
        v-model="last_name"
        :aria-label="$t('Enter last name')"
        :message="'Last name'"
        class="mb-2"
        cols="12"
        data-cy="input_last_name"
        md="6"
        mandatory
        outlined
      />
    </v-row>
    <v-row dense>
      <template v-if="$store.state.config.enable_phone_registration">
        <LabeledSimpleSelect
          v-model="signupMode"
          :items="[
            { text: $t('Email'), value: 'Email' },
            { text: $t('Cell phone'), value: 'Cell phone' },
          ]"
          :message="'Email or phone'"
          class="mb-2"
          cols="12"
          data-cy="signup-mode-selector"
          mandatory
          outlined
        />
      </template>

      <LabeledTextfield
        v-show="signupMode == 'Email'"
        v-model="email"
        @keyup.enter="draftSubmit"
        :aria-label="$t('Enter email')"
        :message="'Email'"
        class="mb-2"
        cols="12"
        data-cy="input_email"
        mandatory
        outlined
      />

      <LabeledTextfield
        v-show="signupMode == 'Email'"
        v-model="confirmEmail"
        @keyup.enter="draftSubmit"
        @paste.prevent
        :aria-label="$t('Re-enter email')"
        :message="'Re-enter email'"
        class="mb-2"
        cols="12"
        data-cy="input_email_confirm"
        mandatory
        outlined
      />

      <LabeledTextfield
        v-show="signupMode == 'Cell phone'"
        v-model="phone"
        @keyup.enter="draftSubmit"
        :aria-label="$t('Enter phone number')"
        :message="'Cell phone number'"
        class="mb-2"
        cols="12"
        data-cy="input_phone"
        mask="(###) ###-####"
        placeholder="(555) 555-5555"
        hide-details
        mandatory
        outlined
      />

      <LabeledTextfield
        v-show="signupMode == 'Cell phone'"
        v-model="confirmPhone"
        @keyup.enter="draftSubmit"
        @paste.prevent
        :aria-label="$t('Re-enter phone number')"
        :message="'Re-enter phone number'"
        class="mb-2"
        cols="12"
        data-cy="input_phone_confirm"
        mask="(###) ###-####"
        placeholder="(555) 555-5555"
        hide-details
        mandatory
        outlined
      />

      <template v-if="$store.state.config.enable_password_authentication">
        <LabeledTextfield
          v-model="password"
          @keyup.enter="draftSubmit"
          :aria-label="$t('Create password')"
          :message="'Password'"
          class="mb-2"
          data-cy="input_password"
          type="password"
          hide-details
          mandatory
          outlined
        />

        <LabeledTextfield
          v-model="password_confirmation"
          @keyup.enter="draftSubmit"
          :aria-label="$t('Confirm password')"
          :message="'Confirm password'"
          class="mb-2"
          data-cy="input_password_confirm"
          type="password"
          hide-details
          mandatory
          outlined
        />

        <v-col cols="12">
          <p
            v-t="
              'The password must be 8 characters with 3 out of 4 of the following: Lowercase characters, Uppercase characters, Numbers, Symbols.'
            "
            class="fs-15 fw-500 mb-2"
          />
        </v-col>
      </template>
    </v-row>

    <v-row
      v-show="signupMode == 'Cell phone'"
      dense
    >
      <v-col>
        <v-checkbox
          v-model="textOptIn"
          :label="
            $t(
              'I consent to receive communication by text message to the cellphone number provided above.',
            )
          "
          data-cy="text-opt-in"
        />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col
        class="c-light-black fs-15"
        tabindex="0"
      >
        {{ $t('This site is protected by reCAPTCHA and the Google') }}
        <a href="https://policies.google.com/privacy"> {{ $t('Privacy Policy') }}</a>
        {{ $t('and') }}
        <a href="https://policies.google.com/terms"> {{ $t('Terms of Service') }}</a>
        {{ $t('apply.') }}
      </v-col>
    </v-row>

    <v-row class="mt-3">
      <v-col>
        <v-btn
          @click="draftSubmit"
          :disabled="submitDisabled"
          :loading="processing"
          :ripple="false"
          class="mnw-300 focus-very-visible"
          color="primary"
          data-cy="signup-button"
          size="x-large"
        >
          <span v-t="'Sign up'" />
        </v-btn>
      </v-col>
    </v-row>

    <template v-if="$store.state.config.terms_agreement">
      <ResourceDialog
        @save="submit"
        ref="termsAgreementDialog"
        :max-width="800"
        :processing="processing"
        :save-button-text="$store.state.config.terms_agreement.accept_label"
        :title="$store.state.config.terms_agreement.title"
        center-title
      >
        <template #form>
          <v-row>
            <v-col>
              <MarkdownContent
                :content="$store.state.config.terms_agreement?.content"
                class="focus-very-visible fs-16"
                tabindex="0"
              />
            </v-col>
          </v-row>
        </template>
      </ResourceDialog>
    </template>
  </div>
</template>

<script setup>
import Api from '@/manager/services/bright_finder';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import MarkdownContent from '@/shared/components/MarkdownContent.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useI18n } from 'vue-i18n';
import usePasswordValidation from '@/shared/composables/usePasswordValidation';
import { useReCaptcha } from 'vue-recaptcha-v3';
import { useRoute, useRouter } from 'vue-router';
import useRouterHelper from '@/shared/composables/useRouterHelper';
import { useStore } from 'vuex';

const confirmEmail = ref(null);
const confirmPhone = ref(null);
const email = ref(null);
const eventBus = useEventBus();
const { locale, t } = useI18n();
const first_name = ref(null);
const { isValidPassword } = usePasswordValidation();
const last_name = ref(null);
const loaded = ref(false);
const password = ref(null);
const password_confirmation = ref(null);
const phone = ref(null);
const processing = ref(null);
const recaptcha = useReCaptcha();
const route = useRoute();
const router = useRouter();
const { updateQuery } = useRouterHelper();
const signupMode = ref('Email');
const store = useStore();
const termsAgreementDialog = ref(null);
const textOptIn = ref(false);

const submitDisabled = computed(() => {
  if (signupMode.value === 'Email' && (!email.value || !confirmEmail.value)) return true;
  if (signupMode.value === 'Cell phone' && (!phone.value || !textOptIn.value)) return true;
  if (store.state.config.enable_password_authentication) {
    return (
      !first_name.value ||
      !last_name.value ||
      !isValidPassword(password.value, password_confirmation.value)
    );
  }
  return !first_name.value || !last_name.value;
});

onMounted(load);

function load() {
  if (store.state.config.disable_self_registration) return router.replace({ name: '/' });
  if (store.state.profile) {
    loaded.value = true;
    return true;
  }

  const parent = {
    default_locale: locale.value,
    is_anonymous: true,
    is_parent: true,
    organization_id: window.organization_id,
  };
  Api.member.create(parent, (resp) => {
    store.commit('setProfile', resp.data);
    loaded.value = true;
  });
  return true;
}

function createAccount(token) {
  if (signupMode.value === 'Cell phone') {
    email.value = null;
  } else {
    phone.value = null;
  }

  let referralRedirectUrl = null;
  if (route.query.action === 'providerReferral') {
    referralRedirectUrl = `/provider_referral?subsidyProgramId=${route.query.subsidyProgramId}&programId=${route.query.programId}`;
  }

  const params = {
    email: email.value,
    first_name: first_name.value,
    is_anonymous: false,
    is_parent: true,
    last_name: last_name.value,
    password: password.value,
    phone: phone.value,
    redirect_url: referralRedirectUrl || route.query.redirect_url,
    text_opted_in: signupMode.value === 'Cell phone',
    terms_agreement_id: store.state.config.terms_agreement?.id,
    token,
  };
  if (route.query.searchId) {
    params.redirect_url = `/searches/${route.query.searchId}`;
  }
  Api.member.profile.update(
    params,
    (resp) => {
      if (resp.data.code_id) {
        const query = {
          action: route.query.action,
          mode: 'register',
          subsidyProgramId: route.query.subsidyProgramId,
          programId: route.query.programId,
          token: resp.data.code_id,
          email_sent_to: email.value,
          phone: phone.value,
          page: route.query.page,
          searchId: route.query.searchId,
        };
        router.push({ name: 'CodeSent', query });
      } else {
        store.dispatch('identify', {
          success() {
            if (route.query.action) {
              if (route.query.action === 'apply') {
                router.replace({
                  name: 'ChildSubsidyNew',
                  params: { subsidyProgramId: route.query.subsidyProgramId },
                });
              } else if (route.query.action === 'childNew') {
                router.replace({ name: 'ChildNew' });
              } else if (route.query.action === 'providerReferral') {
                router.replace({
                  name: 'ChildSubsidyNew',
                  params: { subsidyProgramId: route.query.subsidyProgramId },
                  query: { programId: route.query.programId },
                });
              } else if (route.query.searchId) {
                router.replace({
                  name: 'SearchShow',
                  params: {
                    searchId: route.query.searchId,
                  },
                  query: {
                    action: route.query.action,
                    providerId: route.query.providerId,
                  },
                });
              } else if (route.query.providerId) {
                router.replace({
                  name: 'PublicProviderShow',
                  params: {
                    providerId: route.query.providerId,
                  },
                  query: {
                    action: route.query.action,
                  },
                });
              } else {
                router.replace({ name: 'Dashboard' });
              }
            } else {
              router.replace({ name: 'Dashboard' });
            }
          },
          failure(err) {
            processing.value = false;
            eventBus.error(err);
          },
        });
      }
    },
    (err) => {
      processing.value = false;
      const error = err.response.data.errors[0];
      if (error === 'already taken') {
        if (store.state.config.enable_password_authentication) {
          eventBus.longChime(
            t(
              'Email already taken. Please login with your email and password to access your account.',
            ),
          );
        } else if (store.state.config.enable_phone_registration) {
          eventBus.longChime(
            t(
              'Email or phone is already taken. Please login with your email or phone number to access your account.',
            ),
          );
        } else {
          eventBus.longChime(
            t(
              'Email or phone is already taken. Please login with your email to access your account.',
            ),
          );
        }
      } else {
        eventBus.chime(error);
      }
    },
  );
}

async function draftSubmit() {
  if (submitDisabled.value) return false;
  if (!validate()) return false;
  if (store.state.config.terms_agreement) {
    return termsAgreementDialog.value.open();
  }
  await submit();
  return true;
}

async function submit() {
  if (submitDisabled.value) return false;
  processing.value = true;
  let token;
  if (store.state.config.env.VUE_APP_RECAPTCHA_SITE_KEY) {
    await recaptcha.recaptchaLoaded();
    token = await recaptcha.executeRecaptcha('login');
  }
  createAccount(token);
  return true;
}

function validate() {
  let error = null;
  if (signupMode.value === 'Email' && email.value !== confirmEmail.value) {
    error = 'Email and email confirmation must match';
  }
  if (signupMode.value === 'Cell phone' && phone.value !== confirmPhone.value) {
    error = 'Phone and phone confirmation must match';
  }
  if (error) {
    eventBus.chime(error);
    return false;
  }
  return true;
}
</script>
