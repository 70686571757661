<template>
  <div class="w-100pc ta-right focus-very-visible va-top h-400">
    <GoogleMap
      ref="mapRef"
      :center="mapCenter"
      :options="mapOptions"
      :zoom="zoom"
      style="width: 100%; height: 100%"
    >
      <MatchMarker
        v-for="match in matches"
        @click="$emit('select', match)"
        :key="match.id"
        :match="match"
        :selected="selectedMatch && match.id === selectedMatch.id"
        data-cy="match-marker"
      />
    </GoogleMap>
  </div>
</template>

<script setup>
import { GoogleMap } from 'vue3-google-map';
import MatchMarker from '@/shared/components/map/MatchMarker.vue';
import { Coord } from '@/shared/services/coord';

defineEmits(['select']);
const props = defineProps({
  matches: {
    default: null,
    type: Array,
  },
  search: {
    default: null,
    type: Object,
  },
  searchCounter: {
    default: null,
    type: Number,
  },
  selectedMatch: {
    default: null,
    type: Object,
  },
  visible: Boolean,
});

const mapOptions = reactive({
  draggable: true,
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false,
  disableDefaultUi: true,
});
const mapRef = ref(null);
const zoom = ref(12);

const mapCenter = computed(() => {
  if (props.search && props.search?.center) {
    return new Coord(props.search?.center);
  }

  return { lat: 0, lng: 0 };
});

function fitMap() {
  if (props.matches.length === 0) return;

  // Create new map boundary with default zoom level
  // eslint-disable-next-line no-undef
  const bounds = new google.maps.LatLngBounds();

  // Filter out matches that don't have lat/long values
  // Display results conditionally based on map view mode
  const containsLatLong = props.matches.filter((match) => match.latitude && match.longitude);

  // Extend map boundary to include the search origin
  bounds.extend(
    new Coord(
      props.search?.center || {
        latitude: containsLatLong[0]?.latitude,
        longitude: containsLatLong[0]?.longitude,
      },
    ),
  );

  // Extend the map boundary to include each map location
  containsLatLong.forEach((match) => bounds.extend(new Coord(match)));

  // Re-center and zoom map to fit new bounds
  if (mapRef.value) {
    mapRef.value.map?.fitBounds(bounds);
  }
}

watch(
  () => props.searchCounter,
  () => {
    if (props.search) fitMap();
  },
);

watch(
  () => props.visible,
  (newVal) => {
    if (newVal) fitMap();
  },
);

watch(
  () => props.matches,
  (newVal) => {
    if (newVal) fitMap();
  },
);
</script>
