<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="true"
    offset="5"
    eager
  >
    <template #activator="{ props }">
      <button
        v-bind="props"
        :ripple="false"
        class="bg-white c-black fullheight fullwidth rounded pa-3 focus-very-visible"
      >
        <v-row>
          <v-col
            class="ta-left"
            cols="10"
          >
            <div class="fs-16 mb-1">
              {{ $t('Method of transportation') }}
            </div>
            <div
              v-text="$t(travelMode || 'Show commutes by')"
              class="fs-16 fw-600"
            />
          </v-col>
          <v-col
            class="d-flex align-center justify-end"
            cols="2"
          >
            <v-icon>expand_more</v-icon>
          </v-col>
        </v-row>
      </button>
    </template>

    <v-list
      @update:selected="$emit('update:modelValue', $event)"
      :selected="modelValue"
      mandatory
    >
      <v-list-item
        v-for="(type, index) in $a.assets.transit_options"
        :key="index"
        :value="type.value"
      >
        <v-list-item-title :id="'transit_option_' + index">
          {{ $t(type.text) }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  compatConfig: { MODE: 3 },

  props: {
    modelValue: {
      type: String,
      default: null,
    },
    travelMode: {
      type: String,
      default: null,
    },
  },

  emits: ['close', 'update:modelValue'],

  data() {
    return { menu: false };
  },

  watch: {
    menu(newVal) {
      if (!newVal) {
        this.$emit('close');
      }
    },
  },

  methods: {
    open() {
      this.menu = true;
    },
  },
};
</script>
