<template>
  <div>
    <v-divider />

    <div class="bg-super-light-blue px-4 py-3">
      <transition-group>
        <div
          v-for="(providerId, index) in providerIds"
          :key="providerId"
          class="mb-6 sortable-item"
        >
          <FavoriteCard
            @move="$emit('move', [index, $event])"
            @toggle="$emit('toggle', [index, $event])"
            :active="true"
            :api="api"
            :index="index"
            :length="providerIds.length"
            :provider-id="providerId"
            class="mb-2"
            ordered
          />
        </div>
      </transition-group>
    </div>

    <v-divider />

    <v-row class="my-4 px-4">
      <v-col class="d-flex justify-start">
        <v-btn
          @click="$emit('back')"
          class="focus-very-visible"
          color="primary"
          size="x-large"
          variant="outlined"
        >
          <span v-t="'Back to search'" />
        </v-btn>
      </v-col>
      <template v-if="subsidyProgram.allow_enrolled">
        <v-col class="d-flex justify-end">
          <v-btn
            @click="$emit('next')"
            :disabled="providerIds.length == 0"
            color="primary"
            size="x-large"
          >
            <span v-t="'Continue'" />
            <v-icon class="ms-2"> east </v-icon>
          </v-btn>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script>
import FavoriteCard from '@/parent/components/favorites/FavoriteCard.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    FavoriteCard,
  },

  props: {
    api: {
      type: Object,
      default: null,
    },
    subsidyProgram: {
      type: Object,
      default: null,
    },
    providerIds: {
      type: Array,
      default: null,
    },
  },

  emits: ['back', 'move', 'next', 'toggle'],
};
</script>
