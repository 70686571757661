<template>
  <v-container>
    <ResourceDialog
      @save="save"
      ref="termsAgreementDialog"
      :cancellable="false"
      :max-width="800"
      :processing="processing"
      :save-button-text="termsAgreement.accept_label"
      :title="termsAgreement.title"
      center-title
    >
      <template #form>
        <v-row>
          <v-col>
            <MarkdownContent
              :content="termsAgreement.content"
              class="focus-very-visible fs-16"
              tabindex="0"
            />
          </v-col>
        </v-row>
      </template>
    </ResourceDialog>
  </v-container>
</template>

<script setup>
import Api from '@/shared/services/bright_finder';
import MarkdownContent from '@/shared/components/MarkdownContent.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useReCaptcha } from 'vue-recaptcha-v3';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const eventBus = useEventBus();
const recaptcha = useReCaptcha();
const router = useRouter();
const store = useStore();

const processing = ref(false);
const termsAgreement = ref(store.state.config.terms_agreement);
const termsAgreementDialog = ref(null);

onMounted(() => {
  termsAgreementDialog.value.open({});
});

async function save() {
  let token = null;
  processing.value = true;
  if (import.meta.env.VUE_APP_RECAPTCHA_SITE_KEY) {
    await recaptcha.recaptchaLoaded();
    token = await recaptcha.executeRecaptcha('login');
  }

  const resp = await Api.member.profile
    .update({
      terms_agreement_id: termsAgreement.value.id,
      token,
    })
    .catch((error) => eventBus.error(error));
  if (!resp?.data) return;

  store.commit('setProfile', resp.data);
  router.replace({ name: 'Dashboard' });
}
</script>
