<template>
  <v-container class="pt-16">
    <v-card
      class="mx-auto"
      max-width="800"
      flat
      tile
    >
      <v-card-title>
        <h1 class="fs-28">
          {{ $t('Add a child') }}
        </h1>
      </v-card-title>

      <v-card-text>
        <v-row dense>
          <v-col>
            <MarkdownContent
              :content="store.state.config.parent_pre_eligibility_instruction_text"
              class="fs-16"
            />
          </v-col>
        </v-row>

        <template v-if="!checkedDob">
          <v-row class="mb-4">
            <LabeledDateOfBirth
              v-model="dob"
              cols="12"
              message="Child's DOB"
              mandatory
              very-dense
            />
          </v-row>

          <v-row>
            <v-col class="d-flex justify-end">
              <v-btn
                @click="checkDob"
                :disabled="processing"
                class="focus-very-visible"
                color="primary"
                size="x-large"
              >
                <span>{{ $t('Next') }}</span>
                <v-icon class="ms-2"> east </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </template>

        <template v-else>
          <v-card border>
            <v-card-text>
              <v-row class="font-weight-bold text-subtitle-1">
                <v-col>
                  <LongDate :date="dob" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <div v-if="subsidyProgram">
            <v-card
              class="my-3 rounded-md bg-primary"
              border
            >
              <v-card-text>
                <v-row class="d-flex align-center">
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <div class="text-white">
                      {{ subsidyProgram.name }}
                    </div>
                  </v-col>

                  <v-col
                    class="d-flex justify-end"
                    cols="12"
                    md="6"
                  >
                    <v-btn
                      @click="
                        router.push({
                          name: 'ChildSubsidyNew',
                          params: { subsidyProgramId: subsidyProgram.id },
                        })
                      "
                      :block="display.smAndDown"
                      :disabled="processing"
                      class="focus-very-visible wider-btn"
                      color="white"
                      size="x-large"
                      tabindex="0"
                    >
                      <div class="spaced-button-content">
                        <span>{{ $t(subsidyProgram.pre_eligibility_action_text) }}</span>
                        <v-icon class="ms-2"> east </v-icon>
                      </div>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <MarkdownContent
                      :content="subsidyProgram.pre_eligibility_success_text"
                      class="text-white fs-14"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </div>

          <div v-else>
            <v-row class="px-3 py-3">
              <v-col>
                <MarkdownContent
                  :content="store.state.config.parent_pre_eligibility_failure_text"
                  class="fs-16"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex justify-end">
                <v-btn
                  @click="navigateToDashboard"
                  class="focus-very-visible"
                  color="primary"
                  size="x-large"
                >
                  <span>{{ $t('Back to Dashboard') }}</span>
                  <v-icon class="ms-2"> east </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </template>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script setup>
import LabeledDateOfBirth from '@/shared/components/form/LabeledDateOfBirth.vue';
import LongDate from '@/shared/components/LongDate.vue';
import MarkdownContent from '@/shared/components/MarkdownContent.vue';
import publicApi from '@/shared/services/api/public-api';
import useEventBus from '@/shared/composables/useEventBus';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useDisplay } from 'vuetify';

const router = useRouter();
const store = useStore();
const eventBus = useEventBus();
const display = useDisplay();

const checkedDob = ref(false);
const dob = ref(null);
const processing = ref(false);
const subsidyProgram = ref(null);

const checkDob = async () => {
  if (!dob.value) {
    eventBus.error(['Please fill out all fields']);
    return;
  }

  processing.value = true;
  loadSubsidyPrograms();
};

const loadSubsidyPrograms = async () => {
  publicApi.organization.subsidy_program.index({ dob: dob.value }, (programRes) => {
    checkedDob.value = true;

    [subsidyProgram.value] = programRes.data;
    processing.value = false;
  });
};

const navigateToDashboard = () => {
  router.push({ name: 'Dashboard' });
};
</script>

<style>
.wider-btn {
  min-width: 150px !important;
}

.spaced-button-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
</style>
