<template>
  <v-card
    flat
    tile
  >
    <v-card-text>
      <v-row
        class="d-flex align-center mb-4"
        dense
      >
        <v-col>
          <h2
            v-t="familySubsidyProgram.name"
            class="fs-20 fw-600"
            tabindex="0"
          />
        </v-col>
      </v-row>

      <template v-if="familySubsidyProgram.description">
        <v-row dense>
          <v-col>
            <p
              v-t="familySubsidyProgram.description"
              class="c-light-black fs-16 fw-500"
              tabindex="0"
            />
          </v-col>
        </v-row>
      </template>

      <v-row>
        <v-col cols="12">
          <template v-if="unfinishedSubsidy">
            <v-btn
              @click="continueApplication"
              :block="$vuetify.display.smAndDown"
              class="focus-very-visible wrap-text"
              color="primary"
              data-cy="continue-button"
              tile
            >
              <span>{{ continueButtonText }}</span>
              <v-icon end> east </v-icon>
            </v-btn>
          </template>
          <template v-else-if="canCreateSubsidies">
            <v-btn
              @click="$emit('create', familySubsidyProgram)"
              :block="$vuetify.display.smAndDown"
              class="focus-very-visible wrap-text"
              color="primary"
              data-cy="re-apply-button"
              tile
            >
              <span>{{ startButtonText }}</span>
              <v-icon end> east </v-icon>
            </v-btn>
          </template>
        </v-col>
      </v-row>

      <template
        v-if="familySubsidies.filter((familySubsidy) => familySubsidy.submitted_at).length > 0"
      >
        <v-divider class="my-4" />
      </template>

      <div
        v-for="subsidy in familySubsidies.filter((familySubsidy) => familySubsidy.submitted_at)"
        :key="subsidy.id"
      >
        <v-row
          class="mb-2"
          dense
        >
          <v-col class="d-flex align-center justify-start">
            <v-icon
              class="material-icons-outlined"
              color="primary"
              size="24"
              start
            >
              check_circle
            </v-icon>

            <LongDateTime
              :date="subsidy.submitted_at"
              data-cy="app_submitted"
              prefix="Application submitted at"
              tabindex="0"
            />
          </v-col>

          <v-col class="d-flex justify-end">
            <div class="d-grid">
              <v-btn
                @click="reviewApplication(subsidy)"
                class="focus-very-visible wrap-text"
                color="primary"
              >
                <span>{{ reviewButtonText }}</span>
                <v-icon end> east </v-icon>
              </v-btn>

              <template v-if="canViewClaims(subsidy)">
                <v-btn
                  @click="reviewApplication(subsidy, 'Claims')"
                  class="mt-1"
                  color="primary"
                  variant="outlined"
                >
                  <span v-t="'Claims'" />
                  <v-icon end> east </v-icon>
                </v-btn>
              </template>
            </div>
          </v-col>
        </v-row>

        <v-divider class="mt-4" />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import LongDateTime from '@/shared/components/LongDateTime.vue';

export default {
  components: {
    LongDateTime,
  },

  compatConfig: { MODE: 2 },

  props: {
    familySubsidies: {
      type: Array,
      default: null,
    },
    familySubsidyProgram: {
      type: Object,
      default: null,
    },
    processing: Boolean,
  },

  emits: ['create'],

  computed: {
    canCreateSubsidies() {
      return (
        this.familySubsidies.length === 0 || this.familySubsidyProgram.enable_multiple_applications
      );
    },

    continueButtonText() {
      if (this.$vuetify.display.smAndDown) {
        return this.$t('Continue');
      }
      return this.$t(`Continue ${this.familySubsidyProgram.name}`);
    },

    reviewButtonText() {
      if (this.$vuetify.display.smAndDown) {
        return this.$t('Review');
      }
      return this.$t(`Review ${this.familySubsidyProgram.name}`);
    },

    startButtonText() {
      if (this.$vuetify.display.smAndDown) {
        return this.$t('Start');
      }
      return this.$t(`Start ${this.familySubsidyProgram.name}`);
    },

    unfinishedSubsidy() {
      return this.familySubsidies.filter((familySubsidy) => !familySubsidy.submitted_at)[0];
    },
  },

  methods: {
    canViewClaims(subsidy) {
      // TO-DO : Once family subsidy status is being passed forward include "&& subsidy.status === 'Approved'" (BCD-1067)
      return (
        !!subsidy.submitted_at &&
        this.familySubsidyProgram.enable_claims &&
        this.familySubsidyProgram?.claim_schema_id
      );
    },

    continueApplication() {
      this.$router.push({
        name: 'FamilySubsidyContinue',
        params: { id: this.unfinishedSubsidy.id },
      });
    },

    reviewApplication(subsidy, tab) {
      this.$router.push({
        name: 'FamilySubsidyReview',
        params: { id: subsidy.id },
        query: { tab },
      });
    },
  },
};
</script>
