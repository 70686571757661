<template>
  <v-container
    v-show="loaded"
    class="pt-16"
  >
    <v-card
      class="mx-auto"
      max-width="800"
      flat
      tile
    >
      <v-card-title>
        <h1
          class="fs-28 text-wrap"
          tabindex="0"
        >
          {{ $t('Add a child') }}
        </h1>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col>
            <MarkdownContent
              :content="$store.state.config.parent_pre_eligibility_instruction_text"
              class="mb-0 fs-16"
            />
          </v-col>
        </v-row>

        <template v-if="!createdChild">
          <v-row>
            <LabeledTextfield
              v-model="firstName"
              @input="changed = true"
              :message="$t('First name')"
              cols="12"
              md="4"
              mandatory
            />

            <LabeledTextfield
              v-model="middleName"
              @input="changed = true"
              :message="$t('Middle name')"
              cols="12"
              md="4"
            />

            <LabeledTextfield
              v-model="lastName"
              @input="changed = true"
              :message="$t('Last name')"
              cols="12"
              md="4"
              mandatory
            />
          </v-row>

          <v-row>
            <LabeledDateOfBirth
              v-model="dob"
              :message="$t('Date of birth')"
              cols="12"
              mandatory
            />
          </v-row>

          <v-row>
            <v-col class="d-flex justify-end">
              <v-btn
                @click="createChild"
                :disabled="processing || requiredFieldsMissing"
                class="focus-very-visible"
                color="primary"
                data-testid="child-new-next-button"
                size="x-large"
                tabindex="0"
              >
                <span>{{ $t('Next') }}</span>
                <v-icon class="ms-2"> east </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </template>

        <template v-else>
          <v-card border>
            <v-card-text>
              <v-row
                class="font-weight-bold text-subtitle-1"
                dense
              >
                <v-col>
                  <span>{{ createdChild.name }}</span>
                </v-col>
              </v-row>

              <v-row
                class="font-weight-bold text-subtitle-1"
                dense
              >
                <v-col>
                  <LongDate :date="createdChild.dob" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <template v-if="areSubsidyProgramsLoaded">
            <div v-if="subsidyPrograms.length > 0">
              <v-card
                v-for="subsidyProgram in subsidyPrograms"
                :key="subsidyProgram.id"
                class="my-3 rounded-md bg-primary"
                border
              >
                <v-card-text>
                  <v-row class="d-flex align-center">
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <div class="text-white">
                        {{ subsidyProgram?.name }}
                      </div>
                    </v-col>

                    <v-col
                      class="d-flex justify-end"
                      cols="12"
                      md="6"
                    >
                      <v-btn
                        @click="createSubsidy(subsidyProgram)"
                        :block="$vuetify.display.smAndDown"
                        :disabled="processing"
                        class="focus-very-visible wider-btn"
                        color="white"
                        size="x-large"
                        tabindex="0"
                      >
                        <div class="spaced-button-content">
                          <span>{{ $t(subsidyProgram.pre_eligibility_action_text) }}</span>
                          <v-icon class="ms-2"> east </v-icon>
                        </div>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <MarkdownContent
                        :content="subsidyProgram.pre_eligibility_success_text"
                        class="text-white fs-14"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>

            <div v-else>
              <v-row class="">
                <v-col>
                  <MarkdownContent
                    :content="$store.state.config.parent_pre_eligibility_failure_text"
                    class="fs-16"
                  />
                </v-col>
              </v-row>

              <v-row justify="end">
                <v-col>
                  <v-btn
                    @click="navigateToDashboard"
                    class="focus-very-visible"
                    color="primary"
                    size="x-large"
                    tabindex="0"
                  >
                    <span>{{ $t('Back to Dashboard') }}</span>
                    <v-icon class="ms-2"> east </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </template>
        </template>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="existingChildDialogIsVisible"
      max-width="600"
    >
      <v-card
        class="mx-auto"
        max-width="600px"
      >
        <v-card-title>
          <v-row>
            <v-col
              class="d-flex flex-column"
              cols="auto"
            >
              <span class="fs-24">{{ $t('This child already exists') }}</span>
              <span class="fs-16">{{
                $t('Would you like to continue creating an application?')
              }}</span>
            </v-col>
            <v-spacer />
            <v-col>
              <v-btn
                @click="existingChildDialogIsVisible = false"
                :aria-label="$t('Close')"
                icon="close"
                variant="text"
              />
            </v-col>
          </v-row>
        </v-card-title>

        <v-divider />

        <v-card-text>
          <v-card class="border-sm border-light-gray">
            <v-card-text>
              <v-row>
                <v-col class="d-flex flex-column">
                  <span class="">{{ fullName(existingChild) }}</span>
                  <span class="d-flex align-items-center c-dark-gray">
                    <v-icon
                      class="fs-14 mr-2 pt-2"
                      icon="cake"
                      size="x-small"
                    />
                    <span class="fs-12">
                      {{ existingChild.dob }}
                    </span>
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>

        <v-card-actions>
          <v-btn
            @click="existingChildDialogIsVisible = false"
            variant="text"
          >
            {{ $t('Cancel') }}
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="existingChild"
            @click="router.push({ name: 'ChildApply', params: { childId: existingChild.id } })"
            color="primary"
          >
            {{ $t('Yes, create application for existing child') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script setup>
import Api from '@/shared/services/bright_finder';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import LabeledDateOfBirth from '@/shared/components/form/LabeledDateOfBirth.vue';
import LongDate from '@/shared/components/LongDate.vue';
import MarkdownContent from '@/shared/components/MarkdownContent.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { capitalize } from '@/plugins/filters';

const eventBus = useEventBus();
const router = useRouter();
const store = useStore();

const areSubsidyProgramsLoaded = ref(false);
const existingChildDialogIsVisible = ref(false);
const firstName = ref(null);
const middleName = ref(null);
const lastName = ref(null);
const loaded = ref(false);
const dob = ref(null);
const processing = ref(false);
const createdChild = ref(null);
const existingChild = ref(null);
const subsidyPrograms = ref([]);

const requiredFields = computed(() => [firstName, lastName, dob]);
const requiredFieldsMissing = computed(() => requiredFields.value.some((field) => !field.value));
const v2 = computed(() => store.state.config.enable_parent_dashboard_v2);

onMounted(() => {
  const unregisteredRoute = {
    name: 'Signup',
    query: {
      action: 'childNew',
    },
  };

  store.dispatch('identify', {
    success: () => {
      if (store.state.is_anonymous) router.replace(unregisteredRoute);
      else loaded.value = true;
    },
    failure: () => {
      router.replace(unregisteredRoute);
    },
  });
});

async function createChild() {
  const childParams = {
    first_name: firstName.value,
    middle_name: middleName.value,
    last_name: lastName.value,
    dob: dob.value,
  };

  if (requiredFieldsMissing.value) {
    eventBus.error('Please fill out all fields');
    return;
  }
  processing.value = true;

  if (v2.value) {
    const childrenResp = await Api.parent.children.index();
    const children = childrenResp.data;

    if (children.some((child) => childAlreadyExists(child))) {
      existingChildDialogIsVisible.value = true;
      processing.value = false;
      return;
    }
  }

  const res = await Api.child.create(childParams);
  if (res.status !== 201) return;
  createdChild.value = res.data;

  if (v2.value) {
    processing.value = false;
    await router.push({ name: 'ChildApply', params: { childId: createdChild.value.id } });
  } else {
    await loadSubsidyPrograms();
    processing.value = false;
  }
}

function childAlreadyExists(child) {
  if (
    child.first_name.toLowerCase() === firstName.value.toLowerCase() &&
    child.last_name.toLowerCase() === lastName.value.toLowerCase() &&
    child.dob === dob.value
  ) {
    existingChild.value = child;
    return true;
  }

  return false;
}

function fullName(child) {
  let result = capitalize(child.first_name);
  if (child.middle_name) result += ` ${capitalize(child.middle_name)}`;
  result += ` ${capitalize(child.last_name)}`;

  return result;
}

async function loadSubsidyPrograms() {
  const response = await Api.public_api.organization.subsidy_program.index({
    dob: dob.value,
  });
  subsidyPrograms.value = response.data;
  areSubsidyProgramsLoaded.value = true;
}

async function createSubsidy(subsidyProgram) {
  processing.value = true;
  const resp = await Api.subsidy
    .create({
      child_id: createdChild.value.id,
      subsidy_program_id: subsidyProgram.id,
    })
    .catch((error) => eventBus.error(error));

  router.push({ name: 'SubsidyContinue', params: { subsidyId: resp.data.id } });
}

function navigateToDashboard() {
  router.push({ name: 'Dashboard' });
}
</script>

<style>
.wider-btn {
  min-width: 150px !important;
}

.spaced-button-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
</style>
