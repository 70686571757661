<template>
  <v-card
    v-if="dashboardInstructions"
    class="bg-primary mb-4"
    data-testid="dashboard-instructions"
    tabindex="0"
    border
    flat
    tile
  >
    <v-card-text class="c-white fw-500 markdown">
      <MarkdownContent :content="dashboardInstructions" />
    </v-card-text>
  </v-card>
</template>

<script setup>
import MarkdownContent from '@/shared/components/MarkdownContent.vue';

const props = defineProps({
  schema: {
    type: Object,
    default: null,
  },
});

const dashboardInstructions = computed(() => {
  return props.schema?.meta?.parent_layout?.dashboard_instructions;
});
</script>
