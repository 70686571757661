<template>
  <template v-if="$vuetify.display.smAndDown">
    <v-btn
      @click.stop="openMobileSearch()"
      aria-label="Search your results"
      variant="text"
      icon
    >
      <v-icon color="black">search</v-icon>
    </v-btn>

    <v-dialog v-model="searchDialogIsVisible">
      <v-card
        class="left-0 p-absolute top-0 right-0"
        tile
      >
        <v-card-text class="d-flex align-center justify-center pa-6">
          <v-autocomplete
            v-model="selectedProvider"
            v-model:search="query"
            @focus="focused = true"
            ref="mobileSearch"
            :items="searchedProviders"
            :placeholder="$t('Search providers')"
            aria-label="Search your results"
            color="secondary"
            data-cy="search_for_providers_mobile"
            data-tid="mobileSearchBar"
            density="compact"
            item-title="name"
            item-value="id"
            prepend-inner-icon="search"
            variant="solo-filled"
            hide-details
            hide-no-data
            no-filter
            return-object
            rounded
            tracked
          >
            <template #item="{ item, props }">
              <v-list-item
                v-bind="props"
                :subtitle="item.raw.location"
              />
            </template>
          </v-autocomplete>
        </v-card-text>
      </v-card>
    </v-dialog>
  </template>
  <template v-else>
    <v-autocomplete
      v-model="selectedProvider"
      v-model:search="query"
      @focus="focused = true"
      :aria-label="$t('Search your results, use shift escape to exit')"
      :class="focused ? 'mxw-250' : 'mxw-175'"
      :items="searchedProviders"
      :placeholder="$t('Search providers')"
      color="secondary"
      data-cy="search_for_providers"
      data-tid="global_search_bar"
      density="compact"
      item-title="name"
      item-value="id"
      prepend-inner-icon="search"
      variant="solo-filled"
      flat
      hide-details
      hide-no-data
      no-filter
      persistent-placeholder
      return-object
      rounded
      tracked
    >
      <template #item="{ item, props }">
        <v-list-item
          v-bind="props"
          :subtitle="item.raw.location"
        />
      </template>
    </v-autocomplete>
  </template>
</template>

<script setup>
import Api from '@/shared/services/bright_finder';
import { useRouter } from 'vue-router';

const props = defineProps({
  route: {
    type: String,
    default: 'PublicProviderShow',
  },
});

const focused = ref(false);
const mobileSearch = ref(null);
const query = ref(null);
const router = useRouter();
const searchDialogIsVisible = ref(false);
const searchedProviders = ref([]);
const selectedProvider = ref(null);

watch(selectedProvider, (newVal) => {
  searchDialogIsVisible.value = false;
  router.push({ name: props.route, params: { providerId: newVal.id } });
  query.value = null;
  searchedProviders.value = [];
  selectedProvider.value = null;
});

watch(query, (newVal) => {
  if (newVal) {
    searchProviders();
  }
});

function openMobileSearch() {
  searchDialogIsVisible.value = true;

  setTimeout(() => {
    mobileSearch.value.focus();
  }, 200);
}

function searchProviders() {
  Api.public_api.provider.index({ query: query.value }, (resp) => {
    searchedProviders.value = resp.data;
  });
}
</script>
