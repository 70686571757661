const sections = Object.freeze({
  eligibilityQuestions: 'eligibility-questions',
  eligibility: 'eligibility',
  thirdPartyQuestions: 'third-party-questions',
  otherQuestions: 'other-questions',
  manageHouseholdMembers: 'manage-household-members',
  householdMember: 'household-member',
  verificationQuestions: 'verification-questions',
  confirm: 'confirm',
});

export default sections;
