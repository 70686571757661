<!-- eslint-disable max-len -->
<template>
  <v-container class="mxw-800 mx-auto py-12 px-1">
    <SubsidyProgramValidation :subsidy-program="subsidyProgram">
      <template #open>
        <span class="c-primary font-weight-bold px-7">
          {{ subsidyProgram?.name }}
        </span>
        <div
          ref="progressRef"
          :aria-label="$t('Progress indicator - question') + ' ' + stepCount"
          class="px-2 focus-invisible"
          role="progressbar"
          tabindex="0"
          aria-live
        >
          <template v-if="!progressUnknown">
            <v-progress-linear
              v-model="progress"
              :indeterminate="!loaded"
              class="focus-invisible mb-3"
              color="primary"
            />
          </template>
        </div>

        <div v-if="loaded">
          <FormQuestion
            v-show="section == defaultSection"
            @next="validateAddress()"
            :key="'group'"
            :next-disabled="!validHomeAddress"
            :processing="processing"
            :reversible="false"
            :subtitle="
              subsidyProgram.home_address_subtitle ||
              'Let\'s start with your family\'s primary home address.'
            "
            :title="
              subsidyProgram.home_address_title || 'Welcome! We\'ll make it fast and easy to apply.'
            "
          >
            <template #preamble>
              <p>
                <span class="c-red me-1">*</span>
                <span
                  v-t="'Indicates a required field'"
                  class="c-light-black"
                />
              </p>
            </template>
            <v-row dense>
              <LabeledTextfield
                v-model="group.home_address"
                data-cy="address"
                message="Address"
                mandatory
              />
              <template v-if="subsidyProgram.city_options?.length > 0">
                <LabeledSimpleSelect
                  v-model="group.home_city"
                  :items="subsidyProgram.city_options"
                  data-cy="city-input"
                  md="4"
                  message="City"
                  mandatory
                />
              </template>
              <template v-else>
                <LabeledTextfield
                  v-model="group.home_city"
                  data-cy="city"
                  md="4"
                  message="City"
                  mandatory
                />
              </template>
              <LabeledSimpleSelect
                v-model="group.home_state"
                :items="stateOptions"
                data-cy="state-input"
                md="4"
                message="State"
                mandatory
              />
              <template v-if="subsidyProgram.zip_options?.length > 0">
                <LabeledSimpleSelect
                  v-model="group.home_zip"
                  :items="subsidyProgram.zip_options"
                  data-cy="zip-input"
                  md="4"
                  message="Zip"
                  mandatory
                />
              </template>
              <template v-else>
                <LabeledTextfield
                  v-model="group.home_zip"
                  data-cy="zip"
                  mask="#####"
                  md="4"
                  message="Zip"
                  mandatory
                />
              </template>
            </v-row>
            <v-row
              v-if="subsidyProgram.enable_homeless_address_option"
              dense
            >
              <v-checkbox
                v-model="group.experiencing_homelessness"
                :label="
                  $t(
                    subsidyProgram.homeless_attestation ||
                      'My family is experiencing homelessness.',
                  )
                "
              />
            </v-row>
          </FormQuestion>

          <FormQuestion
            v-if="section == 'children'"
            @back="backFromChildren()"
            @next="forwardFromChildren"
            :key="'children'"
            :next-disabled="!validChildren"
            :processing="processing"
            :subtitle="subsidyProgram.child_subtitle"
            :title="subsidyProgram.child_title || 'Add your children'"
          >
            <SubsidyChildren
              @change="children = $event"
              :children="children"
              :subsidies="subsidies"
            />
          </FormQuestion>

          <QuestionSet
            v-model="group"
            @back="backFromGroupEligibilityQuestion($event)"
            @change:attachments="loadAttachments()"
            @next="forwardFromGroupEligibilityQuestion($event)"
            :attachment-group-id="group.id"
            :attachment-owner-id="subsidyProgram.id"
            :attachment-owner-type="'SubsidyProgram'"
            :attachments="attachments"
            :processing="processing"
            :questions="validGroupEligibilityQuestions"
            :schema="groupSchema.definition"
            :section="section"
            key-name="GroupEligibility"
            transition-name="none"
          />

          <div
            v-for="(child, childIndex) in applicantChildren"
            :key="'child-eligibility' + child.id"
          >
            <QuestionSet
              v-if="applicantEligibilityQuestions[child.id]"
              @back="backFromChildEligibilityQuestion({ questionIndex: $event, childIndex })"
              @change="maybeClearPreferences(child, $event)"
              @input="child = $event"
              @next="forwardFromChildEligibilityQuestion({ questionIndex: $event, childIndex })"
              :attachment-group-id="group.id"
              :attachment-owner-id="subsidyProgram.id"
              :attachment-owner-type="'SubsidyProgram'"
              :attachment-tags-supplements="[subsidyForChild(child).id]"
              :attachments="attachments"
              :header="$t('Child') + ' - ' + child.first_name"
              :key-name="'ChildEligibility' + childIndex.toString()"
              :model-value="child"
              :processing="processing"
              :questions="
                applicantEligibilityQuestions[child.id].filter((question) => question.valid)
              "
              :schema="childSchema.definition"
              :section="section"
              transition-name="none"
            />
          </div>

          <template
            v-if="subsidyProgram.eligibility && subsidies?.length > 0 && section == 'eligibility'"
          >
            <FormQuestion
              @back="backFromEligibility"
              @finish="exit"
              @next="forwardFromEligibility"
              key="eligibility"
              :finishable="eligibleSubsidies?.length == 0"
              :forwardable="eligibleSubsidies?.length > 0"
              :processing="processing"
              :subtitle="
                subsidyProgram.eligibility_subtitle ||
                'Based on the answers you supplied, we\'ve determined your eligibility. If you think this determination is incorrect, please message our support team.'
              "
              :title="subsidyProgram.eligibility_title || 'Here is your eligibility report:'"
              class="focus-very-visible"
              finish-text="Exit"
              next-text="Continue for eligible children"
              next-arrow
            >
              <div
                v-if="eligibleSubsidies?.length > 0"
                class="mb-8"
              >
                <div
                  v-t="'These children are eligible:'"
                  class="fs-20 fw-500 mb-4"
                />
                <SubsidyEligibilityCard
                  v-for="subsidy in eligibleSubsidies"
                  :key="subsidy.id"
                  :elevation="2"
                  :funding-sources="subsidy.meta.funding_sources"
                  :name="childForSubsidy(subsidy).name"
                  :projected-eligibility="subsidy.projected_eligibility"
                  outlined
                />
              </div>

              <div v-if="ineligibleSubsidies?.length > 0">
                <div
                  v-t="'These children are not eligible:'"
                  class="fs-20 fw-500 mb-4"
                />
                <div
                  v-for="subsidy in ineligibleSubsidies"
                  :key="subsidy.id"
                >
                  <SubsidyEligibilityCard
                    :elevation="2"
                    :funding-sources="subsidy.meta.funding_sources"
                    :name="childForSubsidy(subsidy).name"
                    :projected-eligibility="subsidy.projected_eligibility"
                    outlined
                  />
                </div>
              </div>

              <v-alert
                v-if="eligibleSubsidies?.length == 0"
                border="start"
                class="w-100pc mt-6 fs-20"
                color="primary"
                type="info"
              >
                <div>
                  <div
                    v-t="
                      'You are not eligible for this program at this time. Please contact support if you believe this is incorrect.'
                    "
                  />
                </div>
              </v-alert>
            </FormQuestion>
          </template>

          <ThirdPartyQuestions
            v-if="section == 'third-party-questions'"
            @back="backFromThirdPartyQuestions"
            @input="thirdPartyApplication = $event"
            @next="forwardFromThirdPartyQuestions"
            key="third-party-questions"
            :model-value="thirdPartyApplication"
            :next-disabled="thirdPartyContinueDisabled()"
            :processing="processing"
            forwardable
          />

          <QuestionSet
            v-model="group"
            @back="backFromGroupQuestion($event)"
            @change:attachments="loadAttachments()"
            @next="forwardFromGroupQuestion($event)"
            :attachment-group-id="group.id"
            :attachment-owner-id="subsidyProgram.id"
            :attachment-owner-type="'SubsidyProgram'"
            :attachments="attachments"
            :processing="processing"
            :questions="validGroupQuestions"
            :schema="groupSchema.definition"
            :section="section"
            key-name="Group"
            transition-name="none"
          />

          <div
            v-for="(child, childIndex) in eligibleChildren"
            :key="'child' + child.id"
          >
            <QuestionSet
              v-if="validApplicantQuestions[child.id]"
              @back="backFromChildQuestion({ childIndex, questionIndex: $event })"
              @input="child = $event"
              @next="forwardFromChildQuestion({ childIndex, questionIndex: $event })"
              :attachment-group-id="group.id"
              :attachment-owner-id="subsidyProgram.id"
              :attachment-owner-type="'SubsidyProgram'"
              :attachment-tags-supplements="[subsidyForChild(child).id]"
              :attachments="attachments"
              :header="$t('Child') + ' - ' + child.first_name"
              :key-name="'Child' + childIndex"
              :model-value="child"
              :processing="processing"
              :questions="validApplicantQuestions[child.id]"
              :schema="childSchema.definition"
              :section="section"
              transition-name="none"
            />
          </div>

          <template v-if="favoritesSectionEnabled">
            <SubsidyWrapper
              v-for="(child, childIndex) in eligibleChildrenWithPreferencesAllowed"
              :key="`preference-review-${child.id}`"
              :child="child"
              :previous-child="eligibleChildrenWithPreferencesAllowed[childIndex - 1]"
              :subsidies="subsidies"
            >
              <template #default="{ previousChild, previousChildName, previousSubsidy, subsidy }">
                <template v-if="subsidyProgram.enable_advanced_selector">
                  <template v-if="subsidyProgram.allow_program_preference">
                    <ProgramPreferenceEditor
                      v-show="section == 'favorites-' + childIndex"
                      @back="backFromFavoriteQuestion(childIndex)"
                      @next="forwardFromFavoriteQuestion(childIndex)"
                      @reorderPrograms="
                        (from, to) => handleReorderSelectedPrograms(from, to, subsidy)
                      "
                      @selectedCurrentProviderId="handleSelectedCurrentProvider($event, subsidy)"
                      @selectedProgramIds="handleSelectedPrograms($event, subsidy)"
                      @selectedSiblingIds="handleSelectedSiblings($event, subsidy)"
                      @selectedStaffIds="handleSelectedStaff($event, subsidy)"
                      :key="'favorites-' + childIndex"
                      :child-data="child"
                      :current-provider="subsidy.current_provider_id"
                      :default-query="homeAddress"
                      :previous-child-name="previousChildName"
                      :previous-subsidy-program-ids="previousSubsidy?.program_ids"
                      :processing="processing"
                      :sibling-providers="subsidy.sibling_provider_ids"
                      :staff-providers="subsidy.staff_provider_ids"
                      :subsidy="subsidy"
                      :subsidy-program="subsidyProgram"
                    />
                  </template>

                  <template v-else>
                    <ProviderPreferenceEditor
                      v-show="section == 'favorites-' + childIndex"
                      @back="backFromFavoriteQuestion(childIndex)"
                      @next="forwardFromFavoriteQuestion(childIndex)"
                      :key="'favorites-' + childIndex"
                      :child-data="child"
                      :default-query="homeAddress"
                      :previous-child-name="previousChildName"
                      :previous-subsidy-provider-ids="previousSubsidy?.provider_ids"
                      :processing="processing"
                      :subsidy="subsidy"
                      :subsidy-program="subsidyProgram"
                    />
                  </template>
                </template>

                <template v-else>
                  <template v-if="subsidyProgram.allow_preference">
                    <FormQuestion
                      v-show="section == 'favorites-' + childIndex"
                      @back="backFromFavoriteQuestion(childIndex)"
                      @next="forwardFromFavoriteQuestion(childIndex)"
                      :key="'favorites-' + childIndex"
                      :header="$t('Child') + ' - ' + child.first_name"
                      :next-disabled="subsidy.provider_ids?.length == 0"
                      :processing="processing"
                      :subtitle="
                        subsidyProgram.preference_subtitle ||
                        (subsidyProgram.allow_preference_order
                          ? 'Selecting a provider indicates that you’re interested in receiving care from this provider. Rank your favorites by reordering the selected providers from top-bottom. Top being your top choice. We’ll do our best to match you with a provider of your choice!'
                          : 'Selecting a provider indicates that you’re interested in receiving care from this provider.')
                      "
                      :title="
                        subsidyProgram.preference_title ||
                        (subsidyProgram.allow_preference_order
                          ? 'Select and rank the providers you’d like to include in the application for this child'
                          : 'Select the providers you’d like to include in the application for this child')
                      "
                    >
                      <FavoritesSelector
                        :childData="child"
                        :default-query="homeAddress"
                        :ordered="subsidyProgram.allow_preference_order"
                        :previous-child="previousChild"
                        :subsidies="subsidies"
                        :subsidy="subsidy"
                        :subsidy-program="subsidyProgram"
                        class="mt-4 mb-4"
                      />
                    </FormQuestion>
                  </template>

                  <template v-else-if="subsidyProgram.allow_enrolled">
                    <FormQuestion
                      v-show="section == 'favorites-' + childIndex"
                      @back="backFromFavoriteQuestion(childIndex)"
                      @next="forwardFromFavoriteQuestion(childIndex)"
                      :key="'favorites-' + childIndex"
                      :header="$t('Child') + ' - ' + child.first_name"
                      :processing="processing"
                      :subtitle="subsidyProgram.preference_subtitle"
                      :title="
                        subsidyProgram.preference_title ||
                        'If your child is already enrolled in care, add the provider below.'
                      "
                    >
                      <ProviderSelector
                        :child="child"
                        :subsidy="subsidy"
                      />
                    </FormQuestion>
                  </template>
                </template>
              </template>
            </SubsidyWrapper>
          </template>

          <div
            v-for="(question, index) in validGroupVerificationQuestions"
            v-show="section == ['group-verification', index].join('-')"
            :key="['group-verification', index].join('-')"
          >
            <FormQuestion
              @back="backFromGroupVerificationQuestion(index)"
              @next="forwardFromGroupVerificationQuestion(index)"
              :next-disabled="
                question.verification_mandatory &&
                attachments.filter((attachment) => attachment.tag == question.id)?.length == 0
              "
              :processing="processing"
              :schema="{}"
              :subtitle="question.verification_subtitle"
              :title="question.verification_title"
            >
              <AttachmentUploader
                @uploaded="attachments.push($event)"
                :ref="['uploader', question.id].join('')"
                :owner="{
                  type: 'SubsidyProgram',
                  id: subsidyProgram.id,
                  tag: question.id,
                  tags: subsidies.map((subsidy) => subsidy.id).concat([question.id]),
                }"
                class="mb-4"
              />
              <AttachmentList
                @delete="loadAttachments"
                :attachments="attachments.filter((attachment) => attachment.tag == question.id)"
                :processing="processing"
                class="mb-6"
              />
            </FormQuestion>
          </div>

          <div
            v-for="(child, childIndex) in eligibleChildren"
            :key="`child-verification${childIndex}`"
          >
            <div
              v-for="(question, index) in childVerificationQuestions"
              v-show="section == `child-verification${childIndex}-${index}`"
              :key="`child-verification${childIndex}-${index}`"
            >
              <FormQuestion
                @back="backFromChildVerificationQuestion({ childIndex, questionIndex: $event })"
                @next="forwardFromChildVerificationQuestion({ questionIndex: index, childIndex })"
                :header="$t('Child') + ' - ' + child.first_name"
                :next-disabled="
                  question.verification_mandatory &&
                  attachments.filter((attachment) => attachment.tag == child.id + question.id)
                    ?.length == 0
                "
                :processing="processing"
                :schema="childSchema.definition"
                :subtitle="question.verification_subtitle"
                :title="question.verification_title"
              >
                <AttachmentUploader
                  @uploaded="attachments.push($event)"
                  :ref="['uploader', question.id, child.id].join('')"
                  :owner="{
                    type: 'SubsidyProgram',
                    id: subsidyProgram.id,
                    tag: child.id + question.id,
                    tags: [child.id, subsidyForChild(child).id, question.id],
                  }"
                  class="mb-4"
                />
                <AttachmentList
                  @delete="loadAttachments"
                  :attachments="
                    attachments.filter((attachment) => attachment.tag == child.id + question.id)
                  "
                  :processing="processing"
                  class="mb-6"
                />
              </FormQuestion>
            </div>
          </div>

          <div
            v-for="(child, childIndex) in eligibleChildren"
            :key="'child-documents-' + childIndex"
          >
            <FormQuestion
              v-if="subsidyProgram.verify_child"
              v-show="section == 'child-documents-' + childIndex"
              @back="backFromChildDocuments({ childIndex })"
              @next="forwardFromChildDocuments(childIndex)"
              :header="$t('Child') + ' - ' + child.first_name"
              :next-disabled="
                subsidyProgram.verify_child_mandatory &&
                attachments.filter((attachment) => attachment.tag == 'child-documents-' + child.id)
                  ?.length == 0
              "
              :processing="processing"
              :subtitle="subsidyProgram.verify_child_subtitle"
              :title="subsidyProgram.verify_child_title"
            >
              <p class="fs-15 c-light-black" />
              <AttachmentUploader
                @uploaded="attachments.push($event)"
                :owner="{
                  type: 'SubsidyProgram',
                  id: subsidyProgram.id,
                  tag: 'child-documents-' + child.id,
                  tags: ['child-documents', child.id, subsidyForChild(child).id],
                }"
                class="mb-4"
              />
              <AttachmentList
                @delete="loadAttachments"
                :attachments="
                  attachments.filter(
                    (attachment) => attachment.tag == 'child-documents-' + child.id,
                  )
                "
                :processing="processing"
              />
            </FormQuestion>
          </div>

          <FormQuestion
            v-show="section == 'proof-of-residency'"
            @back="backFromDocuments"
            @next="stepForward('confirm')"
            key="proof-of-residency"
            :next-disabled="
              subsidyProgram.verify_home_address_mandatory &&
              attachments.filter((attachment) => attachment.tag == 'proof-of-residency')?.length ==
                0
            "
            :processing="processing"
            :subtitle="
              subsidyProgram.verify_home_address_subtitle ||
              'Ex. A copy of current lease, proof of homeownership, or utility bill (with service or premise address listed) such as your bill for gas, electric, water, or cable.'
            "
            :title="
              subsidyProgram.verify_home_address_title ||
              'Upload a document to verify your current address'
            "
          >
            <p class="fs-15 c-light-black" />
            <AttachmentUploader
              @uploaded="attachments.push($event)"
              ref="uploader_residency"
              :owner="{
                type: 'SubsidyProgram',
                id: subsidyProgram.id,
                tag: 'proof-of-residency',
                tags: subsidies.map((subsidy) => subsidy.id).concat(['proof-of-residency']),
              }"
              class="mb-4"
            />
            <AttachmentList
              @delete="loadAttachments"
              :attachments="
                attachments.filter((attachment) => attachment.tag == 'proof-of-residency')
              "
              :processing="processing"
            />
          </FormQuestion>

          <FormQuestion
            v-if="section == 'confirm'"
            @back="backFromConfirm()"
            @finish="finish"
            key="confirm"
            :finish-disabled="!confirmed"
            :forwardable="false"
            :next-disabled="!confirmed"
            :processing="processing"
            :title="subsidyProgram.confirm_title || 'Confirm your application information'"
            header="Last step"
            finishable
          >
            <template v-if="subsidyProgram.allow_parent_review">
              <FormQuestion
                :processing="processing"
                :subtitle="subsidyProgram.home_address_subtitle"
                :title="
                  subsidyProgram.home_address_title ||
                  'What is your family\'s primary home address.'
                "
                class="mb-4"
                dense
                expanded
                hide-actions
                paddingless
              >
                <v-row>
                  <LabeledTextfield
                    v-model="group.home_address"
                    :hard-lock="reviewLocked"
                    :schema-id="group.schema_id"
                    field="home_address"
                    message="Address"
                    dense
                    mandatory
                  />
                  <LabeledTextfield
                    v-model="group.home_city"
                    :hard-lock="reviewLocked"
                    :schema-id="group.schema_id"
                    field="home_city"
                    md="4"
                    message="City"
                    dense
                    mandatory
                  />
                  <LabeledSimpleSelect
                    v-model="group.home_state"
                    :hard-lock="reviewLocked"
                    :items="$a.assets.states"
                    :schema-id="group.schema_id"
                    field="home_state"
                    md="4"
                    message="State"
                    placeholder="Select one"
                    dense
                    mandatory
                  />
                  <LabeledTextfield
                    v-model="group.home_zip"
                    :hard-lock="reviewLocked"
                    :schema-id="group.schema_id"
                    field="home_zip"
                    md="4"
                    message="Zip"
                    dense
                    mandatory
                  />
                </v-row>
                <v-row
                  v-if="subsidyProgram.enable_homeless_address_option"
                  dense
                >
                  <v-checkbox
                    v-model="group.experiencing_homelessness"
                    :disabled="reviewLocked"
                    :label="
                      $t(
                        subsidyProgram.homeless_attestation ||
                          'My family is experiencing homelessness.',
                      )
                    "
                  />
                </v-row>
              </FormQuestion>

              <v-divider class="mb-4" />

              <div
                v-for="child in eligibleChildren"
                :key="`child-${child.id}`"
              >
                <FormQuestion
                  :header="[$t('Child'), child.first_name].join(' - ')"
                  :processing="processing"
                  class="mb-4"
                  title="Name and date of birth"
                  break-after
                  dense
                  hide-actions
                  paddingless
                >
                  <Child
                    :hard-lock="reviewLocked"
                    :initial-child="child"
                    dense
                    remove-disabled
                  />
                </FormQuestion>
                <v-divider class="mb-4" />
              </div>

              <QuestionSet
                v-model="group"
                @change="waitAndValidate()"
                @change:attachments="loadAttachments()"
                :attachment-group-id="group.id"
                :attachment-owner-id="subsidyProgram.id"
                :attachment-owner-type="'SubsidyProgram'"
                :attachments="attachments"
                :processing="processing"
                :questions="validGroupEligibilityQuestions"
                :readonly="reviewLocked"
                :schema="groupSchema.definition"
                dense
                divided
                expanded
                hide-actions
                paddingless
              />

              <div
                v-for="child in eligibleChildren"
                :key="child.id + '-eligibility'"
              >
                <QuestionSet
                  v-if="applicantEligibilityQuestions[child.id]"
                  @change="waitAndValidate()"
                  @input="child = $event"
                  :attachment-group-id="group.id"
                  :attachment-owner-id="subsidyProgram.id"
                  :attachment-owner-type="'SubsidyProgram'"
                  :attachment-tags-supplements="[subsidyForChild(child).id]"
                  :attachments="attachments"
                  :header="$t('Child') + ' - ' + child.first_name"
                  :model-value="child"
                  :processing="processing"
                  :questions="
                    applicantEligibilityQuestions[child.id].filter((question) => question.valid)
                  "
                  :readonly="reviewLocked"
                  :schema="childSchema.definition"
                  dense
                  divided
                  expanded
                  hide-actions
                  paddingless
                />
              </div>

              <QuestionSet
                v-model="group"
                @change="waitAndValidate()"
                @change:attachments="loadAttachments()"
                :attachment-group-id="group.id"
                :attachment-owner-id="subsidyProgram.id"
                :attachment-owner-type="'SubsidyProgram'"
                :attachments="attachments"
                :processing="processing"
                :questions="validGroupQuestions"
                :readonly="reviewLocked"
                :schema="groupSchema.definition"
                dense
                divided
                expanded
                hide-actions
                paddingless
              />

              <div
                v-for="child in eligibleChildren"
                :key="child.id + '-other'"
              >
                <QuestionSet
                  v-if="applicantQuestions[child.id]"
                  @change="waitAndValidate()"
                  @input="child = $event"
                  :attachment-group-id="group.id"
                  :attachment-owner-id="subsidyProgram.id"
                  :attachment-owner-type="'SubsidyProgram'"
                  :attachment-tags-supplements="[subsidyForChild(child).id]"
                  :attachments="attachments"
                  :header="$t('Child') + ' - ' + child.first_name"
                  :model-value="child"
                  :processing="processing"
                  :questions="applicantQuestions[child.id].filter((question) => question.valid)"
                  :readonly="reviewLocked"
                  :schema="childSchema.definition"
                  dense
                  divided
                  expanded
                  hide-actions
                  paddingless
                />
              </div>

              <template
                v-if="
                  subsidyProgram.allow_preference ||
                  subsidyProgram.allow_program_preference ||
                  subsidyProgram.allow_enrolled
                "
              >
                <SubsidyWrapper
                  v-for="(child, childIndex) in eligibleChildrenWithPreferencesAllowed"
                  :key="`preference-${child.id}`"
                  :child="child"
                  :previous-child="eligibleChildrenWithPreferencesAllowed[childIndex - 1]"
                  :subsidies="subsidies"
                >
                  <template #default="{ previousChild, previousSubsidy, subsidy }">
                    <template v-if="subsidyProgram.enable_advanced_selector">
                      <template v-if="subsidyProgram.allow_program_preference">
                        <ProgramPreferenceReview
                          :key="'favorites-' + childIndex"
                          :child-name="child.first_name"
                          :current-provider-id="subsidy.current_provider_id"
                          :previous-child-name="previousChild?.first_name"
                          :previous-subsidy-program-ids="previousSubsidy?.program_ids"
                          :program-ids="subsidy.program_ids"
                          :sibling-provider-ids="subsidy.sibling_provider_ids"
                          :staff-provider-ids="subsidy.staff_provider_ids"
                          :subsidy-program="subsidyProgram"
                        />
                      </template>
                      <template v-else>
                        <ProviderPreferenceReview
                          :key="'favorites-' + childIndex"
                          :child-name="child.first_name"
                          :current-provider-id="subsidy.current_provider_id"
                          :previous-child-name="previousChild?.first_name"
                          :previous-subsidy-provider-ids="previousSubsidy?.provider_ids"
                          :provider-ids="subsidy.provider_ids"
                          :subsidy-program="subsidyProgram"
                        />
                      </template>
                    </template>

                    <template v-else>
                      <template v-if="subsidyProgram.allow_preference">
                        <FormQuestion
                          @back="backFromFavoriteQuestion(childIndex)"
                          :key="'favorites-' + childIndex"
                          :header="$t('Child') + ' - ' + child.first_name"
                          :processing="processing"
                          :subtitle="
                            subsidyProgram.preference_subtitle ||
                            (subsidyProgram.allow_preference_order
                              ? 'Selecting a provider indicates that you’re interested in receiving care from this provider. Rank your favorites by reordering the selected providers from top-bottom. Top being your top choice. We’ll do our best to match you with a provider of your choice!'
                              : 'Selecting a provider indicates that you’re interested in receiving care from this provider.')
                          "
                          :title="
                            subsidyProgram.preference_title ||
                            (subsidyProgram.allow_preference_order
                              ? 'Select and rank the providers you’d like to include in the application for this child'
                              : 'Select the providers you’d like to include in the application for this child')
                          "
                          hide-actions
                        >
                          <template v-if="subsidyProgram.allow_preference">
                            <FavoritesSelector
                              :childData="child"
                              :default-query="homeAddress"
                              :ordered="subsidyProgram.allow_preference_order"
                              :previous-child="previousChild"
                              :subsidies="subsidies"
                              :subsidy="subsidy"
                              :subsidy-program="subsidyProgram"
                              class="mt-4 mb-4"
                            />
                          </template>
                        </FormQuestion>
                      </template>

                      <template v-else-if="subsidyProgram.allow_enrolled">
                        <FormQuestion
                          :key="'favorites-' + childIndex"
                          :header="$t('Child') + ' - ' + child.first_name"
                          :processing="processing"
                          :subtitle="subsidyProgram.preference_subtitle"
                          :title="
                            subsidyProgram.preference_title ||
                            'If your child is already enrolled in care, add the provider below.'
                          "
                          hide-actions
                        >
                          <ProviderSelector
                            :child="child"
                            :subsidy="subsidy"
                          />
                        </FormQuestion>
                      </template>
                    </template>
                  </template>
                </SubsidyWrapper>
              </template>

              <div
                v-for="question in validGroupVerificationQuestions"
                :key="question.id"
              >
                <v-divider class="my-4" />

                <FormQuestion
                  :subtitle="question.verification_subtitle"
                  :title="question.verification_title"
                  dense
                  hide-actions
                  paddingless
                >
                  <AttachmentList
                    :attachments="attachments.filter((attachment) => attachment.tag == question.id)"
                    :empty-label="$t('No documents provided.')"
                    :processing="processing"
                    dense
                    hide-remove
                  />
                </FormQuestion>
              </div>

              <div
                v-for="child in eligibleChildren"
                :key="child.id + '-verification'"
              >
                <template v-if="applicantVerificationQuestions[child.id]">
                  <div
                    v-for="question in applicantVerificationQuestions[child.id].filter(
                      (questionObject) => questionObject.valid,
                    )"
                    :key="question.id + child.id"
                    no-print
                  >
                    <v-divider class="my-4" />

                    <FormQuestion
                      :subtitle="question.verification_subtitle"
                      :title="question.verification_title"
                      dense
                      hide-actions
                      paddingless
                    >
                      <AttachmentList
                        :attachments="
                          attachments.filter(
                            (attachment) => attachment.tag == child.id + question.id,
                          )
                        "
                        :empty-label="$t('No documents provided.')"
                        :processing="processing"
                        dense
                        hide-remove
                      />
                    </FormQuestion>
                  </div>
                </template>
              </div>

              <template v-if="subsidyProgram.verify_child">
                <div
                  v-for="child in eligibleChildren"
                  :key="child.id + '-documents'"
                >
                  <div no-print>
                    <v-divider class="my-4" />
                    <FormQuestion
                      :header="$t('Child') + ' - ' + child.first_name"
                      :subtitle="subsidyProgram.verify_child_subtitle"
                      :title="subsidyProgram.verify_child_title"
                      dense
                      hide-actions
                      paddingless
                    >
                      <AttachmentList
                        :attachments="
                          attachments.filter(
                            (attachment) => attachment.tag == 'child-documents-' + child.id,
                          )
                        "
                        :empty-label="$t('No documents provided.')"
                        :processing="processing"
                        dense
                        hide-remove
                      />
                    </FormQuestion>
                  </div>
                </div>
              </template>

              <template v-if="subsidyProgram.verify_home_address">
                <v-divider class="my-4" />

                <FormQuestion
                  :subtitle="
                    subsidyProgram.verify_home_address_subtitle ||
                    'Ex. A copy of current lease, proof of homeownership, or utility bill (with service or premise address listed) such as your bill for gas, electric, water, or cable.'
                  "
                  :title="
                    subsidyProgram.verify_home_address_title ||
                    'Upload a document to verify your current address'
                  "
                  dense
                  hide-actions
                  no-print
                  paddingless
                >
                  <AttachmentList
                    :attachments="
                      attachments.filter((attachment) => attachment.tag == 'proof-of-residency')
                    "
                    :empty-label="$t('No documents provided.')"
                    :processing="processing"
                    dense
                    hide-remove
                  />
                </FormQuestion>
              </template>
              <v-divider class="my-8" />
            </template>

            <ThirdPartyQuestions
              v-if="subsidyProgram.enable_third_party_applications"
              key="third-party-questions"
              :model-value="thirdPartyApplication"
              :processing="processing"
              :readonly="true"
            />

            <v-divider
              v-if="subsidyProgram.enable_third_party_applications"
              class="my-8"
            />

            <UserAttestation
              v-if="
                thirdPartyApplication.third_party_application &&
                subsidyProgram.enable_third_party_esign
              "
              @change="confirmed = $event"
              ref="attestation"
              :attestation-label="
                subsidyProgram.third_party_attestation_label[$store.state.profile.default_locale]
              "
              :attestation-text="subsidyProgram.third_party_attestation_text"
              :enable-esign="true"
            />

            <UserAttestation
              v-else
              @change="confirmed = $event"
              ref="attestation"
              :attestation-label="subsidyProgram.attestation_label"
              :attestation-text="subsidyProgram.attestation_text"
              :enable-esign="subsidyProgram.enable_esign"
            />
          </FormQuestion>

          <AddressVerificationDialog
            @cancel="forwardFromHome"
            @confirm="updateHomeAddress"
            ref="validateAddressDialog"
            cancel-button-text="Keep original"
            confirm-button-text="Use recommended"
            title="Verify address"
          />

          <ResourceDialog
            @save="confirmReorderReferredProgram()"
            ref="programPositionDialog"
            :cancellable="true"
            :closeable="true"
            :max-width="600"
            save-button-text="Continue with re-ranking"
            title="Are you sure you want to continue?"
          >
            <template #form>
              <v-row data-testid="programPositionDialog">
                <v-col cols="12">
                  <div class="fs-14">
                    {{
                      $t(
                        "Important: If you don't rank the referred provider as your first choice, your priority at this site won't be guaranteed.",
                      )
                    }}
                  </div>
                </v-col>
              </v-row>
            </template>
          </ResourceDialog>
        </div>
      </template>
    </SubsidyProgramValidation>

    <ConfirmDialog ref="confirmDialog" />
  </v-container>
</template>

<script setup>
import $a from '@/shared/services/assets';
import api from '@/shared/services/bright_finder';
import AddressVerificationDialog from '@/shared/components/AddressVerificationDialog.vue';
import UserAttestation from '@/shared/components/UserAttestation.vue';
import AttachmentList from '@/shared/components/attachments/AttachmentList.vue';
import AttachmentUploader from '@/shared/components/attachments/AttachmentUploader.vue';
import Child from '@/parent/components/children/Child.vue';
import ConfirmDialog from '@/shared/components/ConfirmDialog.vue';
import FormQuestion from '@/shared/components/form/FormQuestion.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import SubsidyChildren from '@/parent/components/subsidy/SubsidyChildren.vue';
import FavoritesSelector from '@/parent/components/favorites/FavoritesSelector.vue';
import ProgramPreferenceEditor from '@/parent/components/ProgramPreferenceEditor.vue';
import ProgramPreferenceReview from '@/parent/components/subsidy/ProgramPreferenceReview.vue';
import ProviderPreferenceEditor from '@/parent/components/ProviderPreferenceEditor.vue';
import ProviderPreferenceReview from '@/parent/components/subsidy/ProviderPreferenceReview.vue';
import ProviderSelector from '@/parent/components/ProviderSelector.vue';
import QuestionSet from '@/shared/components/form/QuestionSet.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import SubsidyWrapper from '@/parent/components/subsidy/SubsidyWrapper.vue';
import SubsidyEligibilityCard from '@/shared/components/subsidy/SubsidyEligibilityCard.vue';
import SubsidyProgramValidation from '@/shared/components/SubsidyProgramValidation.vue';
import ThirdPartyQuestions from '@/parent/components/family_subsidy/ThirdPartyQuestions.vue';
import useEventBus from '@/shared/composables/useEventBus';
import useSubsidy from '@/shared/composables/useSubsidy';
import useSubsidyStepper from '@/shared/composables/useSubsidyStepper';
import useRouterHelper from '@/shared/composables/useRouterHelper.js';
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router';
import { SUBSIDY_EVENTS } from '@/parent/services/constants';
import { useStore } from 'vuex';

const ADDRESS_HAS_UNCONFIRMED_COMPONENTS_ERROR =
  'Your address could not be validated. Please check for any errors and retry. If you believe this address to be correct, please contact support for assistance.';
const LEAVE_WARNING =
  'You have unsaved changes and have not submitted your application. Are you sure you want to leave your application?';
const NOW_INELIGIBLE_ERROR =
  'Application data has changed that has resulted in your application becoming ineligible. Please review your application or contact support if you believe this is incorrect.';

const defaultSection = 'group';
const attachments = ref([]);
const attestation = ref(null);
const children = ref([]);
const confirmed = ref(false);
const confirmDialog = ref(null);
const eventBus = useEventBus();
const finished = ref(false);
const group = ref(null);
const processing = ref(false);
const progressUnknown = ref(false);
const programPositionDialog = ref(null);
const reviewLocked = ref(false);
const router = useRouter();
const route = useRoute();
const { updateQuery } = useRouterHelper();
const store = useStore();
const subsidies = ref([]);
const subsidyProgram = ref(null);
const subsidyProgramId = ref(null);
const thirdPartyApplication = ref(null);
const pendingReorder = ref({});
const progressRef = ref(null);
const validateAddressDialog = ref(null);

const {
  applicantChildren,
  applicantEligibilityQuestions,
  applicantQuestions,
  applicantVerificationQuestions,
  childSchema,
  childEligibilityQuestions,
  childVerificationQuestions,
  eligibleChildren,
  groupSchema,
  loadSchemas,
  loadQuestions,
  questions,
  validate,
  validApplicantQuestions,
  validApplicantQuestionsLength,
  validGroupEligibilityQuestions,
  validApplicantEligibilityQuestionsLength,
  validApplicantVerificationQuestionsLength,
  validGroupQuestions,
  validGroupVerificationQuestions,
} = useSubsidy({ children, group, subsidyProgram, subsidies });
const {
  backFromChildDocuments,
  backFromChildren,
  backFromChildQuestion,
  backFromChildEligibilityQuestion,
  backFromChildVerificationQuestion,
  forwardFromChildEligibilityQuestion,
  forwardFromChildDocuments,
  forwardFromChildQuestion,
  forwardFromChildren,
  forwardFromChildVerificationQuestion,
  progress,
  section,
  step,
  stepBack,
  stepForward,
  stepCount,
  stepTotal,
  transitionName,
} = useSubsidyStepper({
  applicantChildren,
  applicantEligibilityQuestions,
  applicantQuestions,
  applicantVerificationQuestions,
  backFromGroupQuestion,
  backFromGroupVerificationQuestion,
  calculateStepTotal,
  childEligibilityQuestions,
  childVerificationQuestions,
  eligibleChildren,
  forwardFromFavoriteQuestion,
  forwardFromEligibility,
  loadChildren,
  loadSubsidies,
  processing,
  route,
  saveChild,
  saveChildren,
  saveGroup,
  subsidyProgram,
  updateQuery,
  createOrDestroySubsidies,
  validate,
  validGroupEligibilityQuestions,
});

onBeforeRouteLeave(async (_to, _from, next) => {
  if (!finished.value) {
    const confirm = await confirmDialog.value.confirmWithText(LEAVE_WARNING);
    if (!confirm) return next(false);
  }

  next();
});

const eligibleChildrenWithPreferencesAllowed = computed(() => {
  if (!favoritesSectionEnabled.value) return [];
  if (!subsidyProgram.value?.enable_iep_direct_placement) return eligibleChildren.value;

  return eligibleChildren.value?.filter((child) => !child.individualized_education_plan);
});

const eligibleSubsidies = computed(() => {
  return subsidies.value.filter((subsidy) => subsidy.projected_eligibility);
});

const favoritesSectionEnabled = computed(() => {
  return (
    subsidyProgram.value?.allow_preference ||
    subsidyProgram.value?.allow_program_preference ||
    !!subsidyProgram.value?.allow_enrolled
  );
});

const ineligibleSubsidies = computed(() => {
  return subsidies.value.filter((subsidy) => !subsidy.projected_eligibility);
});

const loaded = computed(() => {
  return (
    group.value &&
    subsidyProgram.value &&
    questions.value?.length > 0 &&
    groupSchema.value &&
    !!childSchema.value
  );
});

const homeAddress = computed(() => {
  if (group.value && group.value?.experiencing_homelessness !== true) {
    return [
      group.value.home_address,
      group.value.home_city,
      group.value.home_state,
      group.value.home_zip,
    ].join(', ');
  }

  return null;
});

const stateOptions = computed(() => {
  if (subsidyProgram.value.state_options?.length > 0) {
    return $a.assets.states.filter((state) => subsidyProgram.value.state_options.includes(state));
  }
  return $a.assets.states;
});

const validChildren = computed(() => {
  if (children.value.some((child) => child.editing)) {
    return false;
  }

  if (children.value.filter((child) => child.included)?.length > 0) {
    if (
      children.value.every(
        (child) => !child.included || (child.first_name && child.last_name && child.dob),
      )
    ) {
      return true;
    }
  }
  return false;
});

const validHomeAddress = computed(() => {
  if (group.value) {
    return (
      group.value.experiencing_homelessness ||
      (group.value.home_address &&
        group.value.home_city &&
        group.value.home_state &&
        group.value.home_zip &&
        group.value.home_zip?.length >= 5)
    );
  }
  return false;
});

function createOrDestroySubsidies() {
  return Promise.all(
    children.value.map((child) => {
      const subsidy = subsidies.value.find((subsidyItem) => subsidyItem.child_id === child.id);
      if (subsidy && !child.included) {
        return api.subsidy.promiseDestroy(subsidy.id);
      } else if (!subsidy && child.included) {
        return api.subsidy.create({
          test_code: route.query.test,
          child_id: child.id,
          subsidy_program_id: subsidyProgram.value.id,
        });
      }
    }),
  );
}

async function updateQueryIfNeeded() {
  let updateQueryNeeded = false;
  let newQuery = route.query;

  // Set defaults
  if (section.value == null) {
    section.value = defaultSection;
  }
  if (!stepCount.value || isNaN(stepCount.value)) {
    stepCount.value = 1;
  }

  if (!stepTotal.value) {
    await validate();
    calculateStepTotal();
  }

  if (newQuery.section && newQuery.section !== section.value) {
    section.value = newQuery.section;
    updateQueryNeeded = true;
  }

  const routeStep = parseInt(newQuery.step, 10);
  if (!isNaN(routeStep) && routeStep && routeStep !== stepCount.value) {
    stepCount.value = routeStep;
    progress.value = (routeStep / stepTotal.value) * 100;
    updateQueryNeeded = true;
  }
  if (updateQueryNeeded) {
    updateQuery({
      step: stepCount.value,
      section: section.value,
      programId: route.query?.programId,
    });
  }
}

async function handleAfterLoaded() {
  if (subsidyProgram.value.state_options?.length === 1 && !group.value.home_state) {
    [group.value.home_state] = subsidyProgram.value.state_options;
  }
  if (subsidyProgram.value.city_options?.length === 1 && !group.value.home_city) {
    [group.value.home_city] = subsidyProgram.value.city_options;
  }

  await loadSubsidies();
  await validate();
  calculateStepTotal();
  await updateQueryIfNeeded();
}

watch(
  loaded,
  async (newVal, oldVal) => {
    if (newVal && newVal !== oldVal) {
      await handleAfterLoaded();
    }
  },
  { immediate: true },
);

watch(
  () => route.query,
  async () => {
    calculateStepTotal();
    await updateQueryIfNeeded();
  },
  { deep: true },
);

onMounted(async () => {
  progressUnknown.value = !!route.query.section && !route.query.step;
  await identifyMemberOnMount();
});

async function identifyMemberOnMount() {
  store.dispatch('identify', {
    api: api,
    async success() {
      if (store.state.is_anonymous) {
        finished.value = true;
        router.replace({
          name: 'Signup',
          query: {
            action: 'apply',
            subsidyProgramId: route.params.subsidyProgramId,
            redirect_url: route.fullPath,
          },
        });
        return;
      }
      await load();
    },
    failure() {
      finished.value = true;
      router.replace({
        name: 'Signup',
        query: {
          action: 'apply',
          subsidyProgramId: route.params.subsidyProgramId,
        },
      });
    },
  });
}

async function maybeClearPreferences(child, event) {
  if (!subsidyProgram.value.enable_iep_direct_placement) return;

  if (
    child.individualized_education_plan === false &&
    event?.attribute?.name === 'individualized_education_plan' &&
    event?.value === true
  ) {
    const subsidy = subsidies.value.find((subsidyItem) => subsidyItem.child_id === child.id);

    subsidy.current_provider_id = null;
    subsidy.program_ids = [];
    subsidy.provider_ids = [];
    subsidy.sibling_provider_ids = [];
    subsidy.staff_provider_ids = [];

    await api.subsidy.update(subsidy.id, {
      current_provider_id: subsidy.current_provider_id,
      program_ids: subsidy.program_ids,
      provider_ids: subsidy.provider_ids,
      sibling_provider_ids: subsidy.sibling_provider_ids,
      staff_provider_ids: subsidy.staff_provider_ids,
    });
  }
}

function handleSelectedPrograms({ type, id }, subsidy) {
  const subsidyIndex = subsidies.value.findIndex((sub) => sub.id === subsidy.id);

  switch (type) {
    case SUBSIDY_EVENTS.ADD: {
      const updatedSubsidy = orderAddedSubsidyIds(id, subsidy);
      return (subsidies.value[subsidyIndex] = updatedSubsidy);
    }
    case SUBSIDY_EVENTS.REMOVE:
      return subsidy.program_ids.splice(
        subsidy.program_ids.findIndex((programId) => programId === id),
        1,
      );
    default:
      return null;
  }
}

function orderAddedSubsidyIds(id, subsidy) {
  const programId = route.query?.programId;

  if (programId === id) {
    // We know the programId being added is the program referral, therefore it gets priority
    return { ...subsidy, program_ids: [id, ...subsidy.program_ids] };
  }

  return { ...subsidy, program_ids: [...subsidy.program_ids, id] };
}

function handleReorderSelectedPrograms(fromIndex, toIndex, subsidy, { confirmed = false } = {}) {
  const reorderedProgramId = subsidy.program_ids[fromIndex];

  const isPendingPosition = pendingReferredProgramPosition({
    fromIndex,
    toIndex,
    reorderedProgramId,
    subsidy,
  });

  if (isPendingPosition === false || confirmed === true) {
    subsidy.program_ids.splice(fromIndex, 1);
    subsidy.program_ids.splice(toIndex, 0, reorderedProgramId);
  }
}

function pendingReferredProgramPosition({ fromIndex, toIndex, reorderedProgramId, subsidy } = {}) {
  const programId = route.query?.programId;
  const currentFirstPositionProgramId = subsidy.program_ids[0];

  // Check if another program is being moved to index 0 while the programId is in current first position
  // (another program being moved up to first position replacing the referred program)
  // Check if the reorderedProgramId is the programId and is being moved fromIndex 0 (moved down)

  if (
    programId &&
    ((currentFirstPositionProgramId === programId && toIndex === 0) ||
      (fromIndex === 0 && reorderedProgramId === programId))
  ) {
    // The referred program is being moved from position one, open the program position info dialog
    programPositionDialog.value?.open();
    // Create a pendingReorder state so we can resume reordering if the user confirms
    pendingReorder.value = {
      fromIndex,
      toIndex,
      reorderedProgramId,
      subsidyId: subsidy?.id,
    };

    return true;
  }

  return false;
}

function confirmReorderReferredProgram() {
  // The user is confirming they want to reorder the referred program so use the pendingReorder state
  const { fromIndex, toIndex, subsidyId } = pendingReorder.value;
  const subsidy = subsidies.value.find((sub) => sub.id === subsidyId);

  handleReorderSelectedPrograms(fromIndex, toIndex, subsidy, { confirmed: true });

  // Reset the pending state to fresh and close the dialog
  pendingReorder.value = null;
  programPositionDialog.value?.close();
}

function handleSelectedCurrentProvider(id, subsidy) {
  const index = subsidies.value.findIndex((sub) => sub.id === subsidy.id);
  const updatedSubsidy = { ...subsidy, current_provider_id: id };
  subsidies.value[index] = updatedSubsidy;
}

function handleSelectedSiblings(ids, subsidy) {
  const index = subsidies.value.findIndex((sub) => sub.id === subsidy.id);
  const updatedSubsidy = { ...subsidy, sibling_provider_ids: ids };
  subsidies.value[index] = updatedSubsidy;
}

function handleSelectedStaff(ids, subsidy) {
  const index = subsidies.value.findIndex((sub) => sub.id === subsidy.id);
  const updatedSubsidy = { ...subsidy, staff_provider_ids: ids };
  subsidies.value[index] = updatedSubsidy;
}

async function backFromEligibility() {
  processing.value = true;
  const lastChildIndex = applicantChildren.value.length - 1;
  const lastChild = applicantChildren.value[lastChildIndex];

  if (
    childEligibilityQuestions.value?.length > 0 &&
    applicantEligibilityQuestions.value[lastChild.id].filter((question) => question.valid)?.length >
      0
  ) {
    stepBack(
      `ChildEligibility${lastChildIndex}-${applicantEligibilityQuestions.value[lastChild.id].filter((question) => question.valid).length - 1}`,
    );
  } else if (validGroupEligibilityQuestions.value?.length > 0) {
    stepBack(`GroupEligibility-${validGroupEligibilityQuestions.value.length - 1}`);
  } else {
    stepBack('children');
  }
}

async function backFromGroupEligibilityQuestion(index) {
  processing.value = true;
  await saveGroup();
  await validate();

  if (index - 1 < 0) {
    stepBack('children');
  } else {
    stepBack(`GroupEligibility-${index - 1}`);
  }
}

async function backFromGroupQuestion(index) {
  processing.value = true;

  let revisedIndex = index;
  if (revisedIndex === false) {
    revisedIndex = validGroupQuestions.value?.length;
  } else {
    await validate();
    await saveGroup();
  }

  if (revisedIndex - 1 < 0) {
    if (subsidies.value?.length > 0) {
      if (subsidyProgram.value.enable_third_party_applications) {
        return stepBack('third-party-questions');
      }
      if (subsidyProgram.value.eligibility) {
        return stepBack('eligibility');
      }
    }
    return backFromEligibility();
  }

  return stepBack(`Group-${revisedIndex - 1}`);
}

function backFromConfirm() {
  processing.value = true;
  if (subsidyProgram.value.verify_home_address) {
    stepBack('proof-of-residency');
  } else {
    // eslint-disable-next-line no-floating-promise/no-floating-promise
    backFromDocuments();
  }
}

function backFromGroupVerificationQuestion(index) {
  processing.value = true;

  let revisedIndex = index;
  if (revisedIndex === false) {
    revisedIndex = validGroupVerificationQuestions.value?.length;
  }

  if (revisedIndex - 1 < 0) {
    backFromFavoriteQuestion(eligibleChildrenWithPreferencesAllowed.value.length);
  } else {
    stepBack(`group-verification-${revisedIndex - 1}`);
  }
}

function backFromFavoriteQuestion(childIndex) {
  processing.value = true;
  if (favoritesSectionEnabled.value && childIndex - 1 >= 0) {
    stepBack(`favorites-${childIndex - 1}`);
  } else {
    // eslint-disable-next-line no-floating-promise/no-floating-promise
    backFromChildQuestion({ questionIndex: false });
  }
}

async function backFromDocuments() {
  processing.value = true;
  await backFromChildDocuments({ childIndex: eligibleChildren.value?.length });
}

async function backFromThirdPartyQuestions() {
  processing.value = true;

  if (subsidies.value?.length > 0 && subsidyProgram.value.eligibility) {
    stepBack('eligibility');
  } else {
    await backFromEligibility();
  }
}

function calculateStepTotal() {
  stepTotal.value =
    3 +
    (subsidyProgram.value?.eligibility ? 1 : 0) +
    (subsidyProgram.value?.verify_home_address ? 1 : 0) +
    (subsidyProgram.value?.verify_child ? eligibleChildren.value.length : 0) +
    (subsidyProgram.value?.enable_third_party_applications ? 1 : 0) +
    eligibleChildrenWithPreferencesAllowed.value.length +
    validGroupEligibilityQuestions.value.length +
    validGroupQuestions.value.length +
    validGroupVerificationQuestions.value.length +
    (validApplicantEligibilityQuestionsLength.value +
      validApplicantQuestionsLength.value +
      validApplicantVerificationQuestionsLength.value);
}

function thirdPartyContinueDisabled() {
  if (
    thirdPartyApplication.value.third_party_application === false ||
    (thirdPartyApplication.value.third_party_application &&
      thirdPartyApplication.value.third_party_email &&
      thirdPartyApplication.value.third_party_first_name &&
      thirdPartyApplication.value.third_party_last_name &&
      thirdPartyApplication.value.third_party_phone_number)
  ) {
    return false;
  }

  return true;
}

async function eligibleSubsidiesAreStillEligible() {
  const subsidiesToBeChecked = eligibleSubsidies.value;
  const promises = subsidiesToBeChecked.map(async (subsidy) => {
    const response = await api.subsidy.get(subsidy.id);
    return response.data;
  });

  const responses = await Promise.all(promises);
  return responses.every((response) => response.projected_eligibility !== false);
}

async function exit() {
  processing.value = true;
  finished.value = true;
  if (subsidyProgram.value.enable_submit_signout) {
    await api.member.session.promiseDestroy();
    store.commit('setAuthenticated', false);
    store.commit('setProfile', null);
    if (subsidyProgram.value.submit_title) {
      eventBus.longChime('You have been automatically logged out for security.');
    }
    router.push({ path: '/' });
  } else {
    router.push({ name: 'Dashboard' });
  }
}

async function exitToDashboard() {
  if (subsidyProgram.value.submit_title) {
    eventBus.longChime(subsidyProgram.value.submit_title);
  }
  router.push({
    name: 'Dashboard',
    query: {
      submittedSurveySchemaId: subsidyProgram.value.submitted_survey_schema_id,
    },
  });
}

async function exitByLogout() {
  await api.member.session.promiseDestroy();
  store.commit('setAuthenticated', false);
  store.commit('setProfile', null);
  if (subsidyProgram.value.submit_title) {
    eventBus.longChime(subsidyProgram.value.submit_title);
  }
  router.push({ path: '/' });
}

async function finish() {
  processing.value = true;
  if (!subsidyProgram.value.enable_parent_review_locked) {
    await saveGroup();
    await saveAllEligibleChildren();
  }

  if (!(await eligibleSubsidiesAreStillEligible())) {
    eventBus.longChime(NOW_INELIGIBLE_ERROR);
    stepCount.value = 0;
    transitionName.value = 'slide-right';
    return step('group');
  }

  await submitAllEligibleSubsidies();

  finished.value = true;
  if (subsidyProgram.value.enable_submit_signout) {
    // eslint-disable-next-line no-floating-promise/no-floating-promise
    exitByLogout();
  } else {
    // eslint-disable-next-line no-floating-promise/no-floating-promise
    exitToDashboard();
  }

  return true;
}

async function forwardFromEligibility() {
  processing.value = true;
  await saveGroup();
  await validate();

  if (subsidies.value?.length > 0 && subsidyProgram.value.enable_third_party_applications) {
    stepForward('third-party-questions');
  } else if (validGroupQuestions.value?.length > 0) {
    stepForward('Group-0');
  } else {
    await forwardFromGroupQuestion(1000);
  }
}

async function forwardFromGroupEligibilityQuestion(index) {
  processing.value = true;
  await saveGroup();
  await loadSubsidies();
  await validate();

  if (index + 1 >= validGroupEligibilityQuestions.value?.length) {
    await forwardFromChildEligibilityQuestion({ questionIndex: false });
  } else {
    stepForward(`GroupEligibility-${index + 1}`);
  }
}

async function forwardFromGroupQuestion(index) {
  processing.value = true;
  await saveGroup();
  await validate();

  if (index + 1 >= validGroupQuestions.value?.length) {
    await forwardFromChildQuestion({ questionIndex: false });
  } else {
    stepForward(`Group-${index + 1}`);
  }
}

function forwardFromGroupVerificationQuestion(index) {
  processing.value = true;

  if (index + 1 >= validGroupVerificationQuestions.value?.length) {
    if (childVerificationQuestions.value?.length > 0) {
      stepForward('child-verification0-0');
    } else {
      forwardFromChildDocuments(-1);
    }
  } else {
    stepForward(`group-verification-${index + 1}`);
  }
}

async function forwardFromFavoriteQuestion(childIndex) {
  processing.value = true;
  let revisedChildIndex = childIndex;
  const kids = eligibleChildrenWithPreferencesAllowed.value;
  if (childIndex === false) {
    revisedChildIndex = -1;
  } else {
    const child = kids[revisedChildIndex];
    const subsidy = subsidyForChild(child);
    await api.subsidy.update(subsidy.id, {
      current_provider_id: subsidy.current_provider_id,
      program_ids: subsidy.program_ids,
      provider_ids: subsidy.provider_ids,
      sibling_provider_ids: subsidy.sibling_provider_ids,
      staff_provider_ids: subsidy.staff_provider_ids,
    });
  }
  const doneWithFavorites = !favoritesSectionEnabled.value || revisedChildIndex + 1 >= kids.length;
  if (doneWithFavorites) {
    const groupVerificationQuestionsExist = validGroupVerificationQuestions.value?.length > 0;
    const firstChildHasVerificationQuestions =
      !!kids[0] &&
      applicantVerificationQuestions.value[kids[0].id].filter((question) => question.valid)
        ?.length > 0;
    if (groupVerificationQuestionsExist) {
      stepForward('group-verification-0');
    } else if (firstChildHasVerificationQuestions) {
      stepForward('child-verification0-0');
    } else {
      forwardFromChildDocuments(-1);
    }
  } else {
    stepForward(`favorites-${revisedChildIndex + 1}`);
  }
}

async function forwardFromHome() {
  processing.value = true;
  await saveGroup();
  await loadSubsidies();
  await validate();

  stepForward('children');
}

async function forwardFromThirdPartyQuestions() {
  processing.value = true;
  const updatePromises = eligibleSubsidies.value.map((subsidy) =>
    api.subsidy.update(subsidy.id, {
      third_party_application: thirdPartyApplication.value.third_party_application,
      third_party_email: thirdPartyApplication.value.third_party_email,
      third_party_first_name: thirdPartyApplication.value.third_party_first_name,
      third_party_last_name: thirdPartyApplication.value.third_party_last_name,
      third_party_phone_number: thirdPartyApplication.value.third_party_phone_number,
    }),
  );
  const responses = await Promise.all(updatePromises);
  const anyResponsesUnsuccessful = responses.some((resp) => resp?.status !== 200);
  if (anyResponsesUnsuccessful) {
    processing.value = false;
    return;
  }
  await validate();
  if (validGroupQuestions.value?.length > 0) {
    stepForward('Group-0');
  } else {
    await forwardFromGroupQuestion(1000);
  }
}

async function load() {
  api.group.get((response) => {
    group.value = response.data;
  });
  subsidyProgramId.value = route.params.subsidyProgramId;
  await loadSubsidies();

  if (subsidies.value?.length > 0 && subsidies.value.some((subsidy) => subsidy.submitted_at)) {
    finished.value = true;
    router.replace({ name: 'Dashboard' });
  } else {
    await loadChildren();
    await loadSubsidyProgram();
  }
  await loadQuestions();
  await loadAttachments();
}

async function loadSubsidies() {
  const resp = await api.subsidy.index({ subsidy_program_id: subsidyProgramId.value });
  subsidies.value = resp.data;
  updateThirdPartyApplicationValue();
}

function updateThirdPartyApplicationValue() {
  const subsidy = eligibleSubsidies.value[0];
  thirdPartyApplication.value = {
    third_party_application: subsidy?.third_party_application,
    third_party_email: subsidy?.third_party_email,
    third_party_first_name: subsidy?.third_party_first_name,
    third_party_last_name: subsidy?.third_party_last_name,
    third_party_phone_number: subsidy?.third_party_phone_number,
  };
}

function loadSubsidyProgram() {
  return new Promise((resolve) => {
    api.public_api.organization.subsidy_program.get(subsidyProgramId.value, (resp) => {
      subsidyProgram.value = resp.data;
      reviewLocked.value = subsidyProgram.value.enable_parent_review_locked;

      loadSchemas();
      document.title = `New application - ${subsidyProgram.value.name}`;
      resolve();
    });
  });
}

async function loadAttachments() {
  processing.value = true;
  const params = {
    owner_type: 'SubsidyProgram',
    owner_id: route.params.subsidyProgramId,
  };
  const resp = await api.member.attachment.index(params);
  if (resp?.status !== 200) {
    processing.value = false;
    return;
  }

  attachments.value = resp.data;
  processing.value = false;
  return;
}

async function loadChildren() {
  const resp = await api.child.index();
  children.value = resp.data
    .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
    .map((childData) => {
      const child = {
        ...childData,
        included: !!subsidies.value.find((subsidy) => subsidy.child_id === childData.id),
        editing: false,
      };
      return child;
    });
}

function subsidyForChild(child) {
  return subsidies.value.find((subsidy) => child.id === subsidy.child_id);
}

function childForSubsidy(subsidy) {
  return children.value.find((child) => child.id == subsidy.child_id);
}

async function saveAllEligibleChildren() {
  await Promise.all(
    eligibleChildren.value.map(async (child) => {
      await saveChild(child);
    }),
  );
}

async function saveGroup() {
  const { data } = await api.group.update(group.value);
  group.value = data;
}

async function saveChild(child) {
  if (!child) return;
  await api.child.update(child.id, child);
}

async function saveChildren() {
  await Promise.all(
    applicantChildren.value.map(async (child) => {
      await saveChild(child);
    }),
  );
}

async function submitAllEligibleSubsidies() {
  const revisionParams = {
    revision_author_signed_name: null,
    revision_author_signature_image: null,
  };
  if (subsidyProgram.value.enable_esign) {
    revisionParams.revision_author_signed_name = attestation.value.esignName;
    revisionParams.revision_author_signature_image = attestation.value.getEsignSignature();
  }

  const currentSubsidies = eligibleSubsidies.value;
  // TODO: Do not change any program/provider ids when submitting. Do it before
  await Promise.all(
    currentSubsidies.map((subsidy) => {
      return api.subsidy.update(subsidy.id, {
        test_code: route.query.test,
        revision_author_signed_name: revisionParams.revision_author_signed_name,
        revision_author_signature_image: revisionParams.revision_author_signature_image,
        submitted: true,
        program_ids: subsidy.program_ids,
        provider_ids: subsidy.provider_ids,
        sibling_provider_ids: subsidy.sibling_provider_ids,
        staff_provider_ids: subsidy.staff_provider_ids,
      });
    }),
  );
}

function updateHomeAddress(uspsData) {
  const address = uspsData.standardizedAddress;
  group.value.home_address = address.firstAddressLine;
  group.value.home_city = address.city;
  group.value.home_state = address.state;
  group.value.home_zip = address.zipCode;
  group.value.home_latitude = uspsData.location.latitude;
  group.value.home_longitude = uspsData.location.longitude;
  // eslint-disable-next-line no-floating-promise/no-floating-promise
  forwardFromHome();
}

async function validateAddress() {
  if (
    group.value.experiencing_homelessness ||
    !subsidyProgram.value.enable_home_address_validation
  ) {
    return forwardFromHome();
  }

  const response = await api.member.address_validation.validate({
    addressLines: [group.value.home_address],
    administrativeArea: group.value.home_state,
    locality: group.value.home_city,
    postalCode: group.value.home_zip,
  });

  if (!response.data) {
    return eventBus.chime('Unknown error occurred attempting to validate your address.');
  }

  const { hasUnconfirmedComponents } = response.data.verdict;
  if (hasUnconfirmedComponents) {
    const allSubpremise = response.data.address.addressComponents.every((addressComponent) => {
      if (addressComponent.confirmationLevel === 'CONFIRMED') return true;

      if (addressComponent.componentType === 'subpremise') return true;

      return false;
    });

    if (!allSubpremise) return eventBus.longChime(ADDRESS_HAS_UNCONFIRMED_COMPONENTS_ERROR);
  }

  const { geocode, uspsData } = response.data;
  if (validatedAddressMatches(uspsData.standardizedAddress)) {
    group.value.home_latitude = geocode.location.latitude;
    group.value.home_longitude = geocode.location.longitude;
    return forwardFromHome();
  }

  validateAddressDialog.value?.open({ value: group.value, ...uspsData, ...geocode });

  return true;
}

function validatedAddressMatches(validated) {
  return (
    group.value.home_zip === validated.zipCode &&
    group.value.home_state.toLowerCase() === validated.state.toLowerCase() &&
    group.value.home_city.toLowerCase() === validated.city.toLowerCase() &&
    group.value.home_address.toLowerCase() === validated.firstAddressLine.toLowerCase()
  );
}

function waitAndValidate() {
  setTimeout(() => {
    validate();
  }, 50);
}
</script>
