<template>
  <v-card
    class="b-1 b-radius-8 mb-4"
    data-testid="dashboard-family-subsidy-program"
    elevation="0"
  >
    <v-card-title>
      <v-row>
        <v-col class="d-flex align-center">
          <div>
            <h3 class="fs-20">
              {{ item.name }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-title>

    <v-divider />

    <template v-if="item.description">
      <v-card-text>
        <p>{{ item.description }}</p>
      </v-card-text>

      <v-divider />
    </template>

    <v-card-actions>
      <v-spacer />
      <v-btn
        @click="createFamilySubsidy"
        class="fs-16"
        color="primary"
        role="link"
      >
        {{ $t(item.has_applied ? 'Reapply' : 'Start application') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import baseApi from '@/shared/services/bright_finder';
import { useRouter } from 'vue-router';

const router = useRouter();

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

async function createFamilySubsidy() {
  const resp = await baseApi.parent.family_subsidy.create({
    family_subsidy_program_id: props.item.id,
  });
  if (resp?.status !== 201) return;

  await router.push({ name: 'FamilySubsidyContinue', params: { id: resp.data.id } });
}
</script>
