const subsidyProgramIsOpen = (subsidyProgram) => {
  if (subsidyProgram.opens_at && new Date() < new Date(subsidyProgram.opens_at)) return false;

  if (subsidyProgram.closes_at && new Date() > new Date(subsidyProgram.closes_at)) return false;

  return true;
};

const openSubsidyPrograms = (subsidies, subsidyPrograms) => {
  if (!subsidies || !subsidyPrograms) return [];

  return subsidyPrograms.filter((subsidyProgram) => {
    const submittedCount = subsidies.filter(
      (subsidy) => subsidy.subsidy_program_id === subsidyProgram.id && subsidy.submitted_at,
    ).length;
    if (submittedCount > 0) return false;

    const unsubmittedCount = subsidies.filter(
      (subsidy) =>
        subsidy.subsidy_program_id === subsidyProgram.id &&
        !subsidy.submitted_at &&
        subsidy.projected_eligibility,
    ).length;
    if (unsubmittedCount === 0) return false;

    return subsidyProgramIsOpen(subsidyProgram);
  });
};

export { subsidyProgramIsOpen, openSubsidyPrograms };
