<template>
  <v-row class="bg-super-light-blue d-flex justify-center py-12">
    <v-col
      v-for="(icon, index) in icons"
      :key="index"
      class="px-2 py-8"
      cols="12"
      md="2"
      sm="4"
    >
      <div class="rounded bg-white py-4 mx-auto ta-center w-200 h-200">
        <img
          :alt="$t(icon.text)"
          :src="icon.img"
          classes="fill-cc c-primary"
          height="100"
          width="100"
        />
        <div
          v-t="icon.text"
          class="fw-600 fs-16 mxw-150 mx-auto"
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  compatConfig: { MODE: 2 },

  props: {
    icons: {
      type: Array,
      default: null,
    },
  },
};
</script>
