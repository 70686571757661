<template>
  <v-card
    v-if="providerData"
    class="pa-0"
    border
    flat
    tile
  >
    <v-card-text>
      <v-row class="d-flex align-start">
        <v-col
          v-if="!hideToggle"
          class="py-6"
          cols="1"
        >
          <v-checkbox
            @update:model-value="$emit('toggle', $event)"
            :disabled="!active && !selectable"
            :model-value="active"
            class="py-0 my-0"
            data-cy="selection-checkbox"
            density="compact"
            hide-details
          />
        </v-col>
        <v-col cols="10">
          <div class="c-black">
            <v-row
              class="ma-0 pa-0 d-flex align-center"
              dense
            >
              <v-col cols="11">
                <h4
                  class="c-black fw-600 fs-20"
                  data-cy="selection-name"
                  dense
                >
                  {{ $t(providerData.name) }}
                </h4>
                <div
                  v-if="providerData.license_business_name != providerData.name"
                  class="fs-16"
                >
                  {{ providerData.license_business_name }}
                </div>
              </v-col>
            </v-row>
            <v-row
              class="ma-0 pa-0"
              dense
            >
              <v-col>
                <span class="fs-16 fw-400 me-1">{{
                  [providerData.address1, providerData.city, providerData.zip].join(', ')
                }}</span>
                <span
                  v-if="providerData.distance_from_origin"
                  class="fs-16 fw-400"
                  >- {{ providerData.distance_from_origin }} {{ $t('miles away') }}</span
                >
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <template v-if="providerData.public_notice">
        <v-row class="bg-primary mb-0 c-white">
          <v-col>
            <MarkdownContent
              :content="providerData.public_notice"
              class="fs-14"
            />
          </v-col>
        </v-row>
      </template>
    </v-card-text>
    <template v-if="!hideViewMore">
      <v-card-actions
        @click="$emit('open')"
        @keyup.enter="$emit('open')"
        class="bt-1 pt-2 pb-2 px-4 bc-extra-light-grey pointer"
      >
        <div
          class="fs-14 fw-400"
          data-cy="match-view-more"
        >
          {{ $t('View more information') }}
        </div>
        <v-spacer />
        <v-icon class="fs-22 fw-500"> chevron_right </v-icon>
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
import API from '@/shared/mixins/api';
import MarkdownContent from '@/shared/components/MarkdownContent.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    MarkdownContent,
  },

  mixins: [API],

  props: {
    active: {
      type: Boolean,
      default: false,
    },
    hideToggle: {
      type: Boolean,
      default: false,
    },
    hideViewMore: {
      type: Boolean,
      default: false,
    },
    provider: {
      type: Object,
      default: null,
    },
    providerId: {
      type: String,
      default: null,
    },
    selectable: {
      default: true,
      type: Boolean,
    },
    subsidy: {
      type: Object,
      default: null,
    },
  },

  emits: ['open', 'toggle'],

  data() {
    return {
      providerData: this.provider,
    };
  },

  watch: {
    providerId: {
      immediate: true,
      async handler(newVal) {
        if (newVal) {
          this.providerData = await this.getProviderData();
        }
      },
    },
  },

  methods: {
    async getProviderData() {
      const resp = await this.api.public_api.organization.provider.get(this.providerId);
      return resp.data;
    },
  },
};
</script>
