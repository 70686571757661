<template>
  <div class="h-100pc">
    <v-row
      :class="$vuetify.smAndUp ? 'px-10' : 'px-5'"
      :style="heroStyle"
      class="pt-12 pb-0"
    >
      <v-col>
        <v-row class="mb-6">
          <v-col>
            <h1 :class="introClass">
              {{ $t($store.state.pages.Welcome.intro) }}
            </h1>
          </v-col>
        </v-row>

        <v-row v-if="searchFlags.regionSearchEnabled">
          <v-col class="mxw-1800 mx-auto">
            <v-row class="d-flex justify-center">
              <v-col
                cols="12"
                lg="6"
                md="8"
              >
                <v-card class="py-4 px-6 rounded-pill d-flex align-center mb-16">
                  <v-text-field
                    v-model="search.unformatted_origin"
                    @keyup.enter="submitDisabled ? false : submit()"
                    :class="$vuetify.display.smAndUp ? 'fs-22' : 'fs-16'"
                    :label="$t('Search by address')"
                    class="mt-0 pt-0"
                    hide-details
                  />
                  <v-btn
                    @click="submit"
                    :disabled="submitDisabled"
                    :loading="processing"
                    :ripple="false"
                    :size="$vuetify.display.smAndUp ? 'x-large' : undefined"
                    :style="submitDisabled ? disabled_submit_style : ''"
                    class="focus-very-visible"
                    color="primary"
                    data-cy="submit_welcome"
                    prepend-icon="search"
                    rounded
                  >
                    <span>{{ $t('Submit') }}</span>
                  </v-btn>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <template v-else>
          <v-row v-if="$vuetify.display.mdAndUp">
            <v-col class="mxw-1200 mx-auto">
              <v-row class="d-flex justify-center">
                <v-col
                  cols="12"
                  md="4"
                >
                  <b-location-menu
                    @keyboard.tab="$refs.travel_mode_menu.focus()"
                    ref="location_menu"
                    :autofocus="false"
                    :search="search"
                  />
                </v-col>

                <v-col
                  v-if="searchFlags.transportationOptionsEnabled"
                  cols="12"
                  md="4"
                >
                  <b-travel-mode-menu
                    v-model="search.travel_mode"
                    ref="travel_mode_menu"
                    :travel-mode="selectedTravelMode"
                  />
                </v-col>

                <v-col
                  v-if="searchFlags.dobSearchEnabled"
                  cols="12"
                  md="4"
                >
                  <ChildrenMenuV2
                    @onAddChild="(child) => handleAddChild(child)"
                    @onRemoveChild="(index) => handleRemoveChild(index)"
                    @onUpdateChild="(child, index) => handleChildrenOverride(child, index)"
                    :children="children"
                  />
                </v-col>

                <v-col
                  v-else-if="!searchFlags.defaultAgeGroupEnabled"
                  cols="12"
                  md="4"
                >
                  <ChildrenMenu
                    @decrease="modifyCount($event, -1)"
                    @increase="modifyCount($event, 1)"
                    :age-groups="ageGroups"
                    :sum="sum"
                  />
                </v-col>

                <v-col
                  class="mb-4 ta-center"
                  cols="12"
                >
                  <v-btn
                    @click="submit"
                    :disabled="submitDisabled"
                    :loading="processing"
                    :ripple="false"
                    :style="submitDisabled ? disabled_submit_style : ''"
                    class="focus-very-visible mx-auto w-300"
                    color="primary"
                    data-cy="submit_welcome"
                    prepend-icon="search"
                    size="x-large"
                    rounded
                  >
                    <span>{{ $t('Submit') }}</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row v-else>
            <v-col>
              <v-card class="py-0 mb-4">
                <v-list>
                  <v-list-item
                    @click="openSearchDialog('location')"
                    class="py-1"
                  >
                    <template #prepend>
                      <v-icon color="primary"> location_on </v-icon>
                    </template>
                    <v-list-item-title>
                      <span v-text="search.formatted_origin || $t('Location')"></span>
                    </v-list-item-title>
                    <template #append>
                      <v-btn
                        :ripple="false"
                        aria-label="Click to expand more"
                        size="x-small"
                        variant="text"
                        icon
                      >
                        <v-icon>expand_more</v-icon>
                      </v-btn>
                    </template>
                  </v-list-item>
                  <template v-if="searchFlags.transportationOptionsEnabled">
                    <v-divider class="mx-2" />
                    <v-list-item
                      @click="openSearchDialog('mode')"
                      class="py-1"
                    >
                      <template #prepend>
                        <v-icon color="primary"> directions_car </v-icon>
                      </template>
                      <v-list-item-title>{{ $t(selectedTravelMode) }}</v-list-item-title>
                      <template #append>
                        <v-btn
                          :ripple="false"
                          aria-label="Click to expand more"
                          size="x-small"
                          variant="text"
                          icon
                        >
                          <v-icon>expand_more</v-icon>
                        </v-btn>
                      </template>
                    </v-list-item>
                  </template>
                  <template v-if="!searchFlags.defaultAgeGroupEnabled">
                    <v-divider class="mx-2" />
                    <v-list-item
                      @click="openSearchDialog('children')"
                      class="py-1"
                    >
                      <template #prepend>
                        <v-icon color="primary"> person_outline </v-icon>
                      </template>
                      <v-list-item-title>
                        {{ sum > 0 ? [sum, $t('children')].join(' ') : $t('Children ages') }}
                      </v-list-item-title>
                      <template #append>
                        <v-btn
                          :ripple="false"
                          aria-label="Click to expand more"
                          size="x-small"
                          variant="text"
                          icon
                        >
                          <v-icon>expand_more</v-icon>
                        </v-btn>
                      </template>
                    </v-list-item>
                  </template>
                  <v-divider class="mx-2" />
                </v-list>
                <div class="pa-2">
                  <v-btn
                    @click="submit"
                    :disabled="submitDisabled"
                    :loading="processing"
                    :ripple="false"
                    :style="submitDisabled ? disabled_submit_style : ''"
                    class="w-100pc"
                    color="primary"
                    prepend-icon="search"
                    size="x-large"
                    rounded
                  >
                    {{ $t('Submit') }}
                  </v-btn>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>

    <template
      v-if="
        $store.state.pages.Welcome.sponsor.name ||
        $store.state.pages.Welcome.disclaimers.length > 0 ||
        $store.state.pages.Welcome.partners.length > 0
      "
    >
      <v-row class="bg-white py-8 px-4">
        <v-col
          v-if="$store.state.pages.Welcome.sponsor.name"
          class="pa-6"
          cols="12"
        >
          <div
            v-t="$store.state.pages.Welcome.sponsor.title"
            class="c-black fs-16 fw-500 mb-4 ta-center"
          />
          <div class="d-flex align-start mxw-1000 mx-auto">
            <img
              :alt="$t(`Sponsored by ${$store.state.pages.Welcome.sponsor.name}`)"
              :src="$store.state.pages.Welcome.sponsor.image"
              class="me-4"
              height="40"
            />
            <div
              v-t="$store.state.pages.Welcome.sponsor.text"
              class="fs-16 c-light-black"
            />
          </div>
        </v-col>
        <v-col
          v-if="$store.state.pages.Welcome.disclaimers.length > 0"
          class="pa-6"
          cols="12"
        >
          <div class="mxw-1000 mx-auto">
            <MarkdownContent
              v-for="(item, index) in $store.state.pages.Welcome.disclaimers"
              :key="index"
              :content="item.text"
              class="c-light-black fs-16"
            />
          </div>
        </v-col>
        <v-col
          v-if="$store.state.pages.Welcome.partners.length > 0"
          cols="12"
        >
          <b-partner-roll
            :partners="$store.state.pages.Welcome.partners"
            :title="$store.state.pages.Welcome.partnership_title"
          />
        </v-col>
      </v-row>
    </template>
    <template
      v-if="$store.state.pages.Welcome.programs && $store.state.pages.Welcome.programs.length > 0"
    >
      <v-row class="bg-super-light-blue">
        <v-col class="mxw-1800 mx-auto">
          <b-icon-list :icons="$store.state.pages.Welcome.programs" />
        </v-col>
      </v-row>
    </template>
    <template v-if="$store.state.pages.Welcome.highlights.length > 0">
      <v-row
        v-for="(feature, index) in feature_highlights"
        :key="index"
        class="bg-white pt-6 pb-12 px-6 px-lg-12"
      >
        <v-col class="mxw-1800 mx-auto px-lg-12 px-6">
          <b-feature-highlight
            :img="feature.img"
            :reverse="feature.reverse"
            :subtext="feature.subtext"
            :text="feature.text"
          />
        </v-col>
      </v-row>
    </template>
    <MobileSearchDialog
      @change="search = $event"
      @change:count="modifyCount($event[0], $event[1])"
      @onAddChild="(child) => handleAddChild(child)"
      @onRemoveChild="(index) => handleRemoveChild(index)"
      @onUpdateChild="(child, index) => handleChildrenOverride(child, index)"
      ref="mobileSearchDialog"
      :age-groups="ageGroups"
      :children="children"
    />
    <WelcomeFooter />
  </div>
</template>

<script>
import Api from '@/shared/services/bright_finder';
import ChildrenMenu from '@/parent/components/children/ChildrenMenu.vue';
import ChildrenMenuV2 from '@/parent/components/children/ChildrenMenuV2.vue';
import FeatureHighlight from '@/shared/components/content/feature_highlight.vue';
import IconList from '@/shared/components/content/icon_list.vue';
import LocationMenu from '@/shared/components/form/LocationMenu.vue';
import MarkdownContent from '@/shared/components/MarkdownContent.vue';
import MobileSearchDialog from '@/parent/components/searches/MobileSearchDialog.vue';
import ParentSearchParams from '@/parent/services/parent_search_params';
import PartnerRoll from '@/shared/components/content/partner_roll.vue';
import SearchFlags from '@/parent/services/search-flags';
import TravelModeMenu from '@/shared/components/form/TravelModeMenu.vue';
import WelcomeFooter from '@/shared/components/WelcomeFooter.vue';

const DEFAULT_HIGHLIGHTS = [
  {
    text: 'Find all types of child care, all in one place',
    subtext:
      "Explore physical child care programs based on your location and children's age. Or, explore virtual programs!",
  },
  {
    text: 'Filter to find child care providers that have openings right now',
    subtext:
      'Providers are regularly updating information to showcase available openings. Quickly find providers accepting new families.',
  },
  {
    text: 'Save and create a list of your favorite child care options',
    subtext:
      'Create an account to save your list of favorite child care providers. All you need is your name and email address.',
  },
];

export default {
  compatConfig: { MODE: 3 },

  components: {
    ChildrenMenu,
    ChildrenMenuV2,
    WelcomeFooter,
    MarkdownContent,
    MobileSearchDialog,
    'b-feature-highlight': FeatureHighlight,
    'b-icon-list': IconList,
    'b-location-menu': LocationMenu,
    'b-partner-roll': PartnerRoll,
    'b-travel-mode-menu': TravelModeMenu,
  },

  data() {
    const searchFlags = new SearchFlags(this.$store);

    const children = searchFlags.dobSearchEnabled ? [{ dob: null, care_needed_date: null }] : null;

    return {
      ageGroups: searchFlags.ageGroups,
      children,
      childrenMenu: false,
      disabled_submit_style: 'background-color: #D8D8D8 !important; color: white !important;',
      error: '',
      feature_highlights: this.getFeatureHighlights(),
      location_menu: false,
      mode_menu: false,
      parent: {
        is_anonymous: true,
        is_parent: true,
        organization_id: window.organization_id,
      },
      search: {
        destination: {},
        exclude_closed: searchFlags.closedFilterEnabled,
        location_type: 'home',
        travel_mode: 'DRIVING',
        formatted_origin: null,
        origin: {},
        schema_id: this.getProviderSchemaId(),
        unformatted_origin: null,
        zip: null,
      },
      searchFlags,
      session: {},
      processing: false,
      valid: false,
      childrenV2: true,
    };
  },

  computed: {
    introClass() {
      if (this.$vuetify.display.mdAndUp) {
        return 'fs-40 fw-900 lh-52 c-white mxw-800 mx-auto ta-center';
      }

      return 'fs-24 fw-600 lh-30 c-white mxw-800 mx-auto ta-center';
    },

    childrenDoneDisabled() {
      if (this.searchFlags.regionSearchEnabled) return false;

      let count = 0;

      if (this.searchFlags.dobSearchEnabled) {
        count = this.children?.filter(
          (child) => child.dob !== null && child.care_needed_date !== null,
        ).length;
      } else {
        count = this.ageGroups?.filter((ageGroup) => ageGroup.count > 0).length;
      }

      return count === 0;
    },

    heroStyle() {
      if (this.$store.state.pages.Welcome.hero_style) {
        return this.$store.state.pages.Welcome.hero_style;
      }

      return `background: linear-gradient(to top right, rgba(20, 54, 82, 0.8), rgba(20, 54, 82, 0.8)), url(${this.$store.state.pages.Welcome.hero_image}) center center no-repeat;`;
    },

    locationDoneDisabled() {
      return !this.search.origin || !this.search.origin.place_id;
    },

    selectedTravelMode() {
      return (
        this.$a.assets.transit_options.find((option) => option.value === this.search?.travel_mode)
          .text || 'Transportation method'
      );
    },

    submitDisabled() {
      return (
        this.childrenDoneDisabled ||
        !this.search.travel_mode ||
        (!this.search.unformatted_origin && !this.search.formatted_origin)
      );
    },

    sum() {
      if (this.searchFlags.dobSearchEnabled) {
        return this.children.filter(
          (child) => child.dob !== null && child.care_needed_date !== null,
        ).length;
      }

      return this.ageGroups.reduce((total, ageGroup) => total + ageGroup.count, 0);
    },
  },

  emits: ['click'],

  watch: {
    childrenMenu(newVal) {
      if (newVal) {
        this.focusFirstChildVolume();
      } else {
        this.$refs.childrenMenuActivator.focus();
      }
    },
  },
  created() {
    this.$store.dispatch('identify');
  },

  methods: {
    handleAddChild(child) {
      // We need to duplicate the child object otherwise the equality check on remove has false positive
      this.children.push({ ...child });
    },

    handleRemoveChild(index) {
      this.children = this.children.filter((child) => child !== this.children[index]);
    },

    handleChildrenOverride(child, index) {
      this.children.splice(index, 1, child);
    },

    getChildren() {
      let children = this.ageGroups?.filter((ageGroup) => ageGroup.count > 0) || [];

      if (this.searchFlags.regionSearchEnabled && !this.searchFlags.defaultAgeGroupEnabled) {
        children = [];
      } else if (this.searchFlags.dobSearchEnabled) {
        children = this.children;
      }

      return children;
    },

    getFeatureHighlights() {
      return this.$store.state.pages.Welcome.highlights.map((highlight, index) => ({
        img: highlight.img,
        text: highlight.text || DEFAULT_HIGHLIGHTS[0].text,
        subtext: highlight.subtext || DEFAULT_HIGHLIGHTS[0].subtext,
        reverse: index % 2 !== 0,
      }));
    },

    getProviderSchemaId() {
      const schema = Object.values(this.$store.state.schemas).filter(
        (schema) => schema.data_type === 'Provider',
      )[0];
      if (schema) {
        return schema.id;
      }

      return null;
    },

    modifyCount(index, addition) {
      this.ageGroups[index].count += addition;
    },

    openSearchDialog(mode) {
      this.$refs.mobileSearchDialog.open(mode, this.search);
    },

    submit() {
      this.processing = true;
      this.$store.dispatch('identify', {
        success: this.submitSearch,
        failure: this.submitAndCreateMember,
      });
    },

    submitSearch() {
      const params = new ParentSearchParams(this.parent, this.search, this.getChildren()).asJson();

      Api.search.create(
        params.search,
        (resp) => {
          this.$store.commit('setSearch', resp.data);
          this.$router.push({ name: 'SearchShow', params: { searchId: resp.data.id } });
        },
        (err) => {
          if (err.response && err.response.data && err.response.data.errors) {
            this.$eventBus.$emit('chime', err.response.data.errors[0]);
          } else {
            this.$eventBus.$emit('chime', 'Unknown error occurred');
          }
          this.processing = false;
        },
      );
    },

    submitAndCreateMember() {
      this.parent.default_locale = this.$i18n?.locale;

      const params = new ParentSearchParams(this.parent, this.search, this.getChildren()).asJson();

      Api.member.create(
        params,
        (resp) => {
          const vm = this;
          this.$store.dispatch('identify', {
            profile: resp.data,
            success() {
              vm.submitSearch();
            },
          });
        },
        (err) => {
          this.$eventBus.$emit('error', err);
          this.processing = false;
        },
      );
    },
  },
};
</script>

<style scoped>
.bg-hero {
  background-size: cover;
}

.lh-30 {
  line-height: 30px;
}

.mxw-1000 {
  max-width: 1000px;
}
</style>
