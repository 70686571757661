<template>
  <v-menu
    ref="accountMenu"
    location="bottom left"
    role="mainmenu"
    open-on-click
  >
    <template #activator="{ props }">
      <v-btn
        v-bind="props"
        :aria-label="$t('Account menu')"
        class="c-black focus-visible"
        variant="text"
        rounded
      >
        <v-icon
          class="c-black material-icons-round"
          data-cy="click_menu"
        >
          menu
        </v-icon>
        <v-badge
          :aria-label="$t('Badge')"
          :model-value="$store.state.newMessage"
          dot
        >
          <v-icon v-if="isLoggedIn"> account_circle </v-icon>
        </v-badge>
      </v-btn>
    </template>

    <v-list>
      <v-list-item
        v-if="isLoggedIn"
        id="dashboard_link"
        :to="{ name: 'Dashboard' }"
        class="fw-500 fs-16 c-black d-flex ta-start"
      >
        <span v-t="'Dashboard'" />
      </v-list-item>

      <v-list-item
        v-if="isLoggedIn"
        :to="{ name: 'MemberProfile' }"
        class="fw-500 fs-16 c-black ta-start"
      >
        <span
          v-t="'My profile'"
          data-cy="my_messages"
        />
      </v-list-item>

      <v-list-item
        v-if="isLoggedIn && $store.state.pages.Messaging.enabled"
        :to="{ name: 'MessageIndex' }"
        class="fw-500 fs-16 c-black ta-start"
      >
        <v-badge
          :model-value="$store.state.newMessage"
          dot
        >
          <span
            v-t="'My messages'"
            data-cy="my_messages"
          />
        </v-badge>
      </v-list-item>

      <v-list-item
        v-if="isLoggedIn && $store.state.pages.Navigation.settings_url"
        id="settings_link"
        :href="$store.state.pages.Navigation.settings_url"
        class="fw-500 fs-16 c-black ta-start"
      >
        {{ $t('Settings') }}
      </v-list-item>

      <v-list-item
        v-if="isLoggedIn && $store.state.site.features.enable_registration"
        id="subscriptions_link"
        :to="{ name: 'Subscriptions' }"
        class="fw-500 fs-16 c-black ta-start"
      >
        {{ $t('Email preferences') }}
      </v-list-item>

      <v-list-item
        v-if="!isLoggedIn"
        id="signup_link"
        :to="{ name: 'Signup', query: { mode: 'login' } }"
        class="fs-16 fw-500 c-black ta-start"
      >
        {{ $t('Login') }}
      </v-list-item>

      <v-list-item
        v-if="$store.state.pages.Navigation.parent_faq_url"
        id="faq_link"
        :href="$store.state.pages.Navigation.parent_faq_url"
        class="fs-16 fw-500 c-black ta-start"
        target="_blank"
        tracked
      >
        {{ $t('FAQ') }}
      </v-list-item>

      <v-list-item
        v-if="$store.state.pages.Navigation.link_1_name"
        id="link_1_link"
        :href="$store.state.pages.Navigation.link_1_url"
        class="fs-16 fw-500 c-black ta-start"
        target="_blank"
      >
        {{ $t($store.state.pages.Navigation.link_1_name) }}
      </v-list-item>

      <v-list-item
        v-if="$store.state.pages.Navigation.link_2_name"
        id="link_2_link"
        :href="$store.state.pages.Navigation.link_2_url"
        class="fs-16 fw-500 c-black ta-start"
        target="_blank"
      >
        {{ $t($store.state.pages.Navigation.link_2_name) }}
      </v-list-item>

      <v-list-item
        v-if="$store.state.pages.Navigation.menu_link_1_name"
        id="menu_link_1_link"
        :href="$store.state.pages.Navigation.menu_link_1_url"
        class="fs-16 fw-500 c-black ta-start"
        target="_blank"
      >
        {{ $t($store.state.pages.Navigation.menu_link_1_name) }}
      </v-list-item>

      <v-list-item
        v-if="isAuthenticated && $store.state.site.parent_support_email"
        id="menu_report_issue"
        :href="'mailto:' + $store.state.site.parent_support_email"
        class="fs-16 fw-500 c-black ta-start"
        target="_blank"
        tracked
      >
        {{ $t('Report Issue') }}
      </v-list-item>

      <v-list-item
        id="menu_terms"
        class="fs-16 fw-500 c-black ta-start"
        href="https://www.getbridgecare.com/terms-of-service"
        target="_blank"
        tracked
      >
        {{ $t('Terms of Service') }}
      </v-list-item>

      <v-list-item
        class="fs-16 fw-500 c-black ta-start"
        href="https://www.getbridgecare.com/privacy-policy"
        target="_blank"
        tracked
      >
        {{ $t('Privacy Policy') }}
      </v-list-item>

      <v-list-item
        v-if="isAuthenticated"
        @click="$emit('sign-out')"
        id="account_menu_signout"
        class="fs-16 fw-500 c-black ta-start"
        tracked
      >
        {{ isAnonymous ? $t('Clear session') : $t('Sign out') }}
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script setup>
import { useStore } from 'vuex';

defineEmits(['sign-out']);

const store = useStore();

const isAnonymous = computed(() => store.state.is_anonymous);
const isAuthenticated = computed(() => store.state.is_authenticated);
const isLoggedIn = computed(() => isAuthenticated.value && !isAnonymous.value);
</script>
