<template>
  <v-container>
    <ResourceDialog
      @save="save"
      ref="emailDialog"
      :cancellable="false"
      :closeable="false"
      :fields="fields"
      :max-width="800"
      :processing="processing"
      title="Add your email to continue"
      center-title
    />
  </v-container>
</template>

<script>
import API from '@/shared/mixins/api';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    ResourceDialog,
  },

  mixins: [API],

  data() {
    return {
      email: null,
      fields: [
        { text: 'Email', value: 'email' },
        { text: 'Confirm your email', value: 'confirmEmail' },
      ],
      processing: false,
    };
  },

  mounted() {
    this.$refs.emailDialog.open({});
  },

  methods: {
    async save(newVal) {
      if (newVal.email !== newVal.confirmEmail) {
        this.$eventBus.$emit('error', ['Emails do not match']);
        return;
      }

      if (!newVal.email) {
        this.$eventBus.$emit('error', ['Please fill out all fields']);
        return;
      }

      let token = null;
      this.processing = true;
      if (import.meta.env.VUE_APP_RECAPTCHA_SITE_KEY) {
        await this.$recaptchaLoaded();
        token = await this.$recaptcha('login');
      }

      const resp = await this.api.member.profile
        .update({
          email: newVal.email,
          token,
        })
        .catch((error) => this.$eventBus.$emit('error', error));
      this.processing = false;
      if (!resp?.data) return;

      this.$store.commit('setProfile', null);
      this.$store.commit('setAuthenticated', false);
      this.$router.replace({
        name: 'CodeSent',
        query: { token: resp.data.code_id, email_sent_to: newVal.email },
      });
    },
  },
};
</script>
