<template>
  <template v-if="unsignedAgreements.length > 0">
    <v-alert
      border="start"
      border-color="primary"
      color="surface"
      type="info"
      tile
    >
      <template #prepend>
        <v-icon
          class="me-3"
          color="primary"
          size="24"
        >
          info
        </v-icon>
      </template>
      <template #default>
        {{ unsignedAgreementsText }}
      </template>
      <template #append>
        <v-btn
          @click="unsignedAgreementsDialog.open()"
          aria-label="Open agreement alerts"
          color="primary"
          variant="text"
          icon
        >
          <v-icon>edit_document</v-icon>
        </v-btn>
      </template>
    </v-alert>
  </template>

  <ResourceDialog
    @save="openAgreementDialog(agreement)"
    ref="unsignedAgreementsDialog"
    :max-width="600"
    :processing="processing"
    save-button-text="Open"
    title="Review and sign agreements"
    closeable
    hide-actions
  >
    <template #append>
      <AgreementCard
        v-for="agreement in unsignedAgreements"
        @change="loadUnsignedAgreements"
        @destroy="loadUnsignedAgreements"
        @open="openAgreementDialog(agreement)"
        :key="agreement.id"
        :agreement="agreement"
      />
    </template>
  </ResourceDialog>

  <AgreementDialog
    @change="loadUnsignedAgreements"
    ref="signAgreementDialog"
    :processing="processing"
    role="parent"
  />
</template>

<script setup>
import AgreementCard from '@/parent/components/AgreementCard.vue';
import AgreementDialog from '@/shared/components/AgreementDialog.vue';
import useRouterHelper from '@/shared/composables/useRouterHelper';
import api from '@/shared/services/bright_finder';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import { useStore } from 'vuex';
const { updateQuery } = useRouterHelper();

const store = useStore();
const processing = ref(false);
const signAgreementDialog = ref(null);
const unsignedAgreements = ref([]);
const unsignedAgreementsDialog = ref(null);

async function openAgreementDialog(agreement) {
  await updateQuery({ agreementId: agreement.id });
}

const unsignedAgreementsText = computed(() => {
  if (!unsignedAgreements.value.length) return '';
  if (unsignedAgreements.value.length === 1)
    return '1 agreement is ready for review and needs your action.';

  return `${unsignedAgreements.value.length} agreements are ready for review and need your action.`;
});

async function loadUnsignedAgreements() {
  const resp = await api.parent.agreement.index({
    member_id: store.state.profile.id,
  });
  unsignedAgreements.value = resp.data.filter((agreement) => agreement.status === 'Sent');
}

onMounted(loadUnsignedAgreements);
</script>
