<template>
  <div v-if="loaded">
    <ManageSubsidyHeader
      :submitted-at="subsidy.submitted_at"
      :subtitle="subsidyProgram.name"
      :subtitle-subtext="subsidyProgram.description"
      :title="subsidy.child_application_data.name"
    />

    <v-container>
      <template v-if="subsidyProgram.enable_deferred_acceptance && offerCount > 0">
        <div class="mb-12">
          <h2 class="mb-4">{{ $t('Offers') + ' 🎉' }}</h2>
          <template v-if="childSchema.meta.parent_layout?.enrollments_instructions">
            <v-card
              class="bg-primary mb-4"
              border
              flat
              tile
            >
              <v-card-text class="c-white fs-16 fw-500 markdown">
                <MarkdownContent
                  :content="childSchema.meta.parent_layout.enrollments_instructions"
                />
              </v-card-text>
            </v-card>
          </template>
          <ManageChildSubsidyOfferList
            v-model:processing="processing"
            @changed="handleEnrollmentChange"
            ref="offerList"
            :childData="child"
            :subsidyProgram="subsidyProgram"
            data-testid="offer-list"
          />
        </div>
      </template>

      <template v-if="displayPreferencesSection">
        <div class="mb-12">
          <v-row>
            <v-col>
              <h2 class="mb-4">{{ $t('Preferences') }}</h2>
            </v-col>
            <v-col
              v-if="allowAddPrograms"
              class="text-right"
            >
              <v-btn
                @click="() => $refs.childSubsidyPreferenceList.openAddProgramDialog()"
                color="primary"
              >
                <v-icon start> add </v-icon>
                <span v-t="$t('Add provider')" />
              </v-btn>
            </v-col>
          </v-row>
          <ChildSubsidyPreferenceList
            v-model:processing="processing"
            @changed="handleEnrollmentChange"
            ref="childSubsidyPreferenceList"
            :childData="child"
            :childSchema="childSchema"
            :showAddButton="false"
            :showInstructions="false"
            :subsidy="subsidy"
            :subsidyProgram="subsidyProgram"
            data-testid="preference-list"
          />
        </div>
      </template>

      <h2 class="mb-4">{{ $t('Application') }}</h2>
      <template v-if="childSchema.meta.parent_layout?.applications_instructions">
        <v-card
          class="bg-primary mb-4"
          border
          flat
          tile
        >
          <v-card-text class="c-white fs-16 fw-500 markdown">
            <MarkdownContent :content="childSchema.meta.parent_layout.applications_instructions" />
          </v-card-text>
        </v-card>
      </template>
      <v-card
        class="b-1"
        elevation="0"
        tile
      >
        <ChildSubsidyReview :value="subsidy" />
      </v-card>
    </v-container>
  </div>
  <v-progress-linear
    v-else
    indeterminate
  />
</template>

<script setup>
import Api from '@/shared/services/bright_finder';
import ChildSubsidyPreferenceList from '@/parent/components/subsidy/ChildSubsidyPreferenceList.vue';
import { ENROLLMENT_STATUSES } from '@/shared/assets/constants';
import ManageSubsidyHeader from '@/parent/components/subsidy/ManageSubsidyHeader.vue';
import ManageChildSubsidyOfferList from '@/parent/components/subsidy/ManageChildSubsidyOfferList.vue';
import MarkdownContent from '@/shared/components/MarkdownContent.vue';
import ChildSubsidyReview from '@/parent/views/child-subsidies/ChildSubsidyReview.vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

const route = useRoute();
const store = useStore();

const allowAddPrograms = ref(false);
const enrollmentCount = ref(0);
const offerCount = ref(0);
const child = ref(null);
const loadEnrollmentsFinished = ref(false);
const processing = ref(false);
const subsidy = ref(null);
const subsidyProgram = ref(null);
const childSubsidyPreferenceList = ref(null);
const offerList = ref(null);

const childSchema = computed(() => store.state.schemas[child.value?.schema_id]);
const loaded = computed(
  () => child.value && subsidy.value && subsidyProgram.value && loadEnrollmentsFinished.value,
);
const displayPreferencesSection = computed(
  () =>
    subsidy.value?.locked &&
    enrollmentCount.value > 0 &&
    (allowAddPrograms.value || subsidyProgram.value.enable_parent_enrollments_review),
);

function addProgramsAllowed(enrollments) {
  if (
    subsidyProgram.value?.enable_iep_direct_placement &&
    subsidy.value?.child_application_data?.individualized_education_plan
  ) {
    return false;
  }

  if (subsidy.value?.preferences_unlocked_at) return true;
  if (!subsidyProgram.value.enable_automatic_preference_unlock) return false;

  // Handle when automatic preferences unlock is enabled
  if (enrollments.length === 0) return true;

  if (!enrollments.some((enrollment) => enrollment.prevents_automatic_preference_unlock))
    return true;

  return false;
}

async function load() {
  processing.value = true;
  const { data: subsidyData } = await Api.subsidy.get(route.params.subsidyId);
  subsidy.value = subsidyData;

  const { data: subsidyProgramData } = await Api.public_api.organization.subsidy_program.get(
    subsidy.value.subsidy_program_id,
  );
  subsidyProgram.value = subsidyProgramData;

  await loadEnrollments();
  await loadChild();

  loaded.value = true;
  processing.value = false;
}

async function loadEnrollments() {
  const resp = await Api.parent.enrollment.index({ child_id: subsidy.value.child_id });

  if (resp?.data) {
    const enrollments = resp.data;
    const filteredEnrollments = enrollments.filter(
      (enrollment) => enrollment.subsidy_program_id === subsidyProgram.value.id,
    );

    allowAddPrograms.value = addProgramsAllowed(filteredEnrollments);

    const filteredOffers = filteredEnrollments.filter(
      (enrollment) =>
        enrollment.public_status === ENROLLMENT_STATUSES.OFFERED ||
        enrollment.public_status === ENROLLMENT_STATUSES.ACCEPTED,
    );

    offerCount.value = filteredOffers.length;

    enrollmentCount.value = filteredEnrollments.length;
  }
  loadEnrollmentsFinished.value = true;
}

async function handleEnrollmentChange() {
  await loadEnrollments();
  childSubsidyPreferenceList.value.loadEnrollments();
  offerList.value?.loadOffers();
}

async function loadChild() {
  const resp = await Api.child.get(subsidy.value.child_id);
  child.value = resp.data;
}

onMounted(async () => load());
</script>
