<template>
  <v-card
    id="dob-children"
    class="card-height card-width pt-5"
  >
    <v-card-text
      v-for="(child, index) in validatedChildren"
      :key="index"
      :data-testid="`child-${index}`"
      class="pb-3"
    >
      <div
        v-if="index !== 0"
        class="card-break"
      />
      <v-row
        :class="`justify-space-around pl-2 pr-2 ${isMobile ? 'mt-2 flex-column' : 'align-center'}`"
      >
        <v-text-field
          @click="handleDobInputClick(index)"
          :class="textFieldClasses"
          :error="!child.dob && doneClicked"
          :model-value="child.dob"
          append-icon="expand_more"
          bg-color="white"
          data-testid="child_dob"
          label="Date of birth"
          variant="outlined"
          readonly
        />

        <v-text-field
          @click="handleCareNeededDateInputClick(index)"
          :class="`${textFieldClasses} ${isRemovableClasses}`"
          :error="!child.care_needed_date && doneClicked"
          :model-value="child.care_needed_date"
          append-icon="expand_more"
          bg-color="white"
          data-testid="child_date_care_needed"
          label="Date care needed"
          variant="outlined"
          readonly
        />

        <v-btn
          v-if="children.length > 1"
          @click="$emit('onRemoveChild', index)"
          :class="`centered delete-button ${isMobile ? 'align-start mr-0' : ''}`"
          variant="text"
          icon
        >
          <v-icon class="pt-0 pb-3"> delete </v-icon>
        </v-btn>
      </v-row>
      <v-date-picker
        v-if="calendarActive(child)"
        @update:model-value="(date) => handleDateSelection(date, index)"
        :model-value="pickerValue(index)"
        :width="calendarWidth"
        data-testid="child-date-picker"
      />
    </v-card-text>
    <v-card-actions
      v-if="hasValidChild.length >= 1"
      class="child-actions"
    >
      <v-btn
        @click="addChild"
        :ripple="false"
        class="focus-very-visible"
        color="primary"
        data-testid="add-child-btn"
        variant="outlined"
      >
        {{ $t('Add another child') }}
      </v-btn>
    </v-card-actions>

    <div
      v-if="isInvalid"
      class="pl-4 pr-4 pb-3 d-flex justify-center text-red"
    >
      Please complete required fields
    </div>
  </v-card>
</template>

<script>
import childrenSearchParam from '@/parent/services/children-search-param';
import SearchFlags from '@/parent/services/search-flags';
import {
  newUtcDate,
  dateTimeToIso8601,
  iso8601DateStringToLocalDateTime,
} from '@/shared/services/date-helper';

const DOB_PICKER = 'dob';
const CARE_NEEDED_PICKER = 'care_needed_date';

export default {
  compatConfig: { MODE: 2 },

  props: {
    children: {
      type: Array,
      default() {
        return [{ dob: null, care_needed_date: null }];
      },
    },
    defaultAgeGroups: {
      type: Array,
      default() {
        return [];
      },
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['onAddChild', 'onRemoveChild', 'onUpdateChild'],

  data() {
    return {
      doneClicked: false,
      ageGroupsModified: this.ageGroups,
      today: new Date(),
      validatedChildren: [
        {
          dob: null,
          care_needed_date: null,
          valid: false,
          activePicker: null,
        },
      ],
    };
  },

  computed: {
    hasValidChild() {
      return this.validatedChildren.filter((child) => child.dob && child.care_needed_date);
    },

    isInvalid() {
      return (
        this.doneClicked &&
        this.validatedChildren.filter((child) => child.valid === false).length >= 1
      );
    },

    isRemovableClasses() {
      return this.validatedChildren.length > 1 && !this.isMobile ? 'use-removeable-spacing' : '';
    },

    calendarWidth() {
      return this.isMobile ? '100%' : '405';
    },

    textFieldClasses() {
      return this.isMobile ? 'custom-caret-placement' : 'search-input-width custom-caret-placement';
    },
  },

  watch: {
    children: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.validateChildren(newVal);
      },
    },
  },

  methods: {
    formatDatetoShort(date) {
      return dateTimeToIso8601(date);
    },

    calendarActive(child) {
      return child.activePicker !== null && child.activePicker !== undefined;
    },

    handleDoneClick() {
      // Called from parent component via ref
      this.doneClicked = true;
      return !this.isInvalid;
    },

    validateChildren(children) {
      this.validatedChildren = children.map((child) => this.validateChild(child));
    },

    validateChild(child) {
      if (child.dob && child.care_needed_date) {
        return { ...child, valid: true, activePicker: null };
      }

      return { ...child, valid: false };
    },

    pickerValue(index) {
      const child = this.validatedChildren[index];

      switch (child.activePicker) {
        case DOB_PICKER:
          return child?.dob ? iso8601DateStringToLocalDateTime(child?.dob) : null;
        case CARE_NEEDED_PICKER:
          return child?.care_needed_date
            ? iso8601DateStringToLocalDateTime(child?.care_needed_date)
            : null;
        default:
          return null;
      }
    },

    handleDobInputClick(index) {
      const child = this.validatedChildren[index];
      const newChild = { ...child, activePicker: DOB_PICKER };

      this.validatedChildren.splice(index, 1, newChild);
    },

    handleCareNeededDateInputClick(index) {
      const child = this.validatedChildren[index];
      const newChild = { ...child, activePicker: CARE_NEEDED_PICKER };

      this.validatedChildren.splice(index, 1, newChild);
    },

    handleDateSelection(date, index) {
      const child = this.validatedChildren[index];

      switch (child.activePicker) {
        case DOB_PICKER:
          this.handleDobChange(date, index);
          break;
        case CARE_NEEDED_PICKER:
          this.handleCareNeededDateSelection(date, index);
          break;
        default:
          break;
      }
    },

    handleCareNeededDateSelection(date, index) {
      const child = this.validatedChildren[index];
      child.care_needed_date = dateTimeToIso8601(date);

      this.handleChildChange(child, index);
    },

    handleDobChange(date, index) {
      const child = this.validatedChildren[index];
      const isoDate = dateTimeToIso8601(date);
      child.dob = isoDate;

      if (newUtcDate(date) < newUtcDate(this.today)) {
        // Child DOB is in the past, default care needed date to today
        child.care_needed_date = dateTimeToIso8601(this.today);
      }

      if (newUtcDate(date) >= newUtcDate(this.today)) {
        // Child DOB is in the future, default care needed date to DOB
        child.care_needed_date = isoDate;
      }

      this.$emit('onUpdateChild', child, index);
      this.handleChildChange(child, index);
    },

    handleChildChange(child, index) {
      if (!child.dob || !child.care_needed_date) return;

      const updatedChild = childrenSearchParam({
        searchFlags: new SearchFlags(this.$store),
        child,
        careNeededDate: child.care_needed_date,
        toArray: false,
      });

      this.$emit('onUpdateChild', updatedChild, index);
    },

    addChild() {
      const previousChild = this.validatedChildren.at(-1);

      const newChild = {
        dob: null,
        care_needed_date: previousChild?.care_needed_date || null,
      };

      // Reset the validations that show on "done" button click
      this.doneClicked = false;
      this.$emit('onAddChild', newChild);
    },
  },
};
</script>

<style>
.card-height {
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: none !important;
}

.card-width {
  min-width: 440px;
}

.card-break {
  position: relative;
  top: -24px;
}

.child-actions {
  padding-top: 0px;
  padding-bottom: 0px;
}

.no-wrap {
  flex-wrap: nowrap;
  align-items: center;
}

.delete-button {
  position: relative;
  top: -10px;
}

.use-removeable-spacing {
  margin-left: 10px !important;
}

.custom-caret-placement {
  position: relative;
}

.custom-caret-placement .v-input__append {
  position: absolute;
  top: 18px;
  right: 10px;
}

#dob-children .v-input--is-label-active .v-input__prepend-outer {
  top: -10px;
  position: relative;
}

.search-input-width {
  max-width: 200px;
}

@media only screen and (max-width: 1450px) {
  .care-needed-picker {
    left: unset !important;
  }
}
</style>
