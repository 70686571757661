<template>
  <div v-if="loaded">
    <template v-if="showInstructions">
      <template v-if="childSchema.meta.parent_layout?.enrollments_instructions">
        <v-card
          class="bg-primary mb-4"
          border
          flat
          tile
        >
          <v-card-text class="c-white fs-16 fw-500 markdown">
            <MarkdownContent :content="childSchema.meta.parent_layout.enrollments_instructions" />
          </v-card-text>
        </v-card>
      </template>

      <v-divider class="my-4" />
    </template>

    <template v-if="allowAddPrograms === true">
      <template v-if="showAddButton === true">
        <v-row class="mb-2">
          <v-col class="d-flex justify-start">
            <v-btn
              @click="openAddProgramDialog"
              color="primary"
              size="x-large"
            >
              <v-icon start> add </v-icon>
              <span v-t="'Add providers'" />
            </v-btn>
          </v-col>
        </v-row>
      </template>

      <AddProgramSelectionDialog
        @save="addPrograms"
        ref="addProgramSelectionDialog"
        :count-enrollments-toward-preferences-limit="countEnrollmentsTowardPreferencesLimit"
        :dob="childData.dob"
        :processing="processing"
        :program-ids="subsidyProgramIds"
        :subsidy-program="subsidyProgram"
      />
    </template>

    <EnrollmentCard
      v-for="(enrollment, index) in enrollments"
      @change:status="updateEnrollmentStatus(enrollment, $event)"
      @move="reorder(index, $event)"
      @reload="loadEnrollments"
      @remove="remove(index)"
      :key="enrollment.id"
      :enrollment="enrollment"
      :index="index"
      :length="enrollments.length"
      :processing="processing"
      :removable="getRemovable(enrollment)"
      :reorderable="!!subsidy.preferences_unlocked_at"
      :subsidy-program="subsidyProgram"
      class="mb-4"
      ordered
      outlined
    />
  </div>
</template>

<script setup>
import AddProgramSelectionDialog from '@/parent/components/AddProgramSelectionDialog.vue';
import Api from '@/shared/services/bright_finder';
import EnrollmentCard from '@/parent/components/EnrollmentCard.vue';
import MarkdownContent from '@/shared/components/MarkdownContent.vue';
import useEventBus from '@/shared/composables/useEventBus';

const emit = defineEmits(['changed']);

const eventBus = useEventBus();

defineExpose({ loadEnrollments, openAddProgramDialog });

const props = defineProps({
  childSchema: {
    type: Object,
    required: true,
  },
  childData: {
    type: Object,
    required: true,
  },
  showAddButton: {
    type: Boolean,
    default: true,
  },
  showInstructions: {
    type: Boolean,
    default: true,
  },
  subsidy: {
    type: Object,
    required: true,
  },
  subsidyProgram: {
    type: Object,
    required: true,
  },
});

const processing = defineModel('processing', {
  type: Boolean,
  default: false,
});

const addProgramSelectionDialog = ref(null);
const allowAddPrograms = ref(false);
const loadEnrollmentsFinished = ref(false);
const countEnrollmentsTowardPreferencesLimit = ref(0);
const enrollments = ref([]);
const subsidyProgramIds = ref(props.subsidy?.program_ids);

const loaded = computed(
  () =>
    props.subsidy?.locked &&
    props.subsidyProgram?.enable_parent_enrollments_review &&
    loadEnrollmentsFinished.value,
);

async function addPrograms(newVals) {
  processing.value = true;
  await saveProgramIds(subsidyProgramIds.value.concat(newVals));
  await loadEnrollments();
  addProgramSelectionDialog.value.close();
  emit('changed');
  processing.value = false;
}

function addProgramsAllowed() {
  if (
    props.subsidyProgram?.enable_iep_direct_placement &&
    props.subsidy?.child_application_data?.individualized_education_plan
  ) {
    return false;
  }

  if (props.subsidy?.preferences_unlocked_at) return true;
  if (!props.subsidyProgram.enable_automatic_preference_unlock) return false;

  // Handle when automatic preferences unlock is enabled
  if (enrollments.value.length === 0) return true;

  if (!enrollments.value.some((enrollment) => enrollment.prevents_automatic_preference_unlock))
    return true;

  return false;
}

function getRemovable(enrollment) {
  const unlockedAt = props.subsidy?.preferences_unlocked_at
    ? new Date(props.subsidy.preferences_unlocked_at)
    : null;

  if (!props.subsidy.locked || !unlockedAt) return false;
  if (enrollment.public_status !== 'Selected') return false;
  return true;
}

async function loadEnrollments() {
  const resp = await Api.parent.enrollment.index({ child_id: props.childData.id });

  if (resp?.data) {
    const items = resp.data;
    const filteredItems = items
      .filter((enrollment) => enrollment.subsidy_program_id === props.subsidyProgram?.id)
      .sort((a, b) => a.preference_order - b.preference_order);

    enrollments.value = filteredItems;
    allowAddPrograms.value = addProgramsAllowed();
  }

  const countResponse = await Api.parent.enrollment.index({
    child_id: props.childData.id,
    count: true,
    subsidy_id: props.subsidy?.id,
  });

  if (countResponse?.data) {
    countEnrollmentsTowardPreferencesLimit.value = countResponse.data.count;
  }
  loadEnrollmentsFinished.value = true;
}

function openAddProgramDialog() {
  addProgramSelectionDialog.value.open();
}

async function remove(fromIndex) {
  processing.value = true;
  subsidyProgramIds.value.splice(fromIndex, 1);
  await saveProgramIds(subsidyProgramIds.value);
  await loadEnrollments();
  emit('changed');
  processing.value = false;
}

async function reorder(fromIndex, change) {
  processing.value = true;
  const element = subsidyProgramIds.value[fromIndex];
  subsidyProgramIds.value.splice(fromIndex, 1);
  subsidyProgramIds.value.splice(fromIndex + change, 0, element);
  await saveProgramIds(subsidyProgramIds.value);
  await loadEnrollments();
  emit('changed');
  processing.value = false;
}

async function saveProgramIds(newVals) {
  const res = await Api.subsidy
    .update(props.subsidy.id, { program_ids: newVals })
    .catch((error) => {
      eventBus.error(error);
    });

  if (!res?.data) return;

  subsidyProgramIds.value = res.data.program_ids;
}

async function updateEnrollmentStatus(enrollment, newVal) {
  processing.value = true;
  await Api.parent.enrollment_status
    .update(enrollment.id, newVal)
    .catch((error) => eventBus.error(error));

  await loadEnrollments();
  emit('changed');
  processing.value = false;
}

onMounted(async () => {
  await loadEnrollments();
});
</script>
