<template>
  <ResourceDialog
    @save="save"
    ref="dialog"
    :max-width="800"
    :processing="processing"
    text-class="px-0 mb-0 pb-0"
    title="Add providers"
    closeable
    fullscreen
  >
    <template #close>
      <v-btn
        @click="close"
        :loading="processing"
        class="me-2"
        color="primary"
        variant="outlined"
      >
        <span v-t="'Cancel'" />
      </v-btn>

      <v-btn
        @click="save"
        :loading="processing"
        color="primary"
      >
        <span v-t="'Save'" />
      </v-btn>
    </template>

    <template #form>
      <v-card
        flat
        tile
      >
        <v-card-text class="py-0 px-0">
          <div class="bg-super-light-blue px-4">
            <div class="py-2">
              <v-tabs
                v-model="searchTab"
                @update:model-value="resetResults"
                bg-color="transparent"
                class="mb-2"
              >
                <v-tab
                  class="ls-normal tt-none fs-16 fw-500"
                  data-cy="address-mode-button"
                >
                  {{ $t('Search by address') }}
                </v-tab>
                <v-tab
                  class="ls-normal tt-none fs-16 fw-500"
                  data-cy="address-mode-button"
                >
                  {{ $t('Search by name') }}
                </v-tab>
              </v-tabs>

              <v-card flat>
                <v-row no-gutters>
                  <v-col class="d-flex flex-grow-1 flex-shrink-0">
                    <v-text-field
                      v-model="searchQuery"
                      @keydown.enter="searchProviders"
                      @update:model-value="searchQueryChanged = true"
                      id="search_input"
                      ref="search"
                      :aria-label="searchTab == 0 ? $t('Search by address') : $t('Search by name')"
                      class="b-radius-0 elevation-0"
                      data-cy="query-input"
                      prepend-inner-icon="search"
                      variant="solo"
                      flat
                      hide-details
                      tile
                    />
                  </v-col>
                  <v-col class="d-flex justify-end flex-grow-0 flex-shrink-0">
                    <v-btn
                      @click="searchProviders"
                      :disabled="!searchQueryChanged"
                      :height="58"
                      :loading="searching"
                      :ripple="false"
                      class="focus-very-visible"
                      color="primary"
                      data-cy="search-button"
                      data-testid="search-button"
                      size="x-large"
                      tabindex="0"
                      tile
                    >
                      <span v-t="'Search'" />
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>

              <template v-if="subsidyProgram.selection_limit > 0">
                <v-row class="mb-3 mt-0">
                  <v-col>
                    <span class="fs-16 fw-800 c-primary">{{
                      $t(`You can select ${subsidyProgram.selection_limit} total options`)
                    }}</span>
                  </v-col>
                </v-row>
              </template>

              <template v-if="matches.length == 0">
                <p
                  class="fs-16 mt-2"
                  data-cy="results-hint"
                >
                  <span
                    v-t="'Search by your address to see results near you.'"
                    class="me-1"
                  />
                </p>
              </template>

              <template v-if="searched && searchTab == 0">
                <v-row class="d-flex align-center mb-1">
                  <v-col
                    cols="12"
                    sm="8"
                  >
                    <v-btn
                      v-if="subsidyProgram.enable_search_dob_eligibility_filter"
                      :class="moreFiltersButtonClass"
                      class="focus-visible"
                      elevation="2"
                      variant="outlined"
                      rounded
                    >
                      <v-checkbox
                        v-model="excludeIneligibleByDobChecked"
                        @update:model-value="toggleSearchDobEligibilityFilter"
                        :label="$t('Show eligible options only')"
                        density="compact"
                        variant="outlined"
                        rounded
                      />
                    </v-btn>
                    <v-btn
                      v-if="subsidyProgram.enable_show_seat_availability"
                      :class="moreFiltersButtonClass"
                      class="focus-visible"
                      elevation="2"
                      variant="outlined"
                      rounded
                    >
                      <v-checkbox
                        v-model="filters.require_seat_availability"
                        @click="searchProviders"
                        :label="$t('Show programs with available seats only')"
                        density="compact"
                        variant="outlined"
                        rounded
                      />
                    </v-btn>

                    <template
                      v-if="
                        $store.state.schemas[subsidyProgram.provider_schema_id].meta.openings
                          .enabled &&
                        $store.state.schemas[subsidyProgram.provider_schema_id].meta.openings.public
                      "
                    >
                      <FilterMenu
                        @clear="filters.vacancy = null"
                        @close="searchProviders()"
                        :actions="true"
                        :active="filters.vacancy"
                        :active-classes="['bg-white', 'bc-primary', 'b-1']"
                        :inactive-classes="['bg-white']"
                        :outlined="false"
                        data-cy="vacancy-filter"
                        elevation="2"
                        tid="search_availability_filter"
                        title="Availability"
                        hide-clear-filter
                      >
                        <template #card>
                          <v-checkbox
                            v-model="filters.vacancy"
                            class="mt-0 mb-0"
                            color="primary"
                            false-icon="check_box_outline_blank"
                            true-icon="check_box"
                            hide-details
                          >
                            <template #label>
                              <span
                                class="c-black"
                                data-cy="availability-checkmark"
                                >{{
                                  $t('Only show providers that have reported availability')
                                }}</span
                              >
                            </template>
                          </v-checkbox>
                        </template>
                      </FilterMenu>
                    </template>

                    <v-btn
                      v-if="$store.state.pages.Search.features.enable_more_filters"
                      @click="moreFiltersDialogIsVisible = true"
                      :class="moreFiltersButtonClass"
                      class="focus-visible"
                      data-cy="more-filters-button"
                      data-tid="search_more_filter"
                      elevation="2"
                      rounded
                    >
                      <span
                        v-t="'More filters'"
                        class="bc-body c-black d-none d-md-flex fw-500 fs-14"
                        data-cy="more-filters"
                      />
                      <v-icon class="d-flex d-md-none fw-500"> more_horiz </v-icon>
                    </v-btn>

                    <v-dialog
                      v-model="moreFiltersDialogIsVisible"
                      tabindex="0"
                      width="600px"
                      scrollable
                    >
                      <v-card>
                        <v-card-title class="mb-n-3 pb-0">{{ $t('More filters') }}</v-card-title>
                        <v-card-text class="pt-0 pb-6">
                          <div
                            v-if="
                              $store.state.schemas[subsidyProgram.provider_schema_id].definition
                                .properties.program_types.filterable
                            "
                          >
                            <v-divider class="my-6" />
                            <v-row dense>
                              <v-col class="c-black fs-16 fw-600">{{
                                $t(
                                  $store.state.schemas[subsidyProgram.provider_schema_id].definition
                                    .properties.program_types.alias || 'Program types',
                                )
                              }}</v-col>
                            </v-row>
                            <v-row
                              class="mb-4"
                              dense
                            >
                              <v-col>
                                <v-checkbox
                                  v-for="type in $store.state.schemas[
                                    subsidyProgram.provider_schema_id
                                  ].definition.properties.program_types.items.enum"
                                  v-model="filters.program_types"
                                  :key="type"
                                  :label="capitalize($t(type))"
                                  :value="type"
                                  class="c-black mt-0"
                                  color="primary"
                                  hide-details
                                  multiple
                                />
                              </v-col>
                            </v-row>
                          </div>

                          <div
                            v-if="
                              $store.state.schemas[subsidyProgram.provider_schema_id].definition
                                .properties.categories.filterable
                            "
                          >
                            <v-divider class="my-6" />
                            <v-row dense>
                              <v-col class="c-black fs-16 fw-600">{{
                                $t(
                                  $store.state.schemas[subsidyProgram.provider_schema_id].definition
                                    .properties.categories.alias || 'Categories',
                                )
                              }}</v-col>
                            </v-row>

                            <v-row
                              class="mb-4"
                              dense
                            >
                              <v-col>
                                <div
                                  v-if="
                                    $store.state.schemas[subsidyProgram.provider_schema_id]
                                      .definition.properties.categories.items?.enum?.length < 20
                                  "
                                >
                                  <v-checkbox
                                    v-for="type in $store.state.schemas[
                                      subsidyProgram.provider_schema_id
                                    ].definition.properties.categories.items.enum"
                                    v-model="filters.categories"
                                    :key="type"
                                    :label="capitalize($t(type))"
                                    :value="type"
                                    class="c-black mt-0"
                                    color="primary"
                                    hide-details
                                    multiple
                                  />
                                </div>
                                <div v-else>
                                  <v-autocomplete
                                    v-model="filters.categories"
                                    :items="
                                      $store.state.schemas[subsidyProgram.provider_schema_id]
                                        .definition.properties.categories.items.enum
                                    "
                                    :label="$t('Select all that apply')"
                                    variant="filled"
                                    chips
                                    deleteable-chips
                                    hide-details
                                    multiple
                                  />
                                </div>
                              </v-col>
                            </v-row>
                          </div>

                          <div
                            v-if="
                              $store.state.schemas[subsidyProgram.provider_schema_id].definition
                                .properties.program_season.filterable
                            "
                          >
                            <v-divider class="my-6" />
                            <v-row dense>
                              <v-col class="c-black fs-16 fw-600">{{
                                $t(
                                  $store.state.schemas[subsidyProgram.provider_schema_id].definition
                                    .properties.program_season.alias || 'Season',
                                )
                              }}</v-col>
                            </v-row>
                            <v-row
                              class="mb-4"
                              dense
                            >
                              <v-col>
                                <v-radio-group
                                  v-model="filters.program_season"
                                  class="mt-0"
                                  hide-details
                                >
                                  <v-radio
                                    v-for="type in $store.state.schemas[
                                      subsidyProgram.provider_schema_id
                                    ].definition.properties.program_season.enum"
                                    :key="type"
                                    :label="capitalize($t(type))"
                                    :value="type"
                                    class="c-black mt-0"
                                    color="primary"
                                    hide-details
                                  />
                                </v-radio-group>
                              </v-col>
                            </v-row>
                          </div>

                          <div
                            v-if="
                              $store.state.schemas[subsidyProgram.provider_schema_id].definition
                                .properties.quality_rating_score.filterable
                            "
                          >
                            <v-divider class="my-6" />
                            <v-row dense>
                              <v-col class="c-black fs-16 fw-600">{{ $t('Quality rating') }}</v-col>
                            </v-row>

                            <v-row dense>
                              <v-col>
                                <div v-if="$store.state.site.ratings.Description">
                                  <div class="c-light-black fs-15 pt-2 pb-4">
                                    <span
                                      v-text="$store.state.site.ratings.Description"
                                      class="c-light-black fs-15"
                                    />
                                    <a
                                      v-if="$store.state.site.ratings.Link"
                                      v-t="'Learn more'"
                                      :href="$store.state.site.ratings.Link"
                                      class="c-primary fs-15 ms-1 underlined"
                                      target="_blank"
                                    />
                                  </div>
                                </div>
                                <v-radio-group
                                  v-model="filters.minimum_quality_rating"
                                  class="mt-0 mb-2"
                                  hide-details
                                >
                                  <div
                                    v-for="(level, index) in $store.state.site.ratings.text
                                      .slice()
                                      .reverse()"
                                    :key="index"
                                  >
                                    <RadioWithContext
                                      :index="index"
                                      :item="level"
                                      :suffix="
                                        $store.state.site.ratings.text.length - index <
                                        $store.state.site.ratings.text.length
                                          ? 'or higher'
                                          : null
                                      "
                                      :value="$store.state.site.ratings.max - index"
                                    />
                                    <v-divider
                                      v-if="level.subtitle"
                                      v-show="index + 1 < $store.state.site.ratings.text.length"
                                      class="my-3"
                                    />
                                  </div>
                                </v-radio-group>
                                <v-btn
                                  v-show="filters.minimum_quality_rating"
                                  @click="filters.minimum_quality_rating = null"
                                  color="primary"
                                  size="small"
                                  >{{ $t('Clear filter') }}</v-btn
                                >
                              </v-col>
                            </v-row>
                          </div>

                          <div
                            v-if="
                              $store.state.schemas[subsidyProgram.provider_schema_id].definition
                                .properties.hours.filterable
                            "
                          >
                            <v-divider class="my-6" />
                            <v-row dense>
                              <v-col class="c-black fs-16 fw-600">{{ $t('Hours') }}</v-col>
                            </v-row>
                            <v-row>
                              <LabeledOpenClose
                                v-model="hoursRange"
                                close-title="Pickup time"
                                open-title="Drop-off time"
                                tabindex="0"
                                title-class="fs-14"
                              />
                            </v-row>
                            <div
                              v-t="
                                'We’ll show you results that offer drop-off at the time requested or earlier, and pick-up at the time requested or later'
                              "
                              class="c-gray fs-15 mt-2 mb-3 mxw-400"
                            />
                            <v-row dense>
                              <v-col
                                class="fw-600 fs-14"
                                cols="12"
                                >{{ $t('Days of the week') }}</v-col
                              >
                              <v-col cols="12">
                                <v-checkbox
                                  v-model="filters.week_days"
                                  :label="$t('Monday')"
                                  class="mt-0"
                                  density="compact"
                                  value="0"
                                  hide-details
                                />
                                <v-checkbox
                                  v-model="filters.week_days"
                                  :label="$t('Tuesday')"
                                  class="mt-2"
                                  density="compact"
                                  value="1"
                                  hide-details
                                />
                                <v-checkbox
                                  v-model="filters.week_days"
                                  :label="$t('Wednesday')"
                                  class="mt-2"
                                  density="compact"
                                  value="2"
                                  hide-details
                                />
                                <v-checkbox
                                  v-model="filters.week_days"
                                  :label="$t('Thursday')"
                                  class="mt-2"
                                  density="compact"
                                  value="3"
                                  hide-details
                                />
                                <v-checkbox
                                  v-model="filters.week_days"
                                  :label="$t('Friday')"
                                  class="mt-2"
                                  density="compact"
                                  value="4"
                                  hide-details
                                />
                                <v-checkbox
                                  v-model="filters.week_days"
                                  :label="$t('Saturday')"
                                  class="mt-2"
                                  density="compact"
                                  value="5"
                                  hide-details
                                />
                                <v-checkbox
                                  v-model="filters.week_days"
                                  :label="$t('Sunday')"
                                  class="mt-2"
                                  density="compact"
                                  value="6"
                                  hide-details
                                />
                              </v-col>
                            </v-row>
                          </div>

                          <SearchMoreFilters v-model="filters" />
                        </v-card-text>
                        <v-divider />
                        <v-card-actions class="pt-2">
                          <v-btn
                            @click="clearMoreFilters()"
                            color="primary"
                            data-cy="clear-filters"
                            variant="text"
                            >{{ $t('Clear filters') }}</v-btn
                          >
                          <v-spacer />
                          <v-btn
                            @click="searchProviders()"
                            color="primary"
                            data-cy="apply-filter"
                            >{{ $t('Apply') }}</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-col>
                  <v-col
                    class="ta-right"
                    cols="12"
                    sm="4"
                  >
                    <v-btn
                      @click="listView = !listView"
                      class="bg-white focus-very-visible"
                      color="primary"
                      data-cy="results-mode-button"
                      variant="outlined"
                    >
                      <v-icon
                        v-show="!listView"
                        start
                      >
                        west
                      </v-icon>
                      <span v-t="!listView ? 'List view' : 'Map view'" />
                      <v-icon
                        v-show="listView"
                        end
                      >
                        east
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </template>

              <div v-show="!listView">
                <ProviderPreferenceMapResults
                  @select="selectedMatch = $event"
                  ref="map"
                  :matches="matches"
                  :search="search"
                  :search-counter="searchCounter"
                  :visible="!listView"
                />

                <template v-if="selectedMatch">
                  <ProviderProgramsCard
                    @open="openProvider(selectedMatch.id)"
                    @toggle="toggleSelection($event[0], $event[1])"
                    :key="selectedMatch.id"
                    :dob="dob"
                    :frozen-program-ids="programIds"
                    :program-ids="newProgramIds"
                    :provider="selectedMatch"
                    :selectable="belowSelectionLimit"
                    :subsidy-program="subsidyProgram"
                  />
                </template>
              </div>

              <div v-show="listView">
                <ProviderProgramsCard
                  v-for="provider in matches"
                  @check="toggleSelection($event[0], $event[1])"
                  @open="openProvider(provider.id)"
                  @toggle="toggleSelection($event[0], $event[1])"
                  :key="provider.id"
                  :dob="dob"
                  :frozen-program-ids="programIds"
                  :program-ids="newProgramIds"
                  :provider="provider"
                  :selectable="belowSelectionLimit"
                  :subsidy-program="subsidyProgram"
                  class="my-4"
                />
              </div>

              <template v-if="matches.length > 0 && searchTab == 0">
                <v-card
                  class="bottom-0 elevation-0 bg-super-light-blue w-100pc pb-2 pt-1"
                  tile
                >
                  <v-pagination
                    v-model="page"
                    @update:model-value="loadMatches()"
                    :disabled="processing"
                    :length="pages"
                    :total-visible="8"
                    rounded
                  />
                </v-card>
              </template>
            </div>
          </div>
        </v-card-text>
      </v-card>

      <ContentDialog
        ref="providerDialog"
        :max-width="800"
      >
        <template #content="{ data }">
          <ProviderMatch
            v-if="data"
            @update:model-value="$emit('change')"
            :match="data"
            :outlined="false"
            :schema="providerSchema"
          />
        </template>
      </ContentDialog>
    </template>
  </ResourceDialog>
</template>

<script>
import API from '@/shared/mixins/api';
import ContentDialog from '@/shared/components/ContentDialog.vue';
import FilterMenu from '@/shared/components/form/FilterMenu.vue';
import LabeledOpenClose from '@/shared/components/form/LabeledOpenClose.vue';
import ProviderPreferenceMapResults from '@/parent/components/subsidy/ProviderPreferenceMapResults.vue';
import SearchMoreFilters from '@/shared/components/search/more_filters.vue';
import ProviderMatch from '@/shared/components/search/ProviderMatch.vue';
import ProviderProgramsCard from '@/parent/components/subsidy/ProviderProgramsCard.vue';
import RadioWithContext from '@/shared/components/form/RadioWithContext.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import { calculateMonthsOldOnDate } from '@/parent/services/calculate-months-old-on-date';
import { capitalize } from '@/plugins/filters';

export default {
  compatConfig: { MODE: 2 },

  components: {
    ContentDialog,
    FilterMenu,
    LabeledOpenClose,
    ProviderMatch,
    ProviderPreferenceMapResults,
    ProviderProgramsCard,
    ResourceDialog,
    RadioWithContext,
    SearchMoreFilters,
  },

  mixins: [API],

  props: {
    countEnrollmentsTowardPreferencesLimit: {
      type: Number,
      default: 0,
    },
    defaultQuery: {
      type: String,
      default: null,
    },
    dob: {
      type: String,
      default: null,
    },
    processing: Boolean,
    programIds: {
      type: Array,
      default: null,
    },
    subsidyProgram: {
      type: Object,
      default: null,
    },
  },

  emits: ['change', 'save'],

  setup() {
    return { capitalize };
  },

  data() {
    return {
      careNeededDate:
        this.subsidyProgram.search_for_care_as_of_date || new Date().toLocaleDateString('en-CA'),
      excludeIneligibleByDobChecked: false,
      filters: this.createDefaultSearch(),
      hoursRange: [900, 1600],
      listView: true,
      matches: [],
      moreFiltersDialogIsVisible: null,
      newProgramIds: [],
      page: 1,
      pages: 1,
      providerSchema: this.$store.state.schemas[this.subsidyProgram.provider_schema_id],
      search: null,
      searching: false,
      searched: false,
      searchCounter: 0,
      searchTab: 0,
      searchQuery: this.defaultQuery,
      searchQueryChanged: !!this.defaultQuery,
      selectedMatch: null,
    };
  },

  computed: {
    belowSelectionLimit() {
      if (this.subsidyProgram.selection_limit === 0) return true;

      const count = this.countEnrollmentsTowardPreferencesLimit + this.newProgramIds.length;
      return this.subsidyProgram.selection_limit > count;
    },

    searchChildrenData() {
      return [{ dob: this.dob, care_needed_date: this.careNeededDate }];
    },

    monthsOld() {
      return calculateMonthsOldOnDate(this.dob, this.careNeededDate);
    },

    moreFiltersActive() {
      return (
        this.filters.categories.length > 0 ||
        Object.values(this.filters.highlights).some((item) => item.length > 0) ||
        Object.values(this.filters.custom).some((item) => item && item.length > 0) ||
        this.filters.minimum_quality_rating ||
        this.filters.payment_subsidies_accepted.length > 0 ||
        this.filters.program_season ||
        this.filters.program_types.length > 0 ||
        !!this.filters.subsidy
      );
    },

    moreFiltersButtonClass() {
      let c = ['me-2'];
      if (this.moreFiltersActive) {
        c = c.concat(['bg-white', 'bc-primary', 'b-1']);
      } else {
        c = c.concat(['bg-white', 'bc-very-light-gray']);
      }
      return c.join(' ');
    },
  },

  methods: {
    createDefaultSearch() {
      return {
        categories: [],
        custom: {},
        dropoff: null,
        highlights: {},
        minimum_quality_rating: null,
        payment_subsidies_accepted: [],
        pickup: null,
        program_season: null,
        program_types: [],
        schema_id: this.subsidyProgram.provider_schema_id,
        vacancy: null,
        week_days: [],
      };
    },

    clearHours() {
      this.hoursRange = [900, 1600];
      this.filters.dropoff = null;
      this.filters.pickup = null;
      this.filters.week_days = [];
    },

    clearMoreFilters() {
      this.clearHours();
      this.filters.categories = [];
      this.filters.custom = {};
      this.filters.highlights = {};
      this.filters.minimum_quality_rating = null;
      this.filters.payment_subsidies_accepted = [];
      this.filters.program_season = null;
      this.filters.program_types = [];
      this.moreFiltersDialogIsVisible = false;
      this.searchProviders();
    },

    close() {
      this.$refs.dialog.close();
      this.searchQuery = null;
      this.searchQueryChanged = true;
    },

    loadMatches() {
      this.matches = [];
      this.api.match.index(
        this.search.id,
        {
          page: this.page,
          page_size: 10,
          include: 'details,programs',
          subsidy_program_id: this.subsidyProgram.id,
        },
        (searchResp) => {
          this.searching = false;
          this.matches = this.filterMatches(searchResp.data);
          this.pages = parseInt(searchResp.headers['x-page-count'] || '0', 10);
          this.searchCounter += 1;
        },
      );
    },

    filterMatches(providers) {
      return providers
        .map((provider) => ({
          ...provider,
          programs: provider.programs.filter(
            (program) => program.age_max >= this.monthsOld && this.monthsOld >= program.age_min,
          ),
        }))
        .filter((provider) => provider.programs.length > 0);
    },

    open() {
      this.newProgramIds = [];
      this.$refs.dialog.open();
      this.search = null;
      this.matches = [];
      this.listView = true;
    },

    openProvider(providerId) {
      this.api.public_api.provider.get(providerId, (resp) => {
        this.$refs.providerDialog.open(resp.data);
      });
    },

    resetResults() {
      this.matches = [];
      this.searched = false;
      this.searchQuery = null;
      this.searchQueryChanged = true;
    },

    save() {
      this.$emit('save', this.newProgramIds);
    },

    searchByAddress() {
      if (this.filters.week_days.length > 0) {
        [this.filters.dropoff, this.filters.pickup] = this.hoursRange;
      } else {
        this.filters.dropoff = null;
        this.filters.pickup = null;
      }

      this.searched = true;
      this.api.search.create(
        {
          ...this.filters,
          children: this.searchChildrenData,
          categories: this.filters.categories,
          unformatted_origin: this.searchQuery,
          location_type: 'home',
          subsidy_program_id: this.subsidyProgram.id,
        },
        (resp) => {
          this.search = resp.data;
          this.page = 1;
          this.searchQueryChanged = false;
          this.loadMatches();
        },
        (err) => {
          this.$eventBus.$emit('error', err);
          this.searching = false;
        },
      );
    },

    searchByQuery() {
      this.searchCounter += 1;
      const counter = this.searchCounter;
      this.searched = true;
      this.matches = [];
      this.api.public_api.provider.index(
        {
          include: 'details,programs',
          query: this.searchQuery,
          schema_id: this.subsidyProgram.provider_schema_id,
          subsidy_program_id: this.subsidyProgram.id,
        },
        (resp) => {
          if (counter === this.searchCounter) {
            this.matches = this.filterMatches(resp.data);
            this.searching = false;
            this.searchQueryChanged = false;
          }
        },
      );
    },

    searchProviders() {
      this.searching = true;
      this.moreFiltersDialogIsVisible = false;

      if (!this.searchQuery) {
        this.matches = [];
        this.searching = false;
        return false;
      }

      if (this.searchTab === 0) {
        return this.searchByAddress();
      }
      return this.searchByQuery();
    },

    toggleSelection(programId, newVal) {
      if (newVal) {
        if (!this.newProgramIds.includes(programId)) {
          if (this.belowSelectionLimit) {
            this.newProgramIds.push(programId);
            return;
          }

          this.$eventBus.$emit('chime', this.$t('Exceeded selection limit'));
        }
      } else {
        if (!this.newProgramIds.includes(programId)) return;

        this.newProgramIds.splice(
          this.newProgramIds.findIndex((id) => id === programId),
          1,
        );
      }
    },

    toggleSearchDobEligibilityFilter() {
      this.filters.eligibility_dob = this.excludeIneligibleByDobChecked ? this.dob : null;
      this.searchProviders();
    },
  },
};
</script>
