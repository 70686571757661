<template>
  <v-container
    v-show="loaded"
    class="p-0"
    fluid
  >
    <v-container
      class="pt-8"
      fluid
    >
      <div class="mxw-1200 mx-auto">
        <v-container fluid>
          <v-col :cols="12">
            <v-row>
              <h1 class="fs-24 fw-800 c-black mt-2 me-3">
                {{ $t('You have received a special invite from') }} {{ providerName }}
              </h1>
            </v-row>

            <v-row class="pt-3">
              <p>
                {{ $t(REFERRAL_MESSAGE) }}
              </p>
            </v-row>
          </v-col>

          <v-alert
            v-if="!isSubsidyProgramOpen"
            class="my-3 rounded-md bc-primary"
            color="primary"
            type="info"
            variant="outlined"
          >
            <v-row class="d-flex align-center">
              <v-col cols="12">
                <div class="text-black fw-600">
                  {{ $t('This application is not at available at the moment.') }}'
                </div>

                <div class="text-black pt-3">
                  {{ $t(CLOSED_APPLICATION_MESSAGE) }}
                </div>
              </v-col>
            </v-row>
          </v-alert>

          <v-card
            class="my-3 rounded-md bg-primary"
            border
          >
            <v-card-text>
              <v-row class="d-flex align-center">
                <v-col cols="6">
                  <div class="text-white">
                    {{ program?.name }}
                  </div>
                </v-col>

                <v-col
                  class="d-flex justify-end"
                  cols="6"
                >
                  <v-btn
                    v-if="isSubsidyProgramOpen"
                    @click="handleApply"
                    :disabled="false"
                    class="focus-very-visible wider-btn"
                    color="white"
                    data-testid="subsidy-apply-btn"
                    size="x-large"
                    tabindex="0"
                  >
                    <div class="spaced-button-content">
                      <span>{{ $t(applyLabel) }}</span>
                      <v-icon
                        class="ms-2"
                        icon="chevron_right"
                      />
                    </div>
                  </v-btn>

                  <v-btn
                    v-else
                    :disabled="true"
                    class="focus-very-visible wider-btn disabled-light"
                    color="white"
                    size="x-large"
                    tabindex="0"
                  >
                    <div class="spaced-button-content">
                      <LongDate
                        :date="subsidyProgram?.opens_at"
                        prefix="Opens"
                      />
                    </div>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-container>
      </div>
    </v-container>

    <div class="bg-super-light-blue">
      <ProviderShow
        v-if="program?.provider_id"
        @provider-update="(provider) => providerUpdate(provider)"
        :provider-id="program?.provider_id"
      />
    </div>
  </v-container>
</template>

<script setup>
import publicApi from '@/shared/services/api/public-api';
import { subsidyProgramIsOpen } from '@/parent/services/subsidies';
import ProviderShow from '@/public/views/ProviderShow.vue';
import LongDate from '@/shared/components/LongDate.vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { onMounted } from 'vue';

const CLOSED_APPLICATION_MESSAGE =
  'The link you used to access this page is valid, however the application is not open for you to apply. ' +
  'Please try again later. For more information about when you may be eligible to apply, please view: upk.colorado.gov. ' +
  'Remember: When you are ready to apply, please use this link again to ensure you are prioritized correctly.';

const REFERRAL_MESSAGE =
  'A child under your supervision has been identified as being currently enrolled in this program, ' +
  'having a sibling in this program, or having a family member who works for this program. ' +
  'To be prioritized accordingly, please follow the link below to sign in and complete your application.';

const route = useRoute();
const router = useRouter();
const store = useStore();

const loaded = ref(true);
const subsidyProgram = ref({});
const program = ref({});
const providerName = ref(null);

const isSubsidyProgramOpen = computed(() => {
  return subsidyProgramIsOpen(subsidyProgram.value) || route.query.test === 'daremightythings';
});

const applyLabel = computed(() => {
  return store.state.is_authenticated ? 'Apply' : 'Sign in & apply';
});

onMounted(() => {
  loadSubsidyProgram();
  void loadProgram();
});

function handleApply() {
  if (store.state.is_authenticated) {
    return router.push({
      name: 'ChildSubsidyNew',
      params: { subsidyProgramId: route.query.subsidyProgramId },
      query: { programId: route.query.programId },
    });
  }

  void handleSignInApply();
}

function handleSignInApply() {
  const unregisteredRoute = {
    name: 'Signup',
    query: {
      action: 'providerReferral',
      subsidyProgramId: route.query.subsidyProgramId,
      programId: route.query.programId,
    },
  };

  router.push(unregisteredRoute);
}

function loadSubsidyProgram() {
  return publicApi.organization.subsidy_program.get(route.query?.subsidyProgramId, (resp) => {
    subsidyProgram.value = resp.data;
  });
}

async function loadProgram() {
  const res = await publicApi.organization.program.get(route.query?.programId);
  program.value = res.data;
}

function providerUpdate(provider) {
  providerName.value = provider?.name;
}
</script>

<style>
.wider-btn {
  min-width: 150px !important;
}

.spaced-button-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #f5f5f5 !important;
}
</style>
