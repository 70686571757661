<template>
  <v-row class="d-flex align-center py-6">
    <v-col
      :order="reverse ? 12 : 1"
      class="px-md-12 px-3"
      cols="12"
      md="6"
    >
      <div
        v-t="text"
        class="fs-36 fw-600 mb-3"
      />
      <div
        v-t="subtext"
        class="fs-18 fw-500"
      />
    </v-col>
    <v-col
      :order="reverse ? 1 : 12"
      class="px-md-12 px-3"
      cols="12"
      md="6"
      style="position: relative"
    >
      <v-img
        :src="img"
        class="elevation-3 rounded"
        max-height="550"
        eager
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  compatConfig: { MODE: 2 },

  props: {
    img: {
      type: String,
      default: null,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    subtext: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
  },
};
</script>
