<template>
  <v-card
    v-if="childSchema"
    class="mb-4"
    flat
    tile
  >
    <v-card-text class="px-0">
      <v-row class="d-flex align-center">
        <v-col>
          <h2
            v-t="subsidyProgram.name"
            class="fs-22 fw-600"
            tabindex="0"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <p
            v-t="subsidyProgram.description"
            class="c-light-black fs-16 fw-500 mb-0"
          />
        </v-col>
      </v-row>

      <template v-if="subsidies.length > 0">
        <v-row
          v-for="subsidy in subsidies"
          :key="subsidy.id"
        >
          <v-col>
            <template v-if="subsidy.submitted_at">
              <v-divider class="my-4" />

              <v-row
                class="mb-2"
                dense
              >
                <v-col class="d-flex align-center">
                  <router-link
                    :to="{ name: 'SubsidyReview', params: { subsidyId: subsidy.id } }"
                    class="td-none"
                  >
                    <v-icon
                      class="material-icons-outlined td-none"
                      color="primary"
                      size="24"
                      start
                    >
                      check_circle
                    </v-icon>
                    <LongDateTime
                      :date="subsidy.submitted_at"
                      class="underlined"
                      data-cy="app_submitted"
                      prefix="Application submitted at"
                    />
                  </router-link>
                </v-col>
              </v-row>
            </template>
            <template v-else>
              <v-btn
                :block="$vuetify.display.smAndDown"
                :to="{ name: 'SubsidyContinue', params: { subsidyId: subsidy.id } }"
                class="focus-very-visible"
                color="primary"
                data-cy="continue-button"
                variant="outlined"
                tile
              >
                <span>{{ $t('Continue') }} {{ $t(subsidyProgram.name) }}</span>
              </v-btn>
            </template>
          </v-col>
        </v-row>

        <v-row v-if="subsidies.filter((subsidy) => !subsidy.submitted_at)">
          <v-col>
            <v-btn
              @click="create"
              :block="$vuetify.display.smAndDown"
              class="focus-very-visible"
              color="primary"
              data-cy="re-apply-button"
              variant="outlined"
              tile
            >
              <span v-t="'Re-apply'" />
            </v-btn>
          </v-col>
        </v-row>
      </template>

      <template v-else>
        <v-row>
          <v-col>
            <v-btn
              @click="create"
              :block="$vuetify.display.smAndDown"
              :loading="processing"
              class="focus-very-visible"
              color="primary"
              data-cy="start-button"
              variant="outlined"
              tile
            >
              <span>{{ $t('Start') }} {{ $t(subsidyProgram.name) }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import API from '@/shared/mixins/api';
import LongDateTime from '@/shared/components/LongDateTime.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    LongDateTime,
  },

  mixins: [API],

  props: {
    child: {
      type: Object,
      default: null,
    },
    childSchema: {
      type: Object,
      default: null,
    },
    subsidies: {
      type: Array,
      default: () => [],
    },
    subsidyProgram: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      processing: false,
    };
  },

  methods: {
    create() {
      this.processing = true;
      this.api.subsidy.create(
        { child_id: this.child.id, subsidy_program_id: this.subsidyProgram.id },
        (resp) => {
          this.$router.push({ name: 'SubsidyContinue', params: { subsidyId: resp.data.id } });
        },
        (err) => {
          this.$eventBus.$emit('chime', err.response.data.errors[0]);
        },
      );
    },
  },
};
</script>
