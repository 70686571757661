<template>
  <v-container v-if="profile">
    <h1
      ref="title"
      class="focus-invisible fw-600 me-4 mb-4"
      tabindex="0"
    >
      {{ $t('My Profile') }}
    </h1>

    <v-card
      class="mb-4"
      border
      tile
    >
      <v-card-title>
        {{ $t('Name') }}
      </v-card-title>

      <v-divider />

      <v-card-text class="pt-4 pb-8">
        <v-row>
          <LabeledTextfield
            v-model="profile.first_name"
            @input="nameChanged = true"
            cols="6"
            message="First name"
          />

          <LabeledTextfield
            v-model="profile.last_name"
            @input="nameChanged = true"
            cols="6"
            message="Last name"
          />
        </v-row>
      </v-card-text>
      <v-divider class="mb-4" />

      <v-card-actions>
        <template v-if="nameChanged">
          <v-btn
            @click="updateProfile"
            color="primary"
            size="x-large"
          >
            <span v-t="'Save'" />
          </v-btn>
        </template>

        <template v-if="profile.terms_agreement_accepted_at">
          <v-btn
            :href="agreementLink"
            color="primary"
            size="x-large"
            target="_blank"
            variant="outlined"
            >{{ $t('Download agreement') }}</v-btn
          >
        </template>
      </v-card-actions>
    </v-card>

    <v-card
      class="mb-4"
      border
      tile
    >
      <v-card-title>
        {{ $t('Email') }}
      </v-card-title>

      <v-divider />

      <v-card-text class="pt-4 pb-8">
        <v-row>
          <LabeledTextfield
            v-model="profile.email"
            @click="$refs.emailDialog.open()"
            message="Email"
            readonly
          />
        </v-row>
      </v-card-text>

      <v-divider class="mb-4" />

      <v-card-actions>
        <v-btn
          @click="$refs.emailDialog.open()"
          color="primary"
          size="x-large"
        >
          {{ $t('Update email') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <template v-if="phoneEnabled">
      <v-card
        class="mb-4"
        border
        tile
      >
        <v-card-title>{{ $t('Phone') }}</v-card-title>
        <v-divider />
        <v-card-text class="pt-4 pb-8">
          <v-row>
            <LabeledTextfield
              v-model="profile.phone"
              @click="$refs.phoneDialog.open()"
              mask="(###) ###-####"
              message="Cell phone"
              readonly
            />
            <template v-if="profile.phone">
              <v-col>
                <v-checkbox
                  v-model="profile.text_opted_in"
                  @update:model-value="updateTextOptedIn"
                  :false-value="false"
                  :label="$t(textConsent)"
                  class="mt-0"
                  disabled
                  hide-details
                />
              </v-col>
            </template>
          </v-row>
        </v-card-text>

        <v-divider class="mb-4" />

        <v-card-actions>
          <v-btn
            @click="$refs.phoneDialog.open()"
            :loading="processing"
            color="primary"
            size="x-large"
          >
            {{ $t('Update phone') }}
          </v-btn>

          <template v-if="profile.phone">
            <v-btn
              @click="removePhone"
              :loading="processing"
              append-icon="close"
              color="red"
              size="x-large"
            >
              {{ $t('Remove phone') }}
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </template>

    <v-card
      v-if="$store.state.config.enable_password_authentication"
      class="mb-4"
      border
      flat
      tile
    >
      <v-card-title>{{ $t('Password') }}</v-card-title>
      <v-divider />
      <v-card-text class="pt-4 pb-8">
        <v-row>
          <LabeledTextfield
            v-model="newPassword"
            cols="12"
            message="New Password"
            sm="6"
            type="password"
          />
          <LabeledTextfield
            v-model="newPasswordConfirmation"
            cols="12"
            message="Confirm new password"
            sm="6"
            type="password"
          />
          <v-col cols="12">
            <p class="fs-15 fw-500 mb-2">
              {{
                $t(
                  'The password must be 8 characters with 3 out of 4 of the following: Lowercase characters, Uppercase characters, Numbers, Symbols.',
                )
              }}
            </p>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider class="mb-4" />

      <v-card-actions>
        <v-btn
          @click="updatePasswords"
          :disabled="saveDisabled"
          :loading="processing"
          color="primary"
          size="x-large"
        >
          {{ $t('Update password') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <ConfirmDialog ref="confirmDialog" />

    <ResourceDialog
      @save="updateEmail"
      ref="emailDialog"
      :fields="emailUpdateFields"
      :processing="processing"
      title="Update email"
    />

    <AddPhoneDialog
      @cancel="load"
      @change="load"
      ref="phoneDialog"
      cancel-button-text="Cancel"
      disable-phone-skip="true"
      save-button-text="Send confirmation code"
      title="Update your phone number"
    />

    <MFASettingsCard
      v-show="$store.state.config.enable_mfa_authentication"
      @change="load"
      :profile="profile"
    />

    <VerticalSpacer :min-height="60" />
  </v-container>
</template>

<script setup>
import Api from '@/manager/services/bright_finder';
import AddPhoneDialog from '@/shared/components/AddPhoneDialog.vue';
import ConfirmDialog from '@/shared/components/ConfirmDialog.vue';
import MFASettingsCard from '@/shared/components/MFASettingsCard.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import useEventBus from '@/shared/composables/useEventBus';
import usePasswordValidation from '@/shared/composables/usePasswordValidation';
import { useReCaptcha } from 'vue-recaptcha-v3';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const confirmDialog = ref(null);
const emailUpdateFields = ref([
  { text: 'Email', value: 'email' },
  { text: 'Confirm email', value: 'email_confirmation', protected: true },
]);
const eventBus = useEventBus();
const { isValidPassword } = usePasswordValidation();
const nameChanged = ref(false);
const newPassword = ref(null);
const newPasswordConfirmation = ref(null);
const processing = ref(false);
const profile = ref(null);
const recaptcha = useReCaptcha();
const router = useRouter();
const store = useStore();
const phoneEnabled = getPhoneEnabled();
const textConsent =
  'I consent to receive communication by text message to the cellphone number provided above.';

const saveDisabled = computed(() => {
  return !isValidPassword(newPassword.value, newPasswordConfirmation.value);
});

const agreementLink = computed(() => {
  if (!(profile.value?.terms_agreement_id && profile.value?.terms_agreement_accepted_at))
    return false;

  const params = {
    filename: encodeURIComponent('TermsAgreement.pdf'),
    time: profile.value.terms_agreement_accepted_at,
    name: profile.value.name,
    locale: profile.value.default_locale,
  };
  return Api.public_api.organization.termsAgreement.downloadUrl(
    profile.value.terms_agreement_id,
    params,
  );
});

onMounted(load);

function getPhoneEnabled() {
  const { config } = store.state;
  return config.enable_optional_phone || config.site.features.enable_phone_registration;
}

function handleError(error) {
  eventBus.error(error);
}

async function load() {
  const { data } = await Api.member.profile.get();
  profile.value = data;
}

async function removePhone() {
  if (!profile.value.email) {
    return eventBus.chime('You must add an email or new phone number before removing this one.');
  }

  const confirm = await confirmDialog.value.confirmWithText(
    'Are you sure you want to remove this phone number?',
  );
  if (!confirm) return false;

  processing.value = true;
  await Api.member.phone.destroy();
  processing.value = false;
  await load();
  return true;
}

function updatePasswords() {
  if (!isValidPassword(newPassword.value, newPasswordConfirmation.value)) return;

  processing.value = true;
  Api.member.profile.update(
    { password: newPassword.value },
    (resp) => {
      profile.value = resp.data;
      eventBus.chime('Password saved');
      processing.value = false;
    },
    (error) => {
      eventBus.error(error);
      processing.value = false;
    },
  );
}

async function updateEmail(newVal) {
  if (!newVal.email || !newVal.email_confirmation || newVal.email !== newVal.email_confirmation) {
    return eventBus.chime('Email must be present and match');
  }

  let token = null;
  processing.value = true;
  if (import.meta.env.VUE_APP_RECAPTCHA_SITE_KEY) {
    await recaptcha.recaptchaLoaded();
    token = await recaptcha.executeRecaptcha('login');
  }

  processing.value = true;
  const resp = await Api.member.profile.update({ email: newVal.email, token }).catch(handleError);
  processing.value = false;
  if (!resp?.data) return false;

  store.commit('setProfile', null);
  store.commit('setAuthenticated', false);
  router.replace({
    name: 'CodeSent',
    query: { token: resp.data.code_id, email_sent_to: newVal.email },
  });
  return true;
}

async function updateProfile() {
  let token = null;
  processing.value = true;
  if (import.meta.env.VUE_APP_RECAPTCHA_SITE_KEY) {
    await recaptcha.recaptchaLoaded();
    token = await recaptcha.executeRecaptcha('login');
  }

  Api.member.profile.update(
    {
      first_name: profile.value.first_name,
      last_name: profile.value.last_name,
      token,
    },
    (resp) => {
      profile.value = resp.data;
      eventBus.chime('Profile saved');
      processing.value = false;
    },
    (error) => {
      eventBus.error(error);
      processing.value = false;
    },
  );
}

async function updateTextOptedIn(newVal) {
  if (newVal === null) return;

  processing.value = true;
  const resp = await Api.member.profile.update({ text_opted_in: newVal }).catch(handleError);
  processing.value = false;
  if (!resp?.data) return;

  eventBus.chime('Saved');
}
</script>
