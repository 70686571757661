<template>
  <v-card
    class="bc-very-light-grey bg-super-light-blue b-1 mnh-400"
    tile
  >
    <v-row
      class="mb-3"
      dense
      no-gutters
    >
      <v-col
        v-if="!readonly"
        @click="mode = 'search'"
        @keydown.enter="mode = 'search'"
        :class="mode == 'search' ? 'elevation-2' : 'elevation-0'"
        class="pointer"
      >
        <div
          :class="mode == 'search' ? 'bg-primary c-white' : 'bg-white c-black'"
          class="fw-500 py-6 d-flex align-center justify-center"
          data-cy="search-mode-button"
        >
          {{ $t('Search') }}
        </div>
      </v-col>
      <v-col
        @click="mode = 'selected'"
        @keydown.enter="mode = 'selected'"
        :class="mode == 'selected' ? 'elevation-2' : 'elevation-0'"
        :cols="readonly ? '12' : '6'"
        class="pointer"
      >
        <div
          v-text="$t('Selected') + ' (' + selectedCount + ')'"
          :class="mode == 'selected' ? 'bg-primary c-white' : 'bg-white c-black'"
          class="fw-500 py-6 d-flex align-center justify-center"
          data-cy="selected-mode-button"
        />
      </v-col>
    </v-row>

    <div
      v-show="mode == 'selected'"
      class="pa-3"
    >
      <template v-if="subsidy.provider_ids.length == 0">
        <p
          class="fs-16 c-black pt-8"
          data-cy="selected-hint"
        >
          {{ $t('You have not selected any sites. To add sites, click the Search tab.') }}
        </p>
      </template>

      <transition-group>
        <div
          v-for="(provider_id, index) in subsidy.provider_ids"
          :key="provider_id"
          class="mb-6 sortable-item"
        >
          <FavoriteCard
            @move="reorderProviders(index, index + $event)"
            @toggle="toggleInclusion(index, $event)"
            :active="true"
            :index="index"
            :length="subsidy.provider_ids.length"
            :ordered="allowOrder"
            :provider-id="provider_id"
            :readonly="readonly"
            class="my-4"
          />
        </div>
      </transition-group>
    </div>

    <div
      v-show="mode == 'search'"
      class="pa-3"
      mandatory
    >
      <v-tabs
        v-model="tab"
        bg-color="transparent"
      >
        <v-tab>
          <span
            class="ls-normal tt-none fs-16 fw-500"
            data-cy="address-mode-button"
          >
            {{ $t('Search by address') }}
          </span>
        </v-tab>
        <v-tab>
          <span
            class="ls-normal tt-none fs-16 fw-500"
            data-cy="name-mode-button"
          >
            {{ $t('Search by name') }}
          </span>
        </v-tab>
      </v-tabs>

      <v-card class="d-flex align-center pa-4 mb-4">
        <v-row>
          <v-col class="d-flex flex-grow-1 flex-shrink-0">
            <v-text-field
              v-model="searchQuery"
              @keydown.enter="searchProviders"
              ref="search"
              data-cy="query-input"
              prepend-inner-icon="search"
              autofocus
              hide-details
            />
          </v-col>
          <v-col class="d-flex justify-end flex-grow-0 flex-shrink-0">
            <v-btn
              @click="searchProviders"
              :loading="processing"
              class="ms-3"
              color="primary"
              data-cy="search-button"
              size="x-large"
            >
              {{ $t('Search') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card>

      <v-row
        v-if="subsidyProgram.selection_limit > 0"
        class="mb-3"
      >
        <v-col>
          <span class="fs-16 fw-800 c-primary">{{
            $t(`You can select ${subsidyProgram.selection_limit} total options`)
          }}</span>
        </v-col>
      </v-row>

      <v-window v-model="tab">
        <v-window-item>
          <v-row
            v-if="search"
            class="d-flex align-center mb-1"
          >
            <v-col
              cols="12"
              sm="8"
            >
              <template
                v-if="
                  $store.state.schemas[subsidyProgram.provider_schema_id].meta.openings.enabled &&
                  $store.state.schemas[subsidyProgram.provider_schema_id].meta.openings.public
                "
              >
                <FilterMenu
                  @clear="filters.vacancy = null"
                  @close="searchProviders()"
                  :actions="true"
                  :active="filters.vacancy"
                  :active-classes="['bg-white', 'bc-primary', 'b-1']"
                  :inactive-classes="['bg-white']"
                  :outlined="false"
                  data-cy="vacancy-filter"
                  elevation="2"
                  tid="search_availability_filter"
                  title="Availability"
                  hide-clear-filter
                >
                  <template #card>
                    <v-checkbox
                      v-model="filters.vacancy"
                      class="mt-0 mb-0"
                      color="primary"
                      false-icon="check_box_outline_blank"
                      true-icon="check_box"
                      hide-details
                    >
                      <template #label>
                        <span
                          class="c-black"
                          data-cy="availability-checkmark"
                          >{{ $t('Only show providers that have reported availability') }}</span
                        >
                      </template>
                    </v-checkbox>
                  </template>
                </FilterMenu>
              </template>

              <FilterMenu
                v-if="!$store.state.pages.Search.default_age_group.enabled"
                @close="searchProviders()"
                :actions="true"
                :active="true"
                :active-classes="['bg-white', 'bc-primary', 'b-1']"
                :inactive-classes="['bg-white']"
                :outlined="false"
                data-cy="age-filter"
                elevation="2"
                tid="search_ages_filter"
                title="Age"
                hide-clear-filter
              >
                <template #card>
                  <LabeledVolume
                    v-for="(item, index) in age_groups"
                    @decrease="modifyCount(index, -1)"
                    @increase="modifyCount(index, 1)"
                    :key="index"
                    ref="childVolume"
                    :count="item.count"
                    :dense="true"
                    :subtitle="item.subtitle || item.subtext"
                    :title="item.title || item.text"
                  />
                </template>
              </FilterMenu>

              <v-btn
                v-if="$store.state.pages.Search.features?.enable_more_filters"
                @click="moreFiltersDialogIsVisible = true"
                :class="moreFiltersButtonClass"
                class="focus-visible"
                data-cy="more-filters-button"
                data-tid="search_more_filter"
                elevation="2"
                rounded
              >
                <span
                  class="bc-body c-black d-none d-md-flex fw-500 fs-14"
                  data-cy="more-filters"
                >
                  {{ $t('More filters') }}
                </span>
                <v-icon class="d-flex d-md-none fw-500"> more_horiz </v-icon>
              </v-btn>

              <v-dialog
                v-model="moreFiltersDialogIsVisible"
                width="600px"
                scrollable
              >
                <v-card>
                  <v-card-title class="mb-n-3 pb-0">
                    {{ $t('More filters') }}
                  </v-card-title>
                  <v-card-text class="pt-0 pb-6">
                    <div
                      v-if="
                        $store.state.schemas[subsidyProgram.provider_schema_id].definition
                          .properties.program_types.filterable
                      "
                    >
                      <v-divider class="my-6" />
                      <v-row dense>
                        <v-col class="c-black fs-16 fw-600">
                          {{
                            $t(
                              $store.state.schemas[subsidyProgram.provider_schema_id].definition
                                .properties.program_types.alias || 'Program types',
                            )
                          }}
                        </v-col>
                      </v-row>
                      <v-row
                        class="mb-4"
                        dense
                      >
                        <v-col>
                          <v-checkbox
                            v-for="type in $store.state.schemas[subsidyProgram.provider_schema_id]
                              .definition.properties.program_types.items.enum"
                            v-model="filters.program_types"
                            :key="type"
                            :label="capitalize($t(type))"
                            :value="type"
                            class="c-black mt-0"
                            color="primary"
                            hide-details
                            multiple
                          />
                        </v-col>
                      </v-row>
                    </div>

                    <div
                      v-if="
                        $store.state.schemas[subsidyProgram.provider_schema_id].definition
                          .properties.categories.filterable
                      "
                    >
                      <v-divider class="my-6" />
                      <v-row dense>
                        <v-col class="c-black fs-16 fw-600">
                          {{
                            $t(
                              $store.state.schemas[subsidyProgram.provider_schema_id].definition
                                .properties.categories.alias || 'Categories',
                            )
                          }}
                        </v-col>
                      </v-row>

                      <v-row
                        class="mb-4"
                        dense
                      >
                        <v-col>
                          <div
                            v-if="
                              $store.state.schemas[subsidyProgram.provider_schema_id].definition
                                .properties.categories.items?.enum?.length < 20
                            "
                          >
                            <v-checkbox
                              v-for="type in $store.state.schemas[subsidyProgram.provider_schema_id]
                                .definition.properties.categories.items.enum"
                              v-model="filters.categories"
                              :key="type"
                              :label="capitalize($t(type))"
                              :value="type"
                              class="c-black mt-0"
                              color="primary"
                              hide-details
                              multiple
                            />
                          </div>
                          <div v-else>
                            <v-autocomplete
                              v-model="filters.categories"
                              :items="
                                $store.state.schemas[subsidyProgram.provider_schema_id].definition
                                  .properties.categories.items.enum
                              "
                              :label="$t('Select all that apply')"
                              variant="filled"
                              chips
                              deleteable-chips
                              hide-details
                              multiple
                            />
                          </div>
                        </v-col>
                      </v-row>
                    </div>

                    <div
                      v-if="
                        $store.state.schemas[subsidyProgram.provider_schema_id].definition
                          .properties.program_season.filterable
                      "
                    >
                      <v-divider class="my-6" />
                      <v-row dense>
                        <v-col class="c-black fs-16 fw-600">
                          {{
                            $t(
                              $store.state.schemas[subsidyProgram.provider_schema_id].definition
                                .properties.program_season.alias || 'Season',
                            )
                          }}
                        </v-col>
                      </v-row>
                      <v-row
                        class="mb-4"
                        dense
                      >
                        <v-col>
                          <v-radio-group
                            v-model="filters.program_season"
                            class="mt-0"
                            hide-details
                          >
                            <v-radio
                              v-for="type in $store.state.schemas[subsidyProgram.provider_schema_id]
                                .definition.properties.program_season.enum"
                              :key="type"
                              :label="capitalize($t(type))"
                              :value="type"
                              class="c-black mt-0"
                              color="primary"
                              hide-details
                            />
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </div>

                    <div
                      v-if="
                        $store.state.schemas[subsidyProgram.provider_schema_id].definition
                          .properties.quality_rating_score.filterable
                      "
                    >
                      <v-divider class="my-6" />
                      <v-row dense>
                        <v-col class="c-black fs-16 fw-600">
                          {{ $t('Quality rating') }}
                        </v-col>
                      </v-row>

                      <v-row dense>
                        <v-col>
                          <div v-if="$store.state.site.ratings.Description">
                            <div class="c-light-black fs-15 pt-2 pb-4">
                              <span
                                v-text="$store.state.site.ratings.Description"
                                class="c-light-black fs-15"
                              />
                              <a
                                v-if="$store.state.site.ratings.Link"
                                :href="$store.state.site.ratings.Link"
                                class="c-primary fs-15 ms-1 underlined"
                                target="_blank"
                              >
                                {{ $t('Learn more') }}
                              </a>
                            </div>
                          </div>
                          <v-radio-group
                            v-model="filters.minimum_quality_rating"
                            class="mt-0 mb-2"
                            hide-details
                          >
                            <div
                              v-for="(level, index) in $store.state.site.ratings.text
                                .slice()
                                .reverse()"
                              :key="index"
                            >
                              <RadioWithContext
                                :index="index"
                                :item="level"
                                :suffix="
                                  $store.state.site.ratings.text.length - index <
                                  $store.state.site.ratings.text.length
                                    ? 'or higher'
                                    : null
                                "
                                :value="$store.state.site.ratings.max - index"
                              />
                              <v-divider
                                v-if="level.subtitle"
                                v-show="index + 1 < $store.state.site.ratings.text.length"
                                class="my-3"
                              />
                            </div>
                          </v-radio-group>
                          <v-btn
                            v-show="filters.minimum_quality_rating"
                            @click="filters.minimum_quality_rating = null"
                            color="primary"
                            size="small"
                          >
                            {{ $t('Clear filter') }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </div>

                    <div
                      v-if="
                        $store.state.schemas[subsidyProgram.provider_schema_id].definition
                          .properties.hours.filterable
                      "
                    >
                      <v-divider class="my-6" />
                      <v-row dense>
                        <v-col class="c-black fs-16 fw-600">
                          {{ $t('Hours') }}
                        </v-col>
                      </v-row>
                      <v-row>
                        <LabeledOpenClose
                          v-model="hoursRange"
                          close-title="Pickup time"
                          open-title="Drop-off time"
                          title-class="fs-14"
                        />
                      </v-row>
                      <div class="c-gray fs-15 mt-2 mb-3 mxw-400">
                        {{
                          $t(
                            'We’ll show you results that offer drop-off at the time requested or earlier, and pick-up at the time requested or later',
                          )
                        }}
                      </div>
                      <v-row dense>
                        <v-col
                          class="fw-600 fs-14"
                          cols="12"
                        >
                          {{ $t('Days of the week') }}
                        </v-col>
                        <v-col cols="12">
                          <v-checkbox
                            v-model="filters.week_days"
                            :label="$t('Monday')"
                            class="mt-0"
                            density="compact"
                            value="0"
                            hide-details
                          />
                          <v-checkbox
                            v-model="filters.week_days"
                            :label="$t('Tuesday')"
                            class="mt-2"
                            density="compact"
                            value="1"
                            hide-details
                          />
                          <v-checkbox
                            v-model="filters.week_days"
                            :label="$t('Wednesday')"
                            class="mt-2"
                            density="compact"
                            value="2"
                            hide-details
                          />
                          <v-checkbox
                            v-model="filters.week_days"
                            :label="$t('Thursday')"
                            class="mt-2"
                            density="compact"
                            value="3"
                            hide-details
                          />
                          <v-checkbox
                            v-model="filters.week_days"
                            :label="$t('Friday')"
                            class="mt-2"
                            density="compact"
                            value="4"
                            hide-details
                          />
                          <v-checkbox
                            v-model="filters.week_days"
                            :label="$t('Saturday')"
                            class="mt-2"
                            density="compact"
                            value="5"
                            hide-details
                          />
                          <v-checkbox
                            v-model="filters.week_days"
                            :label="$t('Sunday')"
                            class="mt-2"
                            density="compact"
                            value="6"
                            hide-details
                          />
                        </v-col>
                      </v-row>
                    </div>

                    <SearchMoreFilters v-model="filters" />
                  </v-card-text>
                  <v-divider />
                  <v-card-actions class="pt-2">
                    <v-btn
                      @click="clearMoreFilters()"
                      color="primary"
                      data-cy="clear-filters"
                      variant="text"
                    >
                      {{ $t('Clear filters') }}
                    </v-btn>
                    <v-spacer />
                    <v-btn
                      @click="searchProviders()"
                      color="primary"
                      data-cy="apply-filter"
                    >
                      {{ $t('Apply') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col
              class="ta-right"
              cols="12"
              sm="4"
            >
              <v-btn
                @click="toggleResultsMode"
                class="bg-white focus-very-visible"
                color="primary"
                data-cy="results-mode-button"
                variant="outlined"
              >
                <v-icon
                  v-show="resultsMode == 'map'"
                  start
                >
                  west
                </v-icon>
                <span>
                  {{ $t(resultsMode == 'map' ? 'List view' : 'Map view') }}
                </span>
                <v-icon
                  v-show="resultsMode == 'list'"
                  end
                >
                  east
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <template v-if="search">
            <div v-show="resultsMode == 'map'">
              <div class="w-100pc ta-right focus-very-visible va-top h-400">
                <GoogleMap
                  ref="mapRef"
                  :center="map_center"
                  :options="{
                    draggable: true,
                    zoomControl: true,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: false,
                    disableDefaultUi: true,
                  }"
                  :zoom="zoom"
                  style="width: 100%; height: 100%"
                >
                  <MatchMarker
                    v-for="match in matches"
                    @click="selectedMatch = match"
                    :key="match.id"
                    :match="match"
                    :selected="match == selectedMatch"
                    data-cy="match-marker"
                  />
                </GoogleMap>
              </div>
              <div v-if="selectedMatch">
                <SimpleProviderCard
                  @open="openProvider(selectedMatch.id)"
                  @toggle="toggleSelection(selectedMatch, $event)"
                  :key="selectedMatch.id"
                  :active="subsidy.provider_ids.includes(selectedMatch.id)"
                  :provider="selectedMatch"
                  :selectable="belowSelectionLimit"
                />
              </div>
            </div>

            <div v-show="resultsMode == 'list'">
              <SimpleProviderCard
                v-for="provider in matches"
                @open="openProvider(provider.id)"
                @toggle="toggleSelection(provider, $event)"
                :key="provider.id"
                :active="subsidy.provider_ids.includes(provider.id)"
                :provider="provider"
                :selectable="belowSelectionLimit"
                class="my-4"
              />
            </div>
          </template>

          <template v-if="matches.length == 0">
            <p
              class="fs-14 mt-2"
              data-cy="results-hint"
            >
              <span class="me-1">
                {{ $t('Search by your address to see results near you.') }}
              </span>
            </p>
          </template>
          <template v-if="!subsidy.provider_ids?.length > 0">
            <template v-if="previousSelections">
              <v-btn
                @click="copyPreferences"
                color="primary"
              >
                <v-icon class="me-2"> redo </v-icon>
                <span>{{ $t('Copy selections from:') + ' ' + previousChild.name }}</span>
              </v-btn>
            </template>
          </template>

          <v-card
            v-show="!processing && pages > 1"
            class="bottom-0 elevation-0 bg-super-light-blue w-100pc pb-2 pt-1"
            tile
          >
            <v-pagination
              @update:model-value="changePage"
              :length="pages"
              :model-value="page"
              :total-visible="8"
              class="custom"
              rounded
            />
          </v-card>
        </v-window-item>

        <v-window-item>
          <SimpleProviderCard
            v-for="provider in matches"
            @open="openProvider(provider.id)"
            @toggle="toggleSelection(provider, $event)"
            :key="provider.id"
            :active="subsidy.provider_ids.includes(provider.id)"
            :provider="provider"
            :selectable="belowSelectionLimit"
            class="my-4"
          />

          <p
            v-if="matches.length == 0"
            class="fs-14 mt-2"
            data-cy="results-hint"
          >
            {{ $t('Search by the name of a provider to see results.') }}
          </p>

          <template v-if="searched && !processing && matches.length == 0">
            <p
              class="fs-16 c-black pt-8 px-2"
              data-cy="no-results-hint"
            >
              {{ $t('No results found') }}
            </p>
          </template>
        </v-window-item>
      </v-window>
    </div>

    <ContentDialog
      ref="providerDialog"
      :max-width="800"
    >
      <template #content="{ data }">
        <ProviderMatch
          v-if="data"
          :match="data"
          :outlined="false"
          :schema="$store.state.schemas[subsidyProgram.provider_schema_id]"
        />
      </template>
    </ContentDialog>
  </v-card>
</template>

<script>
import { GoogleMap } from 'vue3-google-map';
import Api from '@/shared/services/bright_finder';
import ContentDialog from '@/shared/components/ContentDialog.vue';
import FavoriteCard from '@/parent/components/favorites/FavoriteCard.vue';
import FilterMenu from '@/shared/components/form/FilterMenu.vue';
import { Coord } from '@/shared/services/coord';
import LabeledOpenClose from '@/shared/components/form/LabeledOpenClose.vue';
import LabeledVolume from '@/shared/components/form/LabeledVolume.vue';
import MatchMarker from '@/shared/components/map/MatchMarker.vue';
import ProviderMatch from '@/shared/components/search/ProviderMatch.vue';
import RadioWithContext from '@/shared/components/form/RadioWithContext.vue';
import SearchMoreFilters from '@/shared/components/search/more_filters.vue';
import SimpleProviderCard from '@/shared/components/SimpleProviderCard.vue';
import { capitalize } from '@/plugins/filters';
import { scrollTo } from 'vuetify/lib/composables/goto';

export default {
  compatConfig: { MODE: 3 },

  components: {
    ContentDialog,
    GoogleMap,
    FavoriteCard,
    FilterMenu,
    LabeledOpenClose,
    LabeledVolume,
    MatchMarker,
    ProviderMatch,
    RadioWithContext,
    SearchMoreFilters,
    SimpleProviderCard,
  },

  props: {
    allowOrder: {
      type: Boolean,
      default: false,
    },
    childData: {
      type: Object,
      default: null,
    },
    defaultQuery: {
      type: String,
      default: null,
    },
    initialMode: {
      type: String,
      default: null,
    },
    previousChild: {
      type: Object,
      default: null,
    },
    previousSubsidy: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    subsidy: {
      type: Object,
      default: null,
    },
    subsidies: {
      type: Array,
      default: null,
    },
    subsidyProgram: {
      type: Object,
      default: null,
    },
  },

  setup() {
    return { capitalize };
  },

  data() {
    return {
      age_groups: this.getDefaultAgeGroups(),
      children: [],
      filters: {
        categories: [],
        custom: {},
        dropoff: null,
        highlights: {},
        minimum_quality_rating: null,
        payment_subsidies_accepted: [],
        pickup: null,
        program_season: null,
        program_types: [],
        schema_id: this.subsidyProgram.provider_schema_id,
        vacancy: null,
        week_days: [],
      },
      hoursRange: [900, 1600],
      map: null,
      mode: this.initialMode || 'search',
      moreFiltersDialogIsVisible: false,
      page: 1,
      pages: null,
      processing: false,
      matches: [],
      resultsMode: 'list',
      searchCounter: 0,
      search: null,
      searched: false,
      searchQuery: this.defaultQuery,
      selectedMatch: null,
      tab: 0,
      unselectedFavoritesIsVisible: false,
      zoom: 7,
    };
  },

  computed: {
    belowSelectionLimit() {
      if (this.subsidyProgram.selection_limit === 0) {
        return true;
      }
      return this.subsidyProgram.selection_limit > this.subsidy.provider_ids.length;
    },

    map_center() {
      if (this.search && this.search.center) {
        return new Coord(this.search.center);
      }
      return {};
    },

    map_origin() {
      if (this.search && this.search.origin && this.search.origin.latitude) {
        return new Coord(this.search.origin);
      }
      return null;
    },

    moreFiltersActive() {
      return (
        this.filters.categories.length > 0 ||
        Object.values(this.filters.highlights).some((item) => item.length > 0) ||
        Object.values(this.filters.custom).some((item) => item && item.length > 0) ||
        this.filters.minimum_quality_rating ||
        this.filters.payment_subsidies_accepted.length > 0 ||
        this.filters.program_season ||
        this.filters.program_types.length > 0 ||
        !!this.filters.subsidy
      );
    },

    moreFiltersButtonClass() {
      let c = ['me-2'];
      if (this.moreFiltersActive) {
        c = c.concat(['bg-white', 'bc-primary', 'b-1']);
      } else {
        c = c.concat(['bg-white', 'bc-very-light-gray']);
      }
      return c.join(' ');
    },

    previousSelections() {
      if (this.previousChild && this.previousSubsidy) {
        if (this.previousSubsidy.provider_ids.length > 0) {
          return this.previousSubsidy.provider_ids;
        }
        return null;
      }
      return null;
    },

    selectedCount() {
      return this.subsidy.provider_ids.length;
    },
  },

  watch: {
    tab() {
      this.matches = [];
      this.processing = false;
      this.searched = false;
      this.searchQuery = null;
      this.search = null;
    },

    resultsMode(newVal) {
      if (newVal === 'map') {
        setTimeout(() => {
          this.fitMap();
        }, 100);
      }
    },
  },

  created() {
    // eslint-disable-next-line no-undef
    window.vueGoogleMapsInit?.(google);
  },

  methods: {
    async changePage(newPage) {
      this.page = newPage;
      if (this.resultsMode === 'list') {
        await scrollTo(0);
      }
      this.loadMatches();
    },

    clearHours() {
      this.hoursRange = [900, 1600];
      this.filters.dropoff = null;
      this.filters.pickup = null;
      this.filters.week_days = [];
    },

    clearMoreFilters() {
      this.clearHours();
      this.filters.categories = [];
      this.filters.custom = {};
      this.filters.highlights = {};
      this.filters.minimum_quality_rating = null;
      this.filters.payment_subsidies_accepted = [];
      this.filters.program_season = null;
      this.filters.program_types = [];
      this.moreFiltersDialogIsVisible = false;
      this.searchProviders();
    },

    copyPreferences() {
      // eslint-disable-next-line vue/no-mutating-props
      this.subsidy.provider_ids = JSON.parse(JSON.stringify(this.previousSelections));
      this.mode = 'selected';
    },

    getDefaultAgeGroups() {
      if (this.$store.state.pages.Search.default_age_group.enabled) {
        const group = this.$store.state.pages.Search.default_age_group;
        return [
          {
            count: 1,
            group: group.name,
            max: group.max,
            min: group.min,
            title: group.name,
          },
        ];
      }
      return JSON.parse(JSON.stringify(this.$store.state.ageGroups.filter((ag) => !ag.hidden))).map(
        (age) => Object.assign(age, { count: 0, group: age.name }),
      );
    },

    fitMap() {
      if (this.matches.length <= 1) {
        return;
      }

      // eslint-disable-next-line no-undef
      const bounds = new google.maps.LatLngBounds();
      if (this.map_origin) {
        bounds.extend(this.map_origin);
      }

      for (let index = 0; index < this.matches.length; index += 1) {
        bounds.extend(new Coord(this.matches[index]));
      }
    },

    loadMatches() {
      Api.match.index(
        this.search.id,
        { page: this.page, subsidy_program_id: this.subsidyProgram.id },
        (searchResp) => {
          this.processing = false;
          this.matches = searchResp.data;
          this.pages = parseInt(searchResp.headers['x-page-count'] || '0', 10);

          const vm = this;
          if (!this.map) {
            vm.$refs.mapRef?.$mapPromise?.then((map) => {
              vm.map = map;
            });
          }

          if (this.resultsMode === 'map') {
            this.fitMap();
          }
        },
      );
    },

    modifyCount(index, addition) {
      // eslint-disable-next-line operator-assignment
      this.age_groups[index].count = this.age_groups[index].count + addition;
    },

    reorderProviders(fromIndex, toIndex) {
      const element = this.subsidy.provider_ids[fromIndex];
      // eslint-disable-next-line vue/no-mutating-props
      this.subsidy.provider_ids.splice(fromIndex, 1);
      // eslint-disable-next-line vue/no-mutating-props
      this.subsidy.provider_ids.splice(toIndex, 0, element);
    },

    monthsAgo(dateFrom) {
      const dateTo = new Date();
      return (
        dateTo.getMonth() -
        dateFrom.getMonth() +
        12 * (dateTo.getFullYear() - dateFrom.getFullYear())
      );
    },

    openProvider(providerId) {
      Api.public_api.provider.get(providerId, (resp) => {
        this.$refs.providerDialog.open(resp.data);
      });
    },

    searchProviders() {
      this.processing = true;
      this.searchCounter += 1;
      const counter = this.searchCounter;
      this.moreFiltersDialogIsVisible = false;

      if (!this.searchQuery) {
        this.matches = [];
        this.processing = false;
      } else if (this.tab === 0) {
        const defaultAgeGroup = this.$store.state.pages.Search?.default_age_group;
        if (defaultAgeGroup?.enabled) {
          this.children = [
            {
              count: 1,
              group: defaultAgeGroup.name,
              max: defaultAgeGroup.max,
              min: defaultAgeGroup.min,
              title: defaultAgeGroup.name,
              included: true,
            },
          ];
        } else {
          if (this.children.length === 0) {
            const months = this.monthsAgo(new Date(this.childData.dob));
            const ageGroup = this.age_groups.find((ag) => ag.min <= months && ag.max >= months);
            if (ageGroup) {
              ageGroup.count = 1;
            }
          }
          this.children = this.age_groups.filter((ag) => ag.count > 0);
        }

        if (this.filters.week_days.length > 0) {
          [this.filters.dropoff, this.filters.pickup] = this.hoursRange;
        } else {
          this.filters.dropoff = null;
          this.filters.pickup = null;
        }

        this.searched = true;
        Api.search.create(
          {
            categories: this.filters.categories,
            children: this.children,
            custom: this.filters.custom,
            dropoff: this.filters.dropoff,
            highlights: this.filters.highlights,
            location_type: 'home',
            minimum_quality_rating: this.filters.minimum_quality_rating,
            payment_subsidies_accepted: this.filters.payment_subsidies_accepted,
            pickup: this.filters.pickup,
            program_season: this.filters.program_season,
            program_types: this.filters.program_types,
            schema_id: this.subsidyProgram.provider_schema_id,
            unformatted_origin: this.searchQuery,
            vacancy: this.filters.vacancy,
            week_days: this.filters.week_days,
          },
          (resp) => {
            this.search = resp.data;
            this.page = 1;
            this.loadMatches();
          },
          (err) => {
            if (err.response && err.response.data && err.response.data.errors) {
              this.$eventBus.$emit('chime', err.response.data.errors[0]);
            } else {
              this.$eventBus.$emit('chime', 'Unknown error occurred');
            }
            this.processing = false;
          },
        );
      } else {
        this.searched = true;
        Api.public_api.provider.index(
          {
            query: this.searchQuery,
            schema_id: this.subsidyProgram.provider_schema_id,
            subsidy_program_id: this.subsidyProgram.id,
          },
          (resp) => {
            if (counter === this.searchCounter) {
              this.matches = resp.data;
              this.processing = false;
            }
          },
        );
      }
    },

    toggleInclusion(index, newVal) {
      if (newVal) {
        // eslint-disable-next-line vue/no-mutating-props
        this.subsidy.provider_ids.push(this.subsidy.excluded_provider_ids.splice(index, 1)[0]);
      } else {
        // eslint-disable-next-line vue/no-mutating-props
        this.subsidy.excluded_provider_ids.push(this.subsidy.provider_ids.splice(index, 1)[0]);
      }
    },

    toggleResultsMode() {
      if (this.resultsMode === 'list') {
        this.resultsMode = 'map';
      } else {
        this.resultsMode = 'list';
      }
    },

    toggleSelection(provider, newVal) {
      if (newVal) {
        if (!this.subsidy.provider_ids.includes(provider.id)) {
          // eslint-disable-next-line vue/no-mutating-props
          this.subsidy.provider_ids.push(provider.id);
          if (
            this.subsidyProgram.selection_limit > 0 &&
            this.subsidyProgram.selection_limit === this.subsidy.provider_ids.length
          ) {
            this.$eventBus.$emit(
              'chime',
              'You reached your selection limit. Deselect another option if you want to add more.',
            );
          }
        }
      } else if (this.subsidy.provider_ids.includes(provider.id)) {
        // eslint-disable-next-line vue/no-mutating-props
        this.subsidy.provider_ids.splice(
          this.subsidy.provider_ids.findIndex((id) => id === provider.id),
          1,
        );
      }
    },
  },
};
</script>
