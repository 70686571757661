<template>
  <div>
    <v-divider />

    <div class="bg-super-light-blue px-4 pt-6">
      <h4
        v-t="subsidyProgram.sibling_provider_subtitle"
        class="fs-18 mb-3"
        tabindex="0"
      />
      <v-select
        v-model="modelValue"
        v-model:menu="menu"
        ref="siblingSelect"
        :aria-label="$t('Select') + ' ' + $t(ariaLabel)"
        :items="providers"
        :label="$t(placeholderValue)"
        class="b-radius-0"
        item-title="name"
        item-value="id"
        variant="solo"
        clearable
        multiple
      >
        <template #prepend-item>
          <v-list-item
            @click="handleNAChange"
            :title="$t(LABELS.NOT_APPLICABLE)"
          >
            <template #prepend>
              <v-checkbox
                :model-value="na"
                class="mt-0 pt-0"
                color="primary"
                density="compact"
                false-icon="check_box_outline_blank"
                true-icon="check_box"
                hide-details
              />
            </template>
          </v-list-item>

          <v-divider class="mt-2" />
        </template>
      </v-select>
    </div>

    <v-divider />

    <v-row class="my-4 px-4">
      <v-col>
        <v-btn
          @click="$emit('back')"
          class="focus-very-visible"
          color="primary"
          size="x-large"
          variant="outlined"
        >
          <v-icon class="mr-2"> west </v-icon>
          <span v-t="'Previous section'" />
        </v-btn>
      </v-col>

      <template v-if="showNext">
        <v-col class="d-flex justify-end">
          <v-btn
            @click="$emit('next')"
            :disabled="!valid"
            class="focus-very-visible"
            color="primary"
            size="x-large"
            tabindex="0"
          >
            <span v-t="'Continue'" />
            <v-icon class="ms-2"> east </v-icon>
          </v-btn>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script setup>
import { LABELS } from '@/shared/assets/constants';

const props = defineProps({
  providers: {
    type: Array,
    default: null,
  },
  na: Boolean,
  showNext: Boolean,
  subsidyProgram: {
    type: Object,
    default: null,
  },
  valid: Boolean,
});

const menu = ref(false);

const emit = defineEmits(['back', 'change', 'na', 'next']);

const modelValue = defineModel({ default: [] });

const ariaLabel = computed(() => {
  const listProviders = props.providers.filter((provider) =>
    modelValue.value.includes(provider.id),
  );

  if (listProviders) return listProviders.map((lp) => lp.name).join(', ');

  return 'There are no providers; please contact an admin';
});

const placeholderValue = computed(() => {
  return props.na === true ? LABELS.NOT_APPLICABLE : 'Selection required';
});

function handleNAChange() {
  emit('na');
  menu.value = false;
}
</script>

<style>
.mr-2 {
  margin-right: 8px;
}
</style>
