<template>
  <v-container class="pt-10">
    <v-progress-linear indeterminate />
  </v-container>
</template>

<script>
import API from '@/shared/mixins/api';

export default {
  compatConfig: { MODE: 2 },

  mixins: [API],

  data() {
    return {
      familySubsidyProgram: null,
    };
  },

  async created() {
    await this.loadFamilySubsidyProgram();
    if (!this.familySubsidyProgram?.published_at && !this.isInTestMode()) {
      this.$router.replace({ path: '/' });
      return;
    }

    this.$store.dispatch('identify', {
      api: this.api,
      success: this.identifySuccess,
      failure: this.identifyFailure,
    });
  },

  methods: {
    identifyFailure() {
      const redirect = {
        name: 'FamilySubsidyNew',
        params: { familySubsidyProgramId: this.getFamilySubsidyProgramId() },
      };
      window.localStorage.setItem('redirect', JSON.stringify(redirect));
      const mode = this.$store.state.site.features.enable_registration ? 'signup' : 'login';
      this.$router.replace({ name: 'Signup', query: { mode } });
    },

    async identifySuccess() {
      if (this.$store.state.is_anonymous) {
        this.identifyFailure();
        return;
      }

      const { data } = await this.api.parent.family_subsidy.index({
        family_subsidy_program_id: this.getFamilySubsidyProgramId(),
      });
      const inProgressSubsidy = data.find((familySubsidy) => !familySubsidy.submitted_at);
      if (inProgressSubsidy) {
        this.routeToFamilySubsidy(inProgressSubsidy);
        return;
      }

      await this.startNewSubsidy();
    },

    isInTestMode() {
      return this.$route.query.test === 'true';
    },

    getFamilySubsidyProgramId() {
      return this.$route.params.familySubsidyProgramId;
    },

    async loadFamilySubsidyProgram() {
      const resp = await this.api.public_api.organization.family_subsidy_program.get(
        this.getFamilySubsidyProgramId(),
      );
      this.familySubsidyProgram = resp.data;
    },

    routeToFamilySubsidy(subsidy) {
      this.$router.replace({ name: 'FamilySubsidyContinue', params: { id: subsidy.id } });
    },

    async startNewSubsidy() {
      const resp = await this.api.parent.family_subsidy
        .create({ family_subsidy_program_id: this.getFamilySubsidyProgramId() })
        .catch((error) => {
          if (error.response?.data?.errors[0] === 'Family subsidy program has already been taken') {
            this.$eventBus.$emit('error', {
              response: { data: { errors: [this.$t('Application already submitted')] } },
            });
          } else {
            this.$eventBus.$emit('error', error);
          }
          this.$router.replace({ name: 'Dashboard' });
        });
      if (!resp?.data) return;

      this.routeToFamilySubsidy(resp.data);
    },
  },
};
</script>
