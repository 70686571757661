<template>
  <v-card
    :style="cardStyle"
    class="b-1 b-radius-8 mb-4"
    data-testid="dashboard-application-summary"
  >
    <v-card-title>
      <v-row>
        <v-col
          v-if="!isUnactionable && !isActionReview"
          class="d-flex align-center"
          cols="auto"
        >
          <v-avatar
            color="#F79009"
            size="40"
            variant="tonal"
          >
            <v-icon
              color="#F79009"
              icon="priority_high"
              size="20"
            />
          </v-avatar>
        </v-col>

        <v-col
          class="d-flex align-center"
          cols="auto"
        >
          <h3 class="fs-20 text-wrap">
            {{ $t(title) }}
          </h3>
        </v-col>

        <v-spacer />

        <v-col
          v-if="isExternal"
          class="d-flex align-center"
          cols="auto"
          data-testid="status-pill"
        >
          <v-chip
            color="primary"
            variant="tonal"
          >
            {{ $t(item.status) }}
          </v-chip>
        </v-col>
      </v-row>

      <h4 class="fs-14 c-light-black fw-400">
        <LongDateTime
          :date="timestamp"
          :prefix="timestampText"
        />

        <div v-if="isExternal">
          <span v-if="props.item.external_application_id">
            {{ $t('Application ID') }}: {{ props.item.user_facing_application_id }}
          </span>

          <span v-if="props.item.external_application_id && props.item.external_case_id">
            &nbsp;|&nbsp;
          </span>

          <span v-if="props.item.external_case_id">
            {{ $t('Case ID') }}: {{ props.item.user_facing_case_id }}
          </span>
        </div>
      </h4>

      <div class="my-1" />

      <div
        v-if="loaded && item.application_type === 'Subsidy'"
        data-testid="eligibility-section"
      >
        <template v-if="isEligibleForFunding">
          <span class="d-flex align-center fs-16 font-weight-bold">
            <v-icon
              class="mr-2"
              color="success"
              icon="check_circle"
              size="20"
            />
            {{ $t('Eligible') }}
          </span>
          <template v-if="shouldShowFundingSources">
            <template
              v-for="fundingSource in fundingSources"
              :key="fundingSource.id"
            >
              <span
                v-if="!!fundingSource.family_dashboard_name"
                class="d-flex align-center fs-16 font-weight-regular"
                data-testid="funding-source-name"
              >
                <v-icon
                  class="mr-2"
                  color="success"
                  icon="none"
                  size="20"
                />
                {{ fundingSource.family_dashboard_name }}
              </span>
            </template>
          </template>
        </template>
        <template v-else>
          <span class="d-flex align-center fs-16 font-weight-bold">
            <v-icon
              class="mr-2"
              color="error"
              icon="cancel"
              size="20"
            />
            {{ $t('Not Eligible') }}
          </span>
        </template>
      </div>

      <v-alert
        v-if="item.status_help_text"
        :text="$t(item.status_help_text)"
        border-color="primary"
        class="mb-0 fs-16 fw-500 wrap-text"
        color="primary"
        type="info"
        variant="tonal"
        border
      />

      <div v-if="!!item.home_county">
        <span class="fs-14 c-black fw-800"> {{ $t('County processing application') }}: </span>

        <span class="fs-14 c-black fw-400">
          {{ item.home_county }}
        </span>
      </div>

      <div v-if="!!item.redetermination_date">
        <span class="fs-14 c-black fw-800"> {{ $t('Redetermination date') }}: </span>

        <span class="fs-14 c-black fw-400">
          {{ item.redetermination_date }}
        </span>
      </div>
    </v-card-title>

    <v-divider />

    <v-card-actions>
      <v-spacer />

      <v-btn
        v-if="!isUnactionable"
        :to="link"
        :variant="buttonVariant"
        class="fs-16"
        color="primary"
        role="link"
      >
        <template
          v-if="!isActionReview"
          #prepend
        >
          <v-avatar
            color="#FEDF89"
            data-testid="dashboard-application-summary-action-icon"
            size="20"
          >
            <v-icon
              color="#F79009"
              icon="priority_high"
            />
          </v-avatar>
        </template>

        {{ $t(buttonText) }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import Api from '@/shared/services/all_bright_finder';
import { getNextStepLink } from '@/parent/services/dashboard';
import LongDateTime from '@/shared/components/LongDateTime.vue';
import { onMounted } from 'vue';

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

const fundingSources = ref([]);
const loaded = ref(false);

const buttonText = computed(() => {
  return props.item.action;
});

const buttonVariant = computed(() => {
  return isActionReview.value ? 'outlined' : 'elevated';
});

const cardStyle = computed(() => {
  if (isUnactionable.value || isActionReview.value) return;

  return 'border-color: #F79009;';
});

const isActionReview = computed(() => {
  if (isUnactionable.value) return false;

  return props.item.action === 'Review';
});

const isChild = computed(() => {
  return !!props.item.child_id;
});

const isEligibleForFunding = computed(() => {
  return fundingSources.value.length > 0;
});

const isExternal = computed(() => {
  return !!props.item.external_application_id || !!props.item.external_case_id;
});

const isUnactionable = computed(() => {
  return props.item.action === null;
});

const link = computed(() => getNextStepLink(props.item));

const shouldShowFundingSources = computed(() => {
  return fundingSources.value.some((fs) => !!fs.family_dashboard_name);
});

const timestamp = computed(() => {
  return props.item.submitted_at || props.item.created_at;
});

const timestampText = computed(() => {
  return props.item.submitted_at ? 'Submitted' : 'Created';
});

const title = computed(() => {
  const parts = [props.item.program_name];
  if (isChild.value) parts.push(props.item.child_name);

  return parts.join(' | ');
});

async function loadFundingSources() {
  const resp = await Api.parent.subsidy.get(props.item.id);

  if (resp.data) {
    const allfundingSources = resp.data.meta.funding_sources;
    fundingSources.value = allfundingSources.filter((fs) =>
      fs.criteria_results.every((cr) => cr.passes),
    );
  }
}

onMounted(async () => {
  if (props.item.application_type === 'Subsidy') {
    await loadFundingSources();
    loaded.value = true;
  }
});
</script>
