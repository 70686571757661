<template>
  <div
    v-if="shouldRender"
    class="mt-12"
  >
    <h2 class="fs-24 mb-4">
      {{ $t('To do') }}
    </h2>

    <DashboardTask
      v-for="item in props.items"
      :key="item.id"
      :item="item"
    />
  </div>
</template>

<script setup>
import DashboardTask from '@/parent/components/dashboards/DashboardTask.vue';

const props = defineProps({
  items: {
    type: Array,
    default: () => [],
  },
});

const shouldRender = computed(() => {
  return props.items.length > 0;
});
</script>
