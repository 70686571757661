<template>
  <div>
    <LabeledVolume
      v-for="(item, index) in value"
      @decrease="modifyCount(index, -1)"
      @increase="modifyCount(index, 1)"
      :key="index"
      ref="volume"
      :count="item.count"
      :dense="value.length > 6"
      :subtitle="item.subtitle || item.subtext"
      :title="item.title || item.text"
    />
  </div>
</template>

<script>
import LabeledVolume from '@/shared/components/form/LabeledVolume.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    LabeledVolume,
  },

  props: {
    value: {
      type: Array,
      default: null,
    },
    open: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['change'],

  computed: {
    sum() {
      let sum = 0;
      this.value.forEach((item) => {
        sum += item.count;
      });
      return sum;
    },
  },

  methods: {
    focus() {
      this.$nextTick(() => {
        this.$refs.volume[0].focus();
      });
    },

    modifyCount(index, addition) {
      const updatedValue = [...this.value];
      updatedValue[index].count += addition;
      this.$emit('change', updatedValue);
    },
  },
};
</script>
