export default class SearchFlags {
  constructor(store) {
    this.state = store.state || {};
    this.searchPage = this.state?.pages.Search || {};
  }

  get ageGroups() {
    if (this.defaultAgeGroupEnabled) {
      const ageGroup = this.defaultAgeGroup;
      return [
        {
          count: 1,
          group: ageGroup.name,
          max: ageGroup.max,
          min: ageGroup.min,
          title: ageGroup.name,
        },
      ];
    }

    const viewableAgeGroups = JSON.parse(
      JSON.stringify(this.state.ageGroups.filter((ageGroup) => !ageGroup.hidden)),
    );

    return viewableAgeGroups.map((ageGroup) =>
      Object.assign(ageGroup, { count: 0, group: ageGroup.name }),
    );
  }

  get dobSearchEnabled() {
    return this.searchPage.enable_dob_search || false;
  }

  get defaultAgeGroupEnabled() {
    return this.searchPage.default_age_group?.enabled || false;
  }

  get defaultAgeGroup() {
    if (!this.defaultAgeGroupEnabled) return {};

    return this.searchPage.default_age_group || {};
  }

  get moreFiltersEnabled() {
    return this.searchPage.features?.enable_more_filters || false;
  }

  get regionSearchEnabled() {
    return this.searchPage.enable_region_search || false;
  }

  get transportationOptionsEnabled() {
    // Known spelling mistake on transportation
    return this.searchPage.enable_tranportation_options || false;
  }

  get closedFilterEnabled() {
    return this.searchPage.features?.enable_closed_filter || false;
  }
}
