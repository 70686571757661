<script setup>
import AttachmentList from '@/shared/components/attachments/AttachmentList.vue';
import ChangeResponseButton from '@/shared/components/form/ChangeResponseButton.vue';
import FormQuestion from '@/shared/components/form/FormQuestion.vue';
import HouseholdMemberCard from '@/shared/components/household-members/HouseholdMemberCard.vue';
import QuestionSet from '@/shared/components/form/QuestionSet.vue';
import SubsidyEligibilityCard from '@/shared/components/subsidy/SubsidyEligibilityCard.vue';
import UserAttestation from '@/shared/components/UserAttestation.vue';
import ThirdPartyQuestions from '@/parent/components/family_subsidy/ThirdPartyQuestions.vue';
import sections from '@/parent/services/family-subsidy-sections';

const emit = defineEmits(['back', 'change:attachments', 'finish', 'jump']);

const props = defineProps({
  attachments: {
    type: Array,
    required: true,
  },
  enableEsign: {
    type: Boolean,
    default: true,
  },
  familySubsidyAttachments: {
    type: Array,
    default: () => [],
  },
  familySubsidyProgram: {
    type: Object,
    required: true,
  },
  householdMemberQuestionRegister: {
    type: Object,
    required: true,
  },
  householdMemberSchema: {
    type: Object,
    required: true,
  },
  householdMembers: {
    type: Array,
    required: true,
  },
  processing: {
    type: Boolean,
    default: false,
  },
  schema: {
    type: Object,
    required: true,
  },
  subsidy: {
    type: Object,
    required: true,
  },
  validEligibilityQuestions: {
    type: Array,
    required: true,
  },
  validOtherQuestions: {
    type: Array,
    required: true,
  },
  validVerificationQuestions: {
    type: Array,
    required: true,
  },
});

const confirmed = ref(false);
const modelValue = ref(props.subsidy);
const attestation = ref(null);

function handleFinished() {
  const attestationParams = {
    revision_author_signed_name: attestation.value.esignName,
    revision_author_signature_image: attestation.value.getEsignSignature(),
    revision_description: attestation.value.getAttestationText(),
  };

  emit('finish', attestationParams);
}
</script>

<template>
  <FormQuestion
    @back="$emit('back')"
    @finish="handleFinished"
    :finish-disabled="!confirmed"
    :forwardable="false"
    :next-disabled="!confirmed"
    :processing="processing"
    :title="'Confirm your application information'"
    header="Last step"
    finishable
  >
    <SubsidyEligibilityCard
      :elevation="2"
      :funding-sources="subsidy.eligibility_determinations"
      :projected-eligibility="subsidy.projected_eligibility"
      class="focus-very-visible mb-6"
      tabindex="0"
      expanded
      explanation
      outlined
    />

    <QuestionSet
      v-model="modelValue"
      @change:attachments="$emit('change:attachments')"
      @jump="$emit('jump', $event)"
      :attachment-group-id="subsidy.group_id"
      :attachment-owner-id="subsidy.id"
      :attachment-owner-type="'FamilySubsidy'"
      :attachments="familySubsidyAttachments"
      :color="null"
      :key-name="sections.eligibilityQuestions"
      :processing="processing"
      :questions="validEligibilityQuestions"
      :schema="schema.definition"
      mode="review"
      condensed
      dense
      divided
      expanded
      hide-actions
      readonly
      tile
    />

    <template v-if="familySubsidyProgram.enable_third_party_applications">
      <ThirdPartyQuestions
        :key="sections.thirdPartyQuestions"
        :model-value="modelValue"
        :processing="processing"
        condensed
        dense
        readonly
      />

      <ChangeResponseButton
        @jump="$emit('jump', $event)"
        :section="sections.thirdPartyQuestions"
      />

      <v-divider class="my-8" />
    </template>

    <QuestionSet
      v-model="modelValue"
      @change:attachments="$emit('change:attachments')"
      @jump="$emit('jump', $event)"
      :attachment-group-id="subsidy.group_id"
      :attachment-owner-id="subsidy.id"
      :attachment-owner-type="'FamilySubsidy'"
      :attachments="familySubsidyAttachments"
      :color="null"
      :key-name="sections.otherQuestions"
      :processing="processing"
      :questions="validOtherQuestions"
      :schema="schema.definition"
      mode="review"
      condensed
      dense
      divided
      expanded
      hide-actions
      readonly
      tile
    />

    <div
      v-for="(question, index) in validVerificationQuestions"
      :key="question.id"
      class="mb-6"
    >
      <FormQuestion
        :color="null"
        :subtitle="question.verification_subtitle"
        :title="question.verification_title"
        condensed
        dense
        divided
        hide-actions
        tile
      >
        <AttachmentList
          @change:attachments="$emit('change:attachments')"
          @delete="$emit('change:attachments')"
          :attachments="attachments.filter((attachment) => attachment.tag == question.id)"
          class="mb-6"
          empty-label="No attachments added"
        />

        <ChangeResponseButton
          @jump="$emit('jump', $event)"
          :section="`${sections.verificationQuestions}-${index}`"
        />
      </FormQuestion>
    </div>

    <div
      v-for="(householdMember, householdMemberIndex) in householdMembers"
      :key="sections.householdMember + householdMember.id"
    >
      <FormQuestion
        :key="sections.manageHouseholdMembers"
        :color="null"
        :title="`${householdMember.first_name} ${householdMember.last_name}`"
        condensed
        dense
        divided
        hide-actions
        tile
      >
        <HouseholdMemberCard
          :outlined="false"
          :value="householdMember"
          included-check
          locked
        />

        <ChangeResponseButton
          @jump="$emit('jump', $event)"
          :section="sections.manageHouseholdMembers"
        />
      </FormQuestion>

      <QuestionSet
        @jump="$emit('jump', $event)"
        :attachment-group-id="subsidy.group_id"
        :attachment-owner-id="subsidy.id"
        :attachment-owner-type="'FamilySubsidy'"
        :attachment-tags-supplements="[householdMember.id]"
        :attachments="familySubsidyAttachments"
        :color="null"
        :header="`${householdMember.first_name} ${householdMember.last_name}`"
        :key-name="sections.householdMember + householdMemberIndex.toString()"
        :model-value="householdMember"
        :processing="processing"
        :questions="
          householdMemberQuestionRegister[householdMember.id].filter((question) => question.valid)
        "
        :schema="householdMemberSchema.definition"
        class="mb-6"
        mode="review"
        condensed
        dense
        divided
        expanded
        hide-actions
        readonly
        tile
      />
    </div>

    <UserAttestation
      @change="confirmed = $event"
      ref="attestation"
      :attestation-text="familySubsidyProgram.attestation_text"
      :enable-esign="enableEsign"
    />
  </FormQuestion>
</template>
