/**
 * Stepper composable to manage the progress of the user through a Subsidy application.
 *
 * @param applicantChildren,
 * @param applicantEligibilityQuestions,
 * @param applicantQuestions,
 * @param applicantVerificationQuestions,
 * @param backFromGroupQuestion,
 * @param backFromGroupVerificationQuestion,
 * @param calculateStepTotal,
 * @param childEligibilityQuestions,
 * @param childVerificationQuestions,
 * @param eligibleChildren,
 * @param forwardFromFavoriteQuestion,
 * @param forwardFromEligibility,
 * @param loadChildren,
 * @param loadSubsidies,
 * @param processing,
 * @param route,
 * @param saveChild,
 * @param saveChildren,
 * @param saveGroup,
 * @param subsidyProgram,
 * @param updateQuery,
 * @param createOrDestroySubsidies,
 * @param validate,
 * @param validGroupEligibilityQuestions,
 *
 * @returns {{
 * backFromChildren,
 * backFromChildDocuments,
 * backFromChildEligibilityQuestion,
 * backFromChildQuestion,
 * backFromChildVerificationQuestion,
 * forwardFromChildEligibilityQuestion,
 * forwardFromChildQuestion,
 * forwardFromChildDocuments,
 * forwardFromChildren,
 * forwardFromChildVerificationQuestion,
 * progress,
 * section,
 * step,
 * stepBack,
 * stepForward,
 * stepCount,
 * stepTotal,
 * transitionName,
 * }}
 *
 */
export default function useSubsidyStepper({
  applicantChildren,
  applicantEligibilityQuestions,
  applicantQuestions,
  applicantVerificationQuestions,
  backFromGroupQuestion,
  backFromGroupVerificationQuestion,
  calculateStepTotal,
  childEligibilityQuestions,
  childVerificationQuestions,
  eligibleChildren,
  forwardFromFavoriteQuestion,
  forwardFromEligibility,
  loadChildren,
  loadSubsidies,
  processing,
  route,
  saveChild,
  saveChildren,
  saveGroup,
  subsidyProgram,
  updateQuery,
  createOrDestroySubsidies,
  validate,
  validGroupEligibilityQuestions,
}) {
  const progress = ref(2);
  const stepCount = ref(1);
  const stepTotal = ref(50);
  const section = ref(null);
  const transitionName = ref('slide-left');

  async function backFromChildren() {
    processing.value = true;
    await saveChildren();
    stepBack('group');
  }

  async function backFromChildDocuments({ childIndex }) {
    processing.value = true;
    if (subsidyProgram.value.verify_child && childIndex > 0) {
      stepBack(`child-documents-${childIndex - 1}`);
    } else {
      const lastChild = eligibleChildren.value[eligibleChildren.value.length - 1];
      backFromChildVerificationQuestion({
        childIndex: eligibleChildren.value.length - 1,
        questionIndex: applicantVerificationQuestions.value[lastChild.id]?.length,
      });
    }
  }

  async function backFromChildEligibilityQuestion({ questionIndex, childIndex }) {
    processing.value = true;
    const child = applicantChildren.value[childIndex];
    await saveChild(child);
    await validate();

    const hasMoreChildrenToStepThrough = childIndex - 1 >= 0;
    const hasMoreQuestionsToStepThrough = questionIndex - 1 >= 0;
    if (hasMoreQuestionsToStepThrough)
      return stepBack(`ChildEligibility${childIndex}-${questionIndex - 1}`);
    if (!hasMoreChildrenToStepThrough && validGroupEligibilityQuestions.value?.length > 0)
      return stepBack(`GroupEligibility-${validGroupEligibilityQuestions.value.length - 1}`);
    if (!hasMoreChildrenToStepThrough && validGroupEligibilityQuestions.value?.length === 0)
      return stepBack('children');
    const previousChild = applicantChildren.value[childIndex - 1];
    const indexOfLastQuestion =
      applicantEligibilityQuestions.value[previousChild.id].filter((question) => question.valid)
        .length - 1;
    stepBack(`ChildEligibility${childIndex - 1}-${indexOfLastQuestion}`);
  }

  async function backFromChildQuestion({ childIndex = false, questionIndex }) {
    processing.value = true;
    const getValidQuestions = (eligibleChildId) =>
      applicantQuestions.value[eligibleChildId]?.filter((question) => question.valid) || [];
    const sliceIndex = childIndex === false ? eligibleChildren.value.length : childIndex;
    const previousChildIndex = eligibleChildren.value
      .map((eligibleChild) => eligibleChild.id)
      .slice(0, sliceIndex)
      .reverse()
      .findIndex((childId) => getValidQuestions(childId).length > 0);
    const getRevisedChildIndex = () => {
      if (questionIndex !== false) return childIndex;
      if (childIndex === false) return eligibleChildren.value?.length - 1;
      return childIndex - 1 - previousChildIndex;
    };
    const revisedChildIndex = getRevisedChildIndex();
    const child = eligibleChildren.value[revisedChildIndex] || {};
    const revisedQuestionIndex =
      questionIndex === false ? getValidQuestions(child.id).length : questionIndex;
    if (questionIndex === false && previousChildIndex < 0) {
      return backFromGroupQuestion(false);
    }
    await saveChild(child);
    await validate();

    if (revisedQuestionIndex - 1 >= 0) {
      stepBack(`Child${revisedChildIndex}-${revisedQuestionIndex - 1}`);
    } else if (revisedChildIndex - 1 < 0) {
      await backFromGroupQuestion(false);
    } else {
      await backFromChildQuestion({ childIndex: revisedChildIndex, questionIndex: false });
    }

    return true;
  }

  function backFromChildVerificationQuestion({ childIndex, questionIndex }) {
    processing.value = true;
    if (questionIndex - 1 >= 0) {
      stepBack(`child-verification${childIndex}-${questionIndex - 1}`);
    } else if (childIndex - 1 < 0) {
      backFromGroupVerificationQuestion(false);
    } else {
      const previousChild = eligibleChildren.value[childIndex - 1];
      backFromChildVerificationQuestion({
        childIndex: childIndex - 1,
        questionIndex: applicantVerificationQuestions.value[previousChild.id]?.length,
      });
    }
  }

  async function forwardFromChildQuestion({ childIndex, questionIndex }) {
    processing.value = true;
    const validQuestions = (child) =>
      applicantQuestions.value[child.id]?.filter((question) => question.valid) || [];
    const revisedChildIndex =
      questionIndex === false
        ? eligibleChildren.value.findIndex((child) => validQuestions(child).length > 0)
        : childIndex;
    const revisedQuestionIndex = questionIndex === false ? -1 : questionIndex;
    const child = eligibleChildren?.value[revisedChildIndex] || {};

    const noChildrenWithValidQuestions = validQuestions(child).length === 0;
    if (questionIndex === false && noChildrenWithValidQuestions)
      return await forwardFromFavoriteQuestion(false);

    await saveChild(child);
    await validate();

    const hasMoreQuestions = questionIndex + 1 < validQuestions(child).length;
    if (hasMoreQuestions || questionIndex === false)
      return stepForward(`Child${revisedChildIndex}-${revisedQuestionIndex + 1}`);

    const hasMoreChildren = revisedChildIndex + 1 < eligibleChildren.value?.length;
    if (!hasMoreChildren) {
      await forwardFromFavoriteQuestion(false);
    } else {
      await forwardFromChildQuestion({ childIndex: revisedChildIndex + 1, questionIndex: -1 });
    }
  }

  async function forwardFromChildEligibilityQuestion({ childIndex, questionIndex }) {
    processing.value = true;
    const validQuestions = (child) =>
      applicantEligibilityQuestions.value[child.id]?.filter((question) => question.valid) || [];
    const revisedChildIndex =
      questionIndex === false
        ? applicantChildren.value.findIndex((child) => validQuestions(child).length > 0)
        : childIndex;
    const child = applicantChildren.value[revisedChildIndex];
    const noMoreChildrenWithValidQuestions = revisedChildIndex < 0;

    if (
      questionIndex === false &&
      noMoreChildrenWithValidQuestions &&
      subsidyProgram.value.eligibility
    ) {
      await loadSubsidies();
      stepForward('eligibility');
      return;
    }
    if (questionIndex === false && noMoreChildrenWithValidQuestions) {
      await forwardFromEligibility();
      return;
    }
    await saveChild(child);
    await validate();

    if (questionIndex === false) {
      stepForward(`ChildEligibility${revisedChildIndex.toString()}-0`);
      return;
    }
    const hasMoreQuestions = questionIndex + 1 < validQuestions(child).length;
    if (hasMoreQuestions) {
      stepForward(`ChildEligibility${revisedChildIndex.toString()}-${questionIndex + 1}`);
      return;
    }
    const hasMoreChildren = revisedChildIndex + 1 < applicantChildren.value?.length;
    if (hasMoreChildren) {
      stepForward(`ChildEligibility${(revisedChildIndex + 1).toString()}-0`);
      return;
    }
    if (subsidyProgram.value.eligibility) {
      await loadSubsidies();
      stepForward('eligibility');
    } else {
      await forwardFromEligibility();
    }
  }

  function forwardFromChildVerificationQuestion({ questionIndex, childIndex }) {
    processing.value = true;

    if (questionIndex + 1 < childVerificationQuestions.value?.length) {
      stepForward(`child-verification${childIndex}-${questionIndex + 1}`);
      return;
    }
    if (childIndex + 1 >= eligibleChildren.value?.length) {
      forwardFromChildDocuments(-1);
    } else {
      stepForward(`child-verification${childIndex + 1}-0`);
    }
  }

  function forwardFromChildDocuments(childIndex) {
    processing.value = true;
    if (subsidyProgram.value.verify_child && childIndex + 1 < eligibleChildren.value?.length) {
      stepForward(`child-documents-${childIndex + 1}`);
    } else if (subsidyProgram.value.verify_home_address) {
      stepForward('proof-of-residency');
    } else {
      stepForward('confirm');
    }
  }

  async function forwardFromChildren() {
    processing.value = true;
    await saveChildren();
    await saveGroup();
    await loadSubsidies();
    await createOrDestroySubsidies();
    await loadChildren();
    await loadSubsidies();
    await validate();

    calculateStepTotal();

    if (validGroupEligibilityQuestions.value?.length > 0) {
      stepForward('GroupEligibility-0');
    } else if (childEligibilityQuestions.value?.length > 0) {
      stepForward('ChildEligibility0-0');
    } else if (subsidyProgram.value.eligibility) {
      stepForward('eligibility');
    } else {
      await forwardFromEligibility();
    }
  }

  function progressBackward() {
    stepCount.value -= 1;
  }

  function progressForward() {
    stepCount.value += 1;
  }

  async function step(newSection, params = {}) {
    processing.value = false;
    section.value = newSection;

    const programId = route.query?.programId;
    progress.value = (stepCount.value / stepTotal.value) * 100;
    await updateQuery(
      Object.assign(params, { section: section.value, step: stepCount.value, programId }),
    );
  }

  function stepBack(section, params) {
    progressBackward();
    transitionName.value = 'slide-right';
    // eslint-disable-next-line no-floating-promise/no-floating-promise
    step(section, params);
  }

  function stepForward(section, params) {
    progressForward();
    transitionName.value = 'slide-left';
    // eslint-disable-next-line no-floating-promise/no-floating-promise
    step(section, params);
  }

  return {
    backFromChildren,
    backFromChildDocuments,
    backFromChildEligibilityQuestion,
    backFromChildQuestion,
    backFromChildVerificationQuestion,
    forwardFromChildEligibilityQuestion,
    forwardFromChildQuestion,
    forwardFromChildDocuments,
    forwardFromChildren,
    forwardFromChildVerificationQuestion,
    progress,
    section,
    step,
    stepBack,
    stepForward,
    stepCount,
    stepTotal,
    transitionName,
  };
}
