<template>
  <v-container>
    <div class="mxw-800 mx-auto px-3 pb-16">
      <div class="mt-8 mb-3">
        <h1
          ref="title"
          v-t="titleText"
          class="fw-600 fs-32 mb-3 focus-invisible"
          data-cy="register-title"
          tabindex="0"
        />
        <template v-if="subtitle">
          <h2
            class="fs-16 fw-500"
            tabindex="0"
          >
            <span
              v-t="subtitle"
              class="me-1"
              data-cy="register-subtitle"
            />
          </h2>
        </template>
      </div>

      <div v-if="loaded">
        <template v-if="mode == 'signup'">
          <Register />
        </template>

        <template v-else-if="mode == 'login'">
          <Login />
        </template>
      </div>
    </div>
  </v-container>
</template>

<script setup>
import Register from '@/parent/components/ParentRegister.vue';
import Login from '@/parent/components/ParentLogin.vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

const loaded = ref(false);
const mode = ref(null);
const route = useRoute();
const router = useRouter();
const store = useStore();
const title = ref(null);

const subtitle = computed(() => {
  if (mode.value !== 'signup') return null;

  return (
    store.state.pages.Registration.subtitle ||
    'Create an account to apply and message your favorite providers.'
  );
});

const titleText = computed(() => {
  if (mode.value === 'signup') {
    return store.state.pages.Registration.title || 'Create your account';
  }
  return 'Login to your account';
});

watch(
  () => route.query.mode,
  (newVal) => {
    mode.value = newVal;
  },
);

watch(mode.value, (newVal) => {
  const disableSelfRegistration = store.state.disable_self_registration;
  if (newVal === 'login' || (newVal === 'signup' && !disableSelfRegistration)) return;

  router.replace({ path: '/' });
});

onMounted(load);
function load() {
  mode.value = route.query.mode || 'signup';

  store.dispatch('identify', {
    success() {
      if (store.state.is_anonymous) {
        loaded.value = true;
      } else {
        router.replace({ name: 'Dashboard' });
      }
    },
    failure() {
      loaded.value = true;
    },
  });
  title.value.focus();
}
</script>
