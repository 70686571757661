export default class ParentSearchParams {
  constructor(parent, search = null, children = []) {
    this.parent = parent;
    if (!search) return;

    this.search = search;

    this.params = Object.assign(this.parent, {
      search: {
        children,
        destination: this.search.destination,
        exclude_closed: this.search.exclude_closed,
        formatted_destination: this.search.formatted_destination,
        formatted_origin: this.search.formatted_origin,
        home: this.search.location_type === 'home' ? this.search.origin : null,
        location_type: this.search.location_type,
        origin: this.search.origin,
        schema_id: this.search.schema_id,
        travel_mode: this.search.travel_mode || 'DRIVING',
        unformatted_origin: this.search.unformatted_origin,
        work: this.search.location_type === 'work' ? this.search.origin : null,
        zip: this.search.zip,
      },
    });
  }

  asJson() {
    return this.params;
  }
}
